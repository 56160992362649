import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { FixedSizeGrid as Grid } from "react-window";
import moment from 'moment';
import * as XLSX from 'xlsx';

import LoadingSpinner from '../../components/layout/LoadingSpinner';
import AssignModal from './components/AssignModal';
import TouchPointModal from './components/TouchPointModal';
import useFetchUserByEmail from '../../components/UserInfoLoader/LoadUserInfo';
import {
  listAllCallLists,
  listCallListsByUser,
  listClientFix2s,
  listUsersByTeamOne,
} from '../../graphql/queries';
import { DELETE_CALL_LIST, CREATE_CALL_LIST } from '../../graphql/mutations';
import { capitalize } from '../../components/common/capitalize';
import './CallList.css';

const CallList = () => {
  // --------------------------------------
  // State and Refs
  // --------------------------------------
  const [selectedTab, setSelectedTab] = useState('new');
  const [selectedCall, setSelectedCall] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const [singleSelectedClient, setSingleSelectedClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [callStatusFilter, setCallStatusFilter] = useState('all');
  const [contactRoundFilter, setContactRoundFilter] = useState('all');
  const [languageFilter, setLanguageFilter] = useState('all'); // NEW: Language filter state
  const [selectedUser, setSelectedUser] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isTouchpointModalOpen, setIsTouchpointModalOpen] = useState(false);
  const [isCreatingCallList, setIsCreatingCallList] = useState(false);
  const [callLists, setCallLists] = useState([]);
  const [isCallListLoading, setIsCallListLoading] = useState(true); // NEW: Loading state for call list
  const headerRef = useRef(null);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [currentNotes, setCurrentNotes] = useState(''); // Stores the full notes content
  const [userId, setUserId] = useState(null);

  // For user info
  const [userEmail, setUserEmail] = useState(null);
  const { userInfo: userLoggedIn, loading: userLoading, error: userError } = useFetchUserByEmail(userEmail);

  // Grid settings
  const columnCount = 11; // Selection, Name, Last Name, Navigator, Call Date, Contact Round, Preferred Lang, County, Zip
  const columnWidth = 220;
  const rowHeight = 30;
  const [gridWidth, setGridWidth] = useState(window.innerWidth * 0.97);
  const [gridHeight, setGridHeight] = useState(300); // static or can be calculated as needed
  const headerHeight = 50; // Height of the header row
  const footerHeight = 55.6; // Height of the footer
  const availableHeight = useRef(window.innerHeight - headerHeight - footerHeight);

  // Recalculate grid height based on the number of filtered rows
  const calculateGridHeight = () => {
    const visibleRows = Math.min(
      filteredCallLists.length,
      Math.floor(availableHeight.current / rowHeight)
    );
    setGridHeight(visibleRows * rowHeight);
  };

  // --------------------------------------
  // Derived Values
  // --------------------------------------
  const isAdmin = userGroups?.some(group => group === 'Admin' || group === 'Manager');

  // console.log("userId", userId);

  // --------------------------------------
  // Queries and Mutations
  // --------------------------------------
  const [fetchClients] = useLazyQuery(listClientFix2s, {
    variables: { limit: 1000 },
    fetchPolicy: "network-only",
  });

  const { loading, refetch: refetchCallList } = useQuery(
    isAdmin ? listAllCallLists : listCallListsByUser,
    {
      variables: isAdmin
        ? { limit: 500 }
        : { userId: userId, limit: 500 },
      skip: !userId,
      fetchPolicy: 'network-only'
    }
  );

  const [deleteCallList] = useMutation(DELETE_CALL_LIST);
  const [createCallList] = useMutation(CREATE_CALL_LIST);

  // --------------------------------------
  // Effects
  // --------------------------------------
  useEffect(() => {
    fetchUserDetails();
  }, []);

  // 2) Once userLoggedIn is loaded, set userId (when it's available)
  useEffect(() => {
    if (userLoggedIn && userLoggedIn.id) {
      setUserId(userLoggedIn.id);
    }
  }, [userLoggedIn]);

  // Fetching the user data
  const { data: usersData, loading: usersLoading } = useQuery(listUsersByTeamOne, {
    variables: { limit: 1000 },
  });

  useEffect(() => {
    if (usersData?.listUserFixes?.items) {
      const users = [...usersData.listUserFixes.items] // Create a shallow copy
        .sort((a, b) => {
          const nameA = `${capitalize(a.first_name)} ${capitalize(a.last_name)}`.toLowerCase();
          const nameB = `${capitalize(b.first_name)} ${capitalize(b.last_name)}`.toLowerCase();
          return nameA.localeCompare(nameB);
        })
        .map((user) => `${capitalize(user.first_name)} ${capitalize(user.last_name)}`);
      setUserOptions(users);
    }
  }, [usersData]);

  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth * 0.97);
      // if needed, recalculate gridHeight here
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const fetchAllCallLists = async () => {
      if (userLoading) return;
      setIsCallListLoading(true); // Start loading
      let allItems = [];
      let token = null;
      try {
        do {
          const response = await refetchCallList({ limit: 1000, nextToken: token });
          const fetchedData = isAdmin ? response?.data?.listCallLists : response?.data?.CallListByUser;
          if (fetchedData && fetchedData.items) {
            allItems = [...allItems, ...fetchedData.items];
            token = fetchedData.nextToken;
          } else {
            console.warn("Unexpected response structure from refetchCallList:", response);
            break;
          }
        } while (token);
        setCallLists(allItems);
      } catch (error) {
        console.error('Error fetching call lists:', error);
      } finally {
        setIsCallListLoading(false); // End loading
      }
    };

    if (userId) {
      fetchAllCallLists();
    }
  }, [isAdmin, userId, refetchCallList]);

  useEffect(() => {
    const calculateHeight = () => {
      const headerHeight = document.querySelector('.clients-header')?.offsetHeight || 50;
      const footerHeight = 55.6;
      const availableHeight = window.innerHeight - headerHeight - footerHeight;
      const visibleRows = Math.min(dataRowCount, Math.floor(availableHeight / rowHeight));
      setGridHeight(visibleRows * rowHeight);
    };
    window.addEventListener('resize', calculateHeight);
    calculateHeight();
    return () => window.removeEventListener('resize', calculateHeight);
  }, [rowHeight, callLists]); // You can add dataRowCount if defined after callLists calculation

  // --------------------------------------
  // Functions
  // --------------------------------------
  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);
      setUserEmail(signInDetails.loginId.toLowerCase());
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  const onSelect = (event, id, type) => {
    if (type === 'multi') {
      if (event.target.checked) {
        setSelectedClients((prev) => [...prev, id]);
      } else {
        setSelectedClients((prev) => prev.filter((clientId) => clientId !== id));
      }
    } else if (type === 'single') {
      const selectedCall = callLists.find((call) => call.id === id);
      setSingleSelectedClient(selectedCall);
      setSelectedCall(selectedCall);
    }
  };

  const openAssignModal = () => {
    if (selectedClients.length === 0) {
      alert('Please select at least one client to assign.');
      return;
    }
    setIsAssignModalOpen(true);
  };

  const closeAssignModal = () => {
    setSelectedClients([]);
    setIsAssignModalOpen(false);
  };

  const openTouchpointModal = (call) => {
    setSelectedCall(call);
    setIsTouchpointModalOpen(true);
  };

  const closeTouchpointModal = async () => {
    setIsTouchpointModalOpen(false);
    await handleRefreshCallList();
  };

  const openNotesModal = (notes) => {
    setCurrentNotes(notes); // Set the notes to display
    setIsNotesModalOpen(true); // Open the modal
  };

  const closeNotesModal = () => {
    setCurrentNotes(''); // Clear the notes
    setIsNotesModalOpen(false); // Close the modal
  };

  const handleCreateCallList = async () => {
    setIsCreatingCallList(true);
    try {
      let allClients = [];
      let allCallListEntries = [];
      let nextToken = null;

      // 1. Fetch ALL clients
      do {
        const { data } = await fetchClients({
          variables: { limit: 1000, nextToken },
        });
        const fetchedClients = data?.listClientFix2s?.items || [];
        allClients = [...allClients, ...fetchedClients];
        nextToken = data?.listClientFix2s?.nextToken;
      } while (nextToken);

      // 2. Fetch ALL Call List entries
      nextToken = null;
      do {
        const { data } = await refetchCallList({ limit: 1000, nextToken });
        const fetchedCallListEntries = data?.listCallLists?.items || [];
        allCallListEntries = [...allCallListEntries, ...fetchedCallListEntries];
        nextToken = data?.listCallLists?.nextToken;
      } while (nextToken);

      // 3. Determine which clients SHOULD be on the Call List
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

      // Keep a lookup of client data
      const clientMap = new Map();
      // This set holds the IDs of clients that *should* be on the list
      const shouldBeOnCallList = new Set();

      for (const client of allClients) {
        clientMap.set(client.id, client);

        const { callStatus, last_contacted_date } = client;
        const lastContacted = new Date(last_contacted_date);

        // Eligible if NOT Opt Out, NOT Unreachable, and lastContacted < threeMonthsAgo
        if (
          callStatus !== 'Opt Out' &&
          callStatus !== 'Unreachable' //&&
          // !isNaN(lastContacted.getTime()) &&
          // lastContacted < threeMonthsAgo
        ) {
          shouldBeOnCallList.add(client.id);
        }
      }

      // 4. Figure out which IDs are CURRENTLY on the Call List
      const currentlyOnCallList = new Set(allCallListEntries.map((entry) => entry.id));

      // 5. Find the difference sets
      const toRemove = [];
      for (const id of currentlyOnCallList) {
        if (!shouldBeOnCallList.has(id)) {
          toRemove.push(id);
        }
      }

      const toAdd = [];
      for (const id of shouldBeOnCallList) {
        if (!currentlyOnCallList.has(id)) {
          toAdd.push(id);
        }
      }

      // 6. Remove unneeded Call List entries in bulk
      const removalPromises = toRemove.map((id) => {
        const client = clientMap.get(id);
        const entry = allCallListEntries.find((e) => e.id === id);

        // if (client) {
        //   console.log(
        //     `Removing client ${client.name} ${client.last_name} (ID: ${id}) from the Call List`
        //   );
        // }

        if (!entry) {
          return Promise.resolve();
        }

        return deleteCallList({
          variables: {
            input: { id: entry.id },
          },
        }).catch((err) =>
          console.error(
            `Error removing client ${client?.name} ${client?.last_name} from the Call List:`,
            err
          )
        );
      });

      await Promise.all(removalPromises);

      // 7. Add missing Call List entries in bulk
      const additionPromises = toAdd.map((id) => {
        const client = clientMap.get(id);
        // console.log(`Adding client ${client.name} ${client.last_name} (ID: ${id}) to the Call List`);

        return createCallList({
          variables: {
            input: {
              id: client.id, // Must be identical to the Client's ID
              clientID: client.id,
              navigator: 'Unassigned',
              userId: userId,
              callDate: client.last_contacted_date,
              answered: false,
              appointmentMade: false,
              needsFollowUp: false,
              notes: '',
              contactRound: client.callStatus === 'Completed' ? 3 : -1,
              followUpScheduledDate: null,
            },
            condition: {
              attribute_not_exists: 'id',
            },
          },
        }).catch((err) =>
          console.error(
            `Error adding client ${client.name} ${client.last_name} to the Call List:`,
            err
          )
        );
      });

      await Promise.all(additionPromises);

      // 8. Refresh the Call List
      await handleRefreshCallList();

      alert('Call list updated!');
    } catch (error) {
      console.error('Error updating call list:', error);
      alert('Failed to update the call list.');
    } finally {
      setIsCreatingCallList(false);
    }
  };

  // Handles deletion of multiple selected clients
  const handleDeleteSelectedCalls = async () => {
    if (selectedClients.length === 0) {
      alert('Please select at least one client to delete.');
      return;
    }

    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedClients.length} selected clients from the call list?`
    );
    if (!confirmDelete) return;

    try {
      // Delete each selected client from the call list
      await Promise.all(
        selectedClients.map(async (clientId) => {
          await deleteCallList({ variables: { input: { id: clientId } } });
        })
      );

      // Re-fetch the updated call list
      handleRefreshCallList();

      alert('Selected calls successfully deleted from the call list.');
      // Clear selection
      setSelectedClients([]);
    } catch (error) {
      console.error('Error deleting multiple calls:', error);
      alert('Failed to delete the selected calls.');
    }
  };

  const sanitizedCallLists = callLists.map((item) => ({
    ...item,
    updatedAt: item.updatedAt || 'N/A',
  }));

  // --------------------------------------
  // Unique Preferred Languages
  // --------------------------------------
  // We gather *all* languages from callLists so the user can pick from a superset.
  const uniquePreferredLanguages = Array.from(
    new Set(
      callLists
        .flatMap((call) => call.client?.prefered_lang || []) // Flatten the arrays
        .filter(Boolean) // Exclude null/undefined/empty
    )
  );

  const getFilteredCallListByTab = (tab) => {
    return sanitizedCallLists.filter((call) => {
      // Safely handle undefined/null by treating it as -1
      const round = call.contactRound ?? -1;

      if (tab === 'new') {
        // If the value is less than 0 => NEW
        return round < 0;
      }

      if (tab === 'in_progress') {
        // If it is 0 or bigger and less than 3 => IN PROGRESS
        return round >= 0 && round < 3;
      }

      if (tab === 'completed') {
        // If it's exactly 3 => COMPLETE
        return round >= 3;
      }

      return false;
    });
  };

  const getFilteredCallList = () => {
    let filteredList = getFilteredCallListByTab(selectedTab).filter((call) => {
      // 1) Filter by call status
      if (callStatusFilter === 'called' && !call.answered) return false;
      if (callStatusFilter === 'not_called' && call.answered) return false;
      if (callStatusFilter === 'appointment_made' && !call.appointmentMade) return false;
      if (callStatusFilter === 'no_appointment' && call.appointmentMade) return false;

      // 2) Filter by contact round
      if (contactRoundFilter !== 'all' && call.contactRound !== parseInt(contactRoundFilter, 10))
        return false;

      // 3) Filter by assigned user
      if (selectedUser && call.navigator !== selectedUser) return false;

      // 4) Filter by language (NEW)
      if (languageFilter !== 'all') {
        const clientLang = call.client?.prefered_lang;

        // Handle arrays or single strings for prefered_lang
        if (Array.isArray(clientLang)) {
          if (!clientLang.some((lang) => lang.trim().toLowerCase() === languageFilter.trim().toLowerCase())) {
            return false;
          }
        } else if (
          typeof clientLang === 'string' &&
          clientLang.trim().toLowerCase() !== languageFilter.trim().toLowerCase()
        ) {
          return false;
        } else if (!clientLang) {
          return false;
        }
      }

      // 5) Search by name / last name / date
      const nameMatch = call.client?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const lastNameMatch = call.client?.last_name?.toLowerCase().includes(searchTerm.toLowerCase());
      const dateMatch = call.callDate?.includes(searchTerm);

      return nameMatch || lastNameMatch || dateMatch;
    });

    // Sorting
    if (sortConfig.key) {
      filteredList = filteredList.sort((a, b) => {
        const aValue = sortConfig.key.split('.').reduce((o, i) => o?.[i], a);
        const bValue = sortConfig.key.split('.').reduce((o, i) => o?.[i], b);

        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }

    return filteredList;
  };

  const filteredCallLists = getFilteredCallList();
  const dataRowCount = filteredCallLists.length;

  useEffect(() => {
    calculateGridHeight(); // Update grid height when filteredCallLists change
  }, [filteredCallLists]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return 'N/A'; // Return 'N/A' if no phone is provided
    const cleaned = ('' + phone).replace(/\D/g, ''); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the pattern
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`; // Format the number
    }
    return phone; // Return original if it doesn't match
  };

  const newCount = getFilteredCallListByTab('new').length;
  const inProgressCount = getFilteredCallListByTab('in_progress').length;
  const completedCount = getFilteredCallListByTab('completed').length;

  const Cell = ({ columnIndex, rowIndex, style }) => {
    const call = filteredCallLists[rowIndex];
    if (!call) return null;

    let content;
    switch (columnIndex) {
      case 0: // Selection
        content = isAdmin ? (
          <input
            type="checkbox"
            checked={selectedClients.includes(call.id)}
            onChange={(e) => onSelect(e, call.id, 'multi')}
          />
        ) : (
          <input
            type="radio"
            name="selectedClient"
            className="round-radio"
            checked={singleSelectedClient?.id === call.id}
            onChange={() => onSelect(null, call.id, 'single')}
          />
        );
        break;
      case 1: // Client Name
        content = (
          <div
            className={isAdmin ? 'clickable-client-name' : ''}
            onClick={() => {
              if (isAdmin) {
                openTouchpointModal(call);
              }
            }}
          >
            {capitalize(call.client?.name) || 'N/A'}
          </div>
        );
        break;
      case 2: // Client Last Name
        content = capitalize(call.client?.last_name) || 'N/A';
        break;
      case 3: // Phone Number
        content = formatPhoneNumber(call.client?.phone) || 'N/A';
        break;
      case 4: // Navigator
        content = call.navigator || 'Unassigned';
        break;
      case 5: // Call Date
        content = moment(call.callDate).format('MM-DD-YYYY');
        break;
      case 6: // Contact Round
        content = call.contactRound || 0;
        break;
      case 7: // Preferred Language
        content = call.client?.prefered_lang || 'N/A';
        break;
      case 8: // County
        content = call.client?.county || 'N/A';
        break;
      case 9: // Zip Code
        content = call.client?.zip_code || 'N/A';
        break;
      case 10: // Notes column
        const truncated = call.notes
          ? call.notes.length > 30
            ? call.notes.substring(0, 30) + '...'
            : call.notes
          : '(No Notes)';
        content = (
          <div
            className="notes-cell"
            onClick={() => openNotesModal(call.notes || '(No Notes)')}
          >
            {truncated}
          </div>
        );
        break;

      default:
        content = '';
    }

    return (
      <div
        style={{
          ...style,
          width: columnWidth,
          height: rowHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: columnIndex === 4 ? 'flex-start' : 'center',
          borderBottom: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          backgroundColor: rowIndex % 2 === 0 ? '#f9f9f9' : '#e0e0e0',
          color: 'black',
          cursor: columnIndex === 1 && isAdmin ? 'pointer' : 'default',
        }}
      >
        {content}
      </div>
    );
  };

  const HeaderCell = ({ columnIndex, style }) => {
    let headerText;
    let sortKey;

    switch (columnIndex) {
      case 0:
        headerText = "Selection";
        sortKey = null; // No sorting for selection column
        break;
      case 1:
        headerText = "Client Name";
        sortKey = "client.name";
        break;
      case 2:
        headerText = "Client Last Name";
        sortKey = "client.last_name";
        break;
      case 3:
        headerText = "Phone Number";
        sortKey = "client.phone";
        break;
      case 4:
        headerText = "Navigator";
        sortKey = "navigator";
        break;
      case 5:
        headerText = "Call Date";
        sortKey = "callDate";
        break;
      case 6:
        headerText = "Contact Round";
        sortKey = "contactRound";
        break;
      case 7:
        headerText = "Preferred Language";
        sortKey = "client.prefered_lang";
        break;
      case 8:
        headerText = "County";
        sortKey = "client.county";
        break;
      case 9:
        headerText = "Zip Code";
        sortKey = "client.zip_code";
        break;
      case 10:
        headerText = "Notes";
        sortKey = null; // or "notes" if you want to sort by notes
        break;
      default:
        headerText = "";
        sortKey = null;
    }

    const onHeaderClick = () => {
      if (sortKey) {
        handleSort(sortKey);
      }
    };

    return (
      <div
        style={{
          ...style,
          width: columnWidth,
          height: rowHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#23395d',
          color: 'white',
          fontWeight: 'bold',
          borderBottom: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          textAlign: 'center',
          cursor: sortKey ? 'pointer' : 'default',
        }}
        onClick={onHeaderClick}
      >
        {headerText}
      </div>
    );
  };

  // Quickly select the first N clients in the filtered list
  const handleQuickSelect = (count) => {
    const newSelection = filteredCallLists.slice(0, count).map((call) => call.id);
    setSelectedClients(newSelection);
  };

  const handleRefreshCallList = async () => {
    let allItems = [];
    let token = null;
    try {
      do {
        const response = await refetchCallList({ limit: 1000, nextToken: token });
        const fetchedData = isAdmin ? response?.data?.listCallLists : response?.data?.CallListByUser;
        if (fetchedData && fetchedData.items) {
          allItems = [...allItems, ...fetchedData.items];
          token = fetchedData.nextToken;
        } else {
          console.warn("Unexpected response structure from refetchCallList:", response);
          break;
        }
      } while (token);
      setCallLists(allItems);
      console.log("Call list has been refreshed.");
    } catch (error) {
      console.error("Error refreshing call list:", error);
    }
  };

  // UI Rendering
  if (isCallListLoading) {
    return <LoadingSpinner />; // Show spinner while loading
  }

  return (
    <div className="call-list-container">
      <header className="clients-header">
        <h1>Call List</h1>
      </header>

      {/* Filter Control */}
      <div className="call-list-filter-control">
        <h3>Filter Call List:</h3>
        <input
          type="text"
          placeholder="Search by name, last name, or date"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select value={callStatusFilter} onChange={(e) => setCallStatusFilter(e.target.value)}>
          <option value="all">All Clients</option>
          <option value="called">Called Clients</option>
          <option value="not_called">Not Called Clients</option>
          <option value="appointment_made">Appointment Made</option>
          <option value="no_appointment">No Appointment Made</option>
        </select>
        <select value={contactRoundFilter} onChange={(e) => setContactRoundFilter(e.target.value)}>
          <option value="all">All Rounds</option>
          <option value="0">Round 0</option>
          <option value="1">Round 1</option>
          <option value="2">Round 2</option>
          <option value="3">Round 3</option>
        </select>

        {/* NEW: Preferred Language Filter */}
        <select value={languageFilter} onChange={(e) => setLanguageFilter(e.target.value)}>
          <option value="all">All Languages</option>
          {uniquePreferredLanguages.map((lang, index) => (
            <option key={index} value={lang}>
              {lang}
            </option>
          ))}
        </select>

        <select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          className="user-select-dropdown"
        >
          {/* Default option for Unassigned */}
          <option value="Unassigned">Unassigned</option>
          <option value="">Select User</option>
          {/* Dynamically render other users */}
          {userOptions.map((user, index) => (
            <option key={index} value={user}>
              {user}
            </option>
          ))}
        </select>

        {isAdmin && (
          <button
            className="create-call-list-button"
            onClick={handleCreateCallList}
            disabled={isCreatingCallList}
          >
            {isCreatingCallList ? <LoadingSpinner /> : 'Create Call List'}
          </button>
        )}
        <button
          className="refresh-call-list-button"
          onClick={handleRefreshCallList}
          disabled={isCreatingCallList}
        >
          {isCreatingCallList ? <LoadingSpinner /> : 'Refresh Call List'}
        </button>
      </div>

      {/* Tab Navigation */}
      <div className="call-list-tabs">
        <button
          className={`call-list-tab ${selectedTab === 'new' ? 'active-tab' : ''}`}
          onClick={() => handleTabChange('new')}
        >
          New ({newCount})
        </button>
        <button
          className={`call-list-tab ${selectedTab === 'in_progress' ? 'active-tab' : ''}`}
          onClick={() => handleTabChange('in_progress')}
        >
          In Progress ({inProgressCount})
        </button>
        <button
          className={`call-list-tab ${selectedTab === 'completed' ? 'active-tab' : ''}`}
          onClick={() => handleTabChange('completed')}
        >
          Completed by Assister ({completedCount})
        </button>
      </div>

      <div className="quick-select-container">
        <span>Quick Select: </span>
        <button onClick={() => handleQuickSelect(5)}>First 5</button>
        <button onClick={() => handleQuickSelect(10)}>First 10</button>
        <button onClick={() => handleQuickSelect(25)}>First 25</button>
        <button onClick={() => handleQuickSelect(50)}>First 50</button>
        <button onClick={() => handleQuickSelect(100)}>First 100</button>

        {/* Clear Selection button appears only if something is selected */}
        {selectedClients.length > 0 && (
          <button onClick={() => setSelectedClients([])}>
            Clear Selection
          </button>
        )}
      </div>

      {/* Header Grid */}
      <div
        className="grid-header"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          overflow: 'auto',
        }}
        ref={headerRef}
      >
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={rowHeight}
          rowCount={1}
          rowHeight={rowHeight}
          width={Math.max(gridWidth, columnWidth * columnCount)}
          style={{ overflow: 'hidden' }}
        >
          {HeaderCell}
        </Grid>
      </div>

      {/* Data Grid */}
      <Grid
        columnCount={columnCount}
        columnWidth={columnWidth}
        height={gridHeight}
        rowCount={dataRowCount}
        rowHeight={rowHeight}
        width={gridWidth}
        className='grid-calls'
        onScroll={({ scrollLeft }) => {
          if (headerRef.current) {
            headerRef.current.scrollLeft = scrollLeft;
          }
        }}
      >
        {Cell}
      </Grid>
      {isNotesModalOpen && (
        <div className="notes-modal-overlay" onClick={closeNotesModal}>
          <div
            className="notes-modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <button className="close-btn" onClick={closeNotesModal}>
              Close
            </button>
            <h3>Call Notes</h3>
            <p>{currentNotes}</p>
          </div>
        </div>
      )}

      <div className="total-items-info">
        <h3>Total Call List Items Displayed: {filteredCallLists.length}</h3>
      </div>

      <div className="action-button-container">
        {isAdmin ? (
          <button
            className="action-button"
            onClick={() => {
              if (selectedClients.length > 0) {
                openAssignModal();
              } else {
                alert('Please select at least one client to assign.');
              }
            }}
          >
            Assign
          </button>
        ) : (
          <button
            className="action-button"
            onClick={() => {
              if (selectedCall) {
                openTouchpointModal(selectedCall);
              } else {
                alert('Please select a client using the round button to create a touchpoint.');
              }
            }}
          >
            Create Touchpoint
          </button>
        )}
        {selectedClients.length > 0 && isAdmin && (
          <button
            className="action-button delete-button"
            onClick={handleDeleteSelectedCalls}
          >
            Delete Selected
          </button>
        )}
      </div>
      {isAssignModalOpen && (
        <AssignModal
          onClose={() => {
            closeAssignModal();
            handleRefreshCallList();
          }}
          selectedClients={selectedClients}
          refetchCallList={handleRefreshCallList}
        />
      )}
      {isTouchpointModalOpen && (
        <TouchPointModal
          onClose={() => {
            closeTouchpointModal();
            handleRefreshCallList();
          }}
          selectedCall={selectedCall}
        />
      )}
    </div>
  );
};

export default CallList;
