import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { VariableSizeGrid as Grid } from "react-window";
import { LIST_FORM_SUBMISSIONS, GET_DYNAMIC_FORM } from "../../graphql/queries";
import "./DynamicReportList.css";

// (Optional) Helper: if you still need normalization, you can adjust it.
// In this case, we simply parse the JSON string.
const parseAnswers = (answersString) => {
    try {
        return JSON.parse(answersString);
    } catch (e) {
        console.error("Error parsing answers JSON:", e);
        return {};
    }
};

function DynamicReportList() {
    // For this example, we assume formId is passed as a prop.
    const formId = "88a2d03c-5cc6-4b3f-b533-c12988995b8a";

    // Query for submissions
    const {
        loading: subLoading,
        error: subError,
        data: subData,
    } = useQuery(LIST_FORM_SUBMISSIONS);

    // Query for the form structure (to retrieve field labels and IDs)
    const {
        loading: formLoading,
        error: formError,
        data: formData,
    } = useQuery(GET_DYNAMIC_FORM, {
        variables: { id: formId },
        skip: !formId,
        fetchPolicy: "network-only",
    });

    // Process submissions: parse the answers JSON string into an object.
    const submissions = subData?.listFormSubmissions?.items || [];
    const normalizedSubmissions = submissions.map((sub) => ({
        ...sub,
        normalizedAnswers: sub.answers ? parseAnswers(sub.answers) : {},
    }));

    // Build an ordered array of field objects (each with id and label) from the form structure.
    const fieldsOrdered = useMemo(() => {
        const arr = [];
        if (
            formData &&
            formData.getDynamicForm &&
            formData.getDynamicForm.cards
        ) {
            // Optionally sort cards by pageIndex (default to 1 if missing)
            const sortedCards = [...formData.getDynamicForm.cards].sort(
                (a, b) => (a.pageIndex || 1) - (b.pageIndex || 1)
            );
            sortedCards.forEach((card) => {
                if (card.fields) {
                    card.fields.forEach((field) =>
                        arr.push({ id: field.id, label: field.label })
                    );
                }
            });
        }
        return arr;
    }, [formData]);

    // Extract header labels and ordered field IDs.
    const headerLabels = useMemo(
        () => fieldsOrdered.map((f) => f.label),
        [fieldsOrdered]
    );
    const orderedFieldIds = useMemo(
        () => fieldsOrdered.map((f) => f.id),
        [fieldsOrdered]
    );

    // Early return if still loading or if there is an error.
    if (subLoading || formLoading) return <p>Loading submissions...</p>;
    if (subError) return <p>Error loading submissions: {subError.message}</p>;
    if (formError) return <p>Error loading form: {formError.message}</p>;

    // Grid dimensions:
    const columnCount = orderedFieldIds.length; // one column per field in the form
    const cellWidth = 200;
    const totalWidth = columnCount * cellWidth;
    const headerHeight = 50;
    const rowHeight = 50;
    const dataRowCount = normalizedSubmissions.length; // one row per submission

    // HeaderCell: renders the header row (question labels)
    const HeaderCell = ({ columnIndex, style }) => {
        const header = headerLabels[columnIndex] || "";
        return (
            <div style={style} className="cell header-cell">
                {header}
            </div>
        );
    };

    // DataCell: renders a cell for a submission's answer.
    // It uses the field ID (from orderedFieldIds) to look up the corresponding answer.
    const DataCell = ({ rowIndex, columnIndex, style }) => {
        const submission = normalizedSubmissions[rowIndex];
        const fieldId = orderedFieldIds[columnIndex];
        const content = submission.normalizedAnswers[fieldId] || "";
        return (
            <div style={style} className="cell">
                {content}
            </div>
        );
    };

    return (
        <div className="report-list-container">
            <h1>All Submissions</h1>
            <div style={{ overflow: "auto" }}>
                {/* Header Grid: displays a single row of headers */}
                <Grid
                    columnCount={columnCount}
                    columnWidth={() => cellWidth}
                    height={headerHeight}
                    rowCount={1}
                    rowHeight={() => headerHeight}
                    width={totalWidth}
                    style={{ overflowX: "hidden", overflowY: "hidden" }}
                >
                    {HeaderCell}
                </Grid>
                {/* Data Grid: displays submission answers */}
                <Grid
                    columnCount={columnCount}
                    columnWidth={() => cellWidth}
                    height={400}
                    rowCount={dataRowCount}
                    rowHeight={() => rowHeight}
                    width={totalWidth}
                >
                    {DataCell}
                </Grid>
            </div>
        </div>
    );
}

export default DynamicReportList;
