import React, { useState, useEffect, useMemo } from 'react';
import { FaBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { capitalize } from '../../components/common/capitalize';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { listUsers, listNotifications } from '../../graphql/queries';
import { invokeLambdaNotification } from '../../hooks/invokeLambdaNotification/InvokeLambdaNotification'; // <-- Your Lambda-invoking helper
import './UserTab.css';
import NotificationModal from '../../components/layout/NotificationModal';
import { updateNotification } from '../../graphql/mutations';

function UserTab() {
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const { loading1, error: userError, data: userData } = useQuery(listUsers);
  const { loading2, error: notificationError, data: notificationData } = useQuery(listNotifications);
  const [userId, setUserId] = useState(null);

  // Store just the numeric count of unread notifications
  const [unreadCount, setUnreadCount] = useState(0);
  // We'll assume 'userInfo' holds the user ID needed by your Lambda
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Users List
  const users = React.useMemo(() => {
    return userData?.listUserFixes?.items || [];
  }, [userData]);


  // Helper to find user ID by email
  const findUserIdByEmail = (users, email) => {
    if (!email?.trim() || !Array.isArray(users)) return null;

    const user = users.find((u) => u.thc_email_id === email);
    return user ? user.id : null; // Retorna solo el ID del usuario
  };

  // 1. Fetch Amplify user details
  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();

      // Identify groups from the Cognito ID token
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);

      // Example: parse name from signInDetails.loginId
      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0];

      setUserDetails({
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        email: signInDetails.loginId,
      });

      // Here you'd set userInfo from your GraphQL or DB reference if needed
      // For example, if userInfo is simply the email or some numeric ID:
      setUserInfo(signInDetails.loginId); // or a user ID from your DB
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  //Roles
  const isManager = userGroups?.some(group => group === 'Manager');
  const isAdmin = userGroups?.some(group => group === 'Admin');

  // Update userId when userInfo or users change
  useEffect(() => {
    if (userInfo && users) {
      const id = findUserIdByEmail(users, userInfo);
      setUserId(id);
      if (!id) console.error('User ID not found for email:', userInfo);
    }
  }, [userInfo, users]); // Recalculate userId whenever userInfo or users change



  // Fetch unread notification count when userId changes
  useEffect(() => {
    const fetchUnreadCount = async () => {
      if (!userId) return;
      try {
        const count = await invokeLambdaNotification(userId);
        setUnreadCount(count || 0);
      } catch (error) {
        console.error('Error fetching unread notifications:', error);
      }
    };

    fetchUnreadCount();
  }, [userId]); // Fetch notifications whenever userId changes


  // 2. Handle the entire user tab click (navigates if admin)
  const handleUserTabClick = () => {
    if (userGroups.includes('Admin')) {
      navigate('/admin-home');
    } else {
      console.log('Access denied. User is not an admin.');
    }
  };
  //Notification Modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  // Filtered Notifications List
  const notifications = React.useMemo(() => {
    return (
      notificationData?.listNotifications?.items.filter(
        (notification) =>
          (notification.status === "unread" || notification.status === "read") &&
        (notification.active === true) &&
          (notification.adminID === userId ||
            notification.supervisorID === userId ||
            notification.userID === userId)
      ) || []
    );
  }, [notificationData, userId]);

  const [updNotification] = useMutation(updateNotification, {
    refetchQueries: [{ query: listNotifications, variables: { limit: 100 } }]
  });


  // Function to provide the notifications user ID and return the user's first and last name.
  const findFullNameByNotification = (users, userID) => {
    if (!userID?.trim() || !Array.isArray(users)) return null;

    const user = users.find((u) => u.id === userID);
    return user ? `${user.first_name} ${user.last_name}` : null;
  };

  const enrichedNotifications = notifications.map((notification) => ({
    ...notification,
    fullname: findFullNameByNotification(users, notification.userID) || 'Unknown',
  }));

  // Depuración: Iterar sobre las notificaciones y verificar los datos
  enrichedNotifications.forEach((notification) => {

  });

  const handleViewAll = () => {
    window.location.href = "/notification"; // URL a la que deseas redirigir
  };



  const handleNotificationClick = async (notification) => {
    try {
      // Actualiza la notificación a "read" en el servidor
      await updNotification({
        variables: {
          input: {
            id: notification.id,
            status: "read",
          },
        },
      });

      // Actualiza el estado local
      setUnreadCount((prevCount) => Math.max(prevCount - 1, 0)); // Resta 1 al contador local

      // Redirige a la página relacionada con la Notificacion (Tickets List)
      if (notification.relatedId) {
        navigate(`/issue-ticket-list/?ticketId=${notification.relatedId}`);
      } else {
        console.error("Notification relatedId is missing or invalid:", notification);
      }

    } catch (error) {
      console.error("Error updating notification:", error);
    }
    // Quiero actualizar  la variable unreadCount en cuando vaya dando click
  };

  return (
    <div className="user-tab" >
      {/* Bell icon + unread count */}
      <div className="notification-icon" onClick={toggleModal}>
        <FaBell />
        {/* Show the number of unread notifications */}
        <span className="notification-count">{unreadCount}</span>
      </div>
      {/* Notification Modal */}
      <NotificationModal
        notifications={enrichedNotifications}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        isAdmin={isAdmin}
        onViewAll={handleViewAll}
        handleNotificationClick={handleNotificationClick}
      />

      <div className="user-info" onClick={handleUserTabClick}>
        {userDetails ? (
          <>
            <div className="user-name">
              {userDetails.firstName + ' ' + userDetails.lastName[0] + '.'}
            </div>
            <div className="user-role">{userGroups[0]}</div>
          </>
        ) : (
          <div>Loading user information...</div>
        )}
      </div>
    </div>
  );
}

export default UserTab;
