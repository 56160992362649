import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { listUsers, GET_DeleteEquipmentHistories__BY_DATE_RANGE, GET_GeneralInventory__BY_DATE_RANGE, GET_POWERBANK_BY_DATE_RANGE, GET_MOUSE_BY_DATE_RANGE, GET_KEYBOARDS_BY_DATE_RANGE, GET_MONITORS_BY_DATE_RANGE, GET_LAPTOP_BY_DATE_RANGE, GET_PHONE_BY_DATE_RANGE, GET_PRINTER_BY_DATE_RANGE, GET_SCANNER_BY_DATE_RANGE, GET_TABLET_BY_DATE_RANGE, GET_EXTENSION_BY_DATE_RANGE, GET_BACKUP_BY_DATE_RANGE, GET_USB_BY_DATE_RANGE, GET_DOCKINGSTATION_BY_DATE_RANGE, GET_SPEAKERS_BY_DATE_RANGE, GET_HEADSET_BY_DATE_RANGE, GET_PROJECTOR_BY_DATE_RANGE, GET_EXTERNALDISK_BY_DATE_RANGE, GET_BAG_BY_DATE_RANGE, GET_CLICKCOUNTER_BY_DATE_RANGE, GET_SUPPLIES_BY_DATE_RANGE } from '../../../graphql/queries'; // Adjust the import path accordingly
import './ReportByDate.css';
import DatePicker from 'react-datepicker';
import useFilteredData from '../../../components/layout/useFilteredDate';
import EquipmentTable from '../../../components/layout/EquipmentTable';
import { FaHome } from 'react-icons/fa';
import { useMemo } from 'react';

const ReportByDate = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const selectedDateType = location.state?.selectedDate ?? new Date().toLocaleDateString("en-US");
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState('');
  const [searchField, setSearchField] = useState('');
  const [selectedInput, setSelectedInput] = useState(""); // Para inputs de texto
  const [selectedUserId, setSelectedUserId] = useState(""); // Para búsqueda por usuario

  const { loading1, error: userError, data: userData } = useQuery(listUsers);


  const [selectedRows, setSelectedRows] = useState({
    MiceSelect: [],
    KeyboardsSelect: [],
    MonitorsSelect: [],
    ScannersSelect: [],
    PrintersSelect: [],
    ExtensionCordsSelect: [],
    PhonesSelect: [],
    PowerBanksSelect: [],
    BackupBateriesSelect: [],
    USBSelect: [],
    DockingStationsSelect: [],
    SpeakerSelect: [],
    LaptopsSelect: [],
    TabletSelect: [],
    HeadsetSelect: [],
    ProjectorSelect: [],
    ExternoDiskSelect: [],
    GeneralInventorySelect: [],
    BagSelect: [],
    ClickCounterSelect: [],
    SuppliesSelect: [],
    DeletEquipSelect: [],
  });


  const handleInputChange = (e) => {
    setSelectedInput(e.target.value);
  };

  const handleUserChange = (e) => {
    setSelectedUserId(e.target.value);
  };

  const navigatorOptions = userData?.listUserFixes?.items?.map(user => ({
    id: user.id,
    name: `${user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase()} ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase()}`
  })) || [];

  // Construir el filtro dinámico basado en el tipo de fecha seleccionado
  const getFilter = (type) => {
    if (!startDate || !endDate) return null;

    // Condiciones para omitir el filtro
    if (
      (type === "bag" && selectedDateType === "warranty_date") ||
      (type === "deleteEquipmentHistories" && selectedDateType !== "purchase_date") ||
      ((type === "clickCounter" || type === "supplies") && selectedDateType !== "purchase_date")
    ) {
      return null;
    }

    // Retorna el filtro
    return {
      [selectedDateType]: {
        between: [startDate.toISOString().split("T")[0], endDate.toISOString().split("T")[0]],
      },
    };
  };
  // Consultas para Mouses, Keyboards, Monitors....
  const { loading: loadingMice, error: errorMice, data: dataMice } = useQuery(GET_MOUSE_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("mouse"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingKeyboards, error: errorKeyboards, data: dataKeyboards } = useQuery(GET_KEYBOARDS_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("keyboard"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingMonitors, error: errorMonitors, data: dataMonitors } = useQuery(GET_MONITORS_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("monitor"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingPhones, error: errorPhones, data: dataPhones } = useQuery(GET_PHONE_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("phone"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingLaptops, error: errorLaptops, data: dataLaptops } = useQuery(GET_LAPTOP_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("laptop"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingScanners, error: errorScanners, data: dataScanners } = useQuery(GET_SCANNER_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("scanner"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingPrinters, error: errorPrinters, data: dataPrinters } = useQuery(GET_PRINTER_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("printer"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingExtentions, error: errorExtentions, data: dataExtentions } = useQuery(GET_EXTENSION_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("extention"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingTablets, error: errorTablets, data: dataTablets } = useQuery(GET_TABLET_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("tablet"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingBackups, error: errorBackups, data: dataBackups } = useQuery(GET_BACKUP_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("backup"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingUSBS, error: errorUSB, data: dataUSB } = useQuery(GET_USB_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("usb"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingDockingStations, error: erroDockingStations, data: dataDockingStations } = useQuery(GET_DOCKINGSTATION_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("docking"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingSpeakers, error: errorSpeaker, data: dataSpeaker } = useQuery(GET_SPEAKERS_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("speaker"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingHeadsets, error: errorHeadset, data: dataHeadset } = useQuery(GET_HEADSET_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("headset"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingProjectors, error: errorProjectors, data: dataProjectors } = useQuery(GET_PROJECTOR_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("projector"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingExternalDisk, error: errorExternalDisk, data: dataExternalDisk } = useQuery(GET_EXTERNALDISK_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("externalDisk"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingBag, error: errorBag, data: dataBag } = useQuery(GET_BAG_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("bag"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingClickCounter, error: errorClickCounter, data: dataClickCounter } = useQuery(GET_CLICKCOUNTER_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("clickCounter"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingSupplies, error: errorSupplies, data: dataSupplies } = useQuery(GET_SUPPLIES_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("supplies"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingPowerBanks, error: errorPowerBanks, data: dataPowerBank } = useQuery(GET_POWERBANK_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("powerBank"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadinggeneralInventory, error: errorgeneralInventory, data: datageneralInventory } = useQuery(GET_GeneralInventory__BY_DATE_RANGE, {
    variables: {
      filter: getFilter("generalInventory"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingdeleteEquipmentHistories, error: errorDeleteEquipmentHistories, data: dataDeleteEquipmentHistories } = useQuery(GET_DeleteEquipmentHistories__BY_DATE_RANGE, {
    variables: {
      filter: getFilter("deleteEquipmentHistories"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });



  //Filtro de todo la inf para cada tabla
  //Mouse
  const filteredDataMice = useMemo(() => {
    if (!dataMice?.listMouseFixes || !dataMice) return [];

    return dataMice.listMouseFixes.items.filter(mouse => {
      if (!searchField) return true; // ✅ Si no hay campo de búsqueda, mostrar todo

      let fieldValue = mouse[searchField] ? mouse[searchField].toString().toLowerCase() : "";

      // ✅ Manejo especial para userId (comparar con la selección del usuario)
      if (searchField === "userId") {
        return mouse.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase()); // ✅ Filtra según el input
    });
  }, [dataMice, searchField, selectedInput, selectedUserId]); // ✅ Dependencias correctas

  //Keyboards
  const filteredDataKeyboards = useMemo(() => {
    if (!dataKeyboards || !dataKeyboards?.listKeyboardFixes) return [];

    return dataKeyboards?.listKeyboardFixes?.items.filter(keyboard => {
      if (!searchField) return true;

      let fieldValue = keyboard[searchField] ? keyboard[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return keyboard.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });
  }, [dataKeyboards, searchField, selectedInput, selectedUserId]);



  //Monitor
  const filteredDataMonitors = useMemo(() => {
    if (!dataMonitors || !dataMonitors?.listMonitorFixes) return [];

    return dataMonitors?.listMonitorFixes?.items.filter(monitor => {
      if (!searchField) return true;

      let fieldValue = monitor[searchField] ? monitor[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return monitor.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });
  }, [dataMonitors, searchField, selectedInput, selectedUserId]);


  //Phones
  const filteredDataPhones = useMemo(() => {
    if (!dataPhones || !dataPhones?.listPhoneFixes) return [];

    return dataPhones?.listPhoneFixes?.items.filter(phone => {
      if (!searchField) return true;

      let fieldValue = phone[searchField] ? phone[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return phone.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });
  }, [dataPhones, searchField, selectedInput, selectedUserId]);

  //Laptops
  const filteredDataLaptops = useMemo(() => {
    if (!dataLaptops || !dataLaptops?.listLaptopFixes) return [];

    return dataLaptops?.listLaptopFixes?.items.filter(laptop => {
      if (!searchField) return true;

      let fieldValue = laptop[searchField] ? laptop[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return laptop.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });
  }, [dataLaptops, searchField, selectedInput, selectedUserId]);

  //Power Banks
  const filteredDataPowerBanks = useMemo(() => {
    if (!dataPowerBank || !dataPowerBank?.listPowerBankFixes) return [];

    return dataPowerBank?.listPowerBankFixes?.items.filter(powerBank => {
      if (!searchField) return true;

      let fieldValue = powerBank[searchField] ? powerBank[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return powerBank.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });
  }, [dataPowerBank, searchField, selectedInput, selectedUserId]);


  //Scanner
  const filteredDataScanners = useMemo(() => {
    if (!dataScanners || !dataScanners?.listScannerFixes) return [];

    return dataScanners?.listScannerFixes?.items.filter(scanner => {
      if (!searchField) return true;

      let fieldValue = scanner[searchField] ? scanner[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return scanner.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });
  }, [dataScanners, searchField, selectedInput, selectedUserId]);

  //Printer
  const filteredDataPrinters = useMemo(() => {
    if (!dataPrinters || !dataPrinters?.listPrinterFixes) return [];

    return dataPrinters?.listPrinterFixes?.items.filter(printer => {
      if (!searchField) return true;

      let fieldValue = printer[searchField] ? printer[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return printer.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });
  }, [dataPrinters, searchField, selectedInput, selectedUserId]);

  //Extension Cord
  const filteredDataExtensions = useMemo(() => {
    if (!dataExtentions || !dataExtentions?.listExtensionCordFixes) return [];

    return dataExtentions?.listExtensionCordFixes?.items.filter(extention => {
      if (!searchField) return true;

      let fieldValue = extention[searchField] ? extention[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return extention.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataExtentions, searchField, selectedInput, selectedUserId]);

  //Tablets 
  const filteredDataTablets = useMemo(() => {
    if (!dataTablets || !dataTablets?.listTabletRemarkableFixes) return [];

    return dataTablets?.listTabletRemarkableFixes?.items.filter(tablet => {
      if (!searchField) return true;

      let fieldValue = tablet[searchField] ? tablet[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return tablet.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataTablets, searchField, selectedInput, selectedUserId]);

  //backup 
  const filteredDataBackups = useMemo(() => {
    if (!dataBackups || !dataBackups?.listBackupBatteryFixes) return [];

    return dataBackups?.listBackupBatteryFixes?.items.filter(backup => {
      if (!searchField) return true;

      let fieldValue = backup[searchField] ? backup[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return backup.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataBackups, searchField, selectedInput, selectedUserId]);

  //backup 
  const filteredDataUSB = useMemo(() => {
    if (!dataUSB || !dataUSB?.listUSBFixes) return [];

    return dataUSB?.listUSBFixes?.items.filter(usb => {
      if (!searchField) return true;

      let fieldValue = usb[searchField] ? usb[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return usb.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataUSB, searchField, selectedInput, selectedUserId]);

  //docking 
  const filteredDataDockings = useMemo(() => {
    if (!dataDockingStations || !dataDockingStations?.listDockingStationFixes) return [];

    return dataDockingStations?.listDockingStationFixes?.items.filter(docking => {
      if (!searchField) return true;

      let fieldValue = docking[searchField] ? docking[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return docking.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataDockingStations, searchField, selectedInput, selectedUserId]);

  //speaker 
  const filteredDataSpeakers = useMemo(() => {
    if (!dataSpeaker || !dataSpeaker?.listSpeakerFixes) return [];

    return dataSpeaker?.listSpeakerFixes?.items.filter(speaker => {
      if (!searchField) return true;

      let fieldValue = speaker[searchField] ? speaker[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return speaker.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataSpeaker, searchField, selectedInput, selectedUserId]);

  //headset 
  const filteredDataHeadsets = useMemo(() => {
    if (!dataHeadset || !dataHeadset?.listHeadsetFixes) return [];

    return dataHeadset?.listHeadsetFixes?.items.filter(headset => {
      if (!searchField) return true;

      let fieldValue = headset[searchField] ? headset[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return headset.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataHeadset, searchField, selectedInput, selectedUserId]);


  //projector 
  const filteredDataProjectors = useMemo(() => {
    if (!dataProjectors || !dataProjectors?.listProjectors) return [];

    return dataProjectors?.listProjectors?.items.filter(projector => {
      if (!searchField) return true;

      let fieldValue = projector[searchField] ? projector[searchField].toString().toLowerCase() : "";

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataProjectors, searchField, selectedInput, selectedUserId]);

  //externalDisk 
  const filteredDataExternalDisks = useMemo(() => {
    if (!dataExternalDisk || !dataExternalDisk?.listExternalDiskFixes) return [];

    return dataExternalDisk?.listExternalDiskFixes?.items.filter(externalDisk => {
      if (!searchField) return true;

      let fieldValue = externalDisk[searchField] ? externalDisk[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return externalDisk.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataExternalDisk, searchField, selectedInput, selectedUserId]);


  //bag 
  const filteredDataBags = useMemo(() => {
    if (!dataBag || !dataBag?.listBagFixes) return [];

    return dataBag?.listBagFixes?.items.filter(bag => {
      if (!searchField) return true;

      let fieldValue = bag[searchField] ? bag[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return bag.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataBag, searchField, selectedInput, selectedUserId]);

  //clickCounter 
  const filteredDataClick = useMemo(() => {
    if (!dataClickCounter || !dataClickCounter?.listClickCounterFixes) return [];

    return dataClickCounter?.listClickCounterFixes?.items.filter(clickCounter => {
      if (!searchField) return true;

      let fieldValue = clickCounter[searchField] ? clickCounter[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return clickCounter.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataClickCounter, searchField, selectedInput, selectedUserId]);

  //supplies 
  const filteredDataSupplies = useMemo(() => {
    if (!dataSupplies || !dataSupplies?.listSuppliesFixes) return [];

    return dataSupplies?.listSuppliesFixes?.items.filter(supplies => {
      if (!searchField) return true;

      let fieldValue = supplies[searchField] ? supplies[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return supplies.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataSupplies, searchField, selectedInput, selectedUserId]);

  //generalInventory 
  const filteredDataSocialMedia = useMemo(() => {
    if (!datageneralInventory || !datageneralInventory?.listGeneralInventoryFixes) return [];

    return datageneralInventory?.listGeneralInventoryFixes?.items.filter(generalInventory => {
      if (!searchField) return true;

      let fieldValue = generalInventory[searchField] ? generalInventory[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return generalInventory.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [datageneralInventory, searchField, selectedInput, selectedUserId]);


  //generalInventory 
  const filteredDataDeleteEquipment = useMemo(() => {
    if (!dataDeleteEquipmentHistories || !dataDeleteEquipmentHistories?.listDeleteEquipmentHistoryFixes) return [];

    return dataDeleteEquipmentHistories?.listDeleteEquipmentHistoryFixes?.items.filter(deleteEquipmentHistories => {
      if (!searchField) return true;

      let fieldValue = deleteEquipmentHistories[searchField] ? deleteEquipmentHistories[searchField].toString().toLowerCase() : "";


      if (searchField === "userId") {
        return deleteEquipmentHistories.userId === selectedUserId;
      }

      return fieldValue.includes(selectedInput.toLowerCase());
    });

  }, [dataDeleteEquipmentHistories, searchField, selectedInput, selectedUserId]);

  // There are no items available to display on the selected date 
  const allTablesEmpty =
    filteredDataMice.length === 0 &&
    filteredDataKeyboards.length === 0 &&
    filteredDataMonitors.length === 0 &&
    filteredDataPhones.length === 0 &&
    filteredDataPowerBanks.length === 0 &&
    filteredDataScanners.length === 0 &&
    filteredDataPrinters.length === 0 &&
    filteredDataExtensions.length === 0 &&
    filteredDataTablets.length === 0 &&
    filteredDataBackups.length === 0 &&
    filteredDataUSB.length === 0 &&
    filteredDataDockings.length === 0 &&
    filteredDataSpeakers.length === 0 &&
    filteredDataHeadsets.length === 0 &&
    filteredDataProjectors.length === 0 &&
    filteredDataExternalDisks.length === 0 &&
    filteredDataBags.length === 0 &&
    filteredDataClick.length === 0 &&
    filteredDataSupplies.length === 0 &&
    filteredDataSocialMedia.length === 0 &&
    filteredDataDeleteEquipment.length === 0 &&
    filteredDataLaptops.length === 0;


  // Funcion para manejar las filas selecciondas de cualquier tabla y sumar el precio 
  const handleRowSelection = (table, id) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [table]: prevSelectedRows[table]?.includes(id)
        ? prevSelectedRows[table].filter((rowId) => rowId !== id)
        : [...(prevSelectedRows[table] || []), id],
    }));
  };

  // Function to handle the "To" date selection
  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      setDateError('"To" date must be after the "From" date.');
      setEndDate(startDate); // Reset "To" date to "From" date
    } else {
      setDateError('');
      setEndDate(date);
    }
  };

  // Función para contar items
  const countItems = (data) => {
    return data ? data.length : 0; // Retorna la cantidad de ítems o 0 si no hay ítems
  };

  // Funcion para contar los precios seleccionados en cada una de las tablas
  const calculateTotalPrice = (table, data) => {
    return data
      .filter((item) => selectedRows[table]?.includes(item.id))
      .reduce((sum, item) => sum + (item.price || 0), 0);
  };

  const calculateGrandTotal = () => {
    return (
      calculateTotalPrice("MiceSelect", filteredDataMice) +
      calculateTotalPrice("KeyboardsSelect", filteredDataKeyboards) +
      calculateTotalPrice("MonitorsSelect", filteredDataMonitors) +
      calculateTotalPrice("PhonesSelect", filteredDataPhones) +
      calculateTotalPrice("LaptopsSelect", filteredDataLaptops) +
      calculateTotalPrice("ScannersSelect", filteredDataScanners) +
      calculateTotalPrice("PrintersSelect", filteredDataPrinters) +
      calculateTotalPrice("ExtensionCordsSelect", filteredDataExtensions) +
      calculateTotalPrice("PowerBanksSelect", filteredDataPowerBanks) +
      calculateTotalPrice("BackupBateriesSelect", filteredDataBackups) +
      calculateTotalPrice("USBSelect", filteredDataUSB) +
      calculateTotalPrice("DockingStationsSelect", filteredDataDockings) +
      calculateTotalPrice("SpeakerSelect", filteredDataSpeakers) +
      calculateTotalPrice("TabletSelect", filteredDataTablets) +
      calculateTotalPrice("HeadsetSelect", filteredDataHeadsets) +
      calculateTotalPrice("ProjectorSelect", filteredDataProjectors) +
      calculateTotalPrice("ExternoDiskSelect", filteredDataExternalDisks) +
      calculateTotalPrice("GeneralInventorySelect", filteredDataSocialMedia) +
      calculateTotalPrice("BagSelect", filteredDataBags) +
      calculateTotalPrice("ClickCounterSelect", filteredDataClick) +
      calculateTotalPrice("SuppliesSelect", filteredDataSupplies) +
      calculateTotalPrice("DeletEquipSelect", filteredDataDeleteEquipment)
      // Agrega más tablas si es necesario

    );
  };

  // Verificar si alguna consulta está cargando
  const isLoading = loadingMice || loadingKeyboards || loadingMonitors || loadingLaptops || loadingPhones || loadingPrinters || loadingScanners || loadingExtentions || loadingTablets || loadingBackups || loadingUSBS || loadingDockingStations || loadingSpeakers || loadingHeadsets || loadingProjectors || loadingExternalDisk || loadingBag || loadingClickCounter || loadingSupplies || loadingPowerBanks || loadinggeneralInventory || loadingdeleteEquipmentHistories;

  // Verificar si alguna consulta tiene error
  const hasError = errorMice || errorKeyboards || errorMonitors || errorLaptops || errorPhones || errorPrinters || errorScanners || errorExtentions || errorTablets || errorBackups || errorUSB || erroDockingStations || errorSpeaker || errorHeadset || errorProjectors || errorExternalDisk || errorBag || errorClickCounter || errorSupplies || errorPowerBanks || errorgeneralInventory || errorDeleteEquipmentHistories;

  // + all Total
  const totalMicePurchase = filteredDataMice.reduce((total, mouse) => total + (mouse.price || 0), 0).toFixed(2);
  const totalKeyboardsPurchase = filteredDataKeyboards.reduce((total, keyboard) => total + (keyboard.price || 0), 0).toFixed(2);
  const totalMonitorPurchase = filteredDataMonitors.reduce((total, monitor) => total + (monitor.price || 0), 0).toFixed(2);
  const totalLaptopsPurchase = filteredDataLaptops.reduce((total, laptop) => total + (laptop.price || 0), 0).toFixed(2);
  const totalPhonesPurchase = filteredDataPhones.reduce((total, phone) => total + (phone.price || 0), 0).toFixed(2);
  const totalScannersPurchase = filteredDataScanners.reduce((total, scanner) => total + (scanner.price || 0), 0).toFixed(2);
  const totalPrintersPurchase = filteredDataPrinters.reduce((total, printer) => total + (printer.price || 0), 0).toFixed(2);
  const totalExtensionCordPurchase = filteredDataExtensions.reduce((total, extension) => total + (extension.price || 0), 0).toFixed(2);
  const totalTabletsPurchase = filteredDataTablets.reduce((total, tablet) => total + (tablet.price || 0), 0).toFixed(2);
  const totalBackupBaterriesPurchase = filteredDataBackups.reduce((total, backup) => total + (backup.price || 0), 0).toFixed(2);
  const totalUSBPurchase = filteredDataUSB.reduce((total, usb) => total + (usb.price || 0), 0).toFixed(2);
  const totalDockingStationsPurchase = filteredDataDockings.reduce((total, dockingStation) => total + (dockingStation.price || 0), 0).toFixed(2);
  const totalSpeakersPurchase = filteredDataSpeakers.reduce((total, speaker) => total + (speaker.price || 0), 0).toFixed(2);
  const totalHeadsetsPurchase = filteredDataHeadsets.reduce((total, headset) => total + (headset.price || 0), 0).toFixed(2);
  const totalProjectorPurchase = filteredDataProjectors.reduce((total, projector) => total + (projector.price || 0), 0).toFixed(2);
  const totalExternalDiskPurchase = filteredDataExternalDisks.reduce((total, externalDisk) => total + (externalDisk.price || 0), 0).toFixed(2);
  const totalBagPurchase = filteredDataBags.reduce((total, bag) => total + (bag.price || 0), 0).toFixed(2);
  const totalClickCounterPurchase = filteredDataClick.reduce((total, clickCounter) => total + (clickCounter.price || 0), 0).toFixed(2);
  const totalSuppliesPurchase = filteredDataSupplies.reduce((total, supplies) => total + (supplies.price || 0), 0).toFixed(2);
  const totalPowerBankPurchase = filteredDataPowerBanks.reduce((total, powerBank) => total + (powerBank.price || 0), 0).toFixed(2);
  const totalGeneralInventoryPurchase = filteredDataSocialMedia.reduce((total, generalInventory) => total + (generalInventory.price || 0), 0).toFixed(2);
  const totalDeleteEquipmentPurchase = filteredDataDeleteEquipment.reduce((total, deleteEquipmentHistories) => total + (deleteEquipmentHistories.price || 0), 0).toFixed(2);

  const totalPurchase = (
    parseFloat(totalMicePurchase || 0) +
    parseFloat(totalKeyboardsPurchase || 0) +
    parseFloat(totalMonitorPurchase || 0) +
    parseFloat(totalLaptopsPurchase || 0) +
    parseFloat(totalPhonesPurchase || 0) +
    parseFloat(totalScannersPurchase || 0) +
    parseFloat(totalPrintersPurchase || 0) +
    parseFloat(totalExtensionCordPurchase || 0) +
    parseFloat(totalTabletsPurchase || 0) +
    parseFloat(totalBackupBaterriesPurchase || 0) +
    parseFloat(totalUSBPurchase || 0) +
    parseFloat(totalDockingStationsPurchase || 0) +
    parseFloat(totalSpeakersPurchase || 0) +
    parseFloat(totalHeadsetsPurchase || 0) +
    parseFloat(totalProjectorPurchase || 0) +
    parseFloat(totalExternalDiskPurchase || 0) +
    parseFloat(totalPowerBankPurchase || 0) +
    parseFloat(totalGeneralInventoryPurchase || 0) +
    parseFloat(totalBagPurchase || 0) +
    parseFloat(totalClickCounterPurchase || 0) +
    parseFloat(totalSuppliesPurchase || 0) +
    parseFloat(totalDeleteEquipmentPurchase || 0)
  ).toFixed(2);

  return (
    <div>
      {/* Botón para ir a la página principal */}
      <div>
        <button
          onClick={() => navigate('/inventory-home')}
          className="home-button"
        >
          <FaHome className="icon-large" />
        </button>
      </div>

      <div className="datePicker">

        <label className="rangeDate">Select Date Range: </label>
        <div className="dp">

          <label>From: </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            isClearable
            dateFormat="MM/dd/yyyy"  // Formato actualizado

          />
        </div>
        <div className="dp">
          <label>To: </label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            isClearable
            dateFormat="MM/dd/yyyy"  // Formato actualizado


          />
        </div>
        {dateError && <p className="date-error">{dateError}</p>}
      </div>


      {isLoading ? (
        <p>Loading...</p>
      ) : hasError ? (
        <p>Error: {errorMice?.message || errorKeyboards?.message || errorMonitors?.message || errorPowerBanks?.message || errorgeneralInventory?.message}</p>
      ) : (

        <div className="inventory-page">



          {/* Renderiza el total de dinero gastado solo si hay un valor positivo */}
          {


            selectedDateType === "purchase_date" && (
              <>
                {parseFloat(totalPurchase) >= 0 && (
                  <label className='totalMoney'>Total Money Spent: $ {totalPurchase}</label>
                )}
              </>
            )

          }
          <div className="reports-search">
            {/* Dropdown para elegir qué campo filtrar */}
            <select onChange={(e) => {
              let value = e.target.value.toLowerCase() === "tagid" ? "tagId" : e.target.value;
              setSearchField(value);
            }}>
              <option value="">Select an opcion</option>
              <option value="tagid">TAG</option>
              <option value="brand">Brand</option>
              <option value="store">Store</option>
              <option value="userId">Assigned To</option>
              <option value="location">Location</option>
            </select>

            {/* Mostrar input o select según el campo elegido */}
            {searchField === "userId" ? (
              <select onChange={handleUserChange}>
                <option value="">Select an Employee</option>
                {navigatorOptions.map(user => (
                  <option key={user.id} value={user.id}>{user.name}</option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                placeholder="Search..."
                value={selectedInput}
                onChange={handleInputChange}
              />
            )}
          </div>


          <div className="grand-total-label">
            <label>
              Total Selected Price of all items: ${calculateGrandTotal().toFixed(2)}
            </label>
          </div>

          {/* Mostrar mensaje solo si todas las tablas están vacías */}
          {allTablesEmpty ? (
            <div className="no-items-message">
              🚫 There are no items available to display on the selected date.
            </div>
          ) : (
            <>

              <EquipmentTable
                title="Mice"
                data={filteredDataMice}
                totalPurchase={totalMicePurchase}
                itemCount={countItems(filteredDataMice)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.MiceSelect}
                onRowSelection={(id) => handleRowSelection("MiceSelect", id)}
                totalSelectedPrice={calculateTotalPrice("MiceSelect", filteredDataMice)}
                showTotalSelectedPrice={false} // Controla si mostrar o no

              />

              <EquipmentTable
                title="Keyboards"
                data={filteredDataKeyboards}
                totalPurchase={totalKeyboardsPurchase}
                itemCount={countItems(filteredDataKeyboards)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.KeyboardsSelect}
                onRowSelection={(id) => handleRowSelection("KeyboardsSelect", id)}
                totalSelectedPrice={calculateTotalPrice("KeyboardsSelect", filteredDataKeyboards)}


              />

              <EquipmentTable
                title="Monitors"
                data={filteredDataMonitors}
                totalPurchase={totalMonitorPurchase}
                itemCount={countItems(filteredDataMonitors)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.MonitorsSelect}
                onRowSelection={(id) => handleRowSelection("MonitorsSelect", id)}
                totalSelectedPrice={calculateTotalPrice("MonitorsSelect", filteredDataMonitors)}


              />

              <EquipmentTable
                title="Laptops"
                data={filteredDataLaptops}
                totalPurchase={totalLaptopsPurchase}
                itemCount={countItems(filteredDataLaptops)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.LaptopsSelect}
                onRowSelection={(id) => handleRowSelection("LaptopsSelect", id)}
                totalSelectedPrice={calculateTotalPrice("LaptopsSelect", filteredDataLaptops)}

              />

              <EquipmentTable
                title="Phones"
                data={filteredDataPhones}
                totalPurchase={totalPhonesPurchase}
                itemCount={countItems(filteredDataPhones)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.PhonesSelect}
                onRowSelection={(id) => handleRowSelection("PhonesSelect", id)}
                totalSelectedPrice={calculateTotalPrice("PhonesSelect", filteredDataPhones)}

              />

              <EquipmentTable
                title="Power Banks"
                data={filteredDataPowerBanks}
                totalPurchase={totalPowerBankPurchase}
                itemCount={countItems(filteredDataPowerBanks)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.PowerBanksSelect}
                onRowSelection={(id) => handleRowSelection("PowerBanksSelect", id)}
                totalSelectedPrice={calculateTotalPrice("PowerBanksSelect", filteredDataPowerBanks)}

              />
              <EquipmentTable
                title="Scanners"
                data={filteredDataScanners}
                totalPurchase={totalScannersPurchase}
                itemCount={countItems(filteredDataScanners)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.ScannersSelect}
                onRowSelection={(id) => handleRowSelection("ScannersSelect", id)}
                totalSelectedPrice={calculateTotalPrice("ScannersSelect", filteredDataScanners)}

              />
              <EquipmentTable
                title="Printers"
                data={filteredDataPrinters}
                totalPurchase={totalScannersPurchase}
                itemCount={countItems(filteredDataPrinters)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.PrintersSelect}
                onRowSelection={(id) => handleRowSelection("PrintersSelect", id)}
                totalSelectedPrice={calculateTotalPrice("PrintersSelect", filteredDataPrinters)}

              />
              <EquipmentTable
                title="Extension Cords"
                data={filteredDataExtensions}
                totalPurchase={totalExtensionCordPurchase}
                itemCount={countItems(filteredDataExtensions)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.ExtensionCordsSelect}
                onRowSelection={(id) => handleRowSelection("ExtensionCordsSelect", id)}
                totalSelectedPrice={calculateTotalPrice("ExtensionCordsSelect", filteredDataExtensions)}

              />
              <EquipmentTable
                title="Tablets"
                data={filteredDataTablets}
                totalPurchase={totalTabletsPurchase}
                itemCount={countItems(filteredDataTablets)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.TabletSelect}
                onRowSelection={(id) => handleRowSelection("TabletSelect", id)}
                totalSelectedPrice={calculateTotalPrice("TabletSelect", filteredDataTablets)}

              />
              <EquipmentTable
                title="Backup Batteries"
                data={filteredDataBackups}
                totalPurchase={totalBackupBaterriesPurchase}
                itemCount={countItems(filteredDataBackups)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.BackupBateriesSelect}
                onRowSelection={(id) => handleRowSelection("BackupBateriesSelect", id)}
                totalSelectedPrice={calculateTotalPrice("BackupBateriesSelect", filteredDataBackups)}

              />
              <EquipmentTable
                title="USB"
                data={filteredDataUSB}
                totalPurchase={totalUSBPurchase}
                itemCount={countItems(filteredDataUSB)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.USBSelect}
                onRowSelection={(id) => handleRowSelection("USBSelect", id)}
                totalSelectedPrice={calculateTotalPrice("USBSelect", filteredDataUSB)}

              />
              <EquipmentTable
                title="Docking Stations"
                data={filteredDataDockings}
                totalPurchase={totalDockingStationsPurchase}
                itemCount={countItems(filteredDataDockings)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.DockingStationsSelect}
                onRowSelection={(id) => handleRowSelection("DockingStationsSelect", id)}
                totalSelectedPrice={calculateTotalPrice("DockingStationsSelect", filteredDataDockings)}

              />
              <EquipmentTable
                title="Speakers"
                data={filteredDataSpeakers}
                totalPurchase={totalSpeakersPurchase}
                itemCount={countItems(filteredDataSpeakers)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.SpeakerSelect}
                onRowSelection={(id) => handleRowSelection("SpeakerSelect", id)}
                totalSelectedPrice={calculateTotalPrice("SpeakerSelect", filteredDataSpeakers)}

              />
              <EquipmentTable
                title="Headsets"
                data={filteredDataHeadsets}
                totalPurchase={totalHeadsetsPurchase}
                itemCount={countItems(filteredDataHeadsets)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.HeadsetSelect}
                onRowSelection={(id) => handleRowSelection("HeadsetSelect", id)}
                totalSelectedPrice={calculateTotalPrice("HeadsetSelect", filteredDataHeadsets)}

              />
              <EquipmentTable
                title="Media & Equipment"
                data={filteredDataSocialMedia}
                totalPurchase={totalGeneralInventoryPurchase}
                itemCount={countItems(filteredDataSocialMedia)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.GeneralInventorySelect}
                onRowSelection={(id) => handleRowSelection("GeneralInventorySelect", id)}
                totalSelectedPrice={calculateTotalPrice("GeneralInventorySelect", filteredDataSocialMedia)}

              />
              <EquipmentTable
                title="Projectors"
                data={filteredDataProjectors}
                totalPurchase={totalProjectorPurchase}
                itemCount={countItems(filteredDataProjectors)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.ProjectorSelect}
                onRowSelection={(id) => handleRowSelection("ProjectorSelect", id)}
                totalSelectedPrice={calculateTotalPrice("ProjectorSelect", filteredDataProjectors)}

              />

              <EquipmentTable
                title="External Disks"
                data={filteredDataExternalDisks}
                totalPurchase={totalExternalDiskPurchase}
                itemCount={countItems(filteredDataExternalDisks)}
                selectedDateType={selectedDateType}
                selectedRows={selectedRows.ExternoDiskSelect}
                onRowSelection={(id) => handleRowSelection("ExternoDiskSelect", id)}
                totalSelectedPrice={calculateTotalPrice("ExternoDiskSelect", filteredDataExternalDisks)}

              />

              {(selectedDateType === "purchase_date" || selectedDateType === "returnable_date") && (
                <EquipmentTable
                  title="Bag"
                  data={filteredDataBags}
                  totalPurchase={totalBagPurchase}
                  itemCount={countItems(filteredDataBags)}
                  selectedDateType={selectedDateType}
                  selectedRows={selectedRows.BagSelect}
                  onRowSelection={(id) => handleRowSelection("BagSelect", id)}
                  totalSelectedPrice={calculateTotalPrice("BagSelect", filteredDataBags)}

                />
              )}

              {selectedDateType === "purchase_date" && (
                <>
                  <EquipmentTable
                    title="Click Counter"
                    data={filteredDataClick}
                    totalPurchase={totalClickCounterPurchase}
                    itemCount={countItems(filteredDataClick)}
                    selectedDateType={selectedDateType}
                    selectedRows={selectedRows.ClickCounterSelect}
                    onRowSelection={(id) => handleRowSelection("ClickCounterSelect", id)}
                    totalSelectedPrice={calculateTotalPrice("ClickCounterSelect", filteredDataClick)}

                  />
                  <EquipmentTable
                    title="Supplies"
                    data={filteredDataSupplies}
                    totalPurchase={totalSuppliesPurchase}
                    itemCount={countItems(filteredDataSupplies)}
                    selectedDateType={selectedDateType}
                    selectedRows={selectedRows.SuppliesSelect}
                    onRowSelection={(id) => handleRowSelection("SuppliesSelect", id)}
                    totalSelectedPrice={calculateTotalPrice("SuppliesSelect", filteredDataSupplies)}

                  />

                  <EquipmentTable
                    title="Broken Equipment"
                    data={filteredDataDeleteEquipment}
                    totalPurchase={totalDeleteEquipmentPurchase}
                    itemCount={countItems(filteredDataDeleteEquipment)}
                    selectedDateType={selectedDateType}
                    selectedRows={selectedRows.DeletEquipSelect}
                    onRowSelection={(id) => handleRowSelection("DeletEquipSelect", id)}
                    totalSelectedPrice={calculateTotalPrice("DeletEquipSelect", filteredDataDeleteEquipment)}

                  />
                </>

              )}
            </>
          )}
        </div>



      )}


    </div>

  );

};



export default ReportByDate;
