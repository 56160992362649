import React, { useState, useEffect } from 'react';
import "./Issue_Ticket.css"; // Optional: External CSS file for styles
import logo from '../../assets/images/BCH Logo Final.png';
import { useQuery, useMutation, useApolloClient, useLazyQuery } from '@apollo/client';
import { getUserWithAssignments, listNotifications, listUsers, listTickets } from '../../graphql/queries';
import { CREATE_TICKET, createNotification } from '../../graphql/mutations';
import { capitalize } from '../../components/common/capitalize';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import useFetchUserByEmail from '../../components/UserInfoLoader/LoadUserInfo'


const Issue_Ticket = () => {

  const { data: userData } = useQuery(listUsers);
  const { data: NotifData, refetch: refetchNotifications } = useQuery(listNotifications);
  const { data: ticketData, refetch: refetchTickets } = useQuery(listTickets);
  const [checkedEquipment, setCheckedEquipment] = useState([]);
  const navigate = useNavigate();
  const [softwareList, setSoftwareList] = useState([{ id: 1, selected: "", name: "" }]);
  const availableSoftware = ["Microsoft Office", "3CX", "Compass", "Adobe", "Heartland", "Outlook"];
  const [quantityError, setQuantityError] = useState('');
  const [priorityError, setPriorityError] = useState('');
  const [dateError, setDateError] = useState('');
  const [problemError, setProblemError] = useState('');
  const [programError, setProgramError] = useState('');
  const [requestError, setRequestError] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const client = useApolloClient(); // Obtener el cliente de Apollo
  const cache = client.cache; // Acceso al caché
  const [userEmail, setUserEmail] = useState(null);
  const [allFilteredEquipment, setAllFilteredEquipment] = useState({});

  const [newIssue_Ticket, setNewIssue_Ticket] = useState({
    id: '',
    type: '',
    name: '',
    location: '',
    time: '',
    date: '',
    dateTimeSupervisor: new Date().toISOString(),
    dateTimeAdmin: new Date().toISOString(),
    supervisor_name: '',
    admin_name: '',
    type_issue: '',
    program_name: '',
    other_program_name: "",  // Para almacenar el valor cuando se selecciona "Other"
    other_request_item: "",// Para almacenar el valor cuando se selecciona "Other"
    equipment_name: '',
    equipment_tag: '',
    equipment_brand: '',
    equipment_model: '',
    equipment_ram: '',
    equipment_storage: '',
    status: 'new',
    completed_date: '',
    request_item: '',
    quantity: '0',
    tried_troubleshooting: '',
    priority: '1',
    supervisor_approval: '',
    troubleshooting_problem1: '',
    troubleshooting_problem2: '',
    admin_troubleshooting_problem: '',
    troubleshooting_steps_taken1: '',
    troubleshooting_steps_taken2: '',
    admin_troubleshooting_steps_taken: '',
    troubleshooting_resolution1: '',
    troubleshooting_resolution2: '',
    admin_troubleshooting_resolution: '',
    date_needed: '',
    purchase_link: '',
    userId: '',

  });

  const [addTicket] = useMutation(CREATE_TICKET, {
    refetchQueries: [{ query: listTickets, variables: { limit: 100 } }]
  });

  const [addNotification] = useMutation(createNotification, {
    refetchQueries: [{ query: listNotifications, variables: { limit: 100 } }]
  });

  const [fetchUserAssignments, { data, error, isLoading }] = useLazyQuery(getUserWithAssignments);

  //listUserFixes 
  const users = (userData?.listUserFixes?.items ?? [])
    .filter((u) => u?.first_name && u?.last_name)
    .map((u) => ({
      id: u.id,
      name: `${u.first_name.trim().charAt(0).toUpperCase()}${u.first_name.trim().slice(1).toLowerCase()} 
           ${u.last_name.trim().charAt(0).toUpperCase()}${u.last_name.trim().slice(1).toLowerCase()}`
        .replace(/\s+/g, ' ') // Elimina espacios dobles accidentales
        .trim() // Asegura que no haya espacios en los extremos
    }))
    .sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }));


  const { userInfo: userLoggedIn, loading: userLoading, error: userError } = useFetchUserByEmail(userEmail);

  const [notifications, setNotifications] = React.useState([]);
  React.useEffect(() => {
    if (NotifData?.listNotifications?.items) {
      setNotifications(NotifData.listNotifications.items);
    }
  }, [NotifData]);

  //On mount, fetch session for groups, set userEmail
  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const session = await fetchAuthSession();
        const groups = session.tokens.accessToken.payload['cognito:groups'] || [];
        setUserGroups(groups);
        // Also fetch username
        const { signInDetails } = await getCurrentUser();
        setUserEmail(signInDetails.loginId);
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };
    fetchUserGroup();
  }, []);

  //Once userLoggedIn is loaded, set userId into NewIssue_Ticket
  useEffect(() => {
    if (userLoggedIn?.id) {
      setNewIssue_Ticket((prevData) => ({
        ...prevData,
        userId: userLoggedIn.id,
        name: userLoggedIn.first_name
          ? `${userLoggedIn.first_name} ${userLoggedIn.last_name}`
          : userLoggedIn.id,  // Si no tiene nombre, usa el ID
      }));
    }
  }, [userLoggedIn]);


  // Establecer valor predeterminado en el select
  useEffect(() => {
    if (userLoggedIn) {
      const fullName = `${userLoggedIn.first_name.trim()} ${userLoggedIn.last_name.trim()}`;
      console.log("Setting default select value:", fullName);
      // Solo actualizar si newIssue_Ticket.name está vacío
      setNewIssue_Ticket((prev) => {
        if (!prev.name) {
          return { ...prev, name: fullName };
        }
        return prev;
      });
    }
  }, [userLoggedIn, setNewIssue_Ticket]);


  //Roles
  const isManager = userGroups?.some(group => group === 'Manager');
  const isAdmin = userGroups?.some(group => group === 'Admin');

  useEffect(() => {

    let selectedUserId = null;

    // Si es Admin/Manager, usa el usuario seleccionado en el `select`
    if (isAdmin || isManager) {
      const selectedUser = users.find((user) => user.id === newIssue_Ticket.name);
      selectedUserId = selectedUser ? selectedUser.id : null;
    } else {
      // Si NO es Admin/Manager, usa el usuario logueado
      selectedUserId = newIssue_Ticket.userId;
    }
    const fetchData = async () => {
      try {
        console.log("🔍 Fetching equipment for user:", selectedUserId);
        await fetchUserAssignments({ variables: { id: selectedUserId } });
      } catch (error) {

      }

    };

    fetchData();
  }, [newIssue_Ticket.name, userLoggedIn, isAdmin, isManager]);

  useEffect(() => {
    if (data?.getUserFix) {
      console.log("🔍 Equipos asignados al usuario:", data.getUserFix);

      setAllFilteredEquipment({
        monitors: data.getUserFix.monitors?.items || [],
        laptops: data.getUserFix.laptops?.items || [],
        phones: data.getUserFix.phones?.items || [],
        headsets: data.getUserFix.headsets?.items || [],
        scanners: data.getUserFix.scanners?.items || [],
        printers: data.getUserFix.printers?.items || [],
        mice: data.getUserFix.mice?.items || [],
        keyboards: data.getUserFix.keyboards?.items || [],
        powerBanks: data.getUserFix.powerBanks?.items || [],
        tabletsRemarkable: data.getUserFix.tabletsRemarkable?.items || [],
        speakers: data.getUserFix.speakers?.items || [],
        externalDisks: data.getUserFix.externalDisks?.items || [],
        dockingStations: data.getUserFix.dockingStations?.items || [],
        extensionCords: data.getUserFix.extensionCords?.items || [],
        backuckBatteries: data.getUserFix.backuckBatteries?.items || [],
        USBs: data.getUserFix.USBs?.items || [],
        bags: data.getUserFix.bags?.items || [],
        generalInventory: data.getUserFix.generalInventory?.items || [],
      });


    }
  }, [data]); // Se ejecuta cada vez que `data` cambia


  // Listas de email de los supervisores
  const supervisorEmails = {
    "Dalia Mohamed": "dalia.mohamed@healthcollaborative.net",
    "Elizabeth Wong": "elizabeth.wong@healthcollaborative.net",
    "Ileana Avila": "ileana.avila@healthcollaborative.net",

  };
  /*"Fuji Walker": "fuji.walker@healthcollaborative.net",*/
  /* "Lisa Ortega": "lisa.ortega@healthcollaborative.net",*/
  /* "Gavriel Gutierrez": "gavriel.gutierrez@healthcollaborative.net",*/

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(newIssue_Ticket.name)

    if (name === "program_name" && value === "other") {
      setNewIssue_Ticket({
        ...newIssue_Ticket,
        [name]: value,  // Almacenamos "other" en program_name
        other_program_name: "", // Limpiamos el valor de "other_program_name" si se cambia
      });
    } else {
      setNewIssue_Ticket({
        ...newIssue_Ticket,
        [name]: value,
      });
    }

  };

  const handleEquipmentCheck = (equipment, e) => {
    const equipmentId = `${equipment.tagid || equipment.tagId || equipment.brand}-${equipment.__typename}`;

    setCheckedEquipment((prevChecked) => {
      const alreadyChecked = prevChecked.includes(equipmentId);
      let updatedChecked;

      if (alreadyChecked) {
        updatedChecked = prevChecked.filter((id) => id !== equipmentId);
        setNewIssue_Ticket((prevTicket) => ({
          ...prevTicket,
          equipment_name: '',
          equipment_tag: '',
          equipment_brand: '',
          equipment_model: '',
          equipment_ram: '',
          equipment_storage: '',

        }));
      } else {
        updatedChecked = [...prevChecked, equipmentId];
        setNewIssue_Ticket((prevTicket) => ({
          ...prevTicket,
          equipment_name: equipment.__typename.replace("fix", ""),
          equipment_tag: equipment.tagid || equipment.tagId || '',
          equipment_brand: equipment.brand || '',
          equipment_model: equipment.model || '',
          equipment_ram: equipment.ram || '',
          equipment_storage: equipment.storage || '',
        }));
      }
      return updatedChecked;
    });
  };

  const clearNewIssue_Ticket = () => {
    setNewIssue_Ticket({
      id: '',
      type: '',
      name: '',
      location: '',
      time: '',
      date: '',
      dateTimeSupervisor: '',
      dateTimeAdmin: '',
      supervisor_name: '',
      admin_name: '',
      type_issue: '',
      program_name: '',
      equipment_name: '',
      equipment_tag: '',
      equipment_brand: '',
      equipment_model: '',
      equipment_ram: '',
      equipment_storage: '',
      status: 'new',
      completed_date: '',
      request_item: '',
      other_program_name: "",
      other_request_item: "",
      quantity: '0',
      tried_troubleshooting: '',
      priority: '1',
      supervisor_approval: '',
      troubleshooting_problem1: '',
      troubleshooting_problem2: '',
      admin_troubleshooting_problem: '',
      troubleshooting_steps_taken1: '',
      troubleshooting_steps_taken2: '',
      admin_troubleshooting_steps_taken: '',
      troubleshooting_resolution1: '',
      troubleshooting_resolution2: '',
      admin_troubleshooting_resolution: '',
      date_needed: '',
      purchase_link: '',
      userId: '',
    });
  };

  // Función para buscar un nombre y apellido por id del Usuario
  const findUserByID = (userId, users) => {
    if (!userId || !Array.isArray(users)) return null;
    const user = users.find((u) => u.id === userId);
    return user ? user.name : null; // Retorna el nombre y apellido formateado, o null si no se encuentra
  };

  const findUserIDByName = (name) => {
    if (!name) return null;

    // Verifica que userData.listUserFixes.items sea un array
    if (!Array.isArray(userData?.listUserFixes?.items)) return null;

    // Formatea el nombre de entrada (eliminando espacios extras y poniendo en minúsculas)
    name = name.replace(/\s+/g, ' ').trim().toLowerCase();

    // Busca el usuario en la lista
    const user = userData.listUserFixes.items.find((u) => {
      const formattedUserName = `${u.first_name} ${u.last_name}`
        .replace(/\s+/g, ' ') // Elimina espacios adicionales
        .trim()
        .toLowerCase(); // Convierte todo a minúsculas

      return formattedUserName === name;
    });

    return user ? user.id : null;
  };

  const handleAddTicket = async () => {


    setIsAdding(true); // Desactiva el botón

    if (!newIssue_Ticket.type) {
      alert("Please select an option")
      setIsAdding(false);
      return;
    }

    if (!newIssue_Ticket.type_issue && newIssue_Ticket.type === "problem") {
      alert("Please select the type of problem")
      setIsAdding(false);
      return;
    }

    if (!newIssue_Ticket.supervisor_name || newIssue_Ticket.supervisor_name === "") {
      alert("Please select a Supervisor Name.");
      setIsAdding(false);
      return;
    }

    if (newIssue_Ticket.type === "problem" && newIssue_Ticket.type_issue === "equipment" && !newIssue_Ticket.equipment_name) {
      alert("Please Choose an Equipment");
      setIsAdding(false);
      return;
    }


    try {
      if (!newIssue_Ticket) {
        console.error("No ticket data available.");
        return;
      }

      const program_new = newIssue_Ticket.program_name === "other"
        ? newIssue_Ticket.other_program_name
        : newIssue_Ticket.program_name;

      const request_item_new = newIssue_Ticket.request_item === "other"
        ? newIssue_Ticket.other_request_item
        : newIssue_Ticket.request_item;

      const formattedTime = newIssue_Ticket.time || null;
      const formattedDate = newIssue_Ticket.date
        ? new Date(newIssue_Ticket.date).toISOString().split('T')[0]
        : null;

      const formattedDate_Needed = newIssue_Ticket.date_needed
        ? new Date(newIssue_Ticket.date_needed).toISOString().split('T')[0]
        : null;
      const formattedCompleted_Date = newIssue_Ticket.completed_date
        ? new Date(newIssue_Ticket.completed_date).toISOString().split('T')[0]
        : null;

      // Validaciones
      if (!Number.isInteger(parseInt(newIssue_Ticket.quantity, 10)) || parseInt(newIssue_Ticket.quantity, 10) < 0) {
        setQuantityError('The Quantity must be a positive integer');
        setIsAdding(false);
        return;
      } else {
        setQuantityError('');
      }


      if (!newIssue_Ticket.priority || !Number.isInteger(parseInt(newIssue_Ticket.priority, 10))) {
        setPriorityError('The Priority must be an integer and cannot be empty.');
        setIsAdding(false);
        return;
      } else {
        setPriorityError('');
      }

      if (!newIssue_Ticket.date) {
        setDateError('The Date cannot be empty.');
        setIsAdding(false);
        return;
      } else {
        setDateError('');
      }

      if (newIssue_Ticket.type_issue === "software") {
        if (!newIssue_Ticket.program_name || newIssue_Ticket.program_name.trim() === "") {
          setProgramError("The Software cannot be empty.");
          setIsAdding(false);
          return;
        }
      }
      setProgramError("");

      if (newIssue_Ticket.type === "request") {
        if (!newIssue_Ticket.request_item || newIssue_Ticket.request_item.trim() === "") {
          setRequestError("The Request Item cannot be empty.");
          setIsAdding(false);
          return;
        }
      }
      setRequestError("");

      if (!newIssue_Ticket.troubleshooting_problem1 && newIssue_Ticket.type !== "request") {
        setProblemError('The Problem cannot be empty.');
        setIsAdding(false);
        return;
      } else {
        setProblemError('');
      }

      if (!newIssue_Ticket.troubleshooting_steps_taken1 && newIssue_Ticket.type !== "request") {
        setProblemError('The Steps Taken cannot be empty.');
        setIsAdding(false);
        return;
      } else {
        setProblemError('');
      }

      console.log(newIssue_Ticket.user)

      const safeUserId = newIssue_Ticket.userId && newIssue_Ticket.userId.trim() !== "" ? newIssue_Ticket.userId : "n/a";
      const FullNameIsAdmin = findUserByID(newIssue_Ticket.name, users) // Si selecciona el employee
      const FullNameLogin = `${userLoggedIn.first_name.trim()} ${userLoggedIn.last_name.trim()}`; // usuario login

      // Si pasan todas las validaciones
      const updatedFormData = {
        type: newIssue_Ticket.type,
        name: FullNameIsAdmin || FullNameLogin,
        location: newIssue_Ticket.location,
        time: formattedTime,
        date: formattedDate,
        dateTimeSupervisor: null,
        dateTimeAdmin: null,
        supervisor_name: newIssue_Ticket.supervisor_name,
        admin_name: newIssue_Ticket.admin_name,
        type_issue: newIssue_Ticket.type_issue,
        program_name: program_new,
        equipment_name: newIssue_Ticket.equipment_name,
        equipment_tag: newIssue_Ticket.equipment_tag,
        equipment_brand: newIssue_Ticket.equipment_brand,
        equipment_model: newIssue_Ticket.equipment_model,
        equipment_ram: newIssue_Ticket.equipment_ram,
        equipment_storage: newIssue_Ticket.equipment_storage,
        status: newIssue_Ticket.status,
        completed_date: formattedCompleted_Date,
        request_item: request_item_new,
        quantity: newIssue_Ticket.quantity,
        tried_troubleshooting: newIssue_Ticket.tried_troubleshooting || null,
        priority: newIssue_Ticket.priority,
        date_needed: formattedDate_Needed,
        supervisor_approval: newIssue_Ticket.supervisor_approval,
        troubleshooting_problem1: newIssue_Ticket.troubleshooting_problem1,
        troubleshooting_problem2: newIssue_Ticket.troubleshooting_problem2,
        admin_troubleshooting_problem: newIssue_Ticket.admin_troubleshooting_problem,
        troubleshooting_steps_taken1: newIssue_Ticket.troubleshooting_steps_taken1,
        troubleshooting_steps_taken2: newIssue_Ticket.troubleshooting_steps_taken2,
        admin_troubleshooting_steps_taken: newIssue_Ticket.admin_troubleshooting_steps_taken,
        troubleshooting_resolution1: newIssue_Ticket.troubleshooting_resolution1,
        troubleshooting_resolution2: newIssue_Ticket.troubleshooting_resolution2,
        admin_troubleshooting_resolution: newIssue_Ticket.admin_troubleshooting_resolution,
        purchase_link: newIssue_Ticket.purchase_link,
        userId: safeUserId,

      };
      console.log("data de ticket", updatedFormData)


      //Send ticket To DB
      const { data } = await addTicket({
        variables: { input: updatedFormData }
      });

      const ticketId = data?.createTicket?.id; // ID del Ticket
      const displayTicketId = ticketId.toString(36).toUpperCase().slice(-6);
      clearNewIssue_Ticket();

      // Supervisor Emails
      const supervisorName = newIssue_Ticket.supervisor_name;
      const supervisorEmail = supervisorEmails[supervisorName];

      const base64Content = newIssue_Ticket.errorPhoto
        ? newIssue_Ticket.errorPhoto.replace(/^data:image\/\w+;base64,/, "")
        : null;

      // Función para obtener una fecha 1 mes después de la fecha dada
      const getExpirationDate = (startDate) => {
        const date = new Date(startDate); // Usar la fecha inicial
        date.setMonth(date.getMonth() + 1); // Sumar 1 meses
        return date.toISOString(); // Convertir a formato ISO
      };

      // Generar la fecha de expiración
      const timestampNow = new Date().toISOString(); // Fecha actual en formato ISO
      const expirationDate = getExpirationDate(timestampNow);

      //Send  Compass notification to the user who started the ticket
      const NotificationData = {
        type: 'Ticket',
        userID: safeUserId, // ID del usuario encontrado
        supervisorID: findUserIDByName(supervisorName),
        adminID: 'null',
        relatedId: ticketId,
        status: 'unread',
        active: true,
        creationDate: timestampNow,
        expirationDate: expirationDate,
        userId: safeUserId, // ID del usuario encontrado relacion con la tabla UserFix
      };
      console.log("antes de add la notificacion", NotificationData)

      try {

        // Aquí asumimos que `addNotification` es una función que realiza la mutación GraphQL
        const result = await addNotification({ variables: { input: NotificationData } });

        console.log("Notification created successfully:", result);


        // Extraer la notificación creada del resultado de la mutación
        const createNotification = result?.data?.addNotification;

        if (!createNotification) {
          throw new Error("The server did not return the expected notification data.");
        }

        // Actualizar manualmente el caché de Apollo para incluir la nueva notificación
        cache.modify({
          fields: {
            listNotifications(existingNotifications = { items: [] }) {
              return {
                ...existingNotifications,
                items: [
                  createNotification,
                  ...existingNotifications.items,
                ],
              };
            },
          },
        });

        // Refetch 
        await refetchNotifications();

      } catch (error) {
        console.error("Failed to add the notification:", error);
      }

      if (newIssue_Ticket.errorPhoto) {
        sessionStorage.setItem("temporaryImage", base64Content);
      }

      if (!supervisorEmail) {
        console.error("No email found for the selected supervisor:", supervisorName);
        alert("Invalid supervisor selected. Please check.");
        return;
      }
      else {
        const emailContent = {
          to: supervisorEmail,
          subject: `New Ticket Created by ${newIssue_Ticket.supervisor_name}`,
          body: `
          <html>
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0;">
              <table width="100%" cellspacing="0" cellpadding="0" border="0" >
                <tr>
                  <td align="center">
                    <table width="600" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff" style="border: 1px solid #ddd;">
                      <tr>
                        <td align="center" bgcolor="#1a2a6c" style="padding: 20px;">
                          <h1 style="color: white; margin: 0; font-size: 24px;">New Ticket Notification</h1>
                        </td>
                      </tr>
        
                      <tr>
                        <td style="padding: 30px;">
                          <p style="margin: 0 0 10px; font-size: 16px;">A ticket requires your attention. Please review the details below:</p>
                          
                          <table width="100%" border="1" cellpadding="10" cellspacing="0" style="border-collapse: collapse;">
                            <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Ticket ID</th>
                              <td>${displayTicketId}</td>
                            </tr>
                            <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Employee Name:</th>
                      <td>${capitalize(FullNameIsAdmin) || capitalize(FullNameLogin)}</td>
                            </tr>
                            <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Location:</th>
                              <td>${newIssue_Ticket.location}</td>
                            </tr>
                            <tr>
                              <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Date:</th>
                              <td>${newIssue_Ticket.date}</td>
                            </tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Priority:</th>
                              <td>
                                ${newIssue_Ticket.priority === "1" ? 'Low Priority' :
              newIssue_Ticket.priority === "2" ? 'Medium Priority' :
                newIssue_Ticket.priority === "3" ? 'High Priority' :
                  'Unknown Priority'
            }
                              </td>
                            </tr>
                            <tr>
                              <th align="left" bgcolor="#1a2a6c" style="color: white;">Report Type:</th>
                              <td>${newIssue_Ticket.type}</td>
                            </tr>
                             ${newIssue_Ticket.type_issue ? `
                          <tr>
                            <th align="left" bgcolor="#1a2a6c" style="color: white;">Issue Type:</th>
                            <td>${capitalize(newIssue_Ticket.type_issue)}</td>
                          </tr>
                           ` : ''}
                            ${newIssue_Ticket.equipment_name ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Equipment:</th>
                            <td>${newIssue_Ticket.equipment_name
                ? capitalize(newIssue_Ticket.equipment_name.replace(/fix/gi, "").trim())
                : "N/A"}</td>
                              </tr>
                              ` : ''}
                              
                            ${newIssue_Ticket.program_name ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Software/WebSite:</th>
                                <td>${capitalize(newIssue_Ticket.program_name)}</td>
                              </tr>
                              ` : ''}
                                 ${newIssue_Ticket.request_item ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Request a Item:</th>
                                <td>${capitalize(newIssue_Ticket.request_item)}</td>
                              </tr>
                              ` : ''}
                                 ${newIssue_Ticket.status ? `
                              <tr>
                                <th align="left" bgcolor="#1a2a6c" style="color: white;">Status:</th>
                                <td>${capitalize(newIssue_Ticket.status)}</td>
                              </tr>
                              ` : ''}
                              
                          </table>
                       

                          <p style="margin-top: 30px; font-size: 14px;">
                            Click the link below to update the ticket:
                          </p>
        
                          <table cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                              <td align="center">
                                <a href="https://compasshealthcollaborative.org/issue-ticket-list?ticketId=${ticketId}"
                                   style="display: inline-block; padding: 12px 25px; font-size: 16px; color: white; background-color: #1a2a6c; text-decoration: none; border: 1px solid #007bff;">
                                  Open Ticket #${displayTicketId}
                                </a>
                              </td>
                            </tr>
                          </table>
                           ${base64Content ? `
                             <p style="margin-top: 20px; font-size: 16px;">Attached Image:</p>
                <img src="data:image/png;base64,${base64Content}" alt="Error Photo" style="max-width: 100%; height: auto;"/>
           ` : ''}
                          <p style="margin-top: 40px; font-size: 12px; color: #666; text-align: center;">
                            This is an automated email. Please do not reply.
                             &copy; 2025 Compass Health Collaborative. All Rights Reserved.
                          </p>
                          
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </body>
          </html>
          `,
        };


        await invokeLambdaFunction(emailContent);
        console.log("Notification sent to supervisor.");
      }

    }
    catch (error) {
      console.error("Error adding ticket:", error);
      alert("Failed to add the ticket.");
    }
    setIsAdding(false); // Reactiva el botón después de completar el proceso
    // Refetch 
    await refetchTickets();

  };

  const friendlyNames = {
    laptops: "Laptops",
    printers: "Printers",
    monitors: "Monitors",
    phones: "Phones",
    scanners: "Scanners",
    headsets: "Headsets",
    mice: "Mice",
    keyboards: "Keyboards",
    powerbanks: "Power Banks",
    speakers: "Speakers",
    dockingStations: "Docking Stations",
    extensionCords: "Extension Cords",
    backupBaterries: "Backup Baterries",
    tablets: "Tablets",
    externalDisks: "External Disks",
    usb: "USB Drive",
    bags: "Bags",
    otherEquipment: "Other Equipment",
  };

  // Lambda que enviará un correo al supervisor usando Amazon SES 
  const invokeLambdaFunction = async (emailData) => {
    const url = "https://buykqjzjjh.execute-api.us-east-2.amazonaws.com/compassdev/sendemail";

    try {
      // Obtener token de autenticación (si es necesario)
      const session = await fetchAuthSession();
      if (!session || !session.tokens || !session.tokens.idToken) {
        throw new Error("User is not authenticated or token is missing.");
      }
      const idToken = session.tokens.idToken.toString();

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send email. Error: ${errorText}`);
      }

      const data = await response.json();
      console.log("Lambda function response:", data);
      alert("Ticket and notification sent successfully!");
    } catch (error) {
      console.error("Error invoking Lambda:", error.message);
      alert("Failed to send notification email.");
    }
  };

  return (
    <div className="issue-container">
      <header className="reports-header">
        <h1>System Issue Report - FY 23</h1>
      </header>
      <div className="issue-header">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {/* View Previous*/}
      <div className="previous-report">
        <button onClick={() => navigate('/issue-ticket-list')} >View Previous System Reports</button>
      </div>

      {/* Select Report Type */}
      <div className="issue-section">
        <h3>Select Report Type:</h3>
        <select
          id="type"
          name="type"
          value={newIssue_Ticket.type || ""}
          onChange={handleInputChange}
        >
          <option value="" disabled hidden>
            Select an option
          </option>
          <option value="problem">Report an Issue</option>
          <option value="request">Request an Item</option>

        </select>
      </div>

      {/* Conditionally Render Select Issue Type */}
      {newIssue_Ticket.type === "problem" && (
        <div className="issue-section">
          <h3>Issue Type:</h3>
          <select
            id="type_issue"
            name="type_issue"
            value={newIssue_Ticket.type_issue}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>
              Select an option
            </option>
            <option value="equipment">Equipment</option>
            <option value="software">Software</option>
          </select>
        </div>
      )}
      {/* Conditionally Render Sections */}
      {newIssue_Ticket.type === "problem" && (newIssue_Ticket.type_issue === "software" || newIssue_Ticket.type_issue === "equipment") && (
        <>

          {/* Employee Section */}
          <div className="issue-section">
            <div className="input-issue">
              <label htmlFor="employee-name">Employee Name:</label>
              <select
                id="name"
                name="name"
                value={
                  isAdmin || isManager
                    ? newIssue_Ticket.name  // Si es Admin o Manager, usa el valor seleccionado
                    : `${userLoggedIn?.first_name} ${userLoggedIn?.last_name}` // Si NO es Admin, usa el usuario logueado
                }
                onChange={isAdmin || isManager ? handleInputChange : undefined} // Permite cambiar si es Admin o Manager
                disabled={!(isAdmin || isManager)} // Deshabilitar si no es Admin o Manager
                required={isAdmin || isManager} // Solo es requerido si es Admin o Manager
              >
                {/* Si no es Admin ni Manager, muestra el usuario loggeado como opción */}
                {!(isAdmin || isManager) && (
                  <option value={`${userLoggedIn?.first_name} ${userLoggedIn?.last_name}`}>
                    {`${capitalize(userLoggedIn?.first_name)} ${capitalize(userLoggedIn?.last_name)}`}
                  </option>
                )}

                {/* Opciones generales */}
                <option value="" >Select an employee</option>
                {users.length > 0 ? (
                  users.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>No employees available</option>
                )}

                <option value="n/a">N/A</option>
              </select>
            </div>
            <div className="input-issue">
              <label htmlFor="location">Location:</label>
              <input
                type="text"
                id="location"
                name="location"
                value={newIssue_Ticket.location}
                onChange={handleInputChange}
              />

            </div>

            <div className="input-issue">
              <label htmlFor="supervisor">Supervisor Name:</label>
              <select
                id="supervisor_name"
                name="supervisor_name"
                value={newIssue_Ticket.supervisor_name}
                onChange={(e) => handleInputChange(e)}

              >
                <option value="" disabled hidden>Select a Name</option>
                <option value="Dalia Mohamed">Dalia Mohamed</option>
                <option value="Elizabeth Wong">Elizabeth Wong</option>
                <option value="Ileana Avila">Ileana Avila (Testing)</option>


              </select>

            </div>

            <div className="input-issue">
              <label htmlFor="time-submitted">Time Submitted:</label>
              <input type="time" id="time" name="time" value={newIssue_Ticket.time} onChange={handleInputChange} />

              <label htmlFor="date-submitted">Date:</label>
              <input type="date" id="date" name="date" value={newIssue_Ticket.date} onChange={handleInputChange} />
            </div>
          </div>

          {/* Lista de Equipos Asignados */}
          {newIssue_Ticket.type_issue === "equipment" && (
            <div className="issue-section">
              {(newIssue_Ticket.name || `${userLoggedIn?.first_name} ${userLoggedIn?.last_name}`) && (
                <div className="assigned-equipment">
                  <h3>Equipment Assigned (Choose one):</h3>
                  <div className="equipment-container">
                    {Object.entries(allFilteredEquipment).map(([equipmentType, items]) =>
                      items.length > 0 ? (
                        <div key={equipmentType} className="equipment-category">
                          <label className="equipment-label">
                            {friendlyNames[equipmentType] || equipmentType}
                          </label>
                          <ul>
                            {items.map((equipment) => {
                              const equipmentId = `${equipment.tagid || equipment.tagId || equipment.brand}-${equipment.__typename}`;

                              return (
                                <li key={equipment.id || equipmentId}>
                                  <label className="equipment-item">
                                    <input
                                      type="checkbox"
                                      checked={checkedEquipment.includes(equipmentId)}
                                      onChange={(e) => handleEquipmentCheck(equipment, e)}
                                    />
                                    <span>{equipment.tagid || equipment.tagId || equipment.type}</span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              )}

            </div>
          )}

          {newIssue_Ticket.type_issue === "software" && (
            <div className="issue-section">
              <h3>Software/Website</h3>
              <ul>
                {softwareList.map((software, index) => (
                  <li key={software.id || index} className="software-item">
                    <label className="software-row">
                      {/* Select Software */}
                      <select
                        value={newIssue_Ticket.program_name || ""}
                        name="program_name"
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="" disabled hidden>
                          Select software/website
                        </option>
                        {availableSoftware.map((softwareOption) => (
                          <option key={softwareOption} value={softwareOption}>
                            {softwareOption}
                          </option>
                        ))}
                        <option value="other">Other</option>
                      </select>
                    </label>

                    {/* Show input if "Other" is selected */}
                    {newIssue_Ticket.program_name === "other" && (
                      <input
                        type="text"
                        placeholder="Enter software/website name"
                        value={newIssue_Ticket.other_program_name || ""}
                        name="other_program_name"
                        onChange={(e) => {
                          // Actualiza el estado para "Other"
                          setNewIssue_Ticket({
                            ...newIssue_Ticket,
                            other_program_name: e.target.value, // Actualizamos el valor solo cuando "Other" es seleccionado
                          });
                        }}
                      />
                    )}
                  </li>
                ))}

              </ul>
              {/* Image Upload Section */}
              <div className="upload-photo-section">
                <label htmlFor="errorPhoto">Upload a photo of the error (optional):</label>
                <input
                  type="file"
                  id="errorPhoto"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Handle file upload (store in state or process it)
                      const reader = new FileReader();
                      reader.onload = () => {
                        setNewIssue_Ticket({
                          ...newIssue_Ticket,
                          errorPhoto: reader.result, // Base64 representation of the image
                          errorPhotoName: file.name,
                        });
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                {newIssue_Ticket.errorPhoto && (
                  <div className="photo-preview">
                    <h4>Preview:</h4>
                    <img
                      src={newIssue_Ticket.errorPhoto}
                      alt="Error Preview"
                      style={{ maxWidth: "200px", maxHeight: "200px", marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>

            </div>
          )}
          {/* Troubleshooting Section */}
          {newIssue_Ticket.type === "problem" && (
            <>
              <div className="issue-section">
                <h3>1st Troubleshoot</h3>
                <label>Tried Troubleshooting:</label>
                <select
                  name="tried_troubleshooting"
                  placeholder="tried_troubleshooting"
                  value={newIssue_Ticket.tried_troubleshooting}
                  onChange={handleInputChange}
                >
                  <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>

                <label htmlFor="priority">Priority:</label>
                <select
                  id="priority"
                  name="priority"
                  value={newIssue_Ticket.priority}
                  onChange={handleInputChange}
                  aria-label="Select ticket priority"
                >
                  <option value="" disabled hidden>Select Priority</option>
                  <option value="1">1 - Low Priority</option>
                  <option value="2">2 - Medium Priority</option>
                  <option value="3">3 - High Priority</option>
                </select>


                <label htmlFor="problem">Problem:</label>
                <textarea
                  className='long-text'
                  type='text'
                  id="troubleshooting_problem1"
                  name="troubleshooting_problem1"
                  placeholder="Describe the problem"
                  value={newIssue_Ticket.troubleshooting_problem1}
                  onChange={handleInputChange}
                  required
                ></textarea>

                <label htmlFor="steps-taken">Steps Taken:</label>
                <textarea
                  className='long-text'
                  type='text'
                  id="troubleshooting_steps_taken1"
                  name="troubleshooting_steps_taken1"
                  placeholder="Describe the steps taken"
                  value={newIssue_Ticket.troubleshooting_steps_taken1}
                  onChange={handleInputChange}
                  required

                ></textarea>

                <label htmlFor="resolution">Resolution:</label>
                <textarea
                  className='long-text'
                  type='text'
                  id="troubleshooting_resolution1"
                  name="troubleshooting_resolution1"
                  placeholder="Describe the resolution"
                  value={newIssue_Ticket.troubleshooting_resolution1}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              {/* Mostrar esta sección solo si el usuario es Supervisor */}
              {isManager && (
                <div className="issue-section">
                  <h3>2nd Troubleshoot (Only for supervisors)</h3>
                  <label htmlFor="priority">Priority:</label>
                  <select
                    id="priority"
                    name="priority"
                    value={newIssue_Ticket.priority}
                    onChange={handleInputChange}
                    aria-label="Select ticket priority"
                  >
                    <option value="" disabled hidden>Select Priority</option>
                    <option value="1">1 - Low Priority</option>
                    <option value="2">2 - Medium Priority</option>
                    <option value="3">3 - High Priority</option>
                  </select>


                  <label htmlFor="problem">Problem:</label>
                  <textarea
                    className='long-text'
                    type='text'
                    id="troubleshooting_problem2"
                    name="troubleshooting_problem2"
                    placeholder="Describe the problem"
                    value={newIssue_Ticket.troubleshooting_problem2}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="steps-taken">Steps Taken:</label>
                  <textarea
                    className='long-text'
                    type='text'
                    id="troubleshooting_steps_taken2"
                    name="troubleshooting_steps_taken2"
                    placeholder="Describe the steps taken"
                    value={newIssue_Ticket.troubleshooting_steps_taken2}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="resolution">Resolution:</label>
                  <textarea
                    className='long-text'
                    type='text'
                    id="troubleshooting_resolution2"
                    name="troubleshooting_resolution2"
                    placeholder="Describe the resolution"
                    value={newIssue_Ticket.troubleshooting_resolution2}
                    onChange={handleInputChange}
                  ></textarea>
                  <label htmlFor="status">Status:</label>
                  <select
                    name="status"
                    id="status"
                    value="new"
                    onChange={handleInputChange}
                    aria-label="Select ticket status"
                    disabled
                  >
                    <option value="new">New</option>
                    <option value="in_progress">In Progress</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
              )}

              {/* Mostrar esta sección solo si el usuario es Admin */}
              {isAdmin && (
                <div className="issue-section">
                  <h3>Admin Section (Only for Administration)</h3>
                  <label htmlFor="priority">Priority:</label>
                  <select
                    id="priority"
                    name="priority"
                    value={newIssue_Ticket.priority}
                    onChange={handleInputChange}
                    aria-label="Select ticket priority"
                  >
                    <option value="" disabled hidden>Select Priority</option>
                    <option value="1">1 - Low Priority</option>
                    <option value="2">2 - Medium Priority</option>
                    <option value="3">3 - High Priority</option>
                  </select>


                  <label htmlFor="problem">Problem Found:</label>
                  <textarea
                    className='long-text'
                    type='text'
                    id="admin_troubleshooting_problem"
                    name="admin_troubleshooting_problem"
                    placeholder="Describe the problem"
                    value={newIssue_Ticket.admin_troubleshooting_problem}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="steps-taken">Steps Taken:</label>
                  <textarea
                    className='long-text'
                    type='text'
                    id="admin_troubleshooting_steps_taken"
                    name="admin_troubleshooting_steps_taken"
                    placeholder="Describe the steps taken"
                    value={newIssue_Ticket.admin_troubleshooting_steps_taken}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="resolution">Resolution:</label>
                  <textarea
                    className='long-text'
                    type='text'
                    id="admin_troubleshooting_resolution"
                    name="admin_troubleshooting_resolution"
                    placeholder="Describe the resolution"
                    value={newIssue_Ticket.admin_troubleshooting_resolution}
                    onChange={handleInputChange}
                  ></textarea>

                  <label htmlFor="status">Status:</label>
                  <select
                    name="status"
                    id="status"
                    value={newIssue_Ticket.status || ""}
                    onChange={handleInputChange}
                    aria-label="Select ticket status"
                  >
                    <option value="" disabled hidden className="placeholder-option">Select Status</option>
                    <option value="new">New</option>
                    <option value="in_progress">In Progress</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="completed">Completed</option>
                  </select>

                  {newIssue_Ticket.status === "completed" && (
                    <div className="date-completed_date">
                      <label htmlFor="completed_date">Completed Date:</label>
                      <input
                        type="date"
                        id="completed_date"
                        name="completed_date"
                        value={newIssue_Ticket.completed_date || ""}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}


        </>
      )
      }

      {
        newIssue_Ticket.type === "request" && (
          <>
            {/* Supplies Request Section */}
            <div className="issue-section">
              <div className="issue-section">
                <div className="input-issue">
                  <label htmlFor="employee-name">Employee Name:</label>
                  <select
                    id="name"
                    name="name"
                    value={
                      (isAdmin || isManager)
                        ? newIssue_Ticket.name
                        : `${userLoggedIn?.first_name} ${userLoggedIn?.last_name}`
                    }
                    onChange={isAdmin || isManager ? handleInputChange : undefined} // Permite cambiar si es Admin o Manager
                    disabled={!(isAdmin || isManager)} // Deshabilitar si no es Admin o Manager
                    required={isAdmin || isManager} // Solo es requerido si es Admin o Manager
                  >
                    {/* Si no es Admin ni Manager, muestra el usuario loggeado como opción */}
                    {!(isAdmin || isManager) && (

                      <option value={`${userLoggedIn?.first_name} ${userLoggedIn?.last_name}`}>
                        {`${capitalize(userLoggedIn?.first_name)} ${capitalize(userLoggedIn?.last_name)}`}
                      </option>
                    )}
                    {/* Opciones generales */}
                    <option value="">Select an Employee</option>
                    {users.length > 0 ? (
                      users.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>No employees available</option>
                    )}

                    <option value="n/a">N/A</option>
                  </select>
                </div>
                <div className="input-issue">
                  <label htmlFor="location">Location:</label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    value={newIssue_Ticket.location}
                    onChange={handleInputChange}

                  />

                </div>

                <div className="input-issue">
                  <label htmlFor="supervisor">Supervisor Name:</label>
                  <select
                    id="supervisor_name"
                    name="supervisor_name"
                    value={newIssue_Ticket.supervisor_name}
                    onChange={(e) => handleInputChange(e)}
                    required

                  >
                    <option value="" disabled hidden>Select a Name</option>
                    <option value="Dalia Mohamed">Dalia Mohamed</option>
                    <option value="Elizabeth Wong">Elizabeth Wong</option>
                    <option value="Ileana Avila">Ileana Avila (Testing)</option>

                  </select>
                </div>


                <div className="input-issue">
                  <label htmlFor="time-submitted">Time Submitted:</label>
                  <input type="time" id="time" name="time" value={newIssue_Ticket.time} onChange={handleInputChange} />

                  <label htmlFor="date-submitted">Date:</label>
                  <input type="date" id="date" name="date" value={newIssue_Ticket.date} onChange={handleInputChange} required />
                </div>
              </div>


              <h3>Request Supplies</h3>
              <label htmlFor="request_item">Select Item Type:</label>
              <select id="request_item" name="request_item" value={newIssue_Ticket.request_item} onChange={(e) => handleInputChange(e)}>
                <option value="" disabled hidden>
                  Select an item
                </option>
                <option value="Monitor Charger Cable">Monitor Charger</option>
                <option value="Laptop Charger Cable">Laptop Charger</option>
                <option value="Phone Charger Cable">Phone Charger</option>
                <option value="HDMI Cable">HDMI Cable</option>
                <option value="C-Cable">C-C Cable</option>
                <option value="Wireless usb">Wireless Receiver USB</option>
                <option value="Ink black">Ink Black</option>
                <option value="Ink color">Ink Color</option>
                <option value="Supplies">Office Supplies</option>
                <option value="other">Other</option> {/* Opción para agregar algo personalizado */}
              </select>

              {/* Mostrar el campo de entrada si se selecciona "Other" */}
              {newIssue_Ticket.request_item === "other" && (
                <div style={{ marginTop: "10px" }}>
                  <label htmlFor="custom-item">Specify your item:</label>
                  <input
                    type="text"
                    id="other_request_item"
                    name="other_request_item"
                    value={newIssue_Ticket.other_request_item || ""}
                    onChange={(e) => {
                      // Actualiza el estado para "Other"
                      setNewIssue_Ticket({
                        ...newIssue_Ticket,
                        other_request_item: e.target.value, // Actualizamos el valor solo cuando "Other" es seleccionado
                      });
                    }}
                  />
                </div>
              )}
              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                placeholder="Enter quantity"
                value={newIssue_Ticket.quantity}
                onChange={handleInputChange}
                min="0"
              />
              <label htmlFor="date-needed">Date Needed:</label>
              <input
                type="date"
                id="date-needed"
                name="date_needed"
                value={newIssue_Ticket.date_needed}
                onChange={handleInputChange}
              />
              <label htmlFor="purchase-link">Suggested Purchase Link:</label>
              <input
                type="url"
                id="purchase-link"
                name="purchase_link"
                placeholder="Enter a purchase link (optional)"
                value={newIssue_Ticket.purchase_link}
                onChange={handleInputChange}

              />
              <label>Additional Notes:</label>
              <textarea
                id="troubleshooting_problem1"
                name="troubleshooting_problem1"
                placeholder="If you need ink, please enter the ink number or printer data..."
                value={newIssue_Ticket.troubleshooting_problem1}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </>
        )
      }


      {priorityError && <p className="error">{priorityError}</p>} {/* Display priorityError error message */}
      {quantityError && <p className="error">{quantityError}</p>} {/* Display quantityError error message */}
      {dateError && <p className="error">{dateError}</p>} {/* Display dateError error message */}
      {programError && <p className="error">{programError}</p>} {/* Display programError error message */}
      {problemError && <p className="error">{problemError}</p>} {/* Display problemError error message */}
      {requestError && <p className="error">{requestError}</p>} {/* Display requestError error message */}
      <button type="submit" onClick={handleAddTicket} disabled={isAdding}>
        {isAdding ? 'Sending...' : 'Submit Report'}
      </button>
    </div >
  );
};

export default Issue_Ticket;
