import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './WeeklyReports.css';

const WeeklyReports = ({ allReports, startDate, endDate }) => {
    // const [allReports, setAllReports] = useState([]);
    const generateWeekLabel = (date) => {
        const referenceDate = new Date(2024, 7, 27); // August 27, 2024
        const oneDayInMillis = 86400000;
        const daysDifference = Math.floor((date - referenceDate) / oneDayInMillis);
        const weekNumber = Math.floor((daysDifference - 2) / 7) + 1;
        return `Week ${weekNumber + 1}`;
    };

    const generateDateRange = (startDate, endDate) => {
        const start = new Date(startDate).toLocaleDateString();
        const end = new Date(endDate).toLocaleDateString();
        return `${start} to ${end}`;
    };

    const countHealthcareGovAccInstances = () => {
        const counts = {
            Creation: 0,
            Troubleshooting: 0,
        };
        allReports.forEach(item => {
            if (item.HealtcareGovAcc === 'Creation') {
                counts.Creation += 1;
            } else if (item.HealtcareGovAcc === 'Troubleshooting') {
                counts.Troubleshooting += 1;
            }
        });
        return counts;
    };

    const countNestedSpecificValues = (field, targetValue) => {
        return allReports.reduce((acc, item) => {
            if (item[field] && item[field].includes(targetValue)) {
                acc += 1;
            }
            return acc;
        }, 0) || 0;
    };

    const countSumValues = (field) => {
        return allReports.reduce((acc, item) => {
            acc += parseFloat(item[field]) || 0;
            return acc;
        }, 0) || 0;
    };

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    return (
        <div className='weeklyReports-container'>
            <p>Total Reports Fetched: {allReports.length}</p>
            <table className="weeklyReports-table">
                <thead>
                    <tr>
                        <th>{generateWeekLabel(startDate)}</th>
                        <th>{generateDateRange(startDate, endDate)}</th>
                        {/* Add other column headers here */}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1. Addressing general inquiries about health insurance options*</td>
                        <td>{countSumValues('one2oneInquiry')}</td>
                    </tr>
                    <tr>
                        <td>A. Health Insurance literacy</td>
                        <td>{countSumValues('HealthInsuranceLiteracy')}</td>
                    </tr>
                    <tr>
                        <td>B. Locating providers</td>
                        <td>{countSumValues('Locating_Providers')}</td>
                    </tr>
                    <tr>
                        <td>C. Billing and payment questions</td>
                        <td>{countSumValues('Billing_Payment_Questions')}</td>
                    </tr>
                    <tr>
                        <td>D. Evaluating health care options using tools and information available through a consumer's health plan</td>
                        <td>{countSumValues('EvaluateHealtcareOptionsUsingTools')}</td>
                    </tr>
                    <tr>
                        <td>E. Accessing preventative health services</td>
                        <td>{countSumValues('AccessingPreventativeHealthServices')}</td>
                    </tr>
                    <tr>
                        <td>A. Healthcare.gov Account Creation or Troubleshooting</td>
                        <td>
                            {countHealthcareGovAccInstances().Creation + countHealthcareGovAccInstances().Troubleshooting}
                        </td>
                    </tr>
                    <tr>
                        <td>B. Marketplace Eligibility Assessment and Results Review</td>
                        <td>{countSumValues('MarketplaceEligibility')}</td>
                    </tr>

                    <tr>
                        <td>C. Marketplace Plan Compare</td>
                        <td>{countSumValues('MarketPlanCompare')}</td>
                    </tr>
                    <tr>
                        <td>A. Electronic QHP Enrollment using Healthcare.gov</td>
                        <td>{countSumValues('qhp_enrollment_hcgov')}</td>
                    </tr>
                    <tr>
                        <td>B. Telephonic QHP Enrollment using Marketplace Call Center</td>
                        <td>{countSumValues('elctronic_qhp_enrollment_marketplaceCallCenter')}</td>
                    </tr>
                    <tr>
                        <td>C. Written QHP Enrollment using a paper Marketplace Application</td>
                        <td>{countSumValues('written_qhp_enrollment_hcgov')}</td>
                    </tr>
                    <tr>
                        <td>A. Medicaid/CHIP applications or referrals</td>
                        <td>{countSumValues('medicaid_chip_app_ref')}</td>
                    </tr>
                    <tr>
                        <td>A. Complex Case Help Center Assistance and Referrals</td>
                        <td>{countSumValues('complex_case_help_center')}</td>
                    </tr>
                    <tr>
                        <td>B. Data matching issues/Periodic data matching issues assistance</td>
                        <td>{countSumValues('data_matchingIssues')}</td>
                    </tr>
                    <tr>
                        <td>C. SEP eligibility troubleshooting assistance</td>
                        <td>{countSumValues('SEP_eligibility')}</td>
                    </tr>
                    <tr>
                        <td>D. Employer-sponsored coverage issues assistance</td>
                        <td>{countSumValues('Employer_sponsored_coverage_issues')}</td>
                    </tr>
                    <tr>
                        <td>E. APTC/CSR assistance</td>
                        <td>{countSumValues('APTC_CSR_assistance')}</td>
                    </tr>
                    <tr>
                        <td>F. Other</td>
                        <td>{countSumValues('Complex_Cases_Other')}</td>
                    </tr>
                    <tr>
                        <td>Answering questions about and/or making referrals to:</td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td>Agent/broker</td>
                        <td>{countNestedSpecificValues('Answering_questions_about', 'A. Agent/Broker')}</td>
                    </tr>
                    <tr>
                        <td>Issuer</td>
                        <td>{countNestedSpecificValues('Answering_questions_about', 'B. Issuer')}</td>
                    </tr>
                    <tr>
                        <td>Medicare</td>
                        <td>{countNestedSpecificValues('Answering_questions_about', 'C. Medicare')}</td>
                    </tr>
                    <tr>
                        <td>Other consumer assistance/health insurance program</td>
                        <td>{countNestedSpecificValues('Answering_questions_about', 'D. Other consumer assistance/health insurance program')}</td>
                    </tr>
                    <tr>
                        <td>State Departments of Insurance</td>
                        <td>{countNestedSpecificValues('Answering_questions_about', 'E. State departments of insurance')}</td>
                    </tr>
                    <tr>
                        <td>A. Marketplace tax forms (1095-A)</td>
                        <td>{countSumValues('Marketplace_tax_forms')}</td>
                    </tr>
                    <tr>
                        <td>B. Filing Marketplace exemptions</td>
                        <td>{countSumValues('Filing_Marketplace')}</td>
                    </tr>
                    <tr>
                        <td>C. Submitting Marketplace or insurance coverage appeals</td>
                        <td>{countSumValues('Submitting_Marketplace')}</td>
                    </tr>
                    <tr>
                        <td>Assisted with other consumer information</td>
                        <td>{countSumValues('Assisted_with_other_consumer_information')}</td>
                    </tr>
                </tbody>
            </table>
        </div >
    );
};

export default WeeklyReports;
