import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { listDeleteEquipmentHistoryFixes, listMouseFixes, listUsers } from '../../../graphql/queries';
import { createMouseFix, deleteMouseFix, updateMouseFix, createDeleteEquipmentHistoryFix } from '../../../graphql/mutations';
import './Mouse.css';
import { FaFileExcel } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { capitalize } from '../../../components/common/capitalize';
import useFilteredData from '../../../components/layout/useFilteredDate';
import XLSX from 'xlsx-js-style';
import Inventory_header from '../../../components/layout/Inventory_header';
import SearchBar from '../../../components/layout/SearchBar';

const MouseTable = () => {

  const [mouses, setMouses] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [showMouseAddOverlay, setShowMouseAddOverlay] = useState(false);
  const [selectedMouse, setSelectedMouse] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newMouse, setNewMouse] = useState({
    id: '',
    tagId: '',
    model: '',
    brand: '',
    serial_Number: '',
    wireless: true,
    usb: true,
    combo: false,
    price: '',
    store: '',
    purchase_date: '',
    assignment_date: '',
    warranty_date: '',
    returnable_date: '',
    available: true,
    status: 'inv',
    assigned_to: '',
    location: '',
    team: '',
    userId: '',
  });

  const [sortConfig, setSortConfig] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('');// Initialized with default values
  const [duplicateError, setDuplicateError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [statusError, setStatusError] = useState('');
  const { loading1, error: userError, data: userData } = useQuery(listUsers);


  const navigatorOptions = userData?.listUserFixes?.items
    ?.filter((u) => u && u.first_name && u.last_name) // Filtrar valores nulos o sin nombres
    ?.map((u) => ({
      id: u.id,
      name: `${u.first_name.charAt(0).toUpperCase() + u.first_name.slice(1).toLowerCase()} 
           ${u.last_name.charAt(0).toUpperCase() + u.last_name.slice(1).toLowerCase()}`
    }))
    ?.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' })) || [];


  const handleSaveAsExcel = async () => {
    try {
      const mouseToExport = filteredMouses.length > 0 ? filteredMouses : mouses;

      if (mouseToExport.length > 0) {
        const sortedMouse = [...mouseToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        const worksheetData = sortedMouse.map((mouse, index) => ({
          '#': index + 1, // Agregar el número de fila
          'TAG': mouse.tagId,
          'Brand': mouse.brand,
          'Assigned To': mouse.user?.first_name
            ? mouse.user?.first_name +
            (mouse.user?.last_name ? " " + mouse.user?.last_name : "")
            : "N/A",
          'Available': mouse.available ? 'Yes' : 'No',
          'Serial Number': mouse.serial_Number,
          'Status': mouse.status,
          'Team': mouse.team,
        }));

        // Convert JSON to sheet starting from row 2
        const worksheet = XLSX.utils.json_to_sheet([]);
        let endCol = 0;

        XLSX.utils.sheet_add_aoa(worksheet, [['Mice']], { origin: 'A1' });

        if (worksheetData.length > 0) {
          // Agrega los datos a partir de la fila A2
          XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

          // Define el rango de referencia (!ref)
          const endRow = worksheetData.length + 1; // +1 para el título en A1
          const endCol = Object.keys(worksheetData[0]).length - 1;
          worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
        } else {
          console.error("No hay datos para exportar.");
          return;
        }

        // Merge title cells across the columns
        worksheet['!merges'] = worksheet['!merges'] || [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }); // A1 to H1 for the added column

        // Style the title row
        worksheet['A1'].s = {
          font: {
            bold: true,
            sz: 20, // Font size
            color: { rgb: '1F4E79' } // Dark blue text color
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };

        // Apply styling for the header row (now starting at row 2)
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: '1F4E79' } // Dark blue background
            },
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' } // White text
            },
            alignment: {
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            }
          };
        }

        // Apply alternating row colors and borders starting from row 3
        for (let row = 2; row <= headerRange.e.r; row++) {
          const isEvenRow = row % 2 === 0;
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
              },
              border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              alignment: {
                horizontal: 'center'
              }
            };
          }
        }

        // Adjust columns to fit content
        // Adjust columns to fit content
        worksheet['!cols'] = [
          { wch: 5 }, // Width for Row Number
          { wch: 10 },
          { wch: 15 },
          { wch: 10 },
          { wch: 15 },
          { wch: 15 },
          { wch: 5 },
          { wch: 15 }
        ];

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mouses');

        // Export the file
        XLSX.writeFile(workbook, 'mousesData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };


  const handleEditMouse = (mouses) => {
    setSelectedMouse(mouses);
    setIsEditing(true);
    setShowMouseAddOverlay(true);
    console.log("selectmouse", mouses)

  };

  const { loading, data, fetchMore } = useQuery(listMouseFixes, {
    variables: { limit: 100 },
  });

  const [addMouse] = useMutation(createMouseFix, {
    refetchQueries: [{ query: listMouseFixes, variables: { limit: 100 } }]
  });

  const [removeMouse] = useMutation(deleteMouseFix, {
    refetchQueries: [{ query: listMouseFixes, variables: { limit: 100 } }]
  });

  const [updMouse] = useMutation(updateMouseFix, {
    refetchQueries: [{ query: listMouseFixes, variables: { limit: 100 } }]
  });

  const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistoryFix, {
    refetchQueries: [{ query: listDeleteEquipmentHistoryFixes, variables: { limit: 100 } }]
  });

  useEffect(() => {
    const fetchAllMouses = async () => {
      let allMouses = [];
      let currentNextToken = null;

      do {
        const { data } = await fetchMore({
          variables: { limit: 100, nextToken: currentNextToken },
        });

        allMouses = [...allMouses, ...data.listMouseFixes.items];
        currentNextToken = data.listMouseFixes.nextToken;
      } while (currentNextToken);

      setMouses(allMouses);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllMouses();
    }
  }, [data, fetchMore]);

  const clearNewMouse = () => {
    setNewMouse({
      id: '',
      tagId: '',
      model: '',
      brand: '',
      serial_Number: '',
      wireless: true,
      usb: true,
      combo: false,
      price: '',
      store: '',
      purchase_date: '',
      assignment_date: '',
      warranty_date: '',
      returnable_date: '',
      available: true,
      status: 'inv',
      assigned_to: '',
      location: '',
      team: '',
      userId: '',
    });
  };

  const loadMore = () => {
    if (!nextToken) return; // Check if nextToken is null

    fetchMore({
      variables: { limit: 100, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        setNextToken(fetchMoreResult.listMouseFixes.nextToken);
        return {
          listMouseFixes: {
            ...fetchMoreResult.listMouseFixes,
            items: [...prevResult.listMouseFixes.items, ...fetchMoreResult.listMouseFixes.items]
          }
        };
      }
    });
  };

  const findUserFullNameByID = (userID) => {
    if (!userID) return null;

    const userIDString = userID.toString().trim();

    if (!Array.isArray(userData?.listUserFixes?.items)) return null;
    const user = userData?.listUserFixes?.items.find((u) => u.id === userIDString);
    return user ? `${user.first_name} ${user.last_name}` : null;
  };

  // Filter data
  const filteredMouses = useFilteredData(mouses || [], searchTerm, sortConfig, searchField, userData?.listUserFixes?.items);

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    let formattedValue = value;


    // Convert to lowercase 
    if (['serial_Number'].includes(name)) {
      formattedValue = value.toLowerCase();
    }
    ;
    if (type === 'checkbox') {
      formattedValue = checked;
    }

    if (isEditing) {
      setSelectedMouse({
        ...selectedMouse,
        [name]: formattedValue
      });
    } else {
      setNewMouse({
        ...newMouse,
        id: uuidv4(),
        [name]: formattedValue
      });
    }

  };

  const handleAddMouse = () => {
    // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
    const formattedPurchase = newMouse.purchase_date
      ? new Date(newMouse.purchase_date).toISOString().split('T')[0]
      : null;
    const formattedAssignment = newMouse.assignment_date
      ? new Date(newMouse.assignment_date).toISOString().split('T')[0]
      : null;

    const formattedWarranty = newMouse.warranty_date
      ? new Date(newMouse.warranty_date).toISOString().split('T')[0]
      : null;

    const formattedReturnable = newMouse.returnable_date
      ? new Date(newMouse.returnable_date).toISOString().split('T')[0]
      : null;

    // Validate that the price contains only digits and is a valid number
    let priceValue = null;


    if (newMouse.price !== "" && newMouse.price !== null && newMouse.price != undefined) {
      priceValue = parseFloat(newMouse.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        return;
      }
    }
    setPriceError('');


    // Check for duplicates based on `tagId` and `serial_Number`
    const isDuplicate = mouses.some(mouse =>
      mouse.tagId === newMouse.tagId &&
      mouse.serial_Number === newMouse.serial_Number
    );

    if (isDuplicate) {
      setDuplicateError('A Mouse with the same TAG and Serial Number already exists.');

      return;
    } else {
      setDuplicateError('');
    }


    // Check if newMouse and its properties are defined
    if (!newMouse || typeof newMouse.status === 'undefined' || typeof newMouse.available === 'undefined') {
      console.error('Mouse data is incomplete or invalid.');
      setStatusError('Mouse data is incomplete or invalid.');
      return;
    }

    // Check availability first
    if (newMouse.available === true) {

      // If available, status must be Inventario
      if (newMouse.status !== 'inv') {
        setStatusError('If the mouse is available, its status must be "Inventario".');
        return; // Exit function if validation fails
      }
      // If available and status is Inventario, userId must be empty or "N/A"
      if (newMouse.userId !== '' && newMouse.userId !== 'n/a') {
        setStatusError('If the mouse is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
        return; // Exit function if validation fails
      }

    } else if (newMouse.available === false) {
      // If not available, status must be one of the allowed statuses
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(newMouse.status)) {
        setStatusError('If the mouse is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        return; // Exit function if validation fails
      }
      // Clear error if validation passes
      setStatusError('');
    }

    // If all validations pass, proceed with adding the mouse to the database
    const safeUserId = newMouse.userId && newMouse.userId.trim() !== "" ? newMouse.userId : "n/a";
    addMouse({
      variables: { input: { ...newMouse, userId: safeUserId, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
    })
      .then(() => {
        clearNewMouse();
        alert("The Mouse was an added success")
        setShowMouseAddOverlay(false);
      }).catch(error => {
        console.error("Error adding Mouse:", error);
      });
  };


  const removeUnnecessaryFields = (data, fieldsToRemove) => {
    fieldsToRemove.forEach((field) => {
      delete data[field];
    });
    return data;
  };

  const handleDeleteMouse = async () => {
    try {
      // Remover campos innecesarios
      const MouseData = removeUnnecessaryFields({ ...selectedMouse }, [

        'wireless',
        'usb',
        'combo',
        'warranty_date',
        'returnable_date',
        'status',
        'team',
        'assigned_to',
      ]);

      // Lista de campos permitidos según el esquema GraphQL
      const allowedFields = [
        'id',
        'Item',
        'tagId',
        'tagid',
        'serial_Number',
        'price',
        'brand',
        'model',
        'store',
        'purchase_date',
        'assignment_date',
        'available',
        'previous_employees',
        'location',
        'notes',
        'userId',

      ];

      // Filtrar los campos permitidos
      const cleanedNewMouses = Object.fromEntries(
        Object.entries(MouseData).filter(([key]) => allowedFields.includes(key))
      );


      // Normalizar claves alternativas
      cleanedNewMouses.tagId = cleanedNewMouses.tagId || cleanedNewMouses.tagid;
      delete cleanedNewMouses.tagid;

      cleanedNewMouses.previous_employees =
        cleanedNewMouses.previous_employees || cleanedNewMouses.assigned_to;
      delete cleanedNewMouses.assigned_to;

      cleanedNewMouses.userId =
        cleanedNewMouses.userId || cleanedNewMouses.assigned_to;
      delete cleanedNewMouses.assigned_to;

      cleanedNewMouses.notes =
        cleanedNewMouses.notes || cleanedNewMouses.Description;
      delete cleanedNewMouses.Description;


      // Añadir valores clave predeterminados
      if (!cleanedNewMouses.Item) {
        cleanedNewMouses.Item = "Mouse"; // Establecer el valor predeterminado para `Item`
      }

      // Eliminar valores vacíos
      Object.keys(cleanedNewMouses).forEach((key) => {
        if (cleanedNewMouses[key] === "" || cleanedNewMouses[key] === null) {
          delete cleanedNewMouses[key];
        }
      });

      console.log("New delete equipment", cleanedNewMouses)

      // Agregar empleado a la tabla PreviousEmployee
      await addDeleteEquipmentHistory({
        variables: { input: cleanedNewMouses },
      });

      // Eliminar el Mouse
      await removeMouse({
        variables: { input: { id: cleanedNewMouses.id } },
      });

      // Resetear estados
      setShowMouseAddOverlay(false);
      setIsEditing(false);
      setSelectedMouse(null);

    } catch (error) {
      console.error("Error deleting Mouse", error);
    }
  };


  const handleUpdateMouse = () => {

    const formattedData_purchase = selectedMouse.purchase_date ? new Date(selectedMouse.purchase_date).toISOString().split('T')[0] : null;
    const formattedData_warranty = selectedMouse.warranty_date ? new Date(selectedMouse.warranty_date).toISOString().split('T')[0] : null;
    const formattedData_returnable = selectedMouse.returnable_date ? new Date(selectedMouse.returnable_date).toISOString().split('T')[0] : null;
    const formattedData_assignment = selectedMouse.assignment_date ? new Date(selectedMouse.assignment_date).toISOString().split('T')[0] : null;

    //Check Price
    let priceValue = null;
    if (selectedMouse.price !== "" && selectedMouse.price !== null && selectedMouse.price != undefined) {
      priceValue = parseFloat(selectedMouse.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        return;
      }
    }
    setPriceError('');

    // Check availability first
    if (selectedMouse.available === 'true') {

      // If available, status must be "Inventario"
      if (selectedMouse.status !== 'inv') {
        setStatusError('If the mouse is available, its status must be "Inventario".');
        return; // Exit function if validation fails
      }

      // If available and status is "Inventario", assigned_to must be empty or "N/A"
      if (selectedMouse.userId !== '' && selectedMouse.userId.toLowerCase() !== 'n/a') {
        setStatusError('If the mouse is available and its status is "Inventario", the "userId" field must be empty or "N/A".');
        return; // Exit function if validation fails
      }

    } else if (selectedMouse.available === 'false') {  // Ensure we're checking for availability correctly
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(selectedMouse.status)) {
        setStatusError('If the Mouse is not available, its status must be one of: "broken", "use", "transfer", or "lost".');

        return;
      }

      // Clear error if validation passes
      setStatusError('');
    }


    updMouse({
      variables: {
        input: {
          id: selectedMouse.id,
          tagId: selectedMouse.tagId,
          model: selectedMouse.model,
          brand: selectedMouse.brand,
          serial_Number: selectedMouse.serial_Number,
          wireless: selectedMouse.wireless,
          usb: selectedMouse.usb,
          combo: selectedMouse.combo,
          price: priceValue,
          store: selectedMouse.store,
          purchase_date: formattedData_purchase,
          assignment_date: formattedData_assignment,
          warranty_date: formattedData_warranty,
          returnable_date: formattedData_returnable,
          available: selectedMouse.available,
          status: selectedMouse.status,
          assigned_to: " ",
          location: selectedMouse.location,
          team: selectedMouse.team,
          userId: selectedMouse.userId,
        }
      }
    }).then(() => {
      setIsEditing(true);
      setShowMouseAddOverlay(false);
      setPriceError('');
      setStatusError('');
      setDuplicateError('');

    }).catch(error => {
      console.error("Error updating mouse:", error);
    });
  };


  const requestSort = (key) => {
    setSortConfig((prevConfig) => {
      // Toggle sorting direction if the same key is clicked
      const direction = prevConfig.key === key && prevConfig.direction === 'ascending' ? 'descending' : 'ascending';
      return { key, direction };
    });
  };
  const formatPrice = (price) => {
    if (price === null || price === undefined || price === '') {
      return '';
    }
    const parsedPrice = parseFloat(price);
    return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
  };


  const formatDate = (dateString) => {
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return localDate.toLocaleDateString(undefined, options);
  };


  return (
    <div className="mouse-page">
      <header className="inventory-header">
        <h1>Mouse</h1>
      </header>
      <Inventory_header />
      <div className="inventory-search">
        <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>
      </div>

      <div className="searchBar">
        <button className="add-mouse-btn" onClick={() => {
          clearNewMouse();
          setSelectedMouse(null);
          setIsEditing(false);
          setShowMouseAddOverlay(true);
        }}>+ Add New Mouse</button>
        <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      </div>

      <div className="mouse-table-container" >
        <table className="mouse-table">
          <thead>
            <tr>

              <th>#</th> { }
              <th onClick={() => requestSort('tagId')}>
                TAG
                {sortConfig?.key === 'tagId' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('model')}>
                Model
                {sortConfig?.key === 'model' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('brand')}>
                Brand
                {sortConfig?.key === 'brand' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('serial_Number')}>
                Serial Number
                {sortConfig?.key === 'serial_Number' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('wireless')}>
                Wireless
                {sortConfig?.key === 'wireless' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('usb')}>
                USB
                {sortConfig?.key === 'usb' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('combo')}>
                Combo
                {sortConfig?.key === 'combo' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('price')}>
                Price
                {sortConfig?.key === 'price' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('store')}>
                Store
                {sortConfig?.key === 'store' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('purchase_date')}>
                Purchase Date
                {sortConfig?.key === 'purchase_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('available')}>
                Available
                {sortConfig?.key === 'available' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('status')}>
                Status
                {sortConfig?.key === 'status' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('userId')}>
                Assigned To
                {sortConfig?.key === 'userId' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('assignment_date')}>
                Assignment Date
                {sortConfig?.key === 'assignment_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('warranty_date')}>
                Warranty Date
                {sortConfig?.key === 'warranty_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('returnable_date')}>
                Returnable Date
                {sortConfig?.key === 'returnable_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('location')}>
                Location
                {sortConfig?.key === 'location' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('team')}>
                Team
                {sortConfig?.key === 'team' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

            </tr>
          </thead>
          <tbody>
            {filteredMouses.map((mouse, index) => {
              const userFullName = findUserFullNameByID(mouse.userId);

              return (
                <tr key={mouse.id} onClick={() => handleEditMouse(mouse)}>
                  <td>{index + 1}</td> {/* Número de fila */}
                  <td className="mouse-tagid">{capitalize(mouse.tagId)}</td>
                  <td className="mouse-model">{capitalize(mouse.model?.toLowerCase() || 'N/A')}</td>
                  <td className="mouse-brand">{capitalize(mouse.brand?.toLowerCase() || 'N/A')}</td>
                  <td className="mouse-serial_Number">{mouse.serial_Number?.toUpperCase() || 'N/A'}</td>
                  <td className="mouse-wireless">{mouse.wireless ? 'Yes' : 'No'}</td>
                  <td className="mouse-usb">{mouse.usb ? 'Yes' : 'No'}</td>
                  <td className="mouse-combo">{mouse.combo ? 'Yes' : 'No'}</td>
                  <td className="mouse-price">${formatPrice(mouse.price)}</td>
                  <td className="mouse-store">{mouse.store}</td>
                  <td className="mouse-purchase_date">{mouse.purchase_date ? formatDate(mouse.purchase_date) : 'No Date'}</td>
                  <td className="mouse-available">{mouse.available ? 'Yes' : 'No'} </td>
                  <td className="mouse-status">
                    {mouse.status === 'broken' ? 'Broken' :
                      mouse.status === 'use' ? 'Use' :
                        mouse.status === 'transfer' ? 'Transfer' :
                          mouse.status === 'lost' ? 'Lost' :
                            mouse.status === 'inv' ? 'Inventario' :
                              ''} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                  </td>
                  <td className={`userId ${searchField === 'userId' ? 'highlight' : ''}`}>
                    {userFullName ? capitalize(userFullName) : "N/A"}
                  </td>
                  <td className="mouse-assignment_date">{mouse.assignment_date ? formatDate(mouse.assignment_date) : 'No Date'}</td>
                  <td className="mouse-warranty_date">{mouse.warranty_date ? formatDate(mouse.warranty_date) : 'No Date'}</td>
                  <td className="mouse-returnable_date">{mouse.returnable_date ? formatDate(mouse.returnable_date) : 'No Date'}</td>
                  <td className="mouse-location">{mouse.location}</td>
                  <td className="mouse-team">{mouse.team}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <p>Total items: {filteredMouses.length}</p>

      {nextToken && !loading && (
        <button onClick={loadMore}>Load More</button>
      )}

      {loading && <p>Loading...</p>}

      {showMouseAddOverlay && (

        <div className="mouse_add_overlay"
          onClick={(e) => {
            // Close overlay only if clicked outside the modal content
            if (e.target.classList.contains("mouse_add_overlay")) {
              setShowMouseAddOverlay(false);
              setIsEditing(false);
              setSelectedMouse(null);
            }
          }}>
          <div className="overlay-content-inventory">
            <div className="header-row">
              <h2 className="header-title">{isEditing ? 'Edit Mouse' : 'Add New Mouse'}</h2>
              <button onClick={() => {
                setShowMouseAddOverlay(false);
                clearNewMouse();
                setSelectedMouse(null);
                setIsEditing(false);
              }}>X</button>
            </div>


            <label>TAG:</label>
            <input
              name="tagId"
              placeholder="TAG"
              value={isEditing && selectedMouse ? selectedMouse.tagId : newMouse.tagId}
              onChange={handleInputChange}
              required
            />
            <label>Model:</label>
            <input
              name="model"
              placeholder="Model"
              value={isEditing && selectedMouse ? selectedMouse.model : newMouse.model}
              onChange={handleInputChange}
              required
            />


            <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedMouse.brand : newMouse.brand} onChange={handleInputChange} />
            <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedMouse.serial_Number : newMouse.serial_Number} onChange={handleInputChange} />

            <div className='Inventory_checkboxes'>

              <label>
                Wireless</label>
              <input name="wireless"
                type="checkbox" checked={isEditing ? selectedMouse.wireless : newMouse.wireless}
                onChange={handleInputChange}
              />
              <label>
                USB</label>
              <input name="usb"
                type="checkbox" checked={isEditing ? selectedMouse.usb : newMouse.usb}
                onChange={handleInputChange}
              />
              <label>
                Combo</label>
              <input name="combo"
                type="checkbox" checked={isEditing ? selectedMouse.combo : newMouse.combo}
                onChange={handleInputChange}
              />
            </div>

            <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedMouse.price : newMouse.price} onChange={handleInputChange} />
            <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedMouse.store : newMouse.store} onChange={handleInputChange} />

            <label htmlFor="purchase_date">Purchase Date:</label>
            <input
              type="Date"
              id="purchase_date"
              name="purchase_date"
              value={isEditing ? selectedMouse.purchase_date : newMouse.purchase_date}
              onChange={handleInputChange}

            />
            <label htmlFor="assignment_date">Assignment Date:</label>
            <input
              type="date"
              id="assignment_date"
              name="assignment_date"
              value={isEditing ? selectedMouse.assignment_date : newMouse.assignment_date}
              onChange={handleInputChange}

            />
            <label htmlFor="returnable_date">Warranty Date:</label>
            <input
              type="date"
              id="warranty_date"
              name="warranty_date"
              value={isEditing ? selectedMouse.warranty_date : newMouse.warranty_date}
              onChange={handleInputChange}
            />


            <label htmlFor="returnable_date">Returnable Date:</label>
            <input
              type="date"
              id="returnable_date"
              name="returnable_date"
              value={isEditing ? selectedMouse.returnable_date : newMouse.returnable_date}
              onChange={handleInputChange}
            />

            <label>Available:</label>

            <select name="available" placeholder="available" value={isEditing ? selectedMouse.available : newMouse.available} onChange={handleInputChange} >
              <option value="" disabled hidden className="placeholder-option">Select Availability</option>
              <option value="true">Yes</option>
              <option value="false">No</option>

            </select>
            <label>Status:</label>
            <select name="status" placeholder="status" value={isEditing ? selectedMouse.status : newMouse.status} onChange={handleInputChange} >
              <option value="" disabled hidden>Select Status</option>
              <option value="use">Use</option>
              <option value="broken">Broken</option>
              <option value="transfer">Transfer</option>
              <option value="lost">Lost</option>
              <option value="inv">Inventario</option>
            </select>

            <label>Assigned To:</label>
            <select
              name="userId"
              value={isEditing ? selectedMouse.userId : newMouse.userId}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select an employee
              </option>
              <option value="n/a">N/A</option>
              {navigatorOptions.length > 0 ? (
                navigatorOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>No employees available</option>
              )}


            </select>


            <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedMouse.location : newMouse.location} onChange={handleInputChange} />
            <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedMouse.team : newMouse.team} onChange={handleInputChange} />

            {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
            {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
            {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}
            <div className="button-group">
              <button className="overlay-add-mouse" onClick={isEditing ? handleUpdateMouse : handleAddMouse} >
                {isEditing ? 'Update Mouse' : 'Add Mouse'}
              </button>
              {isEditing && (
                <button
                  className="overlay-delet-mouse"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this mouse?')) {
                      handleDeleteMouse();
                    }
                  }}
                >
                  Delete mouse
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MouseTable;