
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { listDeleteEquipmentHistoryFixes, listKeyboardFixes, listUsers } from '../../../graphql/queries';
import { createKeyboardFix, deleteKeyboardFix, updateKeyboardFix, createDeleteEquipmentHistoryFix } from '../../../graphql/mutations';
import Inventory_header from '../../../components/layout/Inventory_header';
import './Keyboard.css';
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';


const Keyboard = () => {
  const [keyboard, setKeyboard] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [showKeyboardAddOverlay, setShowKeyboardAddOverlay] = useState(false);
  const [selectedKeyboard, setSelectedKeyboard] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('');

  const [newKeyboard, setNewKeyboard] = useState({
    id: '',
    tagid: '',
    model: '',
    brand: '',
    serial_Number: '',
    wireless: true,
    usb: true,
    combo: false,
    price: '',
    store: '',
    purchase_date: '',
    assignment_date: '',
    warranty_date: '',
    returnable_date: '',
    available: true,
    status: 'inv',
    assigned_to: '',
    location: '',
    team: '',
    userId: '',

  });

  const [sortConfig, setSortConfig] = useState(null);
  const [duplicateError, setDuplicateError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [statusError, setStatusError] = useState('');

  const { loading1, error: userError, data: userData } = useQuery(listUsers);


  const handleSaveAsExcel = async () => {
    try {

      const KeyboardtoExport = filteredKeyboards.length > 0 ? filteredKeyboards : keyboard;

      if (KeyboardtoExport.length > 0) {
        // Sort the keyboards by Timestamp in ascending order (earliest first)
        const sortedkeyboard = [...KeyboardtoExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        // Prepare the data for Excel export with custom column titles
        const worksheetData = sortedkeyboard.map((keyboard, index) => ({
          '#': index + 1, // Agregar el número de fila
          'TAG:': keyboard.tagid,
          'Brand': keyboard.brand,
          'Available:': keyboard.available ? 'Yes' : 'No',
          'Assigned to:': keyboard.user?.first_name
            ? keyboard.user?.first_name +
            (keyboard.user?.last_name ? " " + keyboard.user?.last_name : "")
            : "N/A",
          'Serial Number:': keyboard.serial_Number,
          'Status:': keyboard.status,
          'Team:': keyboard.team,

        }));

        // Convert JSON to sheet starting from row 2
        const worksheet = XLSX.utils.json_to_sheet([]);
        let endCol = 0;

        XLSX.utils.sheet_add_aoa(worksheet, [['Keyboards']], { origin: 'A1' });

        if (worksheetData.length > 0) {
          // Agrega los datos a partir de la fila A2
          XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

          // Define el rango de referencia (!ref)
          const endRow = worksheetData.length + 1; // +1 para el título en A1
          const endCol = Object.keys(worksheetData[0]).length - 1;
          worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
        } else {
          console.error("No hay datos para exportar.");
          return;
        }

        // Merge title cells across the columns
        worksheet['!merges'] = worksheet['!merges'] || [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }); // A1 to H1 for the added column

        // Style the title row
        worksheet['A1'].s = {
          font: {
            bold: true,
            sz: 20, // Font size
            color: { rgb: '1F4E79' } // Dark blue text color
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };

        // Apply styling for the header row (now starting at row 2)
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: '1F4E79' } // Dark blue background
            },
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' } // White text
            },
            alignment: {
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            }
          };
        }

        // Apply alternating row colors and borders starting from row 3
        for (let row = 2; row <= headerRange.e.r; row++) {
          const isEvenRow = row % 2 === 0;
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
              },
              border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              alignment: {
                horizontal: 'center'
              }
            };
          }
        }


        // Adjust columns to fit content
        worksheet['!cols'] = [
          { wch: 5 }, // Adjust width for each column
          { wch: 15 },
          { wch: 10 },
          { wch: 15 },
          { wch: 15 },
          { wch: 30 },
          { wch: 15 },
          { wch: 15 }
        ];
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, 'keyboards');

        // Write file
        XLSX.writeFile(workbook, 'keyboardsData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

  const handleEditKeyboard = (keyboard) => {
    setSelectedKeyboard(keyboard);
    setIsEditing(true);
    setShowKeyboardAddOverlay(true);
  };


  const { loading, data, fetchMore } = useQuery(listKeyboardFixes, {
    variables: { limit: 100 },
  });

  const [addKeyboard] = useMutation(createKeyboardFix, {
    refetchQueries: [{ query: listKeyboardFixes, variables: { limit: 100 } }]
  });

  const [removeKeyboard] = useMutation(deleteKeyboardFix, {
    refetchQueries: [{ query: listKeyboardFixes, variables: { limit: 100 } }]
  });
  const [updKeyboard] = useMutation(updateKeyboardFix, {
    refetchQueries: [{ query: listKeyboardFixes, variables: { limit: 100 } }]
  });

  const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistoryFix, {
    refetchQueries: [{ query: listDeleteEquipmentHistoryFixes, variables: { limit: 100 } }]
  });


  useEffect(() => {
    const fetchAllKeyboard = async () => {
      let allKeyboards = [];
      let currentNextToken = null;

      do {
        const { data } = await fetchMore({
          variables: { limit: 100, nextToken: currentNextToken },
        });

        allKeyboards = [...allKeyboards, ...data.listKeyboardFixes.items];
        currentNextToken = data.listKeyboardFixes.nextToken;
      } while (currentNextToken);

      setKeyboard(allKeyboards);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllKeyboard();
    }
  }, [data, fetchMore]);


  const clearNewKeyboard = () => {
    setNewKeyboard({
      id: '',
      tagid: '',
      model: '',
      brand: '',
      serial_Number: '',
      wireless: true,
      usb: true,
      combo: false,
      price: '',
      store: '',
      purchase_date: '',
      assignment_date: '',
      warranty_date: '',
      returnable_date: '',
      available: true,
      status: 'inv',
      assigned_to: '',
      location: '',
      team: '',
      userId: ''
    });
  };

  const loadMore = () => {
    if (!nextToken) return; // Check if nextToken is null

    fetchMore({
      variables: { limit: 100, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        setNextToken(fetchMoreResult.listKeyboardFixes.nextToken);
        return {
          listKeyboardFixes: {
            ...fetchMoreResult.listKeyboardFixes,
            items: [...prevResult.listKeyboardFixes.items, ...fetchMoreResult.listKeyboardFixes.items]
          }
        };
      }
    });
  };


  const findUserFullNameByID = (userID) => {
    if (!userID) return null;

    const userIDString = userID.toString().trim();

    if (!Array.isArray(userData?.listUserFixes?.items)) return null;
    const user = userData?.listUserFixes?.items.find((u) => u.id === userIDString);
    return user ? `${user.first_name} ${user.last_name}` : null;
  };

  // Filter 
  const filteredKeyboards = useFilteredData(keyboard || [], searchTerm, sortConfig, searchField, userData?.listUserFixes?.items);

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    let formattedValue = value;

    // Convert to lowercase 
    if (['serial_Number'].includes(name)) {
      formattedValue = value.toLowerCase();
    }
    ;
    if (type === 'checkbox') {
      formattedValue = checked;
    }
    if (isEditing) {
      setSelectedKeyboard({
        ...selectedKeyboard,
        [name]: formattedValue
      });
    } else {
      setNewKeyboard({
        ...newKeyboard,
        id: uuidv4(),
        [name]: formattedValue
      });
    }

  };

  const handleAddKeyboard = () => {
    // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
    const formattedPurchase = keyboard.purchase_date
      ? new Date(keyboard.purchase_date).toISOString().split('T')[0]
      : null;
    const formattedAssignment = keyboard.assignment_date
      ? new Date(keyboard.assignment_date).toISOString().split('T')[0]
      : null;

    const formattedWarranty = keyboard.warranty_date
      ? new Date(keyboard.warranty_date).toISOString().split('T')[0]
      : null;

    const formattedReturnable = keyboard.returnable_date
      ? new Date(keyboard.returnable_date).toISOString().split('T')[0]
      : null;

    // Validate that the price contains only digits and is a valid number
    let priceValue = null;
    console.log('Price:', keyboard.price);

    if (newKeyboard.price !== "" && newKeyboard.price !== null && newKeyboard.price != undefined) {
      priceValue = parseFloat(newKeyboard.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        console.log('Price validation failed:', keyboard.price);
        return;
      }
    }
    setPriceError('');

    // Check for duplicates based on `tagid` and `serial_Number`
    const isDuplicate = keyboard.some(keyboard =>
      keyboard.tagid === newKeyboard.tagid &&
      keyboard.serial_Number === newKeyboard.serial_Number
    );

    if (isDuplicate) {
      setDuplicateError('A Keyboard with the same TAG and Serial Number already exists.');
      console.log('Duplicate validation failed');
      return;
    } else {
      setDuplicateError('');
    }

    // Check availability first
    if (newKeyboard.available === true) {

      // If available, status must be Inventario
      if (newKeyboard.status !== 'inv') {
        setStatusError('If the keyboard is available, its status must be "Inventario".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }

      // If available and status is Inventario, userId must be empty or "N/A"
      if (newKeyboard.userId !== '' && newKeyboard.userId !== 'n/a') {
        setStatusError('If the keyboard is available and its status is "Inventario", the "userId" field must be empty or "N/A".');
        console.log('userId validation failed');
        return; // Exit function if validation fails
      }
    } else if (newKeyboard.available === false) {
      // If not available, status must be one of the allowed statuses
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(newKeyboard.status)) {
        setStatusError('If the keyboard is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // Clear error if validation passes
      setStatusError('');
    }

    // If all validations pass, proceed with adding the keyboard to the database
    const safeUserId = newKeyboard.userId && newKeyboard.userId.trim() !== "" ? newKeyboard.userId : "n/a";
    addKeyboard({
      variables: { input: { ...newKeyboard, userId: safeUserId, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
    })
      .then(() => {
        clearNewKeyboard();
        alert("The Keyboard was an added success")
        setShowKeyboardAddOverlay(false);
      })
      .catch(error => {
        console.error("Error adding Keyboard:", error);
      });
  };
  const removeUnnecessaryFields = (data, fieldsToRemove) => {
    fieldsToRemove.forEach((field) => {
      delete data[field];
    });
    return data;
  };

  const handleDeleteKeyboard = async () => {
    try {
      // Remover campos innecesarios
      const KeyboardData = removeUnnecessaryFields({ ...selectedKeyboard }, [

        'wireless',
        'usb',
        'combo',
        'warranty_date',
        'returnable_date',
        'status',
        'team',

      ]);

      // Lista de campos permitidos según el esquema GraphQL
      const allowedFields = [
        'id',
        'Item',
        'tagId',
        'tagid', // Agregado
        'serial_Number',
        'price',
        'brand',
        'model',
        'store',
        'purchase_date',
        'assignment_date',
        'available',
        'previous_employees',
        'assigned_to', // Agregado
        'location',
        'notes',
        'userId',
      ];

      // Filtrar los campos permitidos
      const cleanedNewKeyboards = Object.fromEntries(
        Object.entries(KeyboardData).filter(([key]) => allowedFields.includes(key))
      );


      console.log("Antes de normalizar:", cleanedNewKeyboards);

      // Normalizar claves alternativas
      cleanedNewKeyboards.tagId = cleanedNewKeyboards.tagId || cleanedNewKeyboards.tagid;
      delete cleanedNewKeyboards.tagid;

      cleanedNewKeyboards.previous_employees =
        cleanedNewKeyboards.previous_employees || cleanedNewKeyboards.assigned_to;
      delete cleanedNewKeyboards.assigned_to;

      cleanedNewKeyboards.userId =
        cleanedNewKeyboards.userId || cleanedNewKeyboards.assigned_to;
      delete cleanedNewKeyboards.assigned_to;

      cleanedNewKeyboards.notes =
        cleanedNewKeyboards.notes || cleanedNewKeyboards.Description;
      delete cleanedNewKeyboards.Description;
      console.log("Despues de normalizar:", cleanedNewKeyboards);

      // Añadir valores clave predeterminados
      if (!cleanedNewKeyboards.Item) {
        cleanedNewKeyboards.Item = "Keyboard"; // Establecer el valor predeterminado para `Item`
      }

      // Eliminar valores vacíos
      Object.keys(cleanedNewKeyboards).forEach((key) => {
        if (cleanedNewKeyboards[key] === "" || cleanedNewKeyboards[key] === null) {
          delete cleanedNewKeyboards[key];
        }
      });

      // Agregar empleado a la tabla PreviousEmployee
      await addDeleteEquipmentHistory({
        variables: { input: cleanedNewKeyboards },
      });

      // Eliminar el Keyboard
      await removeKeyboard({
        variables: { input: { id: cleanedNewKeyboards.id } },
      });

      // Resetear estados
      setShowKeyboardAddOverlay(false);
      setIsEditing(false);
      setSelectedKeyboard(null);

    } catch (error) {
      console.error("Error deleting Keyboard", error);
    }
  };
  const handleUpdateKeyboard = () => {

    const formattedData_purchase = selectedKeyboard.purchase_date ? new Date(selectedKeyboard.purchase_date).toISOString().split('T')[0] : null;
    const formattedData_warranty = selectedKeyboard.warranty_date ? new Date(selectedKeyboard.warranty_date).toISOString().split('T')[0] : null;
    const formattedData_returnable = selectedKeyboard.returnable_date ? new Date(selectedKeyboard.returnable_date).toISOString().split('T')[0] : null;
    const formattedData_assignment = selectedKeyboard.assignment_date ? new Date(selectedKeyboard.assignment_date).toISOString().split('T')[0] : null;

    //Chek the price
    let priceValue = null;
    if (selectedKeyboard.price !== "" && selectedKeyboard.price !== null && selectedKeyboard.price != undefined) {
      priceValue = parseFloat(selectedKeyboard.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        console.log('Price validation failed:', keyboard.price);
        return;
      }
    }
    setPriceError('');

    // Check availability first
    if (selectedKeyboard.available === "true") {

      // If available, status must be Inventario
      if (selectedKeyboard.status !== 'inv') {
        setStatusError('If the keyboard is available, its status must be "Inventario".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }


      if (selectedKeyboard.userId !== '' && selectedKeyboard.userId !== 'n/a') {
        setStatusError('If the keyboard is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
        console.log('userId validation failed');
        return;
      }
    } else if (selectedKeyboard.available === 'false') {
      // If not available, status must be one of the allowed statuses
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(selectedKeyboard.status)) {
        setStatusError('If the keyboard is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // Clear error if validation passes
      setStatusError('');
    }

    updKeyboard({
      variables: {
        input: {
          id: selectedKeyboard.id,
          tagid: selectedKeyboard.tagid,
          model: selectedKeyboard.model,
          brand: selectedKeyboard.brand,
          serial_Number: selectedKeyboard.serial_Number,
          wireless: selectedKeyboard.wireless,
          usb: selectedKeyboard.usb,
          combo: selectedKeyboard.combo,
          price: priceValue,
          store: selectedKeyboard.store,
          purchase_date: formattedData_purchase,
          assignment_date: formattedData_assignment,
          warranty_date: formattedData_warranty,
          returnable_date: formattedData_returnable,
          available: selectedKeyboard.available,
          status: selectedKeyboard.status,
          assigned_to: "",
          location: selectedKeyboard.location,
          team: selectedKeyboard.team,
          userId: selectedKeyboard.userId,
        }
      }
    }).then(() => {
      setIsEditing(true);
      setShowKeyboardAddOverlay(false);
      setPriceError('');
      setStatusError('');
      setDuplicateError('');

    }).catch(error => {
      console.error("Error updating keyboard:", error);
    });
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  //listUserFixes 
  const UsersOptions = userData?.listUserFixes?.items
    ?.filter((u) => u && u.first_name && u.last_name) // Filtrar valores nulos o sin nombres
    ?.map((u) => ({
      id: u.id,
      name: `${u.first_name.charAt(0).toUpperCase() + u.first_name.slice(1).toLowerCase()} 
           ${u.last_name.charAt(0).toUpperCase() + u.last_name.slice(1).toLowerCase()}`
    }))
    ?.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' })) || [];


  const formatDate = (dateString) => {
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return localDate.toLocaleDateString(undefined, options);
  };

  const formatPrice = (price) => {
    if (price === null || price === undefined || price === '') {
      return '';
    }
    const parsedPrice = parseFloat(price);
    return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
  };

  return (
    <div className='keyboard-page'>
      <header className="inventory-header">
        <h1>Keyboard</h1>
      </header>
      <Inventory_header />
      <div className="inventory-search">
        <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>
      </div>

      <div className="searchBar">
        <button className="add-mouse-btn" onClick={() => {
          clearNewKeyboard();
          setSelectedKeyboard(null);
          setIsEditing(false);
          setShowKeyboardAddOverlay(true);
        }}>+ Add New Keyboard</button>
        <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      </div>
      <div className="keyboard-table-container">
        <table className="keyboard-table">

          <thead>
            <tr>
              <th>#</th> { }
              <th onClick={() => requestSort('tagid')}>
                TAG
                {sortConfig?.key === 'tagid' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('model')}>
                Model
                {sortConfig?.key === 'model' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('brand')}>
                Brand
                {sortConfig?.key === 'brand' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('serial_Number')}>
                Serial Number
                {sortConfig?.key === 'serial_Number' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('wireless')}>
                Wireless
                {sortConfig?.key === 'wireless' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('usb')}>
                USB
                {sortConfig?.key === 'usb' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('combo')}>
                Combo
                {sortConfig?.key === 'combo' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('price')}>
                Price
                {sortConfig?.key === 'price' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('store')}>
                Store
                {sortConfig?.key === 'store' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('purchase_date')}>
                Purchase Date
                {sortConfig?.key === 'purchase_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('available')}>
                Available
                {sortConfig?.key === 'available' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('status')}>
                Status
                {sortConfig?.key === 'status' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('userId')}>
                Assigned To
                {sortConfig?.key === 'userId' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('assignment_date')}>
                Assignment Date
                {sortConfig?.key === 'assignment_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('warranty_date')}>
                Warranty Date
                {sortConfig?.key === 'warranty_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('returnable_date')}>
                Returnable Date
                {sortConfig?.key === 'returnable_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('location')}>
                Location
                {sortConfig?.key === 'location' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('team')}>
                Team
                {sortConfig?.key === 'team' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredKeyboards.map((keyboard, index) => {
              const userFullName = findUserFullNameByID(keyboard.userId);

              return (

                <tr key={keyboard.id} onClick={() => handleEditKeyboard(keyboard)}>
                  <td>{index + 1}</td> {/* Número de fila */}
                  <td className="keyboard-tagid">{capitalize(keyboard.tagid)}</td>
                  <td className="keyboard-model">{capitalize(keyboard.model?.toLowerCase() || 'N/A')}</td>
                  <td className="keyboard-brand">{capitalize(keyboard.brand?.toLowerCase() || 'N/A')}</td>
                  <td className="keyboard-serial_Number">{keyboard.serial_Number?.toUpperCase() || 'N/A'}</td>
                  <td className="keyboard-wireless">{keyboard.wireless ? 'Yes' : 'No'}</td>
                  <td className="keyboard-usb">{keyboard.usb ? 'Yes' : 'No'}</td>
                  <td className="keyboard-combo">{keyboard.combo ? 'Yes' : 'No'}</td>
                  <td className="keyboard-price">${formatPrice(keyboard.price)}</td>
                  <td className="keyboard-store">{keyboard.store}</td>
                  <td className="keyboard-purchase_date">{keyboard.purchase_date ? formatDate(keyboard.purchase_date) : 'No Date'}</td>
                  <td className="keyboard-available">{keyboard.available ? 'Yes' : 'No'} </td>
                  <td className="keyboard-status">
                    {keyboard.status === 'broken' ? 'Broken' :
                      keyboard.status === 'use' ? 'Use' :
                        keyboard.status === 'transfer' ? 'Transfer' :
                          keyboard.status === 'lost' ? 'Lost' :
                            keyboard.status === 'inv' ? 'Inventario' :
                              ''} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                  </td>

                  <td className={`userId ${searchField === 'userId' ? 'highlight' : ''}`}>
                    {userFullName ? capitalize(userFullName) : "N/A"}
                  </td>
                  <td className="keyboard-assignment_date">{keyboard.assignment_date ? formatDate(keyboard.assignment_date) : 'No Date'}</td>
                  <td className="keyboard-warranty_date">{keyboard.warranty_date ? formatDate(keyboard.warranty_date) : 'No Date'}</td>
                  <td className="keyboard-returnable_date">{keyboard.returnable_date ? formatDate(keyboard.returnable_date) : 'No Date'}</td>
                  <td className="keyboard-location">{keyboard.location}</td>
                  <td className="keyboard-team">{keyboard.team}</td>

                </tr>
              );
            })}
          </tbody>
        </table>
      </div>


      {nextToken && !loading && (
        <button onClick={loadMore}>Load More</button>
      )}

      {loading && <p>Loading...</p>}

      {showKeyboardAddOverlay && (

        <div className="keyboard_add_overlay" onClick={(e) => {
          // Close overlay only if clicked outside the modal content
          if (e.target.classList.contains("keyboard_add_overlay")) {
            setShowKeyboardAddOverlay(false);
            setIsEditing(false);
            setSelectedKeyboard(null);
          }
        }}>
          <div className="overlay-content-inventory">
            <div className="header-row">
              <h2 className="header-title">{isEditing ? 'Edit Keyboard' : 'Add New Keyboard'}</h2>
              <button onClick={() => {
                setShowKeyboardAddOverlay(false);
                clearNewKeyboard();
                setSelectedKeyboard(null);
                setIsEditing(false);
              }}>X</button>
            </div>

            <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedKeyboard.tagid : newKeyboard.tagid} onChange={handleInputChange} required />
            <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedKeyboard.model : newKeyboard.model} onChange={handleInputChange} required />

            <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedKeyboard.brand : newKeyboard.brand} onChange={handleInputChange} />
            <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedKeyboard.serial_Number : newKeyboard.serial_Number} onChange={handleInputChange} required />

            <div className='inventory_checkboxes' >
              <label>
                Wireless</label>
              <input name="wireless"
                type="checkbox" checked={isEditing ? selectedKeyboard.wireless : newKeyboard.wireless}
                onChange={handleInputChange}
              />
              <label>
                USB</label>
              <input name="usb"
                type="checkbox" checked={isEditing ? selectedKeyboard.usb : newKeyboard.usb}
                onChange={handleInputChange}
              />
              <label>
                Combo</label>
              <input name="combo"
                type="checkbox" checked={isEditing ? selectedKeyboard.combo : newKeyboard.combo}
                onChange={handleInputChange}
              />
            </div>

            <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedKeyboard.price : newKeyboard.price} onChange={handleInputChange} />
            <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedKeyboard.store : newKeyboard.store} onChange={handleInputChange} />

            <label htmlFor="purchase_date">Purchase Date:</label>
            <input
              type="Date"
              id="purchase_date"
              name="purchase_date"
              value={isEditing ? selectedKeyboard.purchase_date : newKeyboard.purchase_date}
              onChange={handleInputChange}

            />
            <label htmlFor="assignment_date">Assignment Date:</label>
            <input
              type="date"
              id="assignment_date"
              name="assignment_date"
              value={isEditing ? selectedKeyboard.assignment_date : newKeyboard.assignment_date}
              onChange={handleInputChange}

            />
            <label htmlFor="warranty_date">Warranty Date:</label>
            <input
              type="date"
              id="warranty_date"
              name="warranty_date"
              value={isEditing ? selectedKeyboard.warranty_date : newKeyboard.warranty_date}
              onChange={handleInputChange}

            />
            <label htmlFor="returnable_date">Returnable Date:</label>
            <input
              type="date"
              id="returnable_date"
              name="returnable_date"
              value={isEditing ? selectedKeyboard.returnable_date : newKeyboard.returnable_date}
              onChange={handleInputChange}
            />

            <label>Available:</label>

            <select name="available" placeholder="available" value={isEditing ? selectedKeyboard.available : newKeyboard.available} onChange={handleInputChange} >
              <option value="" disabled hidden className="placeholder-option">Select Availability</option>
              <option value="true">Yes</option>
              <option value="false">No</option>

            </select>
            <label>Status:</label>
            <select name="status" placeholder="status" value={isEditing ? selectedKeyboard.status : newKeyboard.status} onChange={handleInputChange} >
              <option value="" disabled hidden>Select Status</option>
              <option value="use">Use</option>
              <option value="broken">Broken</option>
              <option value="transfer">Transfer</option>
              <option value="lost">Lost</option>
              <option value="inv">Inventario</option>
            </select>
            <label>Assigned To:</label>
            <select
              name="userId"
              value={isEditing ? selectedKeyboard.userId : newKeyboard.userId}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select an employee
              </option>
              <option value="n/a">N/A</option>
              {UsersOptions.length > 0 ? (
                UsersOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>No employees available</option>
              )}


            </select>

            <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedKeyboard.location : newKeyboard.location} onChange={handleInputChange} />
            <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedKeyboard.team : newKeyboard.team} onChange={handleInputChange} />

            {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
            {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
            {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

            <div className="button-group">
              <button className="overlay-add-keyboard" onClick={isEditing ? handleUpdateKeyboard : handleAddKeyboard}>
                {isEditing ? 'Update Keyboard' : 'Add Keyboard'}
              </button>
              {isEditing && (
                <button
                  className="overlay-delet-keyboard"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this keyboard?')) {
                      handleDeleteKeyboard();
                    }
                  }}
                >
                  Delete keyboard
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Keyboard;
