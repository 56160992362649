import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import moment from 'moment';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { FixedSizeGrid as Grid } from 'react-window';
import {
  listAppointmentFixes,
  listUsersByTeamOne,
  clientsByName
} from '../../graphql/queries';
import {
  createAppointmentFix,
  updateAppointmentFix,
  deleteAppointmentFix
} from '../../graphql/mutations';
import {
  handleAddAppointment,
  handleUpdateAppointment,
  handleDeleteAppointment,
  handleCancelAppointment,
  generateDurationOptions,
  formatDuration
} from './AppointmentHelperFuncs';

import ClientForm from '../../components/ClientForm';
import useFetchUserByEmail from '../../components/UserInfoLoader/LoadUserInfo';
import { capitalize } from '../../components/common/capitalize';
import './Appointments.css';

const Appointments = () => {
  const client = useApolloClient();
  const [appointments, setAppointments] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const headerRef = useRef(null);

  const [showAppointmentAddOverlay, setShowAppointmentAddOverlay] = useState(false);
  const [showClientFormOverlay, setShowClientFormOverlay] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  // Grid setup
  const columnCount = 11; // We have 11 columns: ID, Title, Date, Time, Duration, Customer, Location, Navigator, ModifiedBy, UpdatedAt, Actions
  const columnWidth = 240;
  const rowHeight = 30;
  const [gridWidth, setGridWidth] = useState(window.innerWidth * 0.97);
  const [gridHeight, setGridHeight] = useState(300); // You can adjust or dynamically calculate

  // Appointment form
  const [newAppointment, setNewAppointment] = useState({
    id: '',
    title: '',
    customer: '',
    location: '',
    date: '',
    recurrence: '',
    service: '',
    navigator: '',
    time: '',
    duration: '',
    notes: '',
    userId: '' // We'll populate this once we have it
  });



  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'ascending' });
  const [searchQuery, setSearchQuery] = useState('');
  const [searchColumn, setSearchColumn] = useState('title');

  // For client searching
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [matchingClients, setMatchingClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientIsEditing, setClientIsEditing] = useState(false);
  const [isFetchingClients, setIsFetchingClients] = useState(false);

  // For user info
  const [userEmail, setUserEmail] = useState(null);
  const { userInfo: userLoggedIn, loading: userLoading, error: userError } = useFetchUserByEmail(userEmail);

  // For user's groups
  const [userGroup, setUserGroup] = useState(null);

  // 1) On mount: fetch session/groups, set userEmail
  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const { signInDetails } = await getCurrentUser();
        const session = await fetchAuthSession();
        const groups = session.tokens.idToken.payload['cognito:groups'] || [];
        setUserGroup(groups);
        setUserEmail(signInDetails.loginId.toLowerCase());
      } catch (error) {
        console.log('User not authenticated', error);
      }
    };
    fetchUserGroup();
  }, []);

  // 2) Once userLoggedIn is loaded, set userId (when it's available)
  useEffect(() => {
    if (userLoggedIn && userLoggedIn.id) {
      setUserId(userLoggedIn.id);
    }
  }, [userLoggedIn]);

  // 3) Client searching
  const fetchClientsOnSearch = async (searchTerm) => {
    setIsFetchingClients(true);
    try {
      const { data } = await client.query({
        query: clientsByName,
        variables: {
          name: searchTerm,
          limit: 100
        }
      });
      const clients = data.clientsByName.items;
      setMatchingClients(clients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setIsFetchingClients(false);
    }
  };

  const handleClientSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setClientSearchTerm(searchTerm);
    if (searchTerm === '') {
      setMatchingClients([]);
    } else {
      fetchClientsOnSearch(searchTerm);
    }
  };

  // 4) Adding a new appointment (show overlay, set defaults)
  const handleAddAppointmentClick = () => {
    if (!userId) {
      console.error('User ID not yet loaded. Cannot create an appointment without userId.');
      return;
    }
    setShowAppointmentAddOverlay(true);
    setIsEditing(false);
    setNewAppointment({
      id: '',
      title: '',
      customer: '',
      location: '',
      date: '',
      recurrence: '',
      service: '',
      navigator: '',
      time: '',
      duration: '',
      notes: '',
      userId: userId
    });
    setClientSearchTerm('');
  };

  // 5) Client selection
  const handleClientSelect = (client) => {
    const customerName = `${client.name} ${client.last_name}`;
    setNewAppointment((prev) => ({
      ...prev,
      customer: customerName,
      clientEmail: client.email
    }));
    setClientSearchTerm(customerName);
    setMatchingClients([]);
  };

  const handleClientAdded = () => {
    // ...
  };

  // 6) Queries
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne, {
    variables: {
      filter: { navigator_id: { ne: '' } },
      limit: 100
    }
  });

  const { loading, data, fetchMore } = useQuery(listAppointmentFixes, {
    variables: { limit: 100 }
  });

  // 7) Set navigator dropdown options
  const navigatorOptions = usersData?.listUserFixes?.items?.map((u) => ({
    id: u.id,
    name: `${u.first_name.charAt(0).toUpperCase() + u.first_name.slice(1).toLowerCase()} ${u.last_name.charAt(0).toUpperCase() + u.last_name.slice(1).toLowerCase()}`
  })) || [];

  // 8) Mutations
  const [createAppt] = useMutation(createAppointmentFix, {
    refetchQueries: [{ query: listAppointmentFixes, variables: { limit: 100 } }]
  });

  const [updateAppt] = useMutation(updateAppointmentFix, {
    refetchQueries: [{ query: listAppointmentFixes, variables: { limit: 100 } }]
  });

  const [deleteAppt] = useMutation(deleteAppointmentFix, {
    refetchQueries: [{ query: listAppointmentFixes, variables: { limit: 100 } }]
  });

  // 9) Once we have data from listAppointmentFixes, set state
  useEffect(() => {
    if (data) {
      const formattedAppointments = data.listAppointmentFixes.items.map((appt) => ({
        ...appt,
        date: new Date(appt.date),
        status: appt.status
      }));
      setAppointments(formattedAppointments);
      setNextToken(data.listAppointmentFixes.nextToken);
    }
  }, [data]);

  // 10) Additional handlers
  const handleAddNewClient = () => {
    setClientIsEditing(false);
    setSelectedClient(null);
    setShowClientFormOverlay(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectAppointment = (appt) => {
    setNewAppointment({
      id: appt.id,
      title: appt.title,
      customer: appt.customer,
      location: appt.location,
      date: appt.date,
      recurrence: appt.recurrence,
      service: appt.service,
      time: appt.time,
      duration: appt.duration,
      navigator: appt.navigator,
      notes: appt.notes,
      status: appt.status,
      clientEmail: appt.clientEmail,
      userId: appt.user.id
    });
    setShowAppointmentAddOverlay(true);
    setIsEditing(true);
    setSelectedAppointment(appt);
  };

  // 11) On add or update, pass the existing newAppointment (including userId) to utility
  const onAddAppointment = async () => {
    if (!newAppointment.user?.id) {
      console.error('No userId in newAppointment. Cannot create appointment.');
      return;
    }
    await handleAddAppointment(newAppointment, setShowAppointmentAddOverlay, createAppt);
  };

  const onUpdateAppointment = async () => {
    if (!newAppointment.user.id) {
      console.error('No userId in newAppointment. Cannot update appointment.');
      return;
    }
    await handleUpdateAppointment(
      newAppointment,
      setShowAppointmentAddOverlay,
      setNewAppointment,
      setIsEditing,
      setSelectedAppointment,
      updateAppt,
      newAppointment.clientEmail
    );
  };

  const onDeleteAppointment = async () => {
    if (window.confirm('Are you sure you want to delete this appointment?')) {
      await handleDeleteAppointment(
        selectedAppointment,
        setShowAppointmentAddOverlay,
        setIsEditing,
        setSelectedAppointment,
        deleteAppt
      );
    }
  };

  const onCancelAppointment = async () => {
    if (window.confirm('Are you sure you want to cancel this appointment?')) {
      await handleCancelAppointment(
        selectedAppointment,
        setShowAppointmentAddOverlay,
        setIsEditing,
        setSelectedAppointment,
        updateAppt,
        selectedAppointment.clientEmail
      );
    }
  };

  // 12) Sorting
  const sortedAppointments = React.useMemo(() => {
    let sortableAppointments = [...appointments];
    if (sortConfig !== null) {
      sortableAppointments.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle date/time/duration
        if (sortConfig.key === 'date' || sortConfig.key === 'updatedAt') {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }
        if (sortConfig.key === 'time') {
          aValue = moment(aValue, 'HH:mm').toDate();
          bValue = moment(bValue, 'HH:mm').toDate();
        }
        if (sortConfig.key === 'duration') {
          aValue = parseInt(aValue, 10);
          bValue = parseInt(bValue, 10);
        }

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableAppointments;
  }, [appointments, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Grid: HeaderCell for column headers
  const HeaderCell = ({ columnIndex, style }) => {
    let headerText;
    let sortKey;

    switch (columnIndex) {
      case 0:
        headerText = 'Appointment ID';
        sortKey = 'id';
        break;
      case 1:
        headerText = 'Title';
        sortKey = 'title';
        break;
      case 2:
        headerText = 'Date';
        sortKey = 'date';
        break;
      case 3:
        headerText = 'Time';
        sortKey = 'time';
        break;
      case 4:
        headerText = 'Duration';
        sortKey = 'duration';
        break;
      case 5:
        headerText = 'Customer';
        sortKey = 'customer';
        break;
      case 6:
        headerText = 'Location';
        sortKey = 'location';
        break;
      case 7:
        headerText = 'Navigator';
        sortKey = 'navigator';
        break;
      case 8:
        headerText = 'Modified By';
        sortKey = 'ModifiedBy';
        break;
      case 9:
        headerText = 'Updated At';
        sortKey = 'updatedAt';
        break;
      case 10:
        headerText = 'Actions';
        sortKey = null; // no sorting for actions
        break;
      default:
        headerText = '';
        sortKey = null;
    }

    const onHeaderClick = () => {
      if (sortKey) {
        requestSort(sortKey);
      }
    };

    return (
      <div
        style={{
          ...style,
          width: columnWidth,
          height: rowHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#23395d',
          color: 'white',
          fontWeight: 'bold',
          borderRight: '1px solid #ddd',
          cursor: sortKey ? 'pointer' : 'default',
        }}
        onClick={onHeaderClick}
      >
        {headerText}
        {sortConfig.key === sortKey && (
          <span style={{ marginLeft: '5px' }}>
            {sortConfig.direction === 'ascending' ? '↑' : '↓'}
          </span>
        )}
      </div>
    );
  };

  // Grid: Cell for each data row
  const Cell = ({ columnIndex, rowIndex, style }) => {
    const appt = filteredAppointments[rowIndex];
    if (!appt) return null;

    let content = '';
    let customStyles = {
      ...style,
      width: columnWidth,
      height: rowHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px solid #ddd',
      borderRight: '1px solid #ddd',
      backgroundColor: rowIndex % 2 === 0 ? '#f9f9f9' : '#e0e0e0', // Alternating row colors
    };
    switch (columnIndex) {
      case 0:
        content = appt.id;
        break;
      case 1:
        content = appt.title;
        break;
      case 2:
        content = moment(appt.date).format('MM-DD-YYYY');
        break;
      case 3:
        content = moment(appt.time, 'HH:mm').format('hh:mm A');
        break;
      case 4:
        content = formatDuration(appt.duration);
        break;
      case 5:
        content = appt.customer;
        break;
      case 6:
        content = appt.location;
        break;
      case 7:
        content = appt.navigator;
        break;
      case 8:
        content = appt.ModifiedBy || '';
        customStyles.justifyContent = 'flex-start'; // Align text to the left
        customStyles.paddingLeft = '10px'; // Add some padding to prevent text from touching the edge
        break;
      case 9:
        content = appt.updatedAt
          ? moment(appt.updatedAt).format('YYYY-MM-DD HH:mm:ss')
          : '';
        break;
      case 10:
        content = (
          <button className="edit-btn" onClick={() => handleSelectAppointment(appt)}>
            Update / Cancel
          </button>
        );
        break;
      default:
        content = '';
    }

    // For row highlighting by status
    // (If you'd like row-based coloring, do it here)
    let rowClass = '';
    if (appt.status === 'canceled') {
      rowClass = 'status-canceled';
    } else if (appt.status === 'confirmed') {
      rowClass = 'status-confirmed';
    } else {
      rowClass = 'status-created';
    }

    return (
      <div
        style={customStyles}
        className={rowClass}
      >
        {content}
      </div>
    );
  };

  // 13) Filtering
  const filteredAppointments = sortedAppointments.filter((appt) => {
    const fieldValue = appt[searchColumn] || '';
    return fieldValue.toString().toLowerCase().includes(searchQuery.toLowerCase());
  });

  const dataRowCount = filteredAppointments.length;

  // 14) Recalculate grid width on window resize if desired
  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth * 0.97);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 15) Conditionals for data loading
  if (usersLoading) return <p>Loading navigators...</p>;
  if (usersError) return <p>Error loading navigators: {usersError.message}</p>;

  return (
    <div className="appointments-page">
      <header className="appointments-header">
        <h1>Appointments</h1>
      </header>

      <div className="appointments-controls">
        <button className="add-appointment-btn" onClick={handleAddAppointmentClick}>
          Add Appointment
        </button>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select
          value={searchColumn}
          onChange={(e) => setSearchColumn(e.target.value)}
        >
          <option value="title">Title</option>
          <option value="customer">Customer</option>
          <option value="location">Location</option>
          <option value="navigator">Navigator</option>
          <option value="ModifiedBy">Modified By</option>
        </select>
      </div>

      {/* Sticky Header Grid */}
      <div
        className="grid-header"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          overflow: 'auto',
        }}
        ref={headerRef}
      >
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={rowHeight}
          rowCount={1}
          rowHeight={rowHeight}
          width={Math.max(gridWidth, columnWidth * columnCount)}
          style={{ overflow: 'hidden' }}
        >
          {HeaderCell}
        </Grid>
      </div>

      {/* Data Grid */}
      <Grid
        columnCount={columnCount}
        columnWidth={columnWidth}
        height={gridHeight}
        rowCount={dataRowCount}
        rowHeight={rowHeight}
        width={gridWidth}

        onScroll={({ scrollLeft }) => {
          if (headerRef.current) {
            headerRef.current.scrollLeft = scrollLeft;
          }
        }}
      >
        {Cell}
      </Grid>

      {showAppointmentAddOverlay && (
        <div
          className="event-add-overlay"
          onClick={(e) => {
            if (e.target.classList.contains('event-add-overlay')) {
              setShowAppointmentAddOverlay(false);
              setIsEditing(false);
              setSelectedAppointment(null);
            }
          }}
        >
          <div className="overlay-content-appointments">
            <button className="close-btn_appointments" onClick={() => setShowAppointmentAddOverlay(false)}>
              X
            </button>
            <h2>{isEditing ? 'Edit Appointment' : 'Add New Appointment'}</h2>
            <input
              required
              name="id"
              placeholder="ID"
              value={newAppointment.id}
              onChange={handleInputChange}
              disabled
            />
            <div className="appointment-form-grid">
              {/* Customer on its own row */}
              <div className="form-group full-width">
                <label htmlFor="customer">Customer</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    id="customer"
                    type="text"
                    placeholder="Search customer..."
                    value={isEditing ? newAppointment.customer : clientSearchTerm}
                    onChange={handleClientSearchChange}
                    style={{ flex: 1 }}
                  />
                  <button
                    className="add-client-btn"
                    onClick={handleAddNewClient}
                    title="Add New Client"
                  >
                    +
                  </button>
                </div>
                {matchingClients.length > 0 && (
                  <ul className="client-suggestions">
                    {matchingClients
                      .slice()
                      .sort((a, b) => {
                        const fullNameA = `${a.name.toLowerCase()} ${a.last_name.toLowerCase()}`;
                        const fullNameB = `${b.name.toLowerCase()} ${b.last_name.toLowerCase()}`;
                        if (fullNameA < fullNameB) return -1;
                        if (fullNameA > fullNameB) return 1;
                        return 0;
                      })
                      .map((client) => (
                        <li key={client.id} onClick={() => handleClientSelect(client)}>
                          {capitalize(client.name)} {capitalize(client.last_name)}
                        </li>
                      ))}
                    {nextToken && (
                      <li
                        className="load-more"
                        onClick={() => fetchClientsOnSearch(clientSearchTerm, nextToken)}
                      >
                        {isFetchingClients ? 'Loading...' : 'Load More'}
                      </li>
                    )}
                  </ul>
                )}
                {isFetchingClients && <p>Loading clients...</p>}
              </div>

              {/* Location on its own row */}
              <div className="form-group full-width">
                <label htmlFor="location">Location</label>
                <select
                  id="location"
                  value={newAppointment.location}
                  onChange={handleInputChange}
                  name="location"
                >
                  <option value="" disabled>
                    Select a location
                  </option>
                  <option value="The Health Collaborative @ MAUC (78207)">
                    The Health Collaborative @ MAUC (78207)
                  </option>
                </select>
              </div>

              {/* Date and Recurring in two columns */}
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  required
                  type="date"
                  name="date"
                  placeholder="Date Date"
                  value={moment(newAppointment.date).format('YYYY-MM-DD')}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="recurrence">Recurring</label>
                <select
                  name="recurrence"
                  value={newAppointment.recurrence}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select recurrence
                  </option>
                  <option value="Not Recurring">Not Recurring</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>

              {/* Service and Navigator in two columns */}
              <div className="form-group">
                <label htmlFor="service">Service</label>
                <select
                  name="service"
                  value={newAppointment.service}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select a service
                  </option>
                  <option value="Health Insurance / Seguro Medico">
                    Health Insurance / Seguro Medico
                  </option>
                  <option value="Medicaid / Chip">Medicaid / Chip</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="navigator">Navigator</label>
                <select
                  required
                  name="navigator"
                  value={newAppointment.userId}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select a navigator
                  </option>
                  {navigatorOptions
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group-row">
                <div className="form-group">
                  <label htmlFor="time">Time</label>
                  <input
                    type="time"
                    name="time"
                    value={newAppointment.time}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="duration">Duration</label>
                  <select
                    name="duration"
                    value={newAppointment.duration}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select a duration
                    </option>
                    {generateDurationOptions()}
                  </select>
                </div>
              </div>

              {/* Booking Notes */}
              <div className="form-group full-width notes">
                <label htmlFor="Booking Notes">Booking Notes</label>
                <textarea
                  name="notes"
                  value={newAppointment.notes}
                  onChange={handleInputChange}
                  className="notes-input"
                />
              </div>
            </div>

            <div className="button-group">
              <button
                className="add-btn"
                onClick={isEditing ? onUpdateAppointment : onAddAppointment}
                disabled={!newAppointment.date}
              >
                {isEditing ? 'Update Appointment' : 'Add Appointment'}
              </button>
              {isEditing && (
                <>
                  <button
                    className="delete-btn"
                    onClick={onCancelAppointment}
                    disabled={!selectedAppointment}
                  >
                    Cancel Appointment
                  </button>
                  {userGroup?.includes('dev') && (
                    <button
                      className="delete-btn"
                      onClick={onDeleteAppointment}
                      disabled={!selectedAppointment}
                    >
                      Delete Appointment
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {showClientFormOverlay && (
        <div className="client-form-overlay">
          <div className="overlay-content-client">
            <ClientForm
              isEditing={clientIsEditing}
              selectedClient={selectedClient}
              onClientAdded={handleClientAdded}
              onClose={() => setShowClientFormOverlay(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Appointments;
