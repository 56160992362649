import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { useQuery, useMutation } from "@apollo/client";
import { GET_DYNAMIC_FORM } from "../../graphql/queries";
import { CREATE_FORM_SUBMISSION, UPDATE_DYNAMIC_FORM } from "../../graphql/mutations";
import "./FormStyles.css"; // the new shared file

function FormPage() {
    const [cards, setCards] = useState([]);
    const [formName, setFormName] = useState("");
    const [answers, setAnswers] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [originalFormName, setOriginalFormName] = useState("");
    const [originalCards, setOriginalCards] = useState([]);
    const [editingCardIndex, setEditingCardIndex] = useState(null);
    const [tempCardLabel, setTempCardLabel] = useState("");


    // Hard-coded form ID (example). Could come from props or route params
    const [formId, setFormId] = useState("88a2d03c-5cc6-4b3f-b533-c12988995b8a");

    // For admin check
    const [userGroups, setUserGroups] = useState([]);
    const [userEmail, setUserEmail] = useState(null);
    const isAdmin = userGroups?.some((group) => group === "Admin" || group === "Manager");

    // "Add Card" modal states
    const [showAddCardModal, setShowAddCardModal] = useState(false);
    const [modalPosition, setModalPosition] = useState("below"); // "above" or "below"
    const [modalCardIndex, setModalCardIndex] = useState(null);

    // New card info
    const [newCardLabel, setNewCardLabel] = useState("Untitled Section");
    const [newCardPageIndex, setNewCardPageIndex] = useState(1);
    const [newCardFields, setNewCardFields] = useState([]);

    // Queries
    const { loading, error, data } = useQuery(GET_DYNAMIC_FORM, {
        variables: { id: formId },
        skip: !formId,
        fetchPolicy: "network-only",
    });

    // Mutations
    const [createSubmission] = useMutation(CREATE_FORM_SUBMISSION);
    const [updateDynamicForm] = useMutation(UPDATE_DYNAMIC_FORM);

    const handleRevertChanges = () => {
        setFormName(originalFormName);
        setCards(originalCards);
        alert("Reverted to original version.");
    };

    // 1. Check user groups
    const fetchUserDetails = async () => {
        try {
            const { signInDetails } = await getCurrentUser();
            const session = await fetchAuthSession();
            const groups = session.tokens.idToken.payload["cognito:groups"] || [];
            setUserGroups(groups);
            setUserEmail(signInDetails.loginId.toLowerCase());
        } catch (err) {
            console.log("User not authenticated", err);
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);

    // 2. Load form data
    useEffect(() => {
        if (data && data.getDynamicForm) {
            setFormName(data.getDynamicForm.name);
            setCards(data.getDynamicForm.cards || []);
            setOriginalFormName(data.getDynamicForm.name);        // Store original name
            setOriginalCards(data.getDynamicForm.cards || []);      // Store original cards
            setCurrentPage(1);
        }
    }, [data]);


    // =============== ADMIN: Insert a new card ===============
    const showAddCardModalAt = (cardIndex, position) => {
        setModalCardIndex(cardIndex);
        setModalPosition(position);
        setNewCardLabel("Untitled Section");
        setNewCardPageIndex(currentPage); // Default to the current page
        setNewCardFields([]);
        setShowAddCardModal(true);
    };

    // Add card in local state
    const addCardAtIndex = (baseIndex, position) => {
        const newCard = {
            id: uuidv4(),
            label: newCardLabel || "Untitled Section",
            pageIndex: newCardPageIndex || 1,
            fields: newCardFields,
        };

        const updated = [...cards];
        const insertIndex = position === "above" ? baseIndex : baseIndex + 1;
        updated.splice(insertIndex, 0, newCard);
        setCards(updated);
    };

    const handleCreateCard = () => {
        if (modalCardIndex == null) {
            setShowAddCardModal(false);
            return;
        }
        addCardAtIndex(modalCardIndex, modalPosition);
        // Reset
        setNewCardLabel("Untitled Section");
        setNewCardPageIndex(1);
        setNewCardFields([]);
        setShowAddCardModal(false);
    };

    // Add, remove, update new fields in the "Add Card" modal
    const addNewField = () => {
        setNewCardFields((prev) => [
            ...prev,
            { id: uuidv4(), label: "", type: "text", options: [] },
        ]);
    };
    const removeNewField = (index) => {
        setNewCardFields((prev) => prev.filter((_, i) => i !== index));
    };
    const updateNewField = (index, key, value) => {
        setNewCardFields((prev) => {
            const updated = [...prev];
            updated[index][key] = value;
            if ((value === "select" || value === "radio") && key === "type") {
                updated[index].options = updated[index].options || [];
            }
            return updated;
        });
    };

    // =============== ADMIN: Save Changes to DB ===============
    function cleanForMutation(obj) {
        if (Array.isArray(obj)) {
            return obj.map(cleanForMutation);
        } else if (obj !== null && typeof obj === "object") {
            const newObj = {};
            Object.keys(obj).forEach((key) => {
                if (key !== "__typename") {
                    newObj[key] = cleanForMutation(obj[key]);
                }
            });
            return newObj;
        }
        return obj;
    }

    const handleSaveForm = async () => {
        try {
            const cleanedCards = cleanForMutation(cards);

            // console.log("id form:", formId);
            // console.log("namne form:", formName);
            console.log("cards form:", cleanedCards);
            const { data } = await updateDynamicForm({
                variables: {
                    input: {
                        id: formId,
                        name: formName,
                        cards: cleanedCards,
                    },
                },
            });
            alert("Changes saved to DB!");
            console.log("Updated form:", data.updateDynamicForm);
        } catch (err) {
            console.error("Error saving form changes:", err);
            alert("Failed to save changes. Check console for details.");
        }
    };

    const updateCardLabel = (cardIndex, newLabel) => {
        setCards((prevCards) =>
            prevCards.map((card, index) =>
                index === cardIndex ? { ...card, label: newLabel } : card
            )
        );
    };


    // =============== Fill-out logic for all users ===============
    const visibleCards = cards.filter((card) => (card.pageIndex || 1) === currentPage);

    const handleAnswerChange = (fieldId, value) => {
        setAnswers((prev) => ({ ...prev, [fieldId]: value }));
    };

    const submitAnswers = async () => {
        if (!formId) {
            alert("No form ID provided. Can't submit answers.");
            return;
        }
        try {
            await createSubmission({
                variables: {
                    input: {
                        formID: formId,
                        answers: JSON.stringify(answers),
                    },
                },
            });
            alert("Answers submitted!");
        } catch (err) {
            console.error("Error creating submission:", err);
        }
    };

    // Pagination
    const maxPage = cards.reduce((max, card) => {
        const page = card.pageIndex || 1;
        return Math.max(max, page);
    }, 1);

    const handleNextPage = () => {
        if (currentPage < maxPage) setCurrentPage(currentPage + 1);
    };
    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    if (loading) return <p>Loading form...</p>;
    if (error) return <p>Error: {error.message}</p>;

    function findGlobalIndex(card) {
        return cards.findIndex((c) => c.id === card.id);
    }

    const isDirty = JSON.stringify(cards) !== JSON.stringify(originalCards);


    return (
        <div className="form-page-root">
            <div className="form-page-container">
                <header className="dynamic-report-header">
                    <h1>{formName}</h1>
                    <p>
                        Page {currentPage} of {maxPage}
                    </p>
                </header>
                {/* If admin, show "Save Changes" button to persist to DB */}
                {isAdmin && isDirty && (
                    <div className="admin-controls">
                        <button
                            onClick={handleSaveForm}
                            style={{ marginLeft: "1rem", backgroundColor: "#2e7d32" }}
                        >
                            Save Changes
                        </button>
                        <button
                            onClick={handleRevertChanges}
                            style={{ marginLeft: "1rem", backgroundColor: "#c62828" }}
                        >
                            Revert Changes
                        </button>
                    </div>
                )}
                {/* Render visible cards */}
                {visibleCards.map((card) => {
                    const cardIndex = findGlobalIndex(card);
                    return (
                        <div key={card.id} className={`card-section ${isAdmin ? "admin-hoverable" : ""}`}>
                            {/* Admin-only top hover button to add a card above */}
                            {isAdmin && (
                                <div className="card-hover-buttons top-buttons">
                                    <button
                                        className="hover-button round-button"
                                        onClick={() => showAddCardModalAt(cardIndex, "above")}
                                    >
                                        +
                                    </button>
                                </div>
                            )}

                            {/* Card Title with inline edit controls for admin */}
                            {isAdmin ? (
                                <div className="card-title-edit">
                                    {editingCardIndex === cardIndex ? (
                                        <div className="card-title-editing">
                                            <input
                                                type="text"
                                                value={tempCardLabel}
                                                onChange={(e) => setTempCardLabel(e.target.value)}
                                            />
                                            <button
                                                onClick={() => {
                                                    updateCardLabel(cardIndex, tempCardLabel);
                                                    setEditingCardIndex(null);
                                                }}
                                            >
                                                Save
                                            </button>
                                            <button onClick={() => setEditingCardIndex(null)}>Cancel</button>
                                        </div>
                                    ) : (
                                        <div className="card-title-display">
                                            <h2>{card.label}</h2>
                                            <button
                                                className="edit-button"
                                                onClick={() => {
                                                    setEditingCardIndex(cardIndex);
                                                    setTempCardLabel(card.label);
                                                }}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <h2>{card.label}</h2>
                            )}

                            {/* Render fields as before */}
                            {card.fields.map((field) => {
                                const fieldAnswer = answers[field.id] || "";
                                return (
                                    <div key={field.id} className="field-view">
                                        <label>{field.label}</label>
                                        {field.type === "text" && (
                                            <input
                                                type="text"
                                                value={fieldAnswer}
                                                onChange={(e) =>
                                                    handleAnswerChange(field.id, e.target.value)
                                                }
                                            />
                                        )}
                                        {field.type === "select" && (
                                            <select
                                                value={fieldAnswer}
                                                onChange={(e) =>
                                                    handleAnswerChange(field.id, e.target.value)
                                                }
                                            >
                                                <option value="">-- Choose an option --</option>
                                                {field.options.map((opt, i) => (
                                                    <option key={i} value={opt.trim()}>
                                                        {opt.trim()}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                        {field.type === "radio" && (
                                            <div style={{ marginLeft: "1rem" }}>
                                                {field.options.map((opt, i) => (
                                                    <label key={i} style={{ marginRight: "1rem" }}>
                                                        <input
                                                            type="radio"
                                                            name={field.id}
                                                            value={opt.trim()}
                                                            checked={fieldAnswer === opt.trim()}
                                                            onChange={() =>
                                                                handleAnswerChange(field.id, opt.trim())
                                                            }
                                                        />
                                                        {opt.trim()}
                                                    </label>
                                                ))}
                                            </div>
                                        )}
                                        {field.type === "checkbox" && (
                                            <input
                                                type="checkbox"
                                                checked={fieldAnswer || false}
                                                onChange={(e) =>
                                                    handleAnswerChange(field.id, e.target.checked)
                                                }
                                            />
                                        )}
                                        {field.type === "date" && (
                                            <input
                                                type="date"
                                                value={fieldAnswer}
                                                onChange={(e) =>
                                                    handleAnswerChange(field.id, e.target.value)
                                                }
                                            />
                                        )}
                                    </div>
                                );
                            })}

                            {/* Admin-only bottom hover button */}
                            {isAdmin && (
                                <div className="card-hover-buttons bottom-buttons">
                                    <button
                                        className="hover-button round-button"
                                        onClick={() => showAddCardModalAt(cardIndex, "below")}
                                    >
                                        +
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                })}


                {/* Pagination */}
                <div className="navigation-buttons">
                    {currentPage > 1 && <button onClick={handlePrevPage}>Back</button>}
                    {currentPage < maxPage ? (
                        <button onClick={handleNextPage}>Next</button>
                    ) : (
                        <button onClick={submitAnswers}>Submit</button>
                    )}
                </div>

                {/* Admin "Add Card" Modal */}
                {isAdmin && showAddCardModal && (
                    <div className="modal-backdrop">
                        <div className="modal-content">
                            <h2>Add New Card</h2>

                            <label>Card Label:</label>
                            <input
                                type="text"
                                value={newCardLabel}
                                onChange={(e) => setNewCardLabel(e.target.value)}
                            />

                            <label>Page Index:</label>
                            <input
                                type="number"
                                min="1"
                                value={newCardPageIndex}
                                onChange={(e) =>
                                    setNewCardPageIndex(parseInt(e.target.value, 10))
                                }
                            />

                            <hr />
                            <h3>Fields for This Card:</h3>
                            {newCardFields.map((field, i) => (
                                <div key={field.id} className="admin-new-field">
                                    <label>Label:</label>
                                    <input
                                        type="text"
                                        value={field.label}
                                        onChange={(e) => updateNewField(i, "label", e.target.value)}
                                    />

                                    <label>Type:</label>
                                    <select
                                        value={field.type}
                                        onChange={(e) => updateNewField(i, "type", e.target.value)}
                                    >
                                        <option value="text">Text</option>
                                        <option value="select">Select</option>
                                        <option value="radio">Radio</option>
                                        <option value="checkbox">Checkbox</option>
                                        <option value="date">Date</option>
                                    </select>

                                    {(field.type === "select" || field.type === "radio") && (
                                        <div style={{ marginLeft: "1rem" }}>
                                            <label>Options (comma-separated):</label>
                                            <input
                                                type="text"
                                                value={field.options?.join(",") || ""}
                                                onChange={(e) =>
                                                    updateNewField(i, "options", e.target.value.split(","))
                                                }
                                            />
                                        </div>
                                    )}

                                    <button
                                        style={{ marginLeft: "1rem", backgroundColor: "#c62828" }}
                                        onClick={() => removeNewField(i)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}

                            <button onClick={addNewField} style={{ marginBottom: "1rem" }}>
                                + Add Field
                            </button>

                            <div>
                                <button onClick={handleCreateCard} style={{ marginRight: "10px" }}>
                                    Create
                                </button>
                                <button onClick={() => setShowAddCardModal(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FormPage;
