import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  getWorkSchedulesByUser,
  getWorkSchedule,
  listUsersByTeamOne,
  GET_ALL_LOCATIONS,
  listScheduleTemplates
} from '../../graphql/queries';

import {
  createWorkSchedule,
  updateWorkSchedule,
  deleteWorkSchedule,
} from '../../graphql/mutations';

import ShiftModal from './components/ShiftModal';
import TemplateModal from './components/TemplateModal';
import LocationModal from '../../components/Locations/ListLocations';
import LoadingSpinner from '../../components/layout/LoadingSpinner'; // <-- Import your spinner

import { capitalize } from '../../components/common/capitalize';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './WorkSchedules.css'; // We'll define a .loading-overlay style here

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const WorkSchedules = () => {
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]); // For user columns
  const [view, setView] = useState(Views.DAY);

  // For SHIFT creation/editing
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);

  // For modals
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  // Filtering + data from DB
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [navigators, setNavigators] = useState([]);
  const [currentUser, setCurrentUser] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [userEmail, setUserEmail] = useState(null);

  // Loading state for spinner
  const [isLoading, setIsLoading] = useState(false);

  // Lazy queries / mutations
  const [fetchUserSchedules] = useLazyQuery(getWorkSchedulesByUser);
  const [fetchSchedule] = useLazyQuery(getWorkSchedule);
  const [fetchUsers] = useLazyQuery(listUsersByTeamOne);
  const [fetchTemplates] = useLazyQuery(listScheduleTemplates);
  const [saveWorkSchedule] = useMutation(createWorkSchedule);
  const [updateSchedule] = useMutation(updateWorkSchedule);
  const [removeWorkSchedule] = useMutation(deleteWorkSchedule);

  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);
      setUserEmail(signInDetails.loginId.toLowerCase());
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  const isAdmin = userGroups?.some(group => group === 'Admin' || group === 'Manager');

  // We'll also define a lazy query for locations
  const [fetchLocations] = useLazyQuery(GET_ALL_LOCATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.listLocationIndependents?.items) {
        const mappedLocations = data.listLocationIndependents.items
          .map((item) => ({
            id: item.id,
            name: item.name,
            color: item.color || 'rgba(169,169,169,0.5)',
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setLocations(mappedLocations);
      }
    },
    onError: (error) => {
      console.error('Error fetching locations:', error);
    },
  });

  // ---------------------------------------
  // Load Templates
  // ---------------------------------------
  const loadAllScheduleTemplates = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchTemplates({ variables: { limit: 50 } });
      if (data?.listScheduleTemplates?.items) {
        const mapped = data.listScheduleTemplates.items.map((tmpl) => ({
          id: tmpl.id,
          name: tmpl.name,
          days: tmpl.days.map((day) => ({
            day: day.day,
            shift: day.shift,
          })),
          totalHours: tmpl.totalHours,
        }));
        setTemplates(mapped);
      }
    } catch (error) {
      console.error('Error loading templates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------------------------
  // Load Schedules for a Specific User
  // ---------------------------------------
  const loadSchedulesByUser = async (user) => {
    try {
      const { data } = await fetchUserSchedules({
        variables: {
          userId: user.id,
          startDate: '2024-08-27', // Adjust your date range
          endDate: '2025-08-29',
        },
      });
      if (data?.workSchedulesByUser?.items) {
        const fetchedEvents = data.workSchedulesByUser.items.map((schedule) => ({
          id: schedule.id,
          title: `${user.title} — ${schedule.location} Shift`,
          start: new Date(`${schedule.date}T${schedule.startTime}`),
          end: new Date(`${schedule.date}T${schedule.endTime}`),
          allDay: false,
          resourceId: user.id,
          location: schedule.location,
          isTemporary: false,
        }));
        setEvents((prev) => [...prev, ...fetchedEvents]);
      }
    } catch (error) {
      console.error('Error loading schedules:', error);
    }
  };

  // ---------------------------------------
  // Load All Users and Their Schedules
  // ---------------------------------------
  const loadAllUsersAndSchedules = async () => {
    try {
      const { data } = await fetchUsers({ variables: { limit: 50 } });

      if (data?.listUserFixes?.items) {
        const users = data.listUserFixes.items
          .filter((u) => u.first_name && u.last_name)
          .map((u) => ({
            id: u.id,
            title: `${capitalize(u.first_name)} ${capitalize(u.last_name)}`,
          }))
          .sort((a, b) => a.title.localeCompare(b.title));

        setResources(users);
        setNavigators(users);

        // Fetch schedules for all users in parallel
        const schedulePromises = users.map((user) =>
          fetchUserSchedules({
            variables: {
              userId: user.id,
              startDate: '2024-08-27',
              endDate: '2025-08-29',
            },
          })
        );

        const scheduleResults = await Promise.all(schedulePromises);

        let allEvents = []; const loadAllUsersAndSchedules = async () => {
          try {
            const { data } = await fetchUsers({ variables: { limit: 50 } });
            if (data?.listUserFixes?.items) {
              const users = data.listUserFixes.items
                .filter((u) => u.first_name && u.last_name)
                .map((u) => ({
                  id: u.id,
                  title: `${capitalize(u.first_name)} ${capitalize(u.last_name)}`,
                }))
                .sort((a, b) => a.title.localeCompare(b.title));

              setResources(users);
              setNavigators(users);

              for (const user of users) {
                await loadSchedulesByUser(user);
              }
            }
          } catch (error) {
            console.error('Error loading users & schedules:', error);
          }
        };
        scheduleResults.forEach((result, index) => {
          const user = users[index];
          const fetchedEvents = result?.data?.workSchedulesByUser?.items.map((schedule) => ({
            id: schedule.id,
            title: `${user.title} — ${schedule.location} Shift`,
            start: new Date(`${schedule.date}T${schedule.startTime}`),
            end: new Date(`${schedule.date}T${schedule.endTime}`),
            allDay: false,
            resourceId: user.id,
            location: schedule.location,
            isTemporary: false,
          })) || [];
          allEvents = [...allEvents, ...fetchedEvents];
        });

        setEvents(allEvents);
      }
    } catch (error) {
      console.error('Error loading users & schedules:', error);
    }
  };

  // ---------------------------------------
  // Initial Data Load
  // ---------------------------------------
  useEffect(() => {
    const loadAllData = async () => {
      setIsLoading(true);
      try {
        // 1) Fetch Locations
        await fetchLocations();

        // 2) Load All Users & Their Schedules
        await loadAllUsersAndSchedules();

        // 3) Load All Templates
        await loadAllScheduleTemplates();
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUserDetails();
  }, []);


  // ---------------------------------------
  // DRAG & DROP: Move Event
  // ---------------------------------------
  const handleEventDrop = async ({ event, start, end, allDay }) => {
    const date = moment(start).format('YYYY-MM-DD');
    const startTime = moment(start).format('HH:mm');
    const endTime = moment(end).format('HH:mm');

    if (event.isTemporary) {
      // Just update local state, do not call DB
      const updatedTempEvent = { ...event, start, end, allDay };
      setEvents((prev) => prev.map((evt) => (evt.id === event.id ? updatedTempEvent : evt)));
      return;
    }

    setIsLoading(true);
    try {
      if (event.id) {
        const updateInput = { id: event.id, date, startTime, endTime };
        await updateSchedule({ variables: { input: updateInput } });
      }
      // Update local
      const updatedEvent = { ...event, start, end, allDay };
      setEvents((prev) => prev.map((evt) => (evt.id === event.id ? updatedEvent : evt)));
    } catch (error) {
      console.error('Error updating on drop:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------------------------
  // DRAG & DROP: Resize Event
  // ---------------------------------------
  const handleEventResize = async ({ event, start, end, allDay }) => {
    const date = moment(start).format('YYYY-MM-DD');
    const startTime = moment(start).format('HH:mm');
    const endTime = moment(end).format('HH:mm');

    if (event.isTemporary) {
      // Just update local state
      const updatedTempEvent = { ...event, start, end, allDay };
      setEvents((prev) => prev.map((evt) => (evt.id === event.id ? updatedTempEvent : evt)));
      return;
    }

    setIsLoading(true);
    try {
      if (event.id) {
        const updateInput = { id: event.id, date, startTime, endTime };
        await updateSchedule({ variables: { input: updateInput } });
      }
      // Update local
      const updatedEvent = { ...event, start, end, allDay };
      setEvents((prev) => prev.map((evt) => (evt.id === event.id ? updatedEvent : evt)));
    } catch (error) {
      console.error('Error updating on resize:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------------------------
  // Save (Create/Update) Shift
  // ---------------------------------------
  const handleSaveShift = async (shift) => {
    const foundLocation = locations.find((loc) => loc.name === shift.location);
    const isTemp = !foundLocation;

    // Build input
    const input = {
      date: shift.date,
      startTime: shift.startTime,
      endTime: shift.endTime,
      breakStart: shift.breakStart,
      breakEnd: shift.breakEnd,
      location: shift.location,
      userId: shift.resourceId,
    };

    setIsLoading(true);
    try {
      if (shift.id && !shift.isTemporary) {
        // Update
        const updateInput = { id: shift.id, ...input };
        await updateSchedule({ variables: { input: updateInput } });

        // Update local
        const updatedEvent = {
          id: shift.id,
          title: `${findUserName(shift.resourceId)} — ${shift.location} Shift`,
          start: new Date(`${shift.date}T${shift.startTime}`),
          end: new Date(`${shift.date}T${shift.endTime}`),
          resourceId: shift.resourceId,
          allDay: false,
          location: shift.location,
          isTemporary: false,
        };
        setEvents((prev) => prev.map((evt) => (evt.id === shift.id ? updatedEvent : evt)));
      } else if (!shift.id && !isTemp) {
        // Create
        const { data } = await saveWorkSchedule({ variables: { input } });
        const createdId = data?.createWorkSchedule?.id || `temp-id-${Date.now()}`;

        // Add local
        const newEvent = {
          id: createdId,
          title: `${findUserName(shift.resourceId)} — ${shift.location} Shift`,
          start: new Date(`${shift.date}T${shift.startTime}`),
          end: new Date(`${shift.date}T${shift.endTime}`),
          resourceId: shift.resourceId,
          allDay: false,
          location: shift.location,
          isTemporary: false,
        };
        setEvents((prev) => [...prev, newEvent]);
      } else {
        // Temporary
        const tempId = `temp-id-${Date.now()}`;
        const newTempEvent = {
          id: tempId,
          title: `${findUserName(shift.resourceId)} — ${shift.location} Shift`,
          start: new Date(`${shift.date}T${shift.startTime}`),
          end: new Date(`${shift.date}T${shift.endTime}`),
          resourceId: shift.resourceId,
          allDay: false,
          location: shift.location,
          isTemporary: true,
        };
        setEvents((prev) => [...prev, newTempEvent]);
      }
    } catch (err) {
      console.error('Error saving shift:', err);
      alert('Failed to save shift. Check console for details.');
    } finally {
      // Close modal + reset
      setIsLoading(false);
      setIsModalOpen(false);
      setSelectedSlot(null);
      setSelectedShift(null);
    }
  };

  // ---------------------------------------
  // onSaveTemplateShifts
  // ---------------------------------------
  const dayOffsetMap = {
    Saturday: 0,
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
  };

  async function onSaveTemplateShifts(navigatorId, startDate, template, location) {
    if (!navigatorId || !startDate || !template || !template.days) {
      alert('Required fields missing to create template shifts.');
      return;
    }

    setIsLoading(true);
    try {
      const selectedDate = moment(startDate, 'YYYY-MM-DD');
      const dayOfWeek = selectedDate.day(); // 0 = Sunday ... 6 = Saturday
      const diffToSaturday = ((dayOfWeek + 7) - 6) % 7;
      const baseSaturday = selectedDate.clone().subtract(diffToSaturday, 'days');

      const newShifts = [];

      for (const dayObj of template.days) {
        if (!dayObj.shift || dayObj.shift.toUpperCase() === 'OFF') {
          continue;
        }
        const offset = dayOffsetMap[dayObj.day];
        if (offset === undefined) continue; // invalid day name

        const shiftDate = baseSaturday.clone().add(offset, 'days');
        const [startTime, endTime] = dayObj.shift.split('-') || [];
        if (!startTime || !endTime) {
          console.warn(`Invalid shift format for '${dayObj.shift}' on ${dayObj.day}`);
          continue;
        }

        // Build input
        const scheduleInput = {
          userId: navigatorId,
          date: shiftDate.format('YYYY-MM-DD'),
          startTime,
          endTime,
          breakStart: '12:00',
          breakEnd: '13:00',
          location,
        };

        // Save to DB
        const { data } = await saveWorkSchedule({ variables: { input: scheduleInput } });
        const createdId = data?.createWorkSchedule?.id;

        // Local event
        const userName = findUserName(navigatorId);
        const newEvent = {
          id: createdId,
          title: `${userName} — ${location} Shift`,
          start: new Date(`${scheduleInput.date}T${startTime}`),
          end: new Date(`${scheduleInput.date}T${endTime}`),
          resourceId: navigatorId,
          location,
          breakStart: scheduleInput.breakStart,
          breakEnd: scheduleInput.breakEnd,
          allDay: false,
          isTemporary: false,
        };
        newShifts.push(newEvent);
      }

      // Update local state
      setEvents((prev) => [...prev, ...newShifts]);
    } catch (err) {
      console.error('Error creating template-based shifts:', err);
      alert('Failed to create shifts from template. See console for details.');
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  }

  // Helper to find a user’s name from resources
  const findUserName = (userId) => {
    const user = resources.find((u) => u.id === userId);
    return user ? user.title : 'Unknown';
  };

  // ---------------------------------------
  // Delete Shift
  // ---------------------------------------
  const handleDeleteShift = async () => {
    if (!selectedShift?.id) return;

    // If it's a temporary event (just remove from UI)
    if (selectedShift.isTemporary) {
      setEvents((prev) => prev.filter((evt) => evt.id !== selectedShift.id));
      setIsModalOpen(false);
      setSelectedShift(null);
      setSelectedSlot(null);
      return;
    }

    setIsLoading(true);
    try {
      // DB deletion
      await removeWorkSchedule({ variables: { input: { id: selectedShift.id } } });

      // Immediately remove from state
      setEvents((prev) => prev.filter((evt) => evt.id !== selectedShift.id));

      // Optionally refetch if you want everything fresh:
      // await refetchAllSchedules();

      // Close modal
      setIsModalOpen(false);
      setSelectedShift(null);
      setSelectedSlot(null);
    } catch (err) {
      console.error('Error deleting shift:', err);
      alert('Failed to delete shift. Check console for details.');
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------------------------
  // Click Event: Edit Shift
  // ---------------------------------------
  const handleSelectEvent = async (event) => {
    if (!isAdmin) return; // Prevent non-admins from editing shifts

    // If it's temporary, no DB fetch needed
    if (event.isTemporary) {
      setSelectedShift({
        ...event,
        date: moment(event.start).format('YYYY-MM-DD'),
        startTime: moment(event.start).format('HH:mm'),
        endTime: moment(event.end).format('HH:mm'),
        breakStart: '',
        breakEnd: '',
      });
      setSelectedSlot(null);
      setIsModalOpen(true);
      return;
    }

    setIsLoading(true);
    try {
      // Otherwise, fetch from DB
      const schedule = await fetchSchedule({ variables: { id: event.id } });
      const dbItem = schedule?.data?.getWorkSchedule;

      if (dbItem) {
        const { id, userId, date, startTime, endTime, breakStart, breakEnd, location } = dbItem;
        setSelectedShift({
          id,
          resourceId: userId,
          date,
          startTime,
          endTime,
          breakStart,
          breakEnd,
          location,
          title: `${findUserName(userId)} — ${location} Shift`,
          isTemporary: false,
        });
      } else {
        // Fallback
        setSelectedShift({
          id: event.id,
          resourceId: event.resourceId,
          title: event.title,
          isTemporary: false,
        });
      }
      setSelectedSlot(null);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching shift details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------------------------
  // Click Blank Slot: Create New Shift
  // ---------------------------------------
  const handleSelectSlot = (slotInfo) => {
    if (!isAdmin) return; // Prevent non-admins from creating shifts

    const date = moment(slotInfo.start).format('YYYY-MM-DD');
    const startTime = moment(slotInfo.start).format('HH:mm');
    const endTime = moment(slotInfo.end).format('HH:mm');
    const resourceId = slotInfo.resourceId || '';

    setSelectedSlot({
      date,
      startTime,
      endTime,
      resourceId,
      location: '',
      isTemporary: false,
    });
    setSelectedShift(null);
    setIsModalOpen(true);
  };
  // ---------------------------------------
  // DELETE via 'Delete' Key
  // ---------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isAdmin) return; // ❌ Non-admins cannot delete shifts
      // If we have an event selected and the user presses 'Delete':
      if (e.key === 'Delete' && selectedShift) {
        e.preventDefault();
        handleDeleteShift();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedShift, isAdmin]); // <-- Ensure it reacts to isAdmin changes

  // ---------------------------------------
  // EVENT COLORING by Location or Temporary
  // ---------------------------------------
  const getTextColor = (bgColor) => {
    const [r, g, b] = bgColor.match(/\d+/g)?.map(Number) || [169, 169, 169];
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? '#000' : '#fff';
  };

  const eventPropGetter = (event) => {
    if (event.isTemporary) {
      return {
        style: {
          backgroundColor: 'rgba(104, 180, 235, 0.5)',
          color: '#fff',
          borderRadius: '4px',
          border: 'none',
        },
      };
    }
    const foundLocation = locations.find((loc) => loc.name === event.location);
    const backgroundColor = foundLocation?.color || 'rgba(169,169,169,0.5)';
    const textColor = getTextColor(backgroundColor);
    return {
      style: {
        backgroundColor,
        color: textColor,
        borderRadius: '4px',
        border: 'none',
      },
    };
  };

  // ---------------------------------------
  // Filter events by selected user + location
  // ---------------------------------------
  const filteredEvents = React.useMemo(() => {
    return events.filter((evt) => {
      const matchesUser = currentUser ? evt.resourceId === currentUser : true;
      const matchesLocation = selectedLocation ? evt.location === selectedLocation : true;
      return matchesUser && matchesLocation;
    });
  }, [events, currentUser, selectedLocation]);



  // When the user changes in the select:
  const handleUserChange = (e) => {
    const value = e.target.value;
    setCurrentUser(value);
    if (value) setView(Views.WEEK);
    else setView(Views.DAY);
  };

  return (
    <div className="WorkSchedules">
      <header className="WorkSchedules-header">
        <h1>Work Schedule</h1>
      </header>

      {/* If loading, display a full-screen overlay with the spinner */}
      {isLoading && (
        <div className="loading-overlay">
          <LoadingSpinner />
        </div>
      )}

      <div className="ws-toolbar">
        <div className="ws-selectors">
          {/* Location Selector */}
          <div className="ws-selector">
            <label htmlFor="location-selector">Location:</label>
            <select
              id="location-selector"
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              disabled={isLoading}
            >
              <option value="">All Locations</option>
              {locations.map((loc) => (
                <option key={loc.id} value={loc.name}>
                  {loc.name}
                </option>
              ))}
            </select>
          </div>

          {/* User Selector */}
          <div className="ws-selector">
            <label htmlFor="user-selector">User:</label>
            <select
              id="user-selector"
              value={currentUser}
              onChange={handleUserChange}
              disabled={isLoading}
            >
              <option value="">All</option>
              {resources.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.title}
                </option>
              ))}
            </select>
          </div>

          {/* Location Modal Button */}
          {isAdmin && (
            <button
              className="ws-location-btn"
              onClick={() => setIsLocationModalOpen(true)}
              disabled={isLoading}
            >
              Add/Remove Location
            </button>
          )}
          {/* Template Modal Button */}
          {isAdmin && (
            <button
              className="ws-template-btn"
              onClick={() => setIsTemplateModalOpen(true)}
              disabled={isLoading}
            >
              Manage Templates
            </button>
          )}
        </div>
      </div>

      <div className="ws-work-schedules-wrapper">
        <DnDCalendar
          localizer={localizer}
          events={filteredEvents}
          resources={view === Views.DAY ? resources : undefined}
          resourceIdAccessor="id"
          resourceTitleAccessor="title"
          startAccessor="start"
          endAccessor="end"
          defaultDate={new Date()}
          view={view}
          onView={setView}
          views={[Views.DAY, Views.WEEK, Views.MONTH]}
          className="ws-work-schedules-calendar"
          draggableAccessor={() => !isLoading} // disable dragging if loading
          resizableAccessor={() => !isLoading} // disable resizing if loading
          selectable={!isLoading}
          scrollToTime={new Date(1970, 1, 1, 6, 0)} // <--- Focus at 5 AM
          min={new Date(1970, 1, 1, 5, 0)}         // <--- Earliest time shown
          max={new Date(1970, 1, 1, 23, 0)}        // <--- Latest time shown
          style={{ height: '90vh' }}
          formats={{
            dayHeaderFormat: (date, culture, localizer) =>
              localizer.format(date, 'dddd, MMMM D, YYYY', culture),  // Example: "Monday, March 4, 2025"
            dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
              `${localizer.format(start, 'MMMM D, YYYY', culture)} - ${localizer.format(end, 'MMMM D, YYYY', culture)}`,
            monthHeaderFormat: 'MMMM YYYY', // Example: "March 2025"
          }}
          onEventDrop={isAdmin ? handleEventDrop : null} // ❌ Non-admins cannot move shifts
          onEventResize={isAdmin ? handleEventResize : null} // ❌ Non-admins cannot resize shifts
          onSelectSlot={isAdmin ? handleSelectSlot : null} // ❌ Non-admins cannot create new shifts
          onSelectEvent={isAdmin ? handleSelectEvent : null} // ❌ Non-admins cannot open shift modal
          eventPropGetter={eventPropGetter}
        />
      </div>

      {/* Shift Modal */}
      {isAdmin && (
        <ShiftModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedSlot(null);
            setSelectedShift(null);
          }}
          onSave={handleSaveShift}
          onSaveTemplateShifts={onSaveTemplateShifts}
          shift={selectedShift || selectedSlot}
          locations={locations}
          navigators={navigators}
          onDelete={handleDeleteShift}
          templates={templates}
        />
      )}

      {/* Location Modal */}
      <LocationModal
        isOpen={isLocationModalOpen}
        onClose={() => setIsLocationModalOpen(false)}
      />

      {/* Template Modal */}
      <TemplateModal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
        templates={templates}
        refetchTemplates={loadAllScheduleTemplates}
      />
    </div>
  );
};

export default WorkSchedules;
