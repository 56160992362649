import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { listDeleteEquipmentHistoryFixes, listLaptopFixes, listUsers } from '../../../graphql/queries';
import { createLaptopFix, deleteLaptopFix, updateLaptopFix, createDeleteEquipmentHistoryFix } from '../../../graphql/mutations';
import './Laptop.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';




const Laptop = () => {
    const [laptop, setLaptop] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showLaptopAddOverlay, setShowLaptopAddOverlay] = useState(false);
    const [selectedLaptop, setSelectedLaptop] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newLaptop, setNewLaptop] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        charger_cable: true,
        admin_password: '',
        password: '',
        norton_antivirus: true,
        os: '',
        ram: '',
        storage: '',
        device_id: '',
        product_id: '',
        bitloker: '',
        microsoft_account: '',
        microsoft_acount_password: '',
        apple_id: '',
        apple_password: '',
        purchase_date: '',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: true,
        status: 'inv',
        assigned_to: '',
        Description: '',
        location: '',
        team: '',
        userId: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [ramError, setRAMError] = useState('');
    const [storageError, setStorageError] = useState('');
    const [AppleIDError, setAppleIDError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);
    const [showPasswords, setShowPasswords] = useState(false);

    const handleSaveAsExcel = async () => {
        try {


            const laptopToExport = filteredLaptops.length > 0 ? filteredLaptops : laptop;
            if (laptopToExport.length > 0) {
                // Sort the Laptops by Timestamp in ascending order (earliest first)
                const sortedLaptop = [...laptopToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));
                // Prepare the data for Excel export with custom column titles

                const worksheetData = sortedLaptop.map((laptop, index) => ({

                    '#': index + 1, // Agregar el número de fila
                    'TAG:': laptop.tagid,
                    'Brand:': laptop.brand,
                    'Available:': laptop.available ? 'Yes' : 'No',
                    'Purchase Date:': laptop.purchase_date
                        ? new Date(laptop.purchase_date).toLocaleDateString('en-US') // Cambia el formato MM/DD/YYYY
                        : '',
                    'Serial Number:': laptop.serial_Number,
                    'Operating System:': laptop.os,
                    'Device ID:': laptop.device_id,
                    'Product ID:': laptop.product_id,
                    'Apple ID:': laptop.apple_id,
                    'Status:': laptop.status,
                    'Assigned to:': laptop.user?.first_name
                        ? laptop.user?.first_name +
                        (laptop.user?.last_name ? " " + laptop.user?.last_name : "")
                        : "N/A",
                    'Team:': laptop.team,
                    'Location:': laptop.location,
                    'Notes:': laptop.Description,

                    // Add other fields as needed

                }));


                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Laptops']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 14 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Adjust width for each column
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 40 },
                    { wch: 30 },
                    { wch: 20 },
                    { wch: 40 },
                    { wch: 40 },
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },


                ];
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Laptops');

                // Write file
                XLSX.writeFile(workbook, 'LaptopsData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditLaptop = (scanner) => {
        setSelectedLaptop(scanner);
        setIsEditing(true);
        setShowLaptopAddOverlay(true);
    };


    const findUserFullNameByID = (userID) => {
        if (!userID) return null;

        const userIDString = userID.toString().trim();

        if (!Array.isArray(userData?.listUserFixes?.items)) return null;
        const user = userData?.listUserFixes?.items.find((u) => u.id === userIDString);
        return user ? `${user.first_name} ${user.last_name}` : null;
    };


    const { loading, data, fetchMore } = useQuery(listLaptopFixes, {
        variables: { limit: 100 },
    });

    const [addLaptop] = useMutation(createLaptopFix, {
        refetchQueries: [{ query: listLaptopFixes, variables: { limit: 100 } }]
    });

    const [removeLaptop] = useMutation(deleteLaptopFix, {
        refetchQueries: [{ query: listLaptopFixes, variables: { limit: 100 } }]
    });
    const [updLaptop] = useMutation(updateLaptopFix, {
        refetchQueries: [{ query: listLaptopFixes, variables: { limit: 100 }, fetchPolicy: "network-only" }]
    });
    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistoryFix, {
        refetchQueries: [{ query: listDeleteEquipmentHistoryFixes, variables: { limit: 100 } }]
    });

    // Efecto para cargar todos los laptops con paginación
    useEffect(() => {
        const fetchAllLaptops = async () => {
            let AllLaptops = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllLaptops = [...AllLaptops, ...data.listLaptopFixes.items];
                currentNextToken = data.listLaptopFixes.nextToken;
            } while (currentNextToken);

            setLaptop(AllLaptops);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllLaptops();
        }
    }, [data, fetchMore]);

    const clearNewLaptop = () => {
        setNewLaptop({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            charger_cable: true,
            admin_password: '',
            password: '',
            norton_antivirus: true,
            os: '',
            ram: '',
            storage: '',
            device_id: '',
            product_id: '',
            bitloker: '',
            microsoft_account: '',
            microsoft_acount_password: '',
            apple_id: '',
            apple_password: '',
            purchase_date: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: true,
            status: 'inv',
            assigned_to: '',
            Description: '',
            location: '',
            team: '',
            userId: '',
        });
    };

    const navigatorOptions = React.useMemo(() => {
        return userData?.listUserFixes?.items
            ?.filter((u) => u && u.first_name && u.last_name) // Filtrar valores nulos o sin nombres
            ?.map((u) => ({
                id: u.id,
                name: `${capitalize(u.first_name)} ${capitalize(u.last_name)}`.replace(/\s+/g, " ").trim()
            }))
            ?.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' })) || [];
    }, [userData]);


    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listLaptopFixes.nextToken);
                return {
                    listLaptopFixes: {
                        ...fetchMoreResult.listLaptopFixes,
                        items: [...prevResult.listLaptopFixes.items, ...fetchMoreResult.listLaptopFixes.items]
                    }
                };
            }
        });
    };


    //Filtro para Search
    const filteredLaptops = useFilteredData(laptop, searchTerm, sortConfig, searchField, userData?.listUserFixes?.items);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Convertir a minúsculas y eliminar espacios en blanco para campos específicos
        if (['device_id', 'product_id', 'bitloker', 'serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        // Convertir a booleano si es el campo charger_cable
        if (name === 'charger_cable') {
            formattedValue = value === 'true'; // Convertir cadena 'true' a booleano
        }

        // Actualización del estado según si se está editando o creando un nuevo dispositivo
        if (isEditing) {
            setSelectedLaptop((prev) => ({ ...prev, [name]: formattedValue }));
        } else {
            setNewLaptop({
                ...newLaptop,
                id: uuidv4(), // Generar un nuevo id si es un nuevo dispositivo
                [name]: formattedValue
            });
        }
    };

    // Cerrar overlay
    const closeOverlay = () => {
        setShowLaptopAddOverlay(false);
        clearNewLaptop();
        setSelectedLaptop(null);
        setIsEditing(false);
        setAppleIDError('');
        setPriceError('');
        setStatusError('');
        setDuplicateError('');
        setStorageError('');
        setRAMError('');

    };

    // Función para alternar la visibilidad de las contraseñas
    const togglePasswords = () => {
        setShowPasswords(prevState => !prevState);
    };

    const handleAddLaptop = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newLaptop.purchase_date
            ? new Date(newLaptop.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newLaptop.assignment_date
            ? new Date(newLaptop.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newLaptop.warranty_date
            ? new Date(newLaptop.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newLaptop.returnable_date
            ? new Date(newLaptop.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newLaptop.price !== "" && newLaptop.price !== null && newLaptop.price != undefined) {
            priceValue = parseFloat(newLaptop.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = laptop.some(laptop =>
            laptop.tagid === newLaptop.tagid &&
            laptop.serial_Number === newLaptop.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A laptop with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }

        //Validate RAM and Storage must be INT
        const ramValue = newLaptop.ram === '' || newLaptop.ram === null ? 0 : parseInt(newLaptop.ram, 10);
        const storageValue = newLaptop.storage === '' || newLaptop.storage === null ? 0 : parseInt(newLaptop.storage, 10);

        const isRAMInteger = Number.isInteger(ramValue);
        const isStorageInteger = Number.isInteger(storageValue);

        if (!isRAMInteger || !isStorageInteger) {
            if (!isRAMInteger) {
                setRAMError('The RAM must be an integer.');
                console.log('RAM validation failed');
            } else {
                setRAMError(''); // Limpia el error si la RAM es válida
            }

            if (!isStorageInteger) {
                setStorageError('The Storage must be an integer.');
                console.log('Storage validation failed');
            } else {
                setStorageError(''); // Limpia el error si el Storage es válido
            }

            return; // Detiene el proceso si cualquiera de las validaciones falla
        } else {
            // Si ambos son válidos, limpia los errores
            setRAMError('');
            setStorageError('');
        }

        // Check availability first
        if (newLaptop.available === true || newLaptop.available === 'true') {

            // If available, status must be Inventario
            if (newLaptop.status !== 'inv') {
                setStatusError('If the Laptop is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, userId must be empty or "N/A"
            if (newLaptop.userId !== '' && newLaptop.userId !== 'n/a') {
                setStatusError('If the Laptop is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }


        } else if (newLaptop.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newLaptop.status)) {
                setStatusError('If the Laptop is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        //Check email

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const isValidEmail = (email) => emailPattern.test(email);

        const validateAppleId = (apple_id) => {
            if (apple_id === null || apple_id.trim() === '' || isValidEmail(apple_id)) {
                setAppleIDError(''); // No hay error
                console.log('Apple ID is valid or empty:', apple_id);
                return true;
            } else {
                setAppleIDError('Apple ID is not a valid email address.'); // Muestra error si no es un email válido
                console.log('Apple ID validation failed:', apple_id);
                return false;
            }
        };
        // Obtener el valor de apple_id y validar
        const { apple_id } = newLaptop; // Suponiendo que newLaptop tiene la propiedad apple_id

        // Validar el valor de apple_id
        const isAppleIdValid = validateAppleId(apple_id);

        if (!isAppleIdValid) {
            console.log('Invalid Apple ID, please correct it.');
            return;
        }

        // If all validations pass, proceed with adding the laptop to the database
        const safeUserId = newLaptop.userId && newLaptop.userId.trim() !== "" ? newLaptop.userId : "n/a";
        addLaptop({
            variables: { input: { ...newLaptop, ram: ramValue, storage: storageValue, userId: safeUserId, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
        })
            .then(() => {
                clearNewLaptop();
                alert("The laptop was an added success")
                setShowLaptopAddOverlay(false);
            }).catch(error => {
                console.error("Error adding laptop:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };

    const handleDeleteLaptop = async () => {
        try {
            // Remover campos innecesarios
            const LaptopData = removeUnnecessaryFields({ ...selectedLaptop }, [

                'charger_cable',
                'admin_password',
                'password',
                'norton_antivirus',
                'os',
                'ram:',
                'storage',
                'device_id',
                'product_id',
                'bitloker',
                'microsoft_account',
                'microsoft_acount_password',
                'apple_id',
                'apple_password',
                'warranty_date',
                'returnable_date',
                'status',
                'team',

            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'Description',
                'notes',
                'userId',

            ];

            // Filtrar los campos permitidos
            const cleanedNewLaptops = Object.fromEntries(
                Object.entries(LaptopData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewLaptops);

            // Normalizar claves alternativas
            cleanedNewLaptops.tagId = cleanedNewLaptops.tagId || cleanedNewLaptops.tagid;
            delete cleanedNewLaptops.tagid;

            cleanedNewLaptops.previous_employees =
                cleanedNewLaptops.previous_employees || cleanedNewLaptops.assigned_to;
            delete cleanedNewLaptops.assigned_to;

            cleanedNewLaptops.userId =
                cleanedNewLaptops.userId || cleanedNewLaptops.assigned_to;
            delete cleanedNewLaptops.assigned_to;

            cleanedNewLaptops.notes =
                cleanedNewLaptops.notes || cleanedNewLaptops.Description;
            delete cleanedNewLaptops.Description;


            // Añadir valores clave predeterminados
            if (!cleanedNewLaptops.Item) {
                cleanedNewLaptops.Item = "Laptop"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewLaptops).forEach((key) => {
                if (cleanedNewLaptops[key] === "" || cleanedNewLaptops[key] === null) {
                    delete cleanedNewLaptops[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewLaptops },
            });

            // Eliminar el Laptop
            await removeLaptop({
                variables: { input: { id: cleanedNewLaptops.id } },
            });

            // Resetear estados
            setShowLaptopAddOverlay(false);
            setIsEditing(false);
            setSelectedLaptop(null);

        } catch (error) {
            console.error("Error deleting Laptop", error);
        }
    };

    const handleUpdateLaptop = async () => {

        const formattedData_purchase = selectedLaptop.purchase_date ? new Date(selectedLaptop.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedLaptop.warranty_date ? new Date(selectedLaptop.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedLaptop.returnable_date ? new Date(selectedLaptop.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedLaptop.assignment_date ? new Date(selectedLaptop.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedLaptop.price !== "" && selectedLaptop.price !== null && selectedLaptop.price != undefined) {
            priceValue = parseFloat(selectedLaptop.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first
        if (selectedLaptop.available === 'true' || selectedLaptop.available === true) {

            // If available, status must be Inventario
            if (selectedLaptop.status !== 'inv') {
                setStatusError('If the Laptop is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, userId must be empty or "N/A"
            if (selectedLaptop.userId == '' && selectedLaptop.userId !== 'n/a') {
                setStatusError('If the Laptop is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                return; // Exit function if validation fails
            }

        } else if (selectedLaptop.available === 'false' || selectedLaptop.available === false) {  // Ensure we're checking for availability correctly
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedLaptop.status)) {
                setStatusError('If the Laptop is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes

            setStatusError('');
        }


        //Validate RAM and Storage must be INT
        const ramValue = selectedLaptop.ram === '' || selectedLaptop.ram === null ? 0 : parseInt(selectedLaptop.ram, 10);
        const storageValue = selectedLaptop.storage === '' || selectedLaptop.storage === null ? 0 : parseInt(selectedLaptop.storage, 10);

        const isRAMInteger = Number.isInteger(ramValue);
        const isStorageInteger = Number.isInteger(storageValue);

        if (!isRAMInteger || !isStorageInteger) {
            if (!isRAMInteger) {
                setRAMError('The RAM must be an integer.');
                console.log('RAM validation failed');
            } else {
                setRAMError(''); // Limpia el error si la RAM es válida
            }

            if (!isStorageInteger) {
                setStorageError('The Storage must be an integer.');
                console.log('Storage validation failed');
            } else {
                setStorageError(''); // Limpia el error si el Storage es válido
            }

            return; // Detiene el proceso si cualquiera de las validaciones falla
        } else {
            // Si ambos son válidos, limpia los errores
            setRAMError('');
            setStorageError('');
        }


        //Check email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const isValidEmail = (email) => emailPattern.test(email);

        const validateAppleId = (apple_id) => {
            // Verificar si apple_id es null o undefined y asignar una cadena vacía en ese caso
            const trimmedAppleId = (apple_id || '').trim();

            if (trimmedAppleId === '' || isValidEmail(trimmedAppleId)) {
                setAppleIDError(''); // No hay error
                console.log('Apple ID is valid or empty:', apple_id);
                return true;
            } else {
                setAppleIDError('Apple ID is not a valid email address.'); // Muestra error si no es un email válido
                console.log('Apple ID validation failed:', apple_id);
                return false;
            }
        };

        // Obtener el valor de apple_id y validar
        const { apple_id } = selectedLaptop || {}; // Asegura que selectedLaptop no sea null o undefined

        // Validar el valor de apple_id
        const isAppleIdValid = validateAppleId(apple_id);

        if (!isAppleIdValid) {
            console.log('Invalid Apple ID, please correct it.');
            return; // Detén el proceso si el Apple ID no es válido
        }

        console.log("Antes de actualizar", selectedLaptop)
        updLaptop({
            variables: {
                input: {
                    id: selectedLaptop.id,
                    tagid: selectedLaptop.tagid,
                    model: selectedLaptop.model,
                    brand: selectedLaptop.brand,
                    serial_Number: selectedLaptop.serial_Number,
                    price: priceValue,
                    store: selectedLaptop.store,
                    charger_cable: selectedLaptop.charger_cable,
                    admin_password: selectedLaptop.admin_password,
                    password: selectedLaptop.password,
                    norton_antivirus: selectedLaptop.norton_antivirus,
                    os: selectedLaptop.os,
                    ram: ramValue,
                    storage: storageValue,
                    device_id: selectedLaptop.device_id,
                    product_id: selectedLaptop.product_id,
                    bitloker: selectedLaptop.bitloker,
                    microsoft_account: selectedLaptop.microsoft_account,
                    microsoft_acount_password: selectedLaptop.microsoft_acount_password,
                    apple_id: selectedLaptop.apple_id,
                    apple_password: selectedLaptop.apple_password,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedLaptop.available,
                    status: selectedLaptop.status,
                    assigned_to: " ",
                    Description: selectedLaptop.Description || null,
                    location: selectedLaptop.location,
                    team: selectedLaptop.team,
                    userId: selectedLaptop.userId,


                }
               
            }
    
        }).then(() => {
       
            setPriceError('');
            setStatusError('');
            setDuplicateError('');
            setShowLaptopAddOverlay(false);


        }).catch(error => {
            console.error("Error updating Laptop:", error);
        });
    };


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };


    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };


    return (
        <div className="laptop-page">
            <header className="inventory-header">
                <h1>Laptop</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>
                {/* Botón para mostrar u ocultar las contraseñas */}
                <button onClick={togglePasswords}>
                    {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                </button>
            </div>

            <div className="searchBar">
                <button className="add-laptop-btn" onClick={() => {
                    clearNewLaptop();
                    setSelectedLaptop(null);
                    setIsEditing(false);
                    setShowLaptopAddOverlay(true);
                }}>+ Add New Laptop</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="laptop-table-container" >
                <table className="laptop-table">

                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('userId')}>
                                Assigned To
                                {sortConfig?.key === 'userId' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('admin_password')}>
                                Admin Password
                                {sortConfig?.key === 'admin_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('password')}>
                                User Password
                                {sortConfig?.key === 'password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('norton_antivirus')}>
                                Norton Antivirus
                                {sortConfig?.key === 'norton_antivirus' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('os')}>
                                Operating System
                                {sortConfig?.key === 'os' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('ram')}>
                                RAM
                                {sortConfig?.key === 'ram' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('storage')}>
                                Storage
                                {sortConfig?.key === 'storage' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('device_id')}>
                                Device ID
                                {sortConfig?.key === 'device_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('product_id')}>
                                Product ID
                                {sortConfig?.key === 'product_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('bitloker')}>
                                Bitloker
                                {sortConfig?.key === 'bitloker' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('microsoft_account')}>
                                Microsoft Account
                                {sortConfig?.key === 'microsoft_account' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('microsoft_acount_password')}>
                                Microsoft Account Password
                                {sortConfig?.key === 'microsoft_acount_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('apple_id')}>
                                Apple ID
                                {sortConfig?.key === 'apple_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('apple_password')}>
                                Apple Password
                                {sortConfig?.key === 'apple_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('Description')}>
                                Notes
                                {sortConfig?.key === 'Description' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredLaptops.map((laptop, index) => {
                            const userFullName = findUserFullNameByID(laptop.userId);

                            return (
                                <tr key={laptop.id} onClick={() => handleEditLaptop(laptop)} >
                                    <td>{index + 1}</td> {/* Número de fila */}
                                    <td className="laptop-tagid">{capitalize(laptop.tagid)}</td>
                                    <td className="laptop-model">{capitalize(laptop.model.toLowerCase())}</td>
                                    <td className="laptop-brand">{capitalize(laptop.brand.toLowerCase())}</td>
                                    <td className="laptop-serial_Number">{laptop.serial_Number.toUpperCase()}</td>
                                    <td className="laptop-price">${formatPrice(laptop.price)}</td>
                                    <td className="laptop-store">{laptop.store}</td>
                                    <td className="laptop-purchase_date">{laptop.purchase_date ? formatDate(laptop.purchase_date) : 'No Date'}</td>
                                    <td className="laptop-available">{laptop.available ? 'Yes' : 'No'}</td>
                                    <td className="laptop-status">
                                        {laptop.status === 'broken' ? 'Broken' :
                                            laptop.status === 'use' ? 'Use' :
                                                laptop.status === 'transfer' ? 'Transfer' :
                                                    laptop.status === 'lost' ? 'Lost' :
                                                        laptop.status === 'inv' ? 'Inventario' :
                                                            ''} {/* Muestra 'Use' si el estado no coincide con ninguno de los anteriores */}
                                    </td>
                                    <td className={`userId ${searchField === 'userId' ? 'highlight' : ''}`}>
                                        {userFullName ? capitalize(userFullName) : "N/A"}
                                    </td>
                                    <td className="laptop-assignment_date">{laptop.assignment_date ? formatDate(laptop.assignment_date) : 'No Date'}</td>
                                    <td className="laptop-charger_cable">{laptop.charger_cable ? 'Yes' : 'No'}
                                    </td>
                                    <td className="laptop-admin_password"> {showPasswords ? String(laptop.admin_password) : '******'}</td>
                                    <td className="laptop-password"> {showPasswords ? String(laptop.password) : '******'}</td>
                                    <td className="laptop-norton_antivirus"> {laptop.norton_antivirus ? 'Yes' : 'No'}</td>
                                    <td className="laptop-os">{String(laptop.os)}</td>
                                    <td className="laptop-ram">{laptop.ram !== null && laptop.ram !== undefined ? laptop.ram + " GB" : "No RAM Info"}</td>
                                    <td className="laptop-storage">{laptop.storage !== null && laptop.storage !== undefined ? laptop.storage + " GB" : "No Storage Info"}</td>
                                    <td className="laptop-device_id">{String(laptop.device_id)}</td>
                                    <td className="laptop-product_id">{String(laptop.product_id)}</td>
                                    <td className="laptop-bitloker">{String(laptop.bitloker)}</td>
                                    <td className="laptop-microsoft_account">{laptop.microsoft_account ? String(laptop.microsoft_account) : ''}</td>
                                    <td className="laptop-microsoft_acount_password">{showPasswords ? String(laptop.microsoft_acount_password) : '******'}</td>
                                    <td className="laptop-apple_id">{laptop.apple_id ? String(laptop.apple_id) : ''}</td>
                                    <td className="laptop-apple_password">{showPasswords ? String(laptop.apple_password) : '******'}</td>


                                    <td className="laptop-warranty_date">{laptop.warranty_date ? formatDate(laptop.warranty_date) : 'No Date'}</td>
                                    <td className="laptop-returnable_date">{laptop.returnable_date ? formatDate(laptop.returnable_date) : 'No Date'}</td>


                                    <td className="laptop-Description">{laptop.Description}</td>
                                    <td className="laptop-location">{laptop.location}</td>
                                    <td className="laptop-team">{laptop.team}</td>

                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showLaptopAddOverlay && (

                <div className="laptop_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("laptop_add_overlay")) {
                        closeOverlay();
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Laptop' : 'Add New Laptop'}</h2>
                            <button onClick={() => {
                                closeOverlay();
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedLaptop.tagid : newLaptop.tagid} onChange={handleInputChange} required />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedLaptop.model : newLaptop.model} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedLaptop.brand : newLaptop.brand} onChange={handleInputChange} />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedLaptop.serial_Number : newLaptop.serial_Number} onChange={handleInputChange} required />

                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedLaptop.price : newLaptop.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedLaptop.store : newLaptop.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedLaptop.purchase_date : newLaptop.purchase_date}
                            onChange={handleInputChange}

                        />
                        <label>Charger Cable:</label>
                        <select
                            name="charger_cable"
                            placeholder="charger_cable"
                            value={
                                isEditing
                                    ? (selectedLaptop?.charger_cable?.toString() || 'true') // Convertir booleano a string y manejar valores predeterminados
                                    : (newLaptop?.charger_cable?.toString() || 'true')
                            }
                            onChange={handleInputChange}
                        >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                        <label>Admin Password:</label><input type={showPasswords ? "text" : "password"} name="admin_password" placeholder="Password " value={isEditing ? selectedLaptop.admin_password : newLaptop.admin_password} onChange={handleInputChange} />
                        <label>User Password:</label><input type={showPasswords ? "text" : "password"} name="password" placeholder="Password " value={isEditing ? selectedLaptop.password : newLaptop.password} onChange={handleInputChange} />
                        <label> Norton Antivirus:</label>
                        <select name="norton_antivirus" placeholder="norton_antivirus" value={
                            isEditing
                                ? (selectedLaptop?.norton_antivirus?.toString() || 'true') // Convertir booleano a string y manejar valores predeterminados
                                : (newLaptop?.norton_antivirus?.toString() || 'true')
                        } onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Operating System:</label><input name="os" placeholder="Operating System" value={isEditing ? selectedLaptop.os : newLaptop.os} onChange={handleInputChange} />
                        <label> RAM:</label><input name="ram" placeholder="RAM" value={isEditing ? selectedLaptop.ram : newLaptop.ram} onChange={handleInputChange} />
                        <label> Storage:</label><input name="storage" placeholder="storage" value={isEditing ? selectedLaptop.storage : newLaptop.storage} onChange={handleInputChange} />
                        <label> Device ID:</label><input name="device_id" placeholder="Device id" value={isEditing ? selectedLaptop.device_id : newLaptop.device_id} onChange={handleInputChange} />
                        <label> Product ID:</label><input name="product_id" placeholder="Product id" value={isEditing ? selectedLaptop.product_id : newLaptop.product_id} onChange={handleInputChange} />
                        <label> Bitloker:</label><input name="bitloker" placeholder="bitloker" value={isEditing ? selectedLaptop.bitloker : newLaptop.bitloker} onChange={handleInputChange} />
                        <label> Microsoft Account:</label><input name="microsoft_account" placeholder="microsoft_account" value={isEditing ? selectedLaptop.microsoft_account : newLaptop.microsoft_account} onChange={handleInputChange} />
                        <label> Microsoft Password:</label><input type={showPasswords ? "text" : "password"} name="microsoft_acount_password" placeholder="microsoft_acount_password" value={isEditing ? selectedLaptop.microsoft_acount_password : newLaptop.microsoft_acount_password} onChange={handleInputChange} />
                        <label> Apple ID:</label><input name="apple_id" placeholder="apple_id" value={isEditing ? selectedLaptop.apple_id : newLaptop.apple_id} onChange={handleInputChange} />
                        <label> Apple Password:</label><input type={showPasswords ? "text" : "password"} name="apple_password" placeholder="apple_password" value={isEditing ? selectedLaptop.apple_password : newLaptop.apple_password} onChange={handleInputChange} />

                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedLaptop.assignment_date : newLaptop.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedLaptop.warranty_date : newLaptop.warranty_date}
                            onChange={handleInputChange}


                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedLaptop.returnable_date : newLaptop.returnable_date}
                            onChange={handleInputChange}

                        />
                        <label>Available:</label>
                        <select
                            name="available"
                            placeholder="available"
                            value={
                                isEditing
                                    ? (selectedLaptop?.available?.toString() || 'true') // Convertir booleano a string y manejar valores predeterminados
                                    : (newLaptop?.available?.toString() || 'true')
                            }
                            onChange={handleInputChange}
                        >
                            <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Status:</label>
                        <select name="status" placeholder="status"
                            value={
                                isEditing
                                    ? (selectedLaptop?.status?.toString() || 'inv') // Convertir booleano a string y manejar valores predeterminados
                                    : (newLaptop?.status?.toString() || 'inv')
                            }
                            onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>

                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="userId"
                            value={isEditing ? selectedLaptop.userId : newLaptop.userId}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled>
                                Select an employee
                            </option>

                            <option value="n/a">N/A</option>
                            {navigatorOptions.length > 0 ? (
                                navigatorOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>No employees available</option>
                            )}

                        </select>


                        <label>Description:</label><input name="Description" placeholder="Description" value={isEditing ? selectedLaptop.Description : newLaptop.Description} onChange={handleInputChange} />
                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedLaptop.location : newLaptop.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedLaptop.team : newLaptop.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display status error message */}
                        {ramError && <p className="error">{ramError}</p>} {/* Display RAM error message */}
                        {storageError && <p className="error">{storageError}</p>} {/* Display Storage error message */}
                        {AppleIDError && <p className="error">{AppleIDError}</p>} {/* Display Apple ID error message */}

                        <div className="button-group">
                            <button className="overlay-add-laptop" onClick={isEditing ? handleUpdateLaptop : handleAddLaptop} >
                                {isEditing ? 'Update Laptop' : 'Add Laptop'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-laptop"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this laptop?')) {
                                            handleDeleteLaptop();
                                        }
                                    }}
                                >
                                    Delete Laptop
                                </button>
                            )}
                            <button className="overlay-add-laptop" onClick={togglePasswords}>
                                {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>


    );






};

export default Laptop;
