import React, { useEffect, useState, useRef } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import './Reports.css';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import logo from '../../../assets/images/hc_login_logo.png';
import { FaSave, FaFileAlt } from 'react-icons/fa';
import LoadingSpinner from '../../../components/layout/LoadingSpinner';
import { createReportFix, CREATE_CLIENTFIX2, UPDATE_CLIENTFIX2 } from '../../../graphql/mutations'; // Updated to use CREATE_CLIENTFIX2

// -- Import the queries for listing clients, listing navigators, etc.
import {
  listUsersByTeamOne,
  clientsByName
} from '../../../graphql/queries';

import useFetchUserByEmail from '../../../components/UserInfoLoader/LoadUserInfo';
import { useNavigate } from 'react-router-dom';

// -- Import your sections
import Section2 from '../Sections/Section2';
import Section3 from '../Sections/Section3';
import Section4 from '../Sections/Section4';
import Section5 from '../Sections/Section5';
import Section6 from '../Sections/Section6';
import Section7 from '../Sections/Section7';
import Section8 from '../Sections/Section8';
import Section9 from '../Sections/Section9';
import Section10 from '../Sections/Section10';
import Section11 from '../Sections/Section11';
import Section27 from '../Sections/Section27';
import Section28 from '../Sections/Section28';
import Section29 from '../Sections/Section29';
import Section30 from '../Sections/Section30';
import Section31 from '../Sections/Section31';
import Section32 from '../Sections/Section32';
import Section33 from '../Sections/Section33';
import Section34 from '../Sections/Section34';
import Section35 from '../Sections/Section35';
import Section36 from '../Sections/Section36';
import Section37 from '../Sections/Section37';
import Section38 from '../Sections/Section38';
import Section39 from '../Sections/Section39';
import Section55 from '../Sections/Section55';
import Section56 from '../Sections/Section56';
import { capitalize } from '../../../components/common/capitalize';

const InitializeFormData = () => {
  const currentDate = new Date();
  currentDate.setUTCHours(currentDate.getUTCHours() - 6); // Example offset for CST
  const formattedDate = currentDate.toISOString().split('T')[0];

  let initialData = {
    Timestamp: new Date().toISOString(),
    Username: '',
    Date: formattedDate,
    Navigator: '',
    userId: '',           // We will set this once we know userLoggedIn.id
    outreachEvent: '',
    flyers: '',
    year_contract: 'Contract 2 Year 1',
    Number_FollowUp_Attempt_Contact_NoResponse: '',
    MACtouches: '',
    name: '',
    lastName: '',
    phone: '',
    address: '',
    city: '',
    county: '',
    email: '',
    prefered_lang: [],
    otherLanguage: '',
    commitCard: '',
    commitcardNo: '',
    signature: '',
    bestTimeToCall: '',
    CommitCardNotes: '',
    no_show: '',
    ConsumerAuthOnFile: '',
    employerCoverage: '',
    cost_to_employee: '',
    familyCovered: '',
    amountFamCoverage: '',
    NumPeopleHousehold: '',
    NumPeopleApplying: '',
    NumAdults: '',
    NumChildren: '',
    EmployeeTool: '',
    IncomeType: '',
    IncomeAmount: '',
    PayFrequency: '',
    coverageAppliedFor: '',
    OtherCoverage: '',
    New_Re_Enrollment: '',
    clientEnroll: '',
    QHP_Amount: '',
    plans: [],
    ClientCompleteMMC: '',
    AppealQuestion: '',
    RescheduleQuestion: '',
    reschedule_reason: [],
    otherRescheduleChange: '',
    one2oneInquiry: '',
    HealthInsuranceLiteracy: '',
    Locating_Providers: '',
    Billing_Payment_Questions: '',
    EvaluateHealtcareOptionsUsingTools: '',
    AccessingPreventativeHealthServices: '',
    HealtcareGovAcc: '',
    createdAccounts: '',
    troubleshootedAccounts: '',
    MarketplaceEligibility: '',
    MarketPlanCompare: '',
    qhp_enrollment_hcgov: '',
    elctronic_qhp_enrollment_marketplaceCallCenter: '',
    written_qhp_enrollment_hcgov: '',
    medicaid_chip_app_ref: '',
    complex_case_help_center: '',
    data_matchingIssues: '',
    data_matchingIssues_other: '',
    SEP_eligibility: '',
    SEP_eligibility_other: '',
    Employer_sponsored_coverage_issues: '',
    Employer_sponsored_coverage_issues_other: '',
    APTC_CSR_assistance: '',
    APTC_CSR_assistance_other: '',
    Complex_Cases_Other: '',
    Answering_questions_about: [],
    Answering_questions_about_other: '',
    Marketplace_tax_forms: '',
    Filing_Marketplace: '',
    Submitting_Marketplace: '',
    Assisted_with_other_consumer_information: '',
    pertinent_info_tracking: '',
    hear_from_us: '',
    event_hear_abt_us: '',
    facebook_hear_abt_us: '',
    insta_hear_abt_us: '',
    SAC_hear_abt_us: '',
    twitter_hear_abt_us: '',
    tv_hear_abt_us: '',
    hospital_hear_abt_us: '',
    library_opt: '',
    workforce_hear_abt_us: '',
    tablepresentation_hear_abt_us: '',
    referral_hear_abt_us: '',
    newspaper_hear_abt_us: '',
    radio_hear_abt_us: '',
    flyer_hear_abt_us: '',
    county2: '',
    other_county: '',
    zip_code: '',
    finalNotes: ''
  };

  // Pre-fill plan info slots 0..14
  for (let i = 0; i < 15; i++) {
    initialData[`plan_ClientName_${i}`] = '';
    initialData[`plan_ClientLastName_${i}`] = '';
    initialData[`Insurer_${i}`] = '';
    initialData[`PlanMetal_${i}`] = '';
    initialData[`PriceofPlan_${i}`] = '';
    initialData[`APCT_Subsidy_${i}`] = '';
    initialData[`DentalConfirm${i}`] = '';
    initialData[`Dentalplan_ClientName_${i}`] = '';
    initialData[`Dentalplan_ClientLastName_${i}`] = '';
    initialData[`Dentalplan_Name_${i}`] = '';
    initialData[`Dentalplan_Cost_${i}`] = '';
  }
  return initialData;
};



const Reports = () => {
  const client = useApolloClient();
  const navigate = useNavigate();

  // 1) Load formData from localStorage or initialize
  const initialFormData = () => {
    const savedData = localStorage.getItem('formData');
    return savedData ? JSON.parse(savedData) : InitializeFormData();
  };

  const [formData, setFormData] = useState(initialFormData());
  const [clientSearchTerm, setClientSearchTerm] = useState(formData.name || '');
  const [currentSection, setCurrentSection] = useState(1);
  const [history, setHistory] = useState([1]);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const [matchingClients, setMatchingClients] = useState([]);
  const [isFetchingClients, setIsFetchingClients] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedClient, setSelectedClient] = useState(null);

  // For user info
  const [userEmail, setUserEmail] = useState(null);
  const { userInfo: userLoggedIn, loading: userLoading, error: userError } = useFetchUserByEmail(userEmail);

  // For user's group
  const [userGroup, setUserGroup] = useState(null);

  // 2) On mount, fetch session for groups, set userEmail
  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const session = await fetchAuthSession();
        const groups = session.tokens.accessToken.payload['cognito:groups'] || [];
        setUserGroup(groups);
        // Also fetch username
        const { signInDetails } = await getCurrentUser();
        setUserEmail(signInDetails.loginId);
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };
    fetchUserGroup();
  }, []);

  // 3) Once userLoggedIn is loaded, set userId into formData
  useEffect(() => {
    if (userLoggedIn && userLoggedIn.id) {
      setFormData((prevData) => ({
        ...prevData,
        userId: userLoggedIn.id,      // Attach userId to the form
        Navigator: userLoggedIn.id,
        Username: userLoggedIn.thc_email_id,
        // If you want Navigator to be the user's ID
      }));
    }
  }, [userLoggedIn]);

  // 4) Query for navigators (used in section 1)
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne, {
    variables: {
      filter: {
        navigator_id: { ne: '' }
      },
      limit: 100
    }
  });

  // 5) Build navigator dropdown
  const navigatorOptions = usersData?.listUserFixes?.items?.map((u) => ({
    id: u.id,
    name: `${capitalize(u.first_name)} ${capitalize(u.last_name)}`
  })) || [];

  // 6) Create or update a client
  const [createClientFix, { loading: loadingClient }] = useMutation(CREATE_CLIENTFIX2);
  const [updateClientFix, { loading: updatingClient }] = useMutation(UPDATE_CLIENTFIX2);

  // 7) Create the new ReportFix
  const [createReportFixMutation, { loading: loadingReport }] = useMutation(createReportFix);

  // 8) Searching for clients
  const fetchClientsOnSearch = async (searchTerm) => {
    setIsFetchingClients(true);
    try {
      const { data } = await client.query({
        query: clientsByName,
        variables: {
          name: searchTerm,
          limit: 100
        }
      });
      const clients = data.clientsByName.items;
      setMatchingClients(clients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setIsFetchingClients(false);
    }
  };

  const handleClientSearchChange = (e) => {
    const displayedTerm = e.target.value;
    const searchTerm = displayedTerm.toLowerCase();
    setClientSearchTerm(displayedTerm);

    if (!searchTerm) {
      setMatchingClients([]);
      setIsDropdownVisible(false);
    } else {
      setIsDropdownVisible(true);
      fetchClientsOnSearch(searchTerm);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClientSelect = (client) => {
    setSelectedClient(client);
    setFormData((prev) => ({
      ...prev,
      name: client.name,
      lastName: client.last_name,
      phone: client.phone,
      address: client.address,
      city: client.city,
      county: client.county,
      email: client.email,
      prefered_lang: client.prefered_lang || [],
      otherLanguage: client.otherLanguage || '',
      zip_code: client.zip_code
    }));
    setClientSearchTerm(client.name);
    setMatchingClients([]);
  };

  // 9) Load from localStorage on mount
  useEffect(() => {
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // 10) Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const numericValue = value.replace(/[^0-9]/g, '');

    if (name === 'QHP_Amount') {
      const amount = value === '' ? 0 : parseInt(value, 10);
      setFormData((prev) => ({
        ...prev,
        QHP_Amount: value,
        plans: amount > 0 ? new Array(amount).fill(null) : []
      }));
      return;
    }

    // If checkbox for array fields
    if (type === 'checkbox') {
      if (name === 'prefered_lang') {
        let updated = [...formData.prefered_lang];
        if (checked) {
          updated.push(value);
        } else {
          updated = updated.filter((lang) => lang !== value);
        }
        setFormData({ ...formData, prefered_lang: updated });
      } else if (name === 'Answering_questions_about') {
        let updated = [...formData.Answering_questions_about];
        if (checked) {
          updated.push(value);
        } else {
          updated = updated.filter((v) => v !== value);
        }
        setFormData({ ...formData, Answering_questions_about: updated });
      } else if (name === 'reschedule_reason') {
        let updated = [...formData.reschedule_reason];
        if (checked) {
          updated.push(value);
        } else {
          updated = updated.filter((reason) => reason !== value);
        }
        setFormData({ ...formData, reschedule_reason: updated });
      }
      return;
    }

    // For county + other_county
    if (name === 'county') {
      setFormData({ ...formData, county: value, other_county: '' });
      return;
    }
    if (name === 'other_county') {
      setFormData({ ...formData, other_county: value.toLowerCase() });
      return;
    }

    // Numeric constraints for phone or zip
    if (name === 'phone' && numericValue.length > 10) return;
    if (name === 'zip_code' && numericValue.length > 5) return;

    let updatedValue = value;
    if (name === 'phone') {
      updatedValue = numericValue;
    }
    if (name === 'name' || name === 'lastName') {
      updatedValue = updatedValue.trim();
    }

    setFormData({ ...formData, [name]: updatedValue });
  };

  // 11) Navigation
  const skippedSections = [
    7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
    18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
    28, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55
  ];

  const handleNext = () => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }

    let next = currentSection;
    if (currentSection === 1) {
      next = formData.outreachEvent === 'yes' ? 2 : 3;
    } else if (currentSection === 2) {
      next = 29;
    } else if (currentSection === 3) {
      next = formData.commitCard === 'yes' ? 4 : 5;
    } else if (currentSection === 4) {
      next = 56;
    } else if (currentSection === 5) {
      next = formData.no_show === 'yes' ? 56 : 6;
    } else if (currentSection === 6) {
      next = formData.ConsumerAuthOnFile === 'yes' ? 7 : 56;
    } else {
      next++;
    }
    while (skippedSections.includes(next)) {
      next++;
    }
    setHistory((prev) => [...prev, next]);
    setCurrentSection(next);
  };

  const handleBack = () => {
    if (history.length > 1) {
      const prevHistory = history.slice(0, -1);
      setHistory(prevHistory);
      setCurrentSection(prevHistory[prevHistory.length - 1]);
    }
  };

  // 12) Save/Release
  const handleSaveProgress = () => {
    localStorage.setItem('formData', JSON.stringify(formData));
    setClientSearchTerm(formData.name);
    alert('Your progress has been saved.');
  };

  const handleRelease = () => {
    localStorage.removeItem('formData');
    setFormData(InitializeFormData());
    setClientSearchTerm('');
    setSelectedClient(null);
    alert('The form has been cleared. Starting a new report.');
    setCurrentSection(1);
    setHistory([1]);
    // Immediately override with the logged-in user
    if (userLoggedIn && userLoggedIn.id) {
      setFormData((prevData) => ({
        ...prevData,
        userId: userLoggedIn.id,
        Navigator: userLoggedIn.id,
        Username: userLoggedIn.thc_email_id,
      }));
    }
  };

  // 13) Submit the new "ReportFix"
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);


    try {
      // If outreachEvent="yes", skip client creation & only create the report
      if (formData.outreachEvent === 'yes') {
        const updatedFormData = {
          ...formData,
          Timestamp: new Date().toISOString()
        };
        const { data } = await createReportFixMutation({
          variables: { input: updatedFormData },
        });

        // Check if the server actually returned a new record
        if (!data?.createReportFix) {
          alert('Error creating outreach report. Please try again later.');
        } else {
          alert('Report submitted successfully');
          // Or setStatusMessage('Report submitted successfully');
          handleRelease(); // Clears localStorage & form
        }
        setIsLoading(false);
        return;
      }

      // For a normal report, we also create/update client
      const updatedFormData = {
        ...formData,
        Timestamp: new Date().toISOString()
      };

      const safeName = formData.name;
      const safeLastName = formData.lastName;

      // 1) If we have a selectedClient, update it
      if (selectedClient && selectedClient.id) {
        try {
          const { data } = await updateClientFix({
            variables: {
              input: {
                id: selectedClient.id,
                name: safeName,
                last_name: safeLastName,
                email: formData.email.toLowerCase() || '',
                address: formData.address || '',
                city: formData.city.toLowerCase() || '',
                county: formData.county.toLowerCase() || '',
                zip_code: formData.zip_code || '',
                last_contacted_date: formData.Date,
                navigator: formData.Navigator || '',
                phone: formData.phone || '',
                prefered_lang: formData.prefered_lang || '',
                // Make sure we pass userId if required:
                userId: userLoggedIn?.id
              }
            }
          });
          if (!data?.updateClientFix2) {
            alert(
              'Warning: Could not update client. Proceeding with report creation anyway.'
            );
          }
        } catch (err) {
          console.error('Error updating client:', err);
          alert(
            'Warning: Could not update client. Proceeding with report creation anyway.'
          );
        }
      } else {
        // 2) Otherwise, create new client
        try {
          const { data } = await createClientFix({
            variables: {
              input: {
                name: safeName,
                last_name: safeLastName,
                email: formData.email.toLowerCase() || '',
                address: formData.address || '',
                city: formData.city.toLowerCase() || '',
                county: formData.county.toLowerCase() || '',
                zip_code: formData.zip_code || '',
                last_contacted_date: formData.Date,
                navigator: formData.Navigator || '',
                phone: formData.phone || '',
                prefered_lang: formData.prefered_lang || '',
                // pass userId here as well
                userId: userLoggedIn?.id
              }
            }
          });

          // Optional: check if data?.createClientFix is null
          if (!data?.createClientFix2) {
            alert(
              'Warning: Could not create new client. Proceeding with report creation anyway.'
            );
          }
        } catch (err) {
          console.error('Error creating client:', err);
          alert(
            'Warning: Could not create new client. Proceeding with report creation anyway.'
          );
        }
      }

      // 3) Finally create the new "ReportFix"
      try {
        const { data } = await createReportFixMutation({
          variables: { input: updatedFormData },
        });

        if (!data?.createReportFix) {
          // The mutation succeeded but returned null—something’s off at resolver level
          alert('Error: Report not created. Please try again.');
        } else {
          // The createReportFix returned an object => success
          alert('Report submitted successfully');
          // window.location.reload(); // or handleRelease()
          handleRelease();
        }
      } catch (error) {
        // This means network error or a GraphQL-level error was thrown
        if (error.networkError) {
          alert(`Network error: ${error.message}`);
        } else {
          console.error('GraphQL error submitting report:', error);
          alert(`Error submitting report: ${error.message}`);
        }
      }
    } catch (error) {
      // This catch is mainly to handle any unexpected errors in your logic
      console.error('Unexpected error in handleSubmit:', error);
      alert('An unexpected error occurred. Please try again.');
    }
    setIsLoading(false);
  };
  if (usersLoading) return <p>Loading navigators...</p>;
  if (usersError) return <p>Error loading navigators: {usersError.message}</p>;

  // 14) Render
  return (
    <div className="reports-container">
      <header className="reports-header">
        <h1>Hybrid Report</h1>
      </header>
      <div className="buttons-container-reports">
        <button
          type="button"
          onClick={handleSaveProgress}
          className="save-progress-button"
          aria-label="Save Progress"
          title="Save Progress"
        >
          <FaSave />
          <span className="save-progress-text"> Save Progress</span>
        </button>

        <button
          type="button"
          onClick={handleRelease}
          className="release-button"
          aria-label="Release"
          title="Release"
        >
          <FaFileAlt />
          <span className="release-text"> Release Progress</span>
        </button>
      </div>

      <div className="report-box">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="report-box">
        <h1>Consumer Form Flow</h1>
        {userGroup?.includes('dev') && (
          <p>{currentSection}</p>
        )}
        <p>Fill out for EVERY client appointment to include No-Shows and Reschedules.</p>
        <p>For example:</p>
        <p>11/01/2021 @ 9:00 AM Mr. Garcia, Applied but did not Enroll  (1st Form/Report)</p>
        <p>11/03/2021 @ 3:00 PM Mr. Garcia, Enrolled in plan (2nd Form/Report)</p>
        <button onClick={() => navigate('/reports-list')} className="list-reports-button">
          View Previous Reports
        </button>
      </div>

      <form ref={formRef} onSubmit={handleSubmit} className="report-form">
        {/* Loading screen */}
        {isLoading && <LoadingSpinner />} {/* Use the reusable LoadingSpinner component */}
        {currentSection === 1 && (
          <div className="form-section">
            <label className="required-asterisk">Date:</label>
            <input
              type="Date"
              name="Date"
              value={formData.Date}
              onChange={handleInputChange}
              required
            />
            <label className="required-asterisk">Navigator Name:</label>
            <select
              name="Navigator"
              value={formData.Navigator}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>Select Navigator</option>
              {navigatorOptions
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
                .map(
                  option => (
                    <option key={option.id} value={option.id}>
                      {option.name} {/* Display the name */}
                    </option>
                  ))}
            </select>

            <label>Is this an outreach/colocation/phone bank event? (one-on-one interaction report)</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="outreachEvent"
                  value="yes"
                  checked={formData.outreachEvent === 'yes'}
                  onChange={handleInputChange}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="outreachEvent"
                  value="no"
                  checked={formData.outreachEvent === 'no'}
                  onChange={handleInputChange}
                  required
                />
                No
              </label>
            </div>
            <label className="required-asterisk">Contract number and Year Number:</label>
            <select
              name="year_contract"
              value={formData.year_contract}
              onChange={handleInputChange}
              required
            >
              {/* <option value="Contract 1 Year 3">Contract 1 Year 3</option> */}
              <option value="Contract 2 Year 1">Contract 2 Year 1</option>
            </select>
          </div>
        )}
        {currentSection === 2 && <Section2 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 3 && (
          <Section3
            formData={formData}
            handleInputChange={handleInputChange}
            clientSearchTerm={clientSearchTerm}
            handleClientSearchChange={handleClientSearchChange}
            matchingClients={matchingClients}
            handleClientSelect={handleClientSelect}
            isFetchingClients={isFetchingClients}
          />
        )}
        {currentSection === 4 && <Section4 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 5 && <Section5 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 6 && <Section6 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 7 && <Section7 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 8 && <Section8 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 9 && <Section9 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 10 && <Section10 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 11 && <Section11 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 27 && <Section27 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 28 && <Section28 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 29 && <Section29 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 30 && <Section30 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 31 && <Section31 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 32 && <Section32 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 33 && <Section33 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 34 && <Section34 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 35 && <Section35 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 36 && <Section36 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 37 && <Section37 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 38 && <Section38 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 39 && <Section39 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 55 && <Section55 formData={formData} handleInputChange={handleInputChange} />}
        {currentSection === 56 && <Section56 formData={formData} handleInputChange={handleInputChange} />}

        <div className="navigation-buttons">
          {currentSection > 1 && (
            <button type="button" onClick={handleBack}>
              Back
            </button>
          )}
          {currentSection < 56 && (
            <button type="button" onClick={handleNext}>
              Next
            </button>
          )}
          {currentSection === 56 && (
            <button type="submit">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Reports;