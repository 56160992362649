/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from '@apollo/client';


export const GET_CLIENTFIX2 = gql`
  query GetClientFix2($id: ID!) {
    getClientFix2(id: $id) {
      id
      name
      last_name
      address
      city
      county
      zip_code
      prefered_lang
      email
      insurance_picked
      plan
      payment_per_month
      phone
      navigator
      callStatus
      user {
        first_name
        last_name
        thc_email_id
        location
      }
    }
  }
`;

export const listClientFix2s = gql`
  query listClientFix2s(
    $filter: ModelClientFix2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientFix2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        last_name
        email
        address
        city
        county
        zip_code
        prefered_lang
        insurance_picked
        last_contacted_date
        navigator
        payment_per_month
        phone
        plan
        callStatus
        user {
          id
          first_name
          last_name
          thc_email_id
          location
      }
      }
      nextToken
      __typename
    }
  }
`;
export const clientsByUser = gql`
  query clientsByUser(
    $userId: ID!
    $limit: Int
    $nextToken: String
  ) {
    ClientFix2ByUser(userId: $userId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        last_name
        email
        address
        city
        county
        zip_code
        prefered_lang
        insurance_picked
        last_contacted_date
        navigator
        payment_per_month
        phone
        plan
        callStatus
        user {
          first_name
          last_name
          thc_email_id
          location
        }
      }
      nextToken
      __typename
    }
  }
`;

export const clientsByName = gql`
  query clientsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFix2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        last_name
        email
        address
        city
        county
        zip_code
        prefered_lang
        insurance_picked
        last_contacted_date
        navigator
        payment_per_month
        phone
        plan
        callStatus
        createdAt
        updatedAt
        user {
          first_name
          last_name
          thc_email_id
          location
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listAppointmentFixes = gql`
  query ListAppointmentFixes(
    $filter: ModelAppointmentFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        customer
        clientEmail
        location
        date
        recurrence
        service
        navigator
        time
        duration
        notes
        ModifiedBy
        status
        reminder24Sent
        reminder30Sent
        user {
          id
          first_name
          last_name
          thc_email_id
          location
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listReportFixes = gql`
  query ListReportFixes(
    $filter: ModelReportFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
      id
      Timestamp
      Username
      Date
      Navigator
      userId
      user {
          first_name
          last_name
          thc_email_id
          location
        }
      outreachEvent
      year_contract
      flyers
      Number_FollowUp_Attempt_Contact_NoResponse
      MACtouches
      name
      lastName
      phone
      address
      city
      county
      email
      prefered_lang
      otherLanguage
      commitCard
      commitcardNo
      signature
      bestTimeToCall
      CommitCardNotes
      no_show
      ConsumerAuthOnFile
      employerCoverage
      cost_to_employee
      familyCovered
      amountFamCoverage
      NumPeopleHousehold
      NumPeopleApplying
      NumAdults
      NumChildren
      EmployeeTool
      IncomeType
      IncomeAmount
      PayFrequency
      coverageAppliedFor
      OtherCoverage
      New_Re_Enrollment
      clientEnroll
      QHP_Amount
      plans
      ClientCompleteMMC
      AppealQuestion
      RescheduleQuestion
      reschedule_reason
      otherRescheduleChange
      one2oneInquiry
      HealthInsuranceLiteracy
      Locating_Providers
      Billing_Payment_Questions
      EvaluateHealtcareOptionsUsingTools
      AccessingPreventativeHealthServices
      HealtcareGovAcc
      createdAccounts
      troubleshootedAccounts
      MarketplaceEligibility
      MarketPlanCompare
      qhp_enrollment_hcgov
      elctronic_qhp_enrollment_marketplaceCallCenter
      written_qhp_enrollment_hcgov
      medicaid_chip_app_ref
      complex_case_help_center
      data_matchingIssues
      data_matchingIssues_other
      SEP_eligibility
      SEP_eligibility_other
      Employer_sponsored_coverage_issues
      Employer_sponsored_coverage_issues_other
      APTC_CSR_assistance
      APTC_CSR_assistance_other
      Complex_Cases_Other
      Answering_questions_about
      Answering_questions_about_other
      Marketplace_tax_forms
      Filing_Marketplace
      Submitting_Marketplace
      Assisted_with_other_consumer_information
      pertinent_info_tracking
      hear_from_us
      event_hear_abt_us
      facebook_hear_abt_us
      insta_hear_abt_us
      SAC_hear_abt_us
      twitter_hear_abt_us
      tv_hear_abt_us
      hospital_hear_abt_us
      library_opt
      workforce_hear_abt_us
      tablepresentation_hear_abt_us
      referral_hear_abt_us
      newspaper_hear_abt_us
      radio_hear_abt_us
      flyer_hear_abt_us
      county2
      other_county
      zip_code
      finalNotes
      plan_ClientName_0
      plan_ClientLastName_0
      Insurer_0
      PlanMetal_0
      PriceofPlan_0
      APCT_Subsidy_0
      DentalConfirm0
      Dentalplan_ClientName_0
      Dentalplan_ClientLastName_0
      Dentalplan_Name_0
      Dentalplan_Cost_0
      plan_ClientName_1
      plan_ClientLastName_1
      Insurer_1
      PlanMetal_1
      PriceofPlan_1
      APCT_Subsidy_1
      DentalConfirm1
      Dentalplan_ClientName_1
      Dentalplan_ClientLastName_1
      Dentalplan_Name_1
      Dentalplan_Cost_1
      plan_ClientName_2
      plan_ClientLastName_2
      Insurer_2
      PlanMetal_2
      PriceofPlan_2
      APCT_Subsidy_2
      DentalConfirm2
      Dentalplan_ClientName_2
      Dentalplan_ClientLastName_2
      Dentalplan_Name_2
      Dentalplan_Cost_2
      plan_ClientName_3
      plan_ClientLastName_3
      Insurer_3
      PlanMetal_3
      PriceofPlan_3
      APCT_Subsidy_3
      DentalConfirm3
      Dentalplan_ClientName_3
      Dentalplan_ClientLastName_3
      Dentalplan_Name_3
      Dentalplan_Cost_3
      plan_ClientName_4
      plan_ClientLastName_4
      Insurer_4
      PlanMetal_4
      PriceofPlan_4
      APCT_Subsidy_4
      DentalConfirm4
      Dentalplan_ClientName_4
      Dentalplan_ClientLastName_4
      Dentalplan_Name_4
      Dentalplan_Cost_4
      plan_ClientName_5
      plan_ClientLastName_5
      Insurer_5
      PlanMetal_5
      PriceofPlan_5
      APCT_Subsidy_5
      DentalConfirm5
      Dentalplan_ClientName_5
      Dentalplan_ClientLastName_5
      Dentalplan_Name_5
      Dentalplan_Cost_5
      plan_ClientName_6
      plan_ClientLastName_6
      Insurer_6
      PlanMetal_6
      PriceofPlan_6
      APCT_Subsidy_6
      DentalConfirm6
      Dentalplan_ClientName_6
      Dentalplan_ClientLastName_6
      Dentalplan_Name_6
      Dentalplan_Cost_6
      plan_ClientName_7
      plan_ClientLastName_7
      Insurer_7
      PlanMetal_7
      PriceofPlan_7
      APCT_Subsidy_7
      DentalConfirm7
      Dentalplan_ClientName_7
      Dentalplan_ClientLastName_7
      Dentalplan_Name_7
      Dentalplan_Cost_7
      plan_ClientName_8
      plan_ClientLastName_8
      Insurer_8
      PlanMetal_8
      PriceofPlan_8
      APCT_Subsidy_8
      DentalConfirm8
      Dentalplan_ClientName_8
      Dentalplan_ClientLastName_8
      Dentalplan_Name_8
      Dentalplan_Cost_8
      plan_ClientName_9
      plan_ClientLastName_9
      Insurer_9
      PlanMetal_9
      PriceofPlan_9
      APCT_Subsidy_9
      DentalConfirm9
      Dentalplan_ClientName_9
      Dentalplan_ClientLastName_9
      Dentalplan_Name_9
      Dentalplan_Cost_9
      plan_ClientName_10
      plan_ClientLastName_10
      Insurer_10
      PlanMetal_10
      PriceofPlan_10
      APCT_Subsidy_10
      DentalConfirm10
      Dentalplan_ClientName_10
      Dentalplan_ClientLastName_10
      Dentalplan_Name_10
      Dentalplan_Cost_10
      plan_ClientName_11
      plan_ClientLastName_11
      Insurer_11
      PlanMetal_11
      PriceofPlan_11
      APCT_Subsidy_11
      DentalConfirm11
      Dentalplan_ClientName_11
      Dentalplan_ClientLastName_11
      Dentalplan_Name_11
      Dentalplan_Cost_11
      plan_ClientName_12
      plan_ClientLastName_12
      Insurer_12
      PlanMetal_12
      PriceofPlan_12
      APCT_Subsidy_12
      DentalConfirm12
      Dentalplan_ClientName_12
      Dentalplan_ClientLastName_12
      Dentalplan_Name_12
      Dentalplan_Cost_12
      plan_ClientName_13
      plan_ClientLastName_13
      Insurer_13
      PlanMetal_13
      PriceofPlan_13
      APCT_Subsidy_13
      DentalConfirm13
      Dentalplan_ClientName_13
      Dentalplan_ClientLastName_13
      Dentalplan_Name_13
      Dentalplan_Cost_13
      plan_ClientName_14
      plan_ClientLastName_14
      Insurer_14
      PlanMetal_14
      PriceofPlan_14
      APCT_Subsidy_14
      DentalConfirm14
      Dentalplan_ClientName_14
      Dentalplan_ClientLastName_14
      Dentalplan_Name_14
      Dentalplan_Cost_14
      createdAt
      }
      nextToken
    }
  }
`;

export const DashboardListReports = gql`
  query ListReportFixes($limit: Int, $nextToken: String) {
    listReportFixes(limit: $limit, nextToken: $nextToken) {
      items {
        id
        Username
        user {
          id
          first_name
          last_name
          thc_email_id
          location
        }
        Date
        Navigator
        clientEnroll
        one2oneInquiry
        HealthInsuranceLiteracy
        Locating_Providers
        Billing_Payment_Questions
        EvaluateHealtcareOptionsUsingTools
        AccessingPreventativeHealthServices
        MarketplaceEligibility
        MarketPlanCompare
        qhp_enrollment_hcgov
        elctronic_qhp_enrollment_marketplaceCallCenter
        written_qhp_enrollment_hcgov
        medicaid_chip_app_ref
        complex_case_help_center
        data_matchingIssues
        SEP_eligibility
        Employer_sponsored_coverage_issues
        APTC_CSR_assistance
        Complex_Cases_Other
        Marketplace_tax_forms
        Filing_Marketplace
        Submitting_Marketplace
        Username
        NumAdults
        NumChildren
        county
        outreachEvent
      }
      nextToken
    }
  }
`;

export const GET_REPORTS_BY_DATE = gql`
  query GetReportsByDate($startDate: String!, $endDate: String!, $nextToken: String) {
  listReportFixes(
    filter: {
      Date: {
        between: [$startDate, $endDate]
      }
    }
    limit: 1000
    nextToken: $nextToken
  ) {
      items {
      id
        Navigator
        Date
        one2oneInquiry
        HealthInsuranceLiteracy
        Locating_Providers
        Billing_Payment_Questions
        EvaluateHealtcareOptionsUsingTools
        AccessingPreventativeHealthServices
        HealtcareGovAcc
        MarketplaceEligibility
        MarketPlanCompare
        qhp_enrollment_hcgov
        elctronic_qhp_enrollment_marketplaceCallCenter
        written_qhp_enrollment_hcgov
        medicaid_chip_app_ref
        complex_case_help_center
        data_matchingIssues
        SEP_eligibility
        Employer_sponsored_coverage_issues
        APTC_CSR_assistance
        Complex_Cases_Other
        Answering_questions_about
        Marketplace_tax_forms
        Filing_Marketplace
        Submitting_Marketplace
        Assisted_with_other_consumer_information
        userId
        user{
        id
        first_name
        last_name
        }
        createdAt
      }
        nextToken
    }
  }
`;
export const GET_REPORTS_BY_SPECIFIC_DATE = gql`
  query GetReportsBySpecificDate($date: String!, $nextToken: String) {
    listReportFixes(
      filter: {
        Date: {
          eq: $date
        }
      }
      limit: 1000
      nextToken: $nextToken
    ) {
      items  {
      id
        Navigator
        userId
        user{
          id
          first_name
          last_name
        }
        Date
        one2oneInquiry
        HealthInsuranceLiteracy
        Locating_Providers
        Billing_Payment_Questions
        EvaluateHealtcareOptionsUsingTools
        AccessingPreventativeHealthServices
        HealtcareGovAcc
        MarketplaceEligibility
        MarketPlanCompare
        qhp_enrollment_hcgov
        elctronic_qhp_enrollment_marketplaceCallCenter
        written_qhp_enrollment_hcgov
        medicaid_chip_app_ref
        complex_case_help_center
        data_matchingIssues
        SEP_eligibility
        Employer_sponsored_coverage_issues
        APTC_CSR_assistance
        Complex_Cases_Other
        Answering_questions_about
        Marketplace_tax_forms
        Filing_Marketplace
        Submitting_Marketplace
        Assisted_with_other_consumer_information
        createdAt
      }
      nextToken
    }
  }
`;

export const listMouseFixes = gql`
  query listMouseFixes(
    $filter: ModelMouseFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMouseFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tagId
        model
        brand
        serial_Number
        wireless
        usb
        combo
        price
        store
        purchase_date
        assignment_date
        warranty_date
        returnable_date
        available
        status
        assigned_to
        location
        team
        userId
        user {
          id
          first_name
          last_name
        }
      }
      nextToken
      __typename
    }
  }
`;

export const GET_MOUSE_BY_DATE_RANGE = gql`
query GetMiceByDateRange($filter: ModelMouseFixFilterInput, $nextToken: String) {
  listMouseFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
      id
      tagId
      model
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
         userId
        user {
          id
          first_name
          last_name
        }
    }
   nextToken
  
  }
}

`;

export const getUserByID = gql`
  query getUserByID($id: ID!) {
    getUser(id: $id) {
      id
      ThreeCX_id
      first_name
      last_name
      thc_email_id
      gmail_email_id
      apple_id
      phone_number
      navigator_id
      createdAt
      updatedAt
      team
      location
      notes
    }
  }
`;

export const listUsers = gql`
  query listUserFixes (
    $filter: ModelUserFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       createdAt
       updatedAt
       team
       location
       notes
      }
      nextToken
      __typename
    }
  }
`;

export const listUsersByTeamOne = gql`
  query listUsersByTeamOne(
    $limit: Int
    $nextToken: String
  ) {
    listUserFixes(
      filter: { team: { eq: "1" } } # Filter to get users with team == 1
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ThreeCX_id
        first_name
        last_name
        thc_email_id
        gmail_email_id
        apple_id
        phone_number
        navigator_id
        createdAt
        updatedAt
        team
        location
      }
      nextToken
      __typename
    }
  }
`;

export const listKeyboardFixes = gql`
  query ListKeyboardFixes(
    $filter: ModelKeyboardFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyboardFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tagid
        model
        brand
        serial_Number
        wireless
        usb
        combo
        price
        store
        purchase_date
        assignment_date
        warranty_date
        returnable_date
        available
        status
        assigned_to
        location
        team
        userId
        user {
          id
          first_name
          last_name
        }
      }
      nextToken
      __typename
    }
  }
`;

export const GET_KEYBOARDS_BY_DATE_RANGE = gql`
 query GetKeyboardByDateRange($filter: ModelKeyboardFixFilterInput, $nextToken: String) {
  listKeyboardFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
         id
        tagid
        model 
        brand
        serial_Number
        wireless
        usb
        combo
        price
        store
        purchase_date
        assignment_date
        warranty_date
        returnable_date
        available
        status
        assigned_to
        location
        team
        userId
        user {
          id
          first_name
          last_name
        }
    }
    nextToken
   }
  }
`;

export const listMonitorFixes = gql`
  query listMonitorFixes (
    $filter: ModelMonitorFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonitorFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
       id
       tagid
       model
       brand
       inches
       serial_Number
       price
       store
       purchase_date
       charger_cable
       monitor_type
       Connector_cable
       assignment_date
       warranty_date
       returnable_date
       available
       status
       assigned_to
       location
       team
       Description
        userId
        user {
          id
          first_name
          last_name
        }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_MONITORS_BY_DATE_RANGE = gql`
 query GetKeyboardByDateRange($filter: ModelMonitorFixFilterInput, $nextToken: String) {
 listMonitorFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
       id
       tagid
       model
       brand
       inches
       serial_Number
       price
       store
       purchase_date
       charger_cable
       monitor_type
       Connector_cable
       assignment_date
       warranty_date
       returnable_date
       available
       status
       assigned_to
       location
       Description
       team
         userId
        user {
          id
          first_name
          last_name
        }
    }
    nextToken
   }
  }
`;

export const listScannerFixes = gql`
  query listScannerFixes (
    $filter: ModelScannerFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScannerFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  model_number
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  scanner_type
  Connector_cable
  wireless
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  Description
     userId
        user {
          id
          first_name
          last_name
        }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_SCANNER_BY_DATE_RANGE = gql`
query GetScannerByDateRange($filter: ModelScannerFixFilterInput, $nextToken: String) {
  listScannerFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
    id
  tagid
  model
  model_number
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  scanner_type
  Connector_cable
  wireless
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  Description
  userId
        user {
          id
          first_name
          last_name
        }
    }
   nextToken
  
  }
}

`;


export const listPrinterFixes = gql`
  query listPrinterFixes (
    $filter: ModelPrinterFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrinterFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
       tagid
       model
      brand
      serial_Number
      price
      store
      purchase_date
      charger_cable
      Connector_cable
      wireless
      black_ink
     color_ink
     black_refill_dates
     color_refill_dates
      assignment_date
      warranty_date
       returnable_date
      available
      status
      assigned_to
      location
      team
      Description
       userId
        user {
          id
          first_name
          last_name
        }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_PRINTER_BY_DATE_RANGE = gql`
query GetPrinterByDateRange($filter: ModelPrinterFixFilterInput, $nextToken: String) {
  listPrinterFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
            id
       tagid
       model
      brand
      serial_Number
      price
      store
      purchase_date
      charger_cable
      Connector_cable
      wireless
      black_ink
      color_ink
      black_refill_dates
      color_refill_dates
      assignment_date
      warranty_date
       returnable_date
      available
      status
      assigned_to
      location
      team
      Description
      userId
      user {
          id
          first_name
          last_name
          }
      
      }

    
   nextToken
  
  }
}

`;

export const listExtensionCordFixes = gql`
  query listExtensionCordFixes (
    $filter: ModelExtensionCordFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtensionCordFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
     id
    tagid
    model
    brand
    serial_Number
    price
    store
    purchase_date
    assignment_date
    warranty_date
    returnable_date
    available
    status
    assigned_to
    location
    team 
      userId
        user {
          id
          first_name
          last_name
            }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_EXTENSION_BY_DATE_RANGE = gql`
query GetExtensionByDateRange($filter: ModelExtensionCordFixFilterInput, $nextToken: String) {
  listExtensionCordFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
    id
    tagid
    model
    brand
    serial_Number
    price
    store
    purchase_date
    assignment_date
    warranty_date
    returnable_date
    available
    status
    assigned_to
    location
    team
      userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;

export const listPhoneFixes = gql`
  query listPhoneFixes (
    $filter: ModelPhoneFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhoneFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
   id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  userId
        user {
          id
          first_name
          last_name
            }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_PHONE_BY_DATE_RANGE = gql`
query GetPhoneByDateRange($filter: ModelPhoneFixFilterInput, $nextToken: String) {
  listPhoneFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
    id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;

export const listBackupBatteryFixes = gql`
  query listBackupBatteryFixes (
    $filter: ModelBackupBatteryFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBackupBatteryFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    userId
        user {
          id
          first_name
          last_name
            }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_BACKUP_BY_DATE_RANGE = gql`
query GetBackupByDateRange($filter: ModelBackupBatteryFixFilterInput, $nextToken: String) {
  listBackupBatteryFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
      id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
     userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;

export const listPowerBankFixes = gql`
  query listPowerBankFixes (
    $filter: ModelPowerBankFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPowerBankFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
  user {
          id
          first_name
          last_name
            }
    }
      nextToken
      __typename
    }
  }
`;

export const GET_POWERBANK_BY_DATE_RANGE = gql`
query GetPowerBankByDateRange($filter: ModelPowerBankFixFilterInput, $nextToken: String) {
  listPowerBankFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
    id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    userId
  user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;

export const listGeneralInventoryFixes = gql`
  query listGeneralInventoryFixes (
    $filter: ModelGeneralInventoryFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeneralInventoryFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team
  userId
  user {
          id
          first_name
          last_name
            }

      }
      nextToken
      __typename
    }
  }
`;
export const GET_GeneralInventory__BY_DATE_RANGE = gql`
query GetGeneralInventorByDateRange($filter: ModelGeneralInventoryFixFilterInput, $nextToken: String) {
  listGeneralInventoryFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team
  user {
          id
          first_name
          last_name
       }
    }
   nextToken
  
  }
}

`;

export const listUSBFixes = gql`
  query listUSBFixes (
    $filter: ModelUSBFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUSBFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
        user {
          id
          first_name
          last_name
            }
     
      }
      nextToken
      __typename
    }
  }
`;

export const GET_USB_BY_DATE_RANGE = gql`
query GetUSBByDateRange($filter: ModelUSBFixFilterInput, $nextToken: String) {
  listUSBFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;

export const listDockingStationFixes = gql`
  query listDockingStationFixes (
    $filter: ModelDockingStationFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDockingStationFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
    userId
        user {
          id
          first_name
          last_name
            }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_DOCKINGSTATION_BY_DATE_RANGE = gql`
query GetDockingStationByDateRange($filter: ModelDockingStationFixFilterInput, $nextToken: String) {
  listDockingStationFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
   userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;

export const listSpeakerFixes = gql`
  query listSpeakerFixes (
    $filter: ModelSpeakerFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeakerFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
   userId
        user {
          id
          first_name
          last_name
            }
 
      }
      nextToken
      __typename
    }
  }
`;
export const GET_SPEAKERS_BY_DATE_RANGE = gql`
query GetSpeakerByDateRange($filter: ModelSpeakerFixFilterInput, $nextToken: String) {
  listSpeakerFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
    userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;

export const listLaptopFixes = gql`
  query listLaptopFixes (
    $filter: ModelLaptopFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLaptopFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password
  userId
  user {
        id
        first_name
        last_name
        }

  }
      nextToken
      __typename
    }
  }
`;

export const GET_LAPTOP_BY_DATE_RANGE = gql`
query GetLaptopByDateRange($filter: ModelLaptopFixFilterInput, $nextToken: String) {
  listLaptopFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
     id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password
  userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;


export const listTabletRemarkableFixes = gql`
  query listTabletRemarkablesFixes (
    $filter: ModelTabletRemarkableFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTabletRemarkableFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
        user {
          id
          first_name
          last_name
            }
  
      }
      nextToken
      __typename
    }
  }
`;

export const GET_TABLET_BY_DATE_RANGE = gql`
query GetTabletRemarkablesByDateRange($filter: ModelTabletRemarkableFixFilterInput, $nextToken: String) {
  listTabletRemarkableFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
   id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;

export const listHeadsetFixes = gql`
  query listHeadsetFixes (
    $filter: ModelHeadsetFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeadsetFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
        user {
          id
          first_name
          last_name
            }

      }
      nextToken
      __typename
    }
  }
`;

export const GET_HEADSET_BY_DATE_RANGE = gql`
query GetHeadsetByDateRange($filter: ModelHeadsetFixFilterInput, $nextToken: String) {
  listHeadsetFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
        user {
          id
          first_name
          last_name
            }
    }
   nextToken
  
  }
}

`;
export const listProjectors = gql`
  query listProjectors (
    $filter: ModelProjectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
  
      }
      nextToken
      __typename
    }
  }
`;
export const GET_PROJECTOR_BY_DATE_RANGE = gql`
query GetProjectorByDateRange($filter: ModelProjectorFilterInput, $nextToken: String) {
  listProjectors(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
    }
   nextToken
  
  }
}

`;

export const listExternalDiskFixes = gql`
  query listExternalDiskFixes (
    $filter: ModelExternalDiskFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExternalDiskFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
  user {
        id
        first_name
        last_name
        }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_EXTERNALDISK_BY_DATE_RANGE = gql`
query GetExternalDiskByDateRange($filter: ModelExternalDiskFixFilterInput, $nextToken: String) {
  listExternalDiskFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
  user  {
        id
        first_name
        last_name
        }
    }
   nextToken
  
  }
}

`;

export const listBagFixes = gql`
  query listBagFixes (
    $filter: ModelBagFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBagFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    userId
  user {
        id
        first_name
        last_name
        }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_BAG_BY_DATE_RANGE = gql`
query GeTBagByDateRange($filter: ModelBagFixFilterInput, $nextToken: String) {
  listBagFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team
    userId
  user {
        id
        first_name
        last_name
        }
    }
   nextToken
  
  }
}

`;

export const listClickCounterFixes = gql`
  query listClickCounterFixes (
    $filter: ModelClickCounterFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClickCounterFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  price
  store
  purchase_date
  available
  assigned_to
  userId
  user {
        id
        first_name
        last_name
         }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_CLICKCOUNTER_BY_DATE_RANGE = gql`
query GetClickCounterByDateRange($filter: ModelClickCounterFixFilterInput, $nextToken: String) {
  listClickCounterFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  price
  store
  purchase_date
  available
  assigned_to
  userId
  user {
        id
        first_name
        last_name
         }
    }
   nextToken
  
  }
}

`;

export const listSuppliesFixes = gql`
  query listSuppliesFixes (
    $filter: ModelSuppliesFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliesFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  name
  tagId
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
  userId
  user {
        id
        first_name
        last_name
        }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_SUPPLIES_BY_DATE_RANGE = gql`
query GetSuppliesByDateRange($filter: ModelSuppliesFixFilterInput, $nextToken: String) {
  listSuppliesFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
  id
  name
  tagId
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
  userId
  user {
        id
        first_name
        last_name
        }
    }
   nextToken
  
  }
}

`;

export const LIST_COUNTIES = gql`
  query ListCounties {
    listCounties {
      items {
        id
        name
        cities {
          items {
            id
            name
            locations {
              items {
                id
                name
                address
                navigators {
                  items {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


// export const GET_ALL_LOCATIONS = gql`
//   query GetAllLocations {
//     listLocations {
//       items {
//         id
//         name
//         address
//         cityID
//         city {
//           name
//         }
//         outreachDate
//         pointsOfContact {
//           name
//           phone
//           email
//         }
//         operationHours {
//           day
//           startTime
//           endTime
//         }
//       }
//     }
//   }
// `;


export const GET_ALL_LOCATIONS = gql`
  query GetAllLocations {
    listLocationIndependents {
      items {
        id
        name
        address
        city
        county
        outreachDate
        navigators {
          items {  # ✅ Correct way to access related data
            id
            first_name
            last_name
          }
        }
        color
      }
    }
  }
`;



export const getDeletedUserHistory = gql`
  query GetDeletedUserHistory($id: ID!) {
    getDeletedUserHistory(id: $id) {
      id
      first_name
      last_name
    }
  }
`;

export const listDeletedUserHistories = gql`
  query listDeletedUserHistories (
    $filter: ModelDeletedUserHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeletedUserHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
         id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       cancellation_date
       notes
      }
      nextToken
      __typename
    }
  }
`;

export const listDeleteEquipmentHistoryFixes = gql`
  query listDeleteEquipmentHistoryFixes(
    $filter: ModelDeleteEquipmentHistoryFixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeleteEquipmentHistoryFixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
  id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
  userId
  user {
        id
        first_name
        last_name
        }
      }
      nextToken
      __typename
    }
  }
`;
export const GET_DeleteEquipmentHistories__BY_DATE_RANGE = gql`
query GetDeleteEquipmentHistoriesByDateRange($filter: ModelDeleteEquipmentHistoryFixFilterInput, $nextToken: String) {
  listDeleteEquipmentHistoryFixes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
   id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
  user {
        id
        first_name
        last_name
    }
    }
   nextToken
  
  }
}

`;
export const GET_PROMOTIONAL_REPORTS_BY_DATE = gql`
query GetPromotionalReportsByDate($startDate: String!, $endDate: String!, $nextToken: String) {
  listPromotionalReports(
    filter: {
      activityDateTime: {
        between: [$startDate, $endDate]
      }
    }
    limit: 1000
    nextToken: $nextToken
  ) {
    items {
      id
      activityName
      activityDateTime
      numberOfConsumersTargeted
      mediaType
      activityDescription
      UploadedFileName
      createdAt
    }
    nextToken
  }
}
`
export const GET_ALL_UNIQUE_FILENAMES = gql`
  query GetAllUniqueFileNames {
  listPromotionalReports(limit: 1000) {
    items {
        UploadedFileName
      }
      nextToken
    }
  }
`;

export const CHECK_FILE_EXISTENCE_BY_NAME = gql`
  query CheckFileExistenceByName($UploadedFileName: String!) {
    listPromotionalReports(filter: { UploadedFileName: { eq: $UploadedFileName } }) {
      items {
        id
        UploadedFileName
      }
    }
  }
`;

export const getCallList = gql`
  query GetCallList($id: ID!) {
    getCallList(id: $id) {
      id
      clientID
      navigator
      user {
        id
        first_name
        last_name
      }
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      createdAt
      updatedAt
    }
  }
`;

// Admin query: fetch all call list items
export const listAllCallLists = gql`
  query ListAllCallLists($limit: Int, $nextToken: String) {
    listCallLists(limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        client {
          name
          last_name
          phone
          email
          prefered_lang
          county
          zip_code
          navigator
        }
        navigator
        user {
        id
        first_name
        last_name
      }
        callDate
        answered
        appointmentMade
        needsFollowUp
        notes
        contactRound
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

// Navigator query: fetch call list items for a specific navigator
export const listCallListsByNavigator = gql`
  query callsByNavigator(
  $navigator: String!
  $limit: Int
  $nextToken: String
) {
  callsByNavigator(navigator: $navigator, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      client {
        id
        name
        last_name
        phone
        email
        prefered_lang
        county
        zip_code
        navigator
      }
      navigator
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      followUpScheduledDate
      lastInteractionID
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const listCallListsByUser = gql`
  query CallListByUser(
  $userId: ID!
  $limit: Int
  $nextToken: String
) {
  CallListByUser(userId: $userId, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      client {
        id
        name
        last_name
        phone
        email
        prefered_lang
        county
        zip_code
        navigator
      }
      navigator
      user {
        id
        first_name
        last_name
      }
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      followUpScheduledDate
      lastInteractionID
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;


export const getWorkSchedulesByUser = gql`
  query workSchedulesByUser($userId: ID!,$startDate: String!, $endDate: String!) {
    workSchedulesByUser(userId: $userId, filter: { date: { between: [$startDate, $endDate] } }) {
      items {
        id
        userId
        date
        startTime
        endTime
        breakStart
        breakEnd
        location
        createdAt
        updatedAt
      }
    }
  }
`;

export const getWorkSchedule = gql`
  query GetWorkSchedule($id: ID!) {
    getWorkSchedule(id: $id) {
      id
      userId
      user {
        id
        first_name
        last_name
      }
      date
      startTime
      endTime
      breakStart
      breakEnd
      location
      createdAt
      updatedAt
    }
  }
`;

export const listScheduleTemplates = gql`
  query ListScheduleTemplates {
    listScheduleTemplates {
      items {
        id
        name
        days {
          day
          shift
        }
        totalHours
        createdAt
        updatedAt
      }
    }
  }
`;

export const listOptOutClients = gql`
  query ListOptOutClients($filter: ModelOptOutClientFilterInput, $limit: Int, $nextToken: String) {
    listOptOutClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        last_name
        address
        city
        county
        zip_code
        prefered_lang
        email
        insurance_picked
        plan
        payment_per_month
        phone
        optOutReason
        optOutDate
        navigator
        callStatus
      }
      nextToken
    }
  }
`;

export const listInteractionsForClient = gql`
  query ListInteractionsForClient($clientID: ID!) {
    callsByClient(clientID: $clientID) {
      items {
        id
        callDate
        navigator
        interactions {
          items {
            id
            callDate
            answered
            appointmentMade
            notes
            contactMethod
            followUpScheduledDate
            createdAt
          }
        }
      }
    }
  }
`;

export const listOverContactedClients = gql`
  query ListOverContactedClients {
    listClientFix2(filter: { lastContactedDate: { lt: "2024-01-01" } }) {
      items {
        id
        firstName
        lastName
        lastContactedDate
      }
    }
  }
`;

export const countClientInteractions = gql`
  query CountClientInteractions($callListID: ID!) {
    interactionsByCallList(callListID: $callListID) {
      items {
        id
      }
    }
  }
`;

export const listFollowUpCalls = gql`
  query ListFollowUpCalls {
    listCallLists(filter: { needsFollowUp: { eq: true } }) {
      items {
        id
        clientID
        callDate
        followUpScheduledDate
        navigator
        notes
        createdAt
      }
    }
  }
`;

export const GET_INTERACTIONS_BY_CALL_LIST = gql`
  query GetInteractionsByCallList($callListID: ID!) {
    interactionsByCallList(callListID: $callListID) {
      items {
        id
        callDate
        time
        answered
        appointmentMade
        notes
        contactMethod
        createdBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const listTickets = gql`
  query listTickets (
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
 id
  type
  name
  location
  time
  date
  dateTimeSupervisor
  dateTimeAdmin
  supervisor_name
  admin_name
  type_issue
  program_name
  equipment_name
  equipment_tag
  equipment_brand
  equipment_model
  equipment_ram
  equipment_storage
  status
  completed_date
  request_item
  quantity
  tried_troubleshooting
  priority
  supervisor_approval
  troubleshooting_problem1
  troubleshooting_problem2
  admin_troubleshooting_problem
  troubleshooting_steps_taken1
  troubleshooting_steps_taken2
  admin_troubleshooting_steps_taken
  troubleshooting_resolution1
  troubleshooting_resolution2
  admin_troubleshooting_resolution
  date_needed
  createdAt
  purchase_link
  user {
    id
    first_name
    last_name
     }
 
      }
      nextToken
      __typename
    }
  }
`;

export const ticketsByEmployee = gql`
  query ticketsByEmployee(
    $userId: ID!
    $limit: Int
    $nextToken: String
  ) {
    ticketsByUser(userId: $userId, limit: $limit, nextToken: $nextToken) {
      items {
      id
        type
        name
        location
        time
        date
        dateTimeSupervisor
        dateTimeAdmin
        supervisor_name
        admin_name
        type_issue
        program_name
        equipment_name
        equipment_tag
        equipment_brand
        equipment_model
        equipment_ram
        equipment_storage
        status
        completed_date
        request_item
        quantity
        tried_troubleshooting
        priority
        supervisor_approval
        troubleshooting_problem1
        troubleshooting_problem2
        admin_troubleshooting_problem
        troubleshooting_steps_taken1
        troubleshooting_steps_taken2
        admin_troubleshooting_steps_taken
        troubleshooting_resolution1
        troubleshooting_resolution2
        admin_troubleshooting_resolution
        date_needed
        createdAt
        purchase_link
        user {
          id
          first_name
          last_name
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listNotifications = gql`
  query listNotifications (
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
      id
      type
      userID
      supervisorID
      adminID
      relatedId
      status
      active
      creationDate
      expirationDate
      userId
      user {
        id
          first_name
          last_name
        }
      }
      nextToken
      __typename
    }
  }
`;

export const getUserWithAssignments = gql`
query GetUserWithAssignments($id: ID!) {
getUserFix(id: $id) {
id
first_name
last_name
location
team
laptops {
items {
id
tagid
brand
model
serial_Number
ram
storage
__typename
}
nextToken
}
monitors {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
printers {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
phones {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
scanners {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
headsets {
items {
id
tagid
brand
serial_Number
__typename
}
nextToken
}
mice {
items {
id
tagId
brand
model
serial_Number
__typename
}
nextToken
}
keyboards {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
powerBanks {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
speakers {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
dockingStations {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
extensionCords {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
backuckBatteries {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
tabletsRemarkable {
items {
id
tagid
brand
model
serial_Number
ram
storage
__typename
}
nextToken
}
externalDisks {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
USBs {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
bags {
items {
id
type
brand
__typename
}
nextToken
}
generalInventory {
items {
id
tagid
brand
model
serial_Number
__typename
}
nextToken
}
}
}
`;

export const GET_DYNAMIC_FORM = gql`
  query GetDynamicForm($id: ID!) {
    getDynamicForm(id: $id) {
      id
      name
      cards {
        id
        label
       pageIndex
        fields {
          id
          label
          type
          options
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const LIST_DYNAMIC_FORMS = gql`
  query ListDynamicForms {
    listDynamicForms {
      items {
        id
        name
        cards {
          id
          label
         pageIndex
          fields {
            id
            label
            type
            options
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_DYNAMIC_FORM_FIELD = gql`
  query GetDynamicFormField($id: ID!) {
    getDynamicFormField(id: $id) {
      id
      label
      type
      options
      createdAt
      updatedAt
    }
  }
`;


export const LIST_FORM_SUBMISSIONS = gql`
  query ListFormSubmissions  {
    listFormSubmissions {
      items {
      id
      formID
      answers
      createdAt
    }
  }
}
`;