/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from '@apollo/client';


export const CREATE_CLIENTFIX2 = gql`
  mutation CreateClientFix2($input: CreateClientFix2Input!) {
    createClientFix2(input: $input) {
      id
      name
      last_name
      email
      address
      city
      county
      zip_code
      prefered_lang
      insurance_picked
      last_contacted_date
      navigator
      payment_per_month
      phone
      plan
      callStatus 
      userId
    }
  }
`;

export const UPDATE_CLIENTFIX2 = gql`
  mutation updateClientFix2($input: UpdateClientFix2Input!) {
    updateClientFix2(input: $input) {
      id
      name
      last_name
      email
      address
      city
      county
      zip_code
      prefered_lang
      insurance_picked
      last_contacted_date
      navigator
      payment_per_month
      phone
      plan
      callStatus 
      userId
    }
  }
`;


export const DELETE_CLIENTFIX2 = gql`
  mutation DeleteClientFix2($input: DeleteClientFix2Input!) {
    deleteClientFix2(input: $input) {
      id
    }
  }
`;


export const UPDATE_CALL_STATUS = gql`
  mutation UpdateCallStatus($id: ID!, $callStatus: String!) {
    updateClientFix2(input: { id: $id, callStatus: $callStatus }) {
      id
      callStatus
    }
  }
`;

export const CREATE_OPT_OUT_CLIENT = gql`
  mutation CreateOptOutClient($input: CreateOptOutClientInput!) {
    createOptOutClient(input: $input) {
      id
      name
      last_name
      address
      city
      county
      zip_code
      prefered_lang
      email
      insurance_picked
      plan
      payment_per_month
      phone
      navigator
      optOutDate
      navigator
      callStatus
    }
  }
`;

export const DELETE_OPT_OUT_CLIENT = gql`
mutation DeleteOptOutClient($input: DeleteOptOutClientInput!) {
  deleteOptOutClient(input: $input) {
    id
  }
}
`;


export const createAppointmentFix = gql`
  mutation CreateAppointmentFix($input: CreateAppointmentFixInput!) {
    createAppointmentFix(input: $input) {
      id
      title
      customer
      clientEmail
      location
      date
      recurrence
      service
      navigator
      time
      duration
      notes
      ModifiedBy
      status
      reminder24Sent
      reminder30Sent
    }
  }
`;

export const updateAppointmentFix = gql`
  mutation UpdateAppointmentFix($input: UpdateAppointmentFixInput!) {
    updateAppointmentFix(input: $input) {
      id
      title
      customer
      clientEmail
      location
      date
      recurrence
      service
      navigator
      time
      duration
      notes
      ModifiedBy
      status
      reminder24Sent
      reminder30Sent
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteAppointmentFix = gql`
  mutation DeleteAppointmentFix($input: DeleteAppointmentFixInput!) {
    deleteAppointmentFix(input: $input) {
      id
    }
  }
`;

export const createReportFix = gql`
  mutation CreateReportFix($input: CreateReportFixInput!) {
    createReportFix(input: $input) {
      id
      Timestamp
      Username
      Date
      Navigator
      userId
      outreachEvent
      year_contract
      flyers
      Number_FollowUp_Attempt_Contact_NoResponse
      MACtouches
      name
      lastName
      phone
      address
      city
      county
      email
      prefered_lang
      otherLanguage
      commitCard
      commitcardNo
      signature
      bestTimeToCall
      CommitCardNotes
      no_show
      ConsumerAuthOnFile
      employerCoverage
      cost_to_employee
      familyCovered
      amountFamCoverage
      NumPeopleHousehold
      NumPeopleApplying
      NumAdults
      NumChildren
      EmployeeTool
      IncomeType
      IncomeAmount
      PayFrequency
      coverageAppliedFor
      OtherCoverage
      New_Re_Enrollment
      clientEnroll
      QHP_Amount
      plans
      ClientCompleteMMC
      AppealQuestion
      RescheduleQuestion
      reschedule_reason
      otherRescheduleChange
      one2oneInquiry
      HealthInsuranceLiteracy
      Locating_Providers
      Billing_Payment_Questions
      EvaluateHealtcareOptionsUsingTools
      AccessingPreventativeHealthServices
      HealtcareGovAcc
      createdAccounts
      troubleshootedAccounts
      MarketplaceEligibility
      MarketPlanCompare
      qhp_enrollment_hcgov
      elctronic_qhp_enrollment_marketplaceCallCenter
      written_qhp_enrollment_hcgov
      medicaid_chip_app_ref
      complex_case_help_center
      data_matchingIssues
      data_matchingIssues_other
      SEP_eligibility
      SEP_eligibility_other
      Employer_sponsored_coverage_issues
      Employer_sponsored_coverage_issues_other
      APTC_CSR_assistance
      APTC_CSR_assistance_other
      Complex_Cases_Other
      Answering_questions_about
      Answering_questions_about_other
      Marketplace_tax_forms
      Filing_Marketplace
      Submitting_Marketplace
      Assisted_with_other_consumer_information
      pertinent_info_tracking
      hear_from_us
      event_hear_abt_us
      facebook_hear_abt_us
      insta_hear_abt_us
      SAC_hear_abt_us
      twitter_hear_abt_us
      tv_hear_abt_us
      hospital_hear_abt_us
      library_opt
      workforce_hear_abt_us
      tablepresentation_hear_abt_us
      referral_hear_abt_us
      newspaper_hear_abt_us
      radio_hear_abt_us
      flyer_hear_abt_us
      county2
      other_county
      zip_code
      finalNotes
      plan_ClientName_0
      plan_ClientLastName_0
      Insurer_0
      PlanMetal_0
      PriceofPlan_0
      APCT_Subsidy_0
      DentalConfirm0
      Dentalplan_ClientName_0
      Dentalplan_ClientLastName_0
      Dentalplan_Name_0
      Dentalplan_Cost_0
      plan_ClientName_1
      plan_ClientLastName_1
      Insurer_1
      PlanMetal_1
      PriceofPlan_1
      APCT_Subsidy_1
      DentalConfirm1
      Dentalplan_ClientName_1
      Dentalplan_ClientLastName_1
      Dentalplan_Name_1
      Dentalplan_Cost_1
      plan_ClientName_2
      plan_ClientLastName_2
      Insurer_2
      PlanMetal_2
      PriceofPlan_2
      APCT_Subsidy_2
      DentalConfirm2
      Dentalplan_ClientName_2
      Dentalplan_ClientLastName_2
      Dentalplan_Name_2
      Dentalplan_Cost_2
      plan_ClientName_3
      plan_ClientLastName_3
      Insurer_3
      PlanMetal_3
      PriceofPlan_3
      APCT_Subsidy_3
      DentalConfirm3
      Dentalplan_ClientName_3
      Dentalplan_ClientLastName_3
      Dentalplan_Name_3
      Dentalplan_Cost_3
      plan_ClientName_4
      plan_ClientLastName_4
      Insurer_4
      PlanMetal_4
      PriceofPlan_4
      APCT_Subsidy_4
      DentalConfirm4
      Dentalplan_ClientName_4
      Dentalplan_ClientLastName_4
      Dentalplan_Name_4
      Dentalplan_Cost_4
      plan_ClientName_5
      plan_ClientLastName_5
      Insurer_5
      PlanMetal_5
      PriceofPlan_5
      APCT_Subsidy_5
      DentalConfirm5
      Dentalplan_ClientName_5
      Dentalplan_ClientLastName_5
      Dentalplan_Name_5
      Dentalplan_Cost_5
      plan_ClientName_6
      plan_ClientLastName_6
      Insurer_6
      PlanMetal_6
      PriceofPlan_6
      APCT_Subsidy_6
      DentalConfirm6
      Dentalplan_ClientName_6
      Dentalplan_ClientLastName_6
      Dentalplan_Name_6
      Dentalplan_Cost_6
      plan_ClientName_7
      plan_ClientLastName_7
      Insurer_7
      PlanMetal_7
      PriceofPlan_7
      APCT_Subsidy_7
      DentalConfirm7
      Dentalplan_ClientName_7
      Dentalplan_ClientLastName_7
      Dentalplan_Name_7
      Dentalplan_Cost_7
      plan_ClientName_8
      plan_ClientLastName_8
      Insurer_8
      PlanMetal_8
      PriceofPlan_8
      APCT_Subsidy_8
      DentalConfirm8
      Dentalplan_ClientName_8
      Dentalplan_ClientLastName_8
      Dentalplan_Name_8
      Dentalplan_Cost_8
      plan_ClientName_9
      plan_ClientLastName_9
      Insurer_9
      PlanMetal_9
      PriceofPlan_9
      APCT_Subsidy_9
      DentalConfirm9
      Dentalplan_ClientName_9
      Dentalplan_ClientLastName_9
      Dentalplan_Name_9
      Dentalplan_Cost_9
      plan_ClientName_10
      plan_ClientLastName_10
      Insurer_10
      PlanMetal_10
      PriceofPlan_10
      APCT_Subsidy_10
      DentalConfirm10
      Dentalplan_ClientName_10
      Dentalplan_ClientLastName_10
      Dentalplan_Name_10
      Dentalplan_Cost_10
      plan_ClientName_11
      plan_ClientLastName_11
      Insurer_11
      PlanMetal_11
      PriceofPlan_11
      APCT_Subsidy_11
      DentalConfirm11
      Dentalplan_ClientName_11
      Dentalplan_ClientLastName_11
      Dentalplan_Name_11
      Dentalplan_Cost_11
      plan_ClientName_12
      plan_ClientLastName_12
      Insurer_12
      PlanMetal_12
      PriceofPlan_12
      APCT_Subsidy_12
      DentalConfirm12
      Dentalplan_ClientName_12
      Dentalplan_ClientLastName_12
      Dentalplan_Name_12
      Dentalplan_Cost_12
      plan_ClientName_13
      plan_ClientLastName_13
      Insurer_13
      PlanMetal_13
      PriceofPlan_13
      APCT_Subsidy_13
      DentalConfirm13
      Dentalplan_ClientName_13
      Dentalplan_ClientLastName_13
      Dentalplan_Name_13
      Dentalplan_Cost_13
      plan_ClientName_14
      plan_ClientLastName_14
      Insurer_14
      PlanMetal_14
      PriceofPlan_14
      APCT_Subsidy_14
      DentalConfirm14
      Dentalplan_ClientName_14
      Dentalplan_ClientLastName_14
      Dentalplan_Name_14
      Dentalplan_Cost_14
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      id
      Timestamp
      Username
      Date
      Navigator
      outreachEvent
      year_contract
      flyers
      Number_FollowUp_Attempt_Contact_NoResponse
      MACtouches
      name
      lastName
      phone
      address
      city
      county
      email
      prefered_lang
      otherLanguage
      commitCard
      commitcardNo
      signature
      bestTimeToCall
      CommitCardNotes
      no_show
      ConsumerAuthOnFile
      employerCoverage
      cost_to_employee
      familyCovered
      amountFamCoverage
      NumPeopleHousehold
      NumPeopleApplying
      NumAdults
      NumChildren
      EmployeeTool
      IncomeType
      IncomeAmount
      PayFrequency
      coverageAppliedFor
      OtherCoverage
      New_Re_Enrollment
      clientEnroll
      QHP_Amount
      plans
      ClientCompleteMMC
      AppealQuestion
      RescheduleQuestion
      reschedule_reason
      otherRescheduleChange
      one2oneInquiry
      HealthInsuranceLiteracy
      Locating_Providers
      Billing_Payment_Questions
      EvaluateHealtcareOptionsUsingTools
      AccessingPreventativeHealthServices
      HealtcareGovAcc
      createdAccounts
      troubleshootedAccounts
      MarketplaceEligibility
      MarketPlanCompare
      qhp_enrollment_hcgov
      elctronic_qhp_enrollment_marketplaceCallCenter
      written_qhp_enrollment_hcgov
      medicaid_chip_app_ref
      complex_case_help_center
      data_matchingIssues
      data_matchingIssues_other
      SEP_eligibility
      SEP_eligibility_other
      Employer_sponsored_coverage_issues
      Employer_sponsored_coverage_issues_other
      APTC_CSR_assistance
      APTC_CSR_assistance_other
      Complex_Cases_Other
      Answering_questions_about
      Answering_questions_about_other
      Marketplace_tax_forms
      Filing_Marketplace
      Submitting_Marketplace
      Assisted_with_other_consumer_information
      pertinent_info_tracking
      hear_from_us
      event_hear_abt_us
      facebook_hear_abt_us
      insta_hear_abt_us
      SAC_hear_abt_us
      twitter_hear_abt_us
      tv_hear_abt_us
      hospital_hear_abt_us
      library_opt
      workforce_hear_abt_us
      tablepresentation_hear_abt_us
      referral_hear_abt_us
      newspaper_hear_abt_us
      radio_hear_abt_us
      flyer_hear_abt_us
      county2
      other_county
      zip_code
      finalNotes
      plan_ClientName_0
      plan_ClientLastName_0
      Insurer_0
      PlanMetal_0
      PriceofPlan_0
      APCT_Subsidy_0
      DentalConfirm0
      Dentalplan_ClientName_0
      Dentalplan_ClientLastName_0
      Dentalplan_Name_0
      Dentalplan_Cost_0
      plan_ClientName_1
      plan_ClientLastName_1
      Insurer_1
      PlanMetal_1
      PriceofPlan_1
      APCT_Subsidy_1
      DentalConfirm1
      Dentalplan_ClientName_1
      Dentalplan_ClientLastName_1
      Dentalplan_Name_1
      Dentalplan_Cost_1
      plan_ClientName_2
      plan_ClientLastName_2
      Insurer_2
      PlanMetal_2
      PriceofPlan_2
      APCT_Subsidy_2
      DentalConfirm2
      Dentalplan_ClientName_2
      Dentalplan_ClientLastName_2
      Dentalplan_Name_2
      Dentalplan_Cost_2
      plan_ClientName_3
      plan_ClientLastName_3
      Insurer_3
      PlanMetal_3
      PriceofPlan_3
      APCT_Subsidy_3
      DentalConfirm3
      Dentalplan_ClientName_3
      Dentalplan_ClientLastName_3
      Dentalplan_Name_3
      Dentalplan_Cost_3
      plan_ClientName_4
      plan_ClientLastName_4
      Insurer_4
      PlanMetal_4
      PriceofPlan_4
      APCT_Subsidy_4
      DentalConfirm4
      Dentalplan_ClientName_4
      Dentalplan_ClientLastName_4
      Dentalplan_Name_4
      Dentalplan_Cost_4
      plan_ClientName_5
      plan_ClientLastName_5
      Insurer_5
      PlanMetal_5
      PriceofPlan_5
      APCT_Subsidy_5
      DentalConfirm5
      Dentalplan_ClientName_5
      Dentalplan_ClientLastName_5
      Dentalplan_Name_5
      Dentalplan_Cost_5
      plan_ClientName_6
      plan_ClientLastName_6
      Insurer_6
      PlanMetal_6
      PriceofPlan_6
      APCT_Subsidy_6
      DentalConfirm6
      Dentalplan_ClientName_6
      Dentalplan_ClientLastName_6
      Dentalplan_Name_6
      Dentalplan_Cost_6
      plan_ClientName_7
      plan_ClientLastName_7
      Insurer_7
      PlanMetal_7
      PriceofPlan_7
      APCT_Subsidy_7
      DentalConfirm7
      Dentalplan_ClientName_7
      Dentalplan_ClientLastName_7
      Dentalplan_Name_7
      Dentalplan_Cost_7
      plan_ClientName_8
      plan_ClientLastName_8
      Insurer_8
      PlanMetal_8
      PriceofPlan_8
      APCT_Subsidy_8
      DentalConfirm8
      Dentalplan_ClientName_8
      Dentalplan_ClientLastName_8
      Dentalplan_Name_8
      Dentalplan_Cost_8
      plan_ClientName_9
      plan_ClientLastName_9
      Insurer_9
      PlanMetal_9
      PriceofPlan_9
      APCT_Subsidy_9
      DentalConfirm9
      Dentalplan_ClientName_9
      Dentalplan_ClientLastName_9
      Dentalplan_Name_9
      Dentalplan_Cost_9
      plan_ClientName_10
      plan_ClientLastName_10
      Insurer_10
      PlanMetal_10
      PriceofPlan_10
      APCT_Subsidy_10
      DentalConfirm10
      Dentalplan_ClientName_10
      Dentalplan_ClientLastName_10
      Dentalplan_Name_10
      Dentalplan_Cost_10
      plan_ClientName_11
      plan_ClientLastName_11
      Insurer_11
      PlanMetal_11
      PriceofPlan_11
      APCT_Subsidy_11
      DentalConfirm11
      Dentalplan_ClientName_11
      Dentalplan_ClientLastName_11
      Dentalplan_Name_11
      Dentalplan_Cost_11
      plan_ClientName_12
      plan_ClientLastName_12
      Insurer_12
      PlanMetal_12
      PriceofPlan_12
      APCT_Subsidy_12
      DentalConfirm12
      Dentalplan_ClientName_12
      Dentalplan_ClientLastName_12
      Dentalplan_Name_12
      Dentalplan_Cost_12
      plan_ClientName_13
      plan_ClientLastName_13
      Insurer_13
      PlanMetal_13
      PriceofPlan_13
      APCT_Subsidy_13
      DentalConfirm13
      Dentalplan_ClientName_13
      Dentalplan_ClientLastName_13
      Dentalplan_Name_13
      Dentalplan_Cost_13
      plan_ClientName_14
      plan_ClientLastName_14
      Insurer_14
      PlanMetal_14
      PriceofPlan_14
      APCT_Subsidy_14
      DentalConfirm14
      Dentalplan_ClientName_14
      Dentalplan_ClientLastName_14
      Dentalplan_Name_14
      Dentalplan_Cost_14
    }
  }
`;

export const updateReportFix = gql`
  mutation UpdateReportFix($input: UpdateReportFixInput!) {
    updateReportFix(input: $input) {
      id
      Timestamp
      Username
      Date
      Navigator
      outreachEvent
      year_contract
      flyers
      Number_FollowUp_Attempt_Contact_NoResponse
      MACtouches
      name
      lastName
      phone
      address
      city
      county
      email
      prefered_lang
      otherLanguage
      commitCard
      commitcardNo
      signature
      bestTimeToCall
      CommitCardNotes
      no_show
      ConsumerAuthOnFile
      employerCoverage
      cost_to_employee
      familyCovered
      amountFamCoverage
      NumPeopleHousehold
      NumPeopleApplying
      NumAdults
      NumChildren
      EmployeeTool
      IncomeType
      IncomeAmount
      PayFrequency
      coverageAppliedFor
      OtherCoverage
      New_Re_Enrollment
      clientEnroll
      QHP_Amount
      plans
      ClientCompleteMMC
      AppealQuestion
      RescheduleQuestion
      reschedule_reason
      otherRescheduleChange
      one2oneInquiry
      HealthInsuranceLiteracy
      Locating_Providers
      Billing_Payment_Questions
      EvaluateHealtcareOptionsUsingTools
      AccessingPreventativeHealthServices
      HealtcareGovAcc
      createdAccounts
      troubleshootedAccounts
      MarketplaceEligibility
      MarketPlanCompare
      qhp_enrollment_hcgov
      elctronic_qhp_enrollment_marketplaceCallCenter
      written_qhp_enrollment_hcgov
      medicaid_chip_app_ref
      complex_case_help_center
      data_matchingIssues
      data_matchingIssues_other
      SEP_eligibility
      SEP_eligibility_other
      Employer_sponsored_coverage_issues
      Employer_sponsored_coverage_issues_other
      APTC_CSR_assistance
      APTC_CSR_assistance_other
      Complex_Cases_Other
      Answering_questions_about
      Answering_questions_about_other
      Marketplace_tax_forms
      Filing_Marketplace
      Submitting_Marketplace
      Assisted_with_other_consumer_information
      pertinent_info_tracking
      hear_from_us
      event_hear_abt_us
      facebook_hear_abt_us
      insta_hear_abt_us
      SAC_hear_abt_us
      twitter_hear_abt_us
      tv_hear_abt_us
      hospital_hear_abt_us
      library_opt
      workforce_hear_abt_us
      tablepresentation_hear_abt_us
      referral_hear_abt_us
      newspaper_hear_abt_us
      radio_hear_abt_us
      flyer_hear_abt_us
      county2
      other_county
      zip_code
      finalNotes
      plan_ClientName_0
      plan_ClientLastName_0
      Insurer_0
      PlanMetal_0
      PriceofPlan_0
      APCT_Subsidy_0
      DentalConfirm0
      Dentalplan_ClientName_0
      Dentalplan_ClientLastName_0
      Dentalplan_Name_0
      Dentalplan_Cost_0
      plan_ClientName_1
      plan_ClientLastName_1
      Insurer_1
      PlanMetal_1
      PriceofPlan_1
      APCT_Subsidy_1
      DentalConfirm1
      Dentalplan_ClientName_1
      Dentalplan_ClientLastName_1
      Dentalplan_Name_1
      Dentalplan_Cost_1
      plan_ClientName_2
      plan_ClientLastName_2
      Insurer_2
      PlanMetal_2
      PriceofPlan_2
      APCT_Subsidy_2
      DentalConfirm2
      Dentalplan_ClientName_2
      Dentalplan_ClientLastName_2
      Dentalplan_Name_2
      Dentalplan_Cost_2
      plan_ClientName_3
      plan_ClientLastName_3
      Insurer_3
      PlanMetal_3
      PriceofPlan_3
      APCT_Subsidy_3
      DentalConfirm3
      Dentalplan_ClientName_3
      Dentalplan_ClientLastName_3
      Dentalplan_Name_3
      Dentalplan_Cost_3
      plan_ClientName_4
      plan_ClientLastName_4
      Insurer_4
      PlanMetal_4
      PriceofPlan_4
      APCT_Subsidy_4
      DentalConfirm4
      Dentalplan_ClientName_4
      Dentalplan_ClientLastName_4
      Dentalplan_Name_4
      Dentalplan_Cost_4
      plan_ClientName_5
      plan_ClientLastName_5
      Insurer_5
      PlanMetal_5
      PriceofPlan_5
      APCT_Subsidy_5
      DentalConfirm5
      Dentalplan_ClientName_5
      Dentalplan_ClientLastName_5
      Dentalplan_Name_5
      Dentalplan_Cost_5
      plan_ClientName_6
      plan_ClientLastName_6
      Insurer_6
      PlanMetal_6
      PriceofPlan_6
      APCT_Subsidy_6
      DentalConfirm6
      Dentalplan_ClientName_6
      Dentalplan_ClientLastName_6
      Dentalplan_Name_6
      Dentalplan_Cost_6
      plan_ClientName_7
      plan_ClientLastName_7
      Insurer_7
      PlanMetal_7
      PriceofPlan_7
      APCT_Subsidy_7
      DentalConfirm7
      Dentalplan_ClientName_7
      Dentalplan_ClientLastName_7
      Dentalplan_Name_7
      Dentalplan_Cost_7
      plan_ClientName_8
      plan_ClientLastName_8
      Insurer_8
      PlanMetal_8
      PriceofPlan_8
      APCT_Subsidy_8
      DentalConfirm8
      Dentalplan_ClientName_8
      Dentalplan_ClientLastName_8
      Dentalplan_Name_8
      Dentalplan_Cost_8
      plan_ClientName_9
      plan_ClientLastName_9
      Insurer_9
      PlanMetal_9
      PriceofPlan_9
      APCT_Subsidy_9
      DentalConfirm9
      Dentalplan_ClientName_9
      Dentalplan_ClientLastName_9
      Dentalplan_Name_9
      Dentalplan_Cost_9
      plan_ClientName_10
      plan_ClientLastName_10
      Insurer_10
      PlanMetal_10
      PriceofPlan_10
      APCT_Subsidy_10
      DentalConfirm10
      Dentalplan_ClientName_10
      Dentalplan_ClientLastName_10
      Dentalplan_Name_10
      Dentalplan_Cost_10
      plan_ClientName_11
      plan_ClientLastName_11
      Insurer_11
      PlanMetal_11
      PriceofPlan_11
      APCT_Subsidy_11
      DentalConfirm11
      Dentalplan_ClientName_11
      Dentalplan_ClientLastName_11
      Dentalplan_Name_11
      Dentalplan_Cost_11
      plan_ClientName_12
      plan_ClientLastName_12
      Insurer_12
      PlanMetal_12
      PriceofPlan_12
      APCT_Subsidy_12
      DentalConfirm12
      Dentalplan_ClientName_12
      Dentalplan_ClientLastName_12
      Dentalplan_Name_12
      Dentalplan_Cost_12
      plan_ClientName_13
      plan_ClientLastName_13
      Insurer_13
      PlanMetal_13
      PriceofPlan_13
      APCT_Subsidy_13
      DentalConfirm13
      Dentalplan_ClientName_13
      Dentalplan_ClientLastName_13
      Dentalplan_Name_13
      Dentalplan_Cost_13
      plan_ClientName_14
      plan_ClientLastName_14
      Insurer_14
      PlanMetal_14
      PriceofPlan_14
      APCT_Subsidy_14
      DentalConfirm14
      Dentalplan_ClientName_14
      Dentalplan_ClientLastName_14
      Dentalplan_Name_14
      Dentalplan_Cost_14
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DeleteReport($input: DeleteReportInput!) {
    deleteReport(input: $input) {
      id
    }
  }
`;

export const deleteReportFix = gql`
  mutation DeleteReportFix($input: DeleteReportFixInput!) {
    deleteReportFix(input: $input) {
      id
    }
  }
`;


export const createMouseFix = gql`
  mutation CreateMouseFix(
    $input: CreateMouseFixInput!
    $condition: ModelMouseFixConditionInput
  ) {
    createMouseFix(input: $input, condition: $condition) {
      id
      tagId
      model
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
      userId
    }
  }
`;


export const updateMouseFix = gql`
  mutation UpdateMouseFix(
    $input: UpdateMouseFixInput!
    $condition: ModelMouseFixConditionInput
  ) {
    updateMouseFix(input: $input, condition: $condition) {
      id
      tagId
      model
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
      userId
    }
  }
`;

export const deleteMouseFix = gql`
  mutation DeleteMouseFix(
    $input: DeleteMouseFixInput!
    $condition: ModelMouseFixConditionInput
  ) {
    deleteMouseFix(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createUser = gql`
  mutation CreateUserFix ($input: CreateUserFixInput!
    $condition: ModelUserFixConditionInput
  ) {
    createUserFix (input: $input, condition: $condition) {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       location
       notes
       locationIndependentID
    }
  }
`;

export const updateUser = gql`
  mutation UpdateUserFix($input: UpdateUserFixInput! $condition: ModelUserFixConditionInput
  ) {
    updateUserFix(input: $input, condition: $condition) {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       location
       notes
       locationIndependentID
       
    }
  }
`;
export const deleteUser = gql`
  mutation DeleteUserFix($input: DeleteUserFixInput!, $condition: ModelUserFixConditionInput) {
    deleteUserFix(input: $input, condition: $condition) 
    {
       id
    }
  }
`;


export const createKeyboardFix = gql`
  mutation CreateKeyboardFix(
    $input: CreateKeyboardFixInput!
    $condition: ModelKeyboardFixConditionInput
  ) {
    createKeyboardFix(input: $input, condition: $condition) {
      id
      tagid
      model
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
      userId
    }
  }
`;


export const updateKeyboardFix = gql`
  mutation UpdateKeyboardFix(
    $input: UpdateKeyboardFixInput!
    $condition: ModelKeyboardFixConditionInput
  ) {
    updateKeyboardFix(input: $input, condition: $condition) {
      id
      tagid
      model
      brand
      serial_Number
      wireless
      usb
      combo
      price
      store
      purchase_date
      assignment_date
      warranty_date
      returnable_date
      available
      status
      assigned_to
      location
      team
      userId
    }
  }
`;

export const deleteKeyboardFix = gql`
  mutation DeleteKeyboardFix(
    $input: DeleteKeyboardFixInput!
    $condition: ModelKeyboardFixConditionInput
  ) {
    deleteKeyboardFix(input: $input, condition: $condition) {
      id
    }
  }
`;


export const createMonitorFix = gql`
  mutation CreateMonitorFix($input: CreateMonitorFixInput!, $condition: ModelMonitorFixConditionInput) {
    createMonitorFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  inches
  serial_Number
  price
  store
  purchase_date
  charger_cable
  monitor_type
  Connector_cable
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  Description
  userId
  
    }
  }
`;

export const updateMonitorFix = gql`
  mutation UpdateMonitorFix($input: UpdateMonitorFixInput!, $condition: ModelMonitorFixConditionInput) {
    updateMonitorFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  inches
  serial_Number
  price
  store
  purchase_date
  charger_cable
  monitor_type
  Connector_cable
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  Description
  userId
    }
  }
`

export const deleteMonitorFix = gql`
  mutation DeleteMonitorFix($input: DeleteMonitorFixInput!, $condition: ModelMonitorFixConditionInput) {
    deleteMonitorFix(input: $input, condition: $condition) {
  id

    }
  }
`;

export const createScannerFix = gql`
  mutation CreateScannerFix($input: CreateScannerFixInput!, $condition: ModelScannerFixConditionInput) {
    createScannerFix(input: $input, condition: $condition) {
id
tagid
model
model_number
brand
serial_Number
price
store
purchase_date
charger_cable
scanner_type
Connector_cable
wireless
assignment_date
warranty_date
returnable_date
available
status
assigned_to
location
team
Description
userId
  
    }
  }
`;

export const updateScannerFix = gql`
  mutation UpdateScannerFix($input: UpdateScannerFixInput!, $condition: ModelScannerFixConditionInput) {
    updateScannerFix(input: $input, condition: $condition) {
  id
  tagid
  model
  model_number
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  scanner_type
  Connector_cable
  wireless
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  Description
  userId
    }
  }
`;

export const deleteScannerFix = gql`
  mutation DeleteScannerFix($input: DeleteScannerFixInput!, $condition: ModelScannerFixConditionInput) {
    deleteScannerFix(input: $input, condition: $condition) {
  id

    }
  }
`;


export const createPrinterFix = gql`
  mutation CreatePrinterFix($input: CreatePrinterFixInput!, $condition: ModelPrinterFixConditionInput) {
    createPrinterFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  Connector_cable
  wireless
  black_ink
  color_ink
  black_refill_dates
  color_refill_dates
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  Description
  userId
    }
  }
`;

export const updatePrinterFix = gql`
  mutation UpdatePrinterFix($input: UpdatePrinterFixInput!, $condition: ModelPrinterFixConditionInput) {
    updatePrinterFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  charger_cable
  Connector_cable
  wireless
  black_ink
  color_ink
  black_refill_dates
  color_refill_dates
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  Description
  team
  userId
    }
  }
`;

export const deletePrinterFix = gql`
  mutation DeletePrinterFix($input: DeletePrinterFixInput!, $condition: ModelPrinterFixConditionInput) {
    deletePrinterFix(input: $input, condition: $condition) {
  id
    }
  }
`;
export const createExtensionCordFix = gql`
  mutation CreateExtensionCordFix($input: CreateExtensionCordFixInput!, $condition: ModelExtensionCordFixConditionInput) {
    createExtensionCordFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
    }
  }
`;

export const updateExtensionCordFix = gql`
  mutation UpdateExtensionCordFix($input: UpdateExtensionCordFixInput!, $condition: ModelExtensionCordFixConditionInput) {
    updateExtensionCordFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
    }
  }
`;

export const deleteExtensionCordFix = gql`
  mutation DeleteExtensionCordFix($input: DeleteExtensionCordFixInput!, $condition: ModelExtensionCordFixConditionInput) {
    deleteExtensionCordFix(input: $input, condition: $condition) {
  id

    }
  }
`;

export const createPhoneFix = gql`
  mutation CreatePhoneFix($input: CreatePhoneFixInput!, $condition: ModelPhoneFixConditionInput) {
    createPhoneFix(input: $input, condition: $condition) {
   id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  userId
    }
  }
`;

export const updatePhoneFix = gql`
  mutation UpdatePhoneFix($input: UpdatePhoneFixInput!, $condition: ModelPhoneFixConditionInput) {
    updatePhoneFix(input: $input, condition: $condition) {
      id
   tagid
   model
   brand
   serial_Number
   price
   store
   purchase_date
  cel_number
  password
  charger_cable
  EID
  imei
  ICCID
  pin
  apple_id
  apple_password
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  userId
    }
  }
`;

export const deletePhoneFix = gql`
  mutation DeletePhoneFix($input: DeletePhoneFixInput!, $condition: ModelPhoneFixConditionInput) {
    deletePhoneFix(input: $input, condition: $condition) {
   id 
    }
  }
`;

export const createBackupBatteryFix = gql`
  mutation CreateBackupBatteryFix($input: CreateBackupBatteryFixInput!, $condition: ModelBackupBatteryFixConditionInput) {
    createBackupBatteryFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId

    }
  }
`;

export const updateBackupBatteryFix = gql`
  mutation UpdateBackupBatteryFix($input: UpdateBackupBatteryFixInput!, $condition: ModelBackupBatteryFixConditionInput) {
    updateBackupBatteryFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId

    }
  }
`;

export const deleteBackupBatteryFix = gql`
  mutation DeleteBackupBatteryFix($input: DeleteBackupBatteryFixInput!, $condition: ModelBackupBatteryFixConditionInput) {
    deleteBackupBatteryFix(input: $input, condition: $condition) {
  id
    }
  }
`;


export const createPowerBankFix = gql`
  mutation CreatePowerBankFix($input: CreatePowerBankFixInput!, $condition: ModelPowerBankFixConditionInput) {
    createPowerBankFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId

    }
  }
`;

export const updatePowerBankFix = gql`
  mutation UpdatePowerBankFix($input: UpdatePowerBankFixInput!, $condition: ModelPowerBankFixConditionInput) {
    updatePowerBankFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId

    }
  }
`;
export const deletePowerBankFix = gql`
  mutation DeletePowerBankFix($input: DeletePowerBankFixInput!, $condition: ModelPowerBankFixConditionInput) {
    deletePowerBankFix(input: $input, condition: $condition) {
  id
  
    }
  }
`;


export const createUSBFix = gql`
  mutation CreateUSBFix($input: CreateUSBFixInput!, $condition: ModelUSBFixConditionInput) {
    createUSBFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId

    }
  }
`;

export const updateUSBFix = gql`
  mutation UpdateUSBFix($input: UpdateUSBFixInput!, $condition: ModelUSBFixConditionInput) {
    updateUSBFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  storage
  descripcion
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId

    }
  }
`;

export const deleteUSBFix = gql`
  mutation DeleteUSBFix($input: DeleteUSBFixInput!, $condition: ModelUSBFixConditionInput) {
    deleteUSBFix(input: $input, condition: $condition) {
  id  
    }
  }
`;

export const createDockingStationFix = gql`
  mutation CreateDockingStationFix($input: CreateDockingStationFixInput!, $condition: ModelDockingStationFixConditionInput) {
    createDockingStationFix(input: $input, condition: $condition) {
   id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
   userId

    }
  }
`;

export const updateDockingStationFix = gql`
  mutation UpdateDockingStationFix($input: UpdateDockingStationFixInput!, $condition: ModelDockingStationFixConditionInput) {
    updateDockingStationFix(input: $input, condition: $condition) {
   id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  mac_compatible
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
   team
   userId

    }
  }
`;

export const deleteDockingStationFix = gql`
  mutation DeleteDockingStationFix($input: DeleteDockingStationFixInput!, $condition: ModelDockingStationFixConditionInput) {
    deleteDockingStationFix(input: $input, condition: $condition) {
   id

    }
  }
`;

export const createSpeakerFix = gql`
  mutation CreateSpeakerFix($input: CreateSpeakerFixInput!, $condition: ModelSpeakerFixConditionInput) {
    createSpeakerFix(input: $input, condition: $condition) {
    id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId

    }
  }
`;

export const updateSpeakerFix = gql`
  mutation UpdateSpeakerFix ($input: UpdateSpeakerFixInput!, $condition: ModelSpeakerFixConditionInput) {
    updateSpeakerFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
userId

    }
  }
`;


export const deleteSpeakerFix = gql`
  mutation DeleteSpeakerFix($input: DeleteSpeakerFixInput!, $condition: ModelSpeakerFixConditionInput) {
    deleteSpeakerFix(input: $input, condition: $condition) {
  id
    }
  }
`;


export const createGeneralInventoryFix = gql`
  mutation CreateGeneralInventoryFix($input: CreateGeneralInventoryFixInput!, $condition: ModelGeneralInventoryFixConditionInput) {
    createGeneralInventoryFix(input: $input, condition: $condition) {
  id
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team
  userId

    }
  }
`;

export const updateGeneralInventoryFix = gql`
  mutation UpdateGeneralInventoryFix ($input: UpdateGeneralInventoryFixInput!, $condition: ModelGeneralInventoryFixConditionInput) {
    updateGeneralInventoryFix(input: $input, condition: $condition) {
  id
  Item_Description
  tagid
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  notes
  location
  team
  userId

    }
  }
`;

export const deleteGeneralInventoryFix = gql`
  mutation DeleteGeneralInventoryFix($input: DeleteGeneralInventoryFixInput!, $condition: ModelGeneralInventoryFixConditionInput) {
    deleteGeneralInventoryFix(input: $input, condition: $condition) {
  id

    }
  }
`;

export const createLaptopFix = gql`
  mutation CreateLaptopFix($input: CreateLaptopFixInput!, $condition: ModelLaptopFixConditionInput) {
    createLaptopFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password
  userId
    }
  }
`;
export const updateLaptopFix = gql`
  mutation UpdateLaptopFix ($input: UpdateLaptopFixInput!, $condition: ModelLaptopFixConditionInput) {
    updateLaptopFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  norton_antivirus
  os
  ram
  storage
  device_id
  product_id
  bitloker
  microsoft_account
  microsoft_acount_password
  apple_id
  apple_password
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  Description
  location
  team
  admin_password
  userId
    }
  }
`;

export const deleteLaptopFix = gql`
  mutation DeleteLaptopFix($input: DeleteLaptopFixInput!, $condition: ModelLaptopFixConditionInput) {
    deleteLaptopFix(input: $input, condition: $condition) {
  id
    }
  }
`;

export const createTabletRemarkableFix = gql`
  mutation CreateTabletRemarkableFix($input: CreateTabletRemarkableFixInput!, $condition: ModelTabletRemarkableFixConditionInput) {
    createTabletRemarkableFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
    }
  }
`;


export const updateTabletRemarkableFix = gql`
  mutation UpdateTabletRemarkableFix ($input: UpdateTabletRemarkableFixInput!, $condition: ModelTabletRemarkableFixConditionInput) {
    updateTabletRemarkableFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  price
  store
  charger_cable
  password
  os
  ram
  storage
  apple_id 
  apple_password
  phone_associate
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
    }
  }
`;
export const deleteTabletRemarkableFix = gql`
  mutation DeleteTabletRemarkableFix($input: DeleteTabletRemarkableFixInput!, $condition: ModelTabletRemarkableFixConditionInput) {
    deleteTabletRemarkableFix(input: $input, condition: $condition) {
 id
    }
  }
`;


export const createHeadsetFix = gql`
  mutation CreateHeadsetFix($input: CreateHeadsetFixInput!, $condition: ModelHeadsetFixConditionInput) {
    createHeadsetFix(input: $input, condition: $condition) {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
    }
  }
`;

export const updateHeadsetFix = gql`
  mutation UpdateHeadsetFix  ($input: UpdateHeadsetFixInput!, $condition: ModelHeadsetFixConditionInput) {
    updateHeadsetFix(input: $input, condition: $condition) {
  id
  tagid
  brand
  serial_Number
  charger_cable
  microphone
  aux_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
    }
  }
`;

export const deleteHeadsetFix = gql`
  mutation DeleteHeadsetFix($input: DeleteHeadsetFixInput!, $condition: ModelHeadsetFixConditionInput) {
    deleteHeadsetFix(input: $input, condition: $condition) {
  id
    }
  }
`;

export const createProjector = gql`
  mutation CreateProjector($input: CreateProjectorInput!, $condition: ModelProjectorConditionInput) {
    createProjector(input: $input, condition: $condition) {
  id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
  
    }
  }
`;

export const updateProjector = gql`
  mutation UpdateProjector($input: UpdateProjectorInput!, $condition: ModelProjectorConditionInput) {
    updateProjector(input: $input, condition: $condition) {
   id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
  
    }
  }
`;

export const deleteProjector = gql`
  mutation DeleteProjector($input: DeleteProjectorInput!, $condition: ModelProjectorConditionInput) {
    deleteProjector(input: $input, condition: $condition) {
  id
  tagId
  brand
  serial_Number
  charger_cable
  output_cable
  price
  store
  purchase_date
  warranty_date
  returnable_date
  available
  status
  location
  
    }
  }
`;

export const createExternalDiskFix = gql`
  mutation CreateExternalDiskFix($input: CreateExternalDiskFixInput!, $condition: ModelExternalDiskFixConditionInput) {
    createExternalDiskFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
  
    }
  }
`;

export const updateExternalDiskFix = gql`
  mutation UpdateExternalDiskFix($input: UpdateExternalDiskFixInput!, $condition: ModelExternalDiskFixConditionInput) {
    updateExternalDiskFix(input: $input, condition: $condition) {
  id
  tagid
  model
  brand
  serial_Number
  storage
  descripcion
  charger_cable
  output_cable
  price
  store
  purchase_date
  assignment_date
  warranty_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
 
    }
  }
`;

export const deleteExternalDiskFix = gql`
  mutation DeleteExternalDiskFix($input: DeleteExternalDiskFixInput!, $condition: ModelExternalDiskFixConditionInput) {
    deleteExternalDiskFix(input: $input, condition: $condition) {
  id
    }
  }
`;

export const createBagFix = gql`
  mutation CreateBagFix($input: CreateBagFixInput!, $condition: ModelBagFixConditionInput) {
    createBagFix(input: $input, condition: $condition) {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
  
    }
  }
`;

export const updateBagFix = gql`
  mutation UpdateBagFix($input: UpdateBagFixInput!, $condition: ModelBagFixConditionInput) {
    updateBagFix(input: $input, condition: $condition) {
  id
  brand
  type
  price
  store
  purchase_date
  assignment_date
  returnable_date
  available
  status
  assigned_to
  location
  team
  userId
 
    }
  }
`;

export const deleteBagFix = gql`
  mutation DeleteBagFix($input: DeleteBagFixInput!, $condition: ModelBagFixConditionInput) {
    deleteBagFix(input: $input, condition: $condition) {
  id
    }
  }
`;

export const createClickCounterFix = gql`
  mutation CreateClickCounterFix($input: CreateClickCounterFixInput!, $condition: ModelClickCounterFixConditionInput) {
    createClickCounterFix(input: $input, condition: $condition) {
  id
  price
  store
  purchase_date
  available
  assigned_to
  userId
  
    }
  }
`;

export const updateClickCounterFix = gql`
  mutation UpdateClickCounterFix($input: UpdateClickCounterFixInput!, $condition: ModelClickCounterFixConditionInput) {
    updateClickCounterFix(input: $input, condition: $condition) {
   id
  price
  store
  purchase_date
  available
  assigned_to
  userId
    }
  }
`;

export const deleteClickCounterFix = gql`
  mutation DeleteClickCounterFix($input: DeleteClickCounterFixInput!, $condition: ModelClickCounterFixConditionInput) {
    deleteClickCounterFix(input: $input, condition: $condition) {
   id
    }
  }
`;

export const createSuppliesFix = gql`
  mutation CreateSuppliesFix($input: CreateSuppliesFixInput!, $condition: ModelSuppliesFixConditionInput) {
    createSuppliesFix(input: $input, condition: $condition) {
  id
  name
  tagId
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
  userId
  
    }
  }
`;

export const updateSuppliesFix = gql`
  mutation UpdateSuppliesFix($input: UpdateSuppliesFixInput!, $condition: ModelSuppliesFixConditionInput) {
    updateSuppliesFix(input: $input, condition: $condition) {
  id
  name
  tagId
  price
  store
  purchase_date
  assignment_date
  available
  assigned_to
  location
  team
  userId
    }
  }
`;

export const deleteSuppliesFix = gql`
  mutation DeleteSuppliesFix($input: DeleteSuppliesFixInput!, $condition: ModelSuppliesFixConditionInput) {
    deleteSuppliesFix(input: $input, condition: $condition) {
   id
    }
  }
`;

export const CREATE_COUNTY = gql`
  mutation CreateCounty($input: CreateCountyInput!) {
    createCounty(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_COUNTY = gql`
  mutation UpdateCounty($input: UpdateCountyInput!) {
    updateCounty(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_COUNTY = gql`
  mutation DeleteCounty($id: ID!) {
    deleteCounty(input: { id: $id }) {
      id
    }
  }
`;
export const CREATE_CITY = gql`
  mutation CreateCity($input: CreateCityInput!) {
    createCity(input: $input) {
      id
      name
      countyID
    }
  }
`;
export const UPDATE_CITY = gql`
  mutation UpdateCity($input: UpdateCityInput!) {
    updateCity(input: $input) {
      id
      name
    }
  }
`;
export const DELETE_CITY = gql`
  mutation DeleteCity($id: ID!) {
    deleteCity(input: { id: $id }) {
      id
    }
  }
`;
// export const CREATE_LOCATION = gql`
//   mutation CreateLocation($input: CreateLocationInput!) {
//     createLocation(input: $input) {
//       id
//       name
//       address
//       cityID
//       city {
//         id
//         name
//       }
//       navigators {
//         items {
//           id
//           name
//         }
//       }
//       operationHours {
//         day
//         startTime
//         endTime
//       }
//       pointsOfContact {
//         name
//         phone
//         email
//       }
//       outreachDate
//     }
//   }
// `;

// export const UPDATE_LOCATION = gql`
//   mutation UpdateLocation($input: UpdateLocationInput!) {
//     updateLocation(input: $input) {
//       id
//       name
//       address
//       cityID
//       city {
//         id
//         name
//       }
//       navigators {
//         items {
//           id
//           name
//         }
//       }
//       operationHours {
//         day
//         startTime
//         endTime
//       }
//       pointsOfContact {
//         name
//         phone
//         email
//       }
//       outreachDate
//     }
//   }
// `;

// export const DELETE_LOCATION = gql`
//   mutation DeleteLocation($id: ID!) {
//     deleteLocation(input: { id: $id }) {
//       id
//     }
//   }
// `;


export const CREATE_NAVIGATOR = gql`
  mutation CreateNavigator($input: CreateNavigatorInput!) {
    createNavigator(input: $input) {
      id
      name
      locationID
    }
  }
`;

export const UPDATE_NAVIGATOR = gql`
  mutation UpdateNavigator($input: UpdateNavigatorInput!) {
    updateNavigator(input: $input) {
      id
      name
      locationID
    }
  }
`;


export const CREATE_LOCATION = gql`
  mutation CreateLocation($input: CreateLocationIndependentInput!) {
  createLocationIndependent(input: $input) {
    id
    name
    address
    city
    county
    outreachDate
    navigators {
      items { 
        id
        first_name
        last_name
      }
    }
    color
  }
}

`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($input: UpdateLocationIndependentInput!) {
    updateLocationIndependent(input: $input) {
      id
      name
      address
      city
      county
      outreachDate
      color
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($input: DeleteLocationIndependentInput!) {
    deleteLocationIndependent(input: $input) {
      id
    }
  }
`;

export const createDeletedUserHistory = gql`
  mutation CreateDeletedUserHistory ($input: CreateDeletedUserHistoryInput!
    $condition: ModelDeletedUserHistoryConditionInput
  ) {
    createDeletedUserHistory (input: $input, condition: $condition) {
        id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       cancellation_date
       notes
       
    }
  }
`;

export const deleteDeletedUserHistory = gql`
  mutation DeleteDeletedUserHistory($input: DeleteDeletedUserHistoryInput!, $condition: ModelDeletedUserHistoryConditionInput) {
    deleteDeletedUserHistory(input: $input, condition: $condition) {
       id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       cancellation_date
       notes
    }
  }
`;
export const updateDeletedUserHistory = gql`
  mutation UpdateDeletedUserHistory($input: UpdateDeletedUserHistoryInput!, $condition: ModelDeletedUserHistoryConditionInput) {
    updateDeletedUserHistory(input: $input, condition: $condition) {
   id
       ThreeCX_id
       ThreeCX_password
       first_name
       last_name
       thc_email_id
       thc_email_password
       gmail_email_id
       gmail_email_password
       apple_id
       apple_password
       phone_number
       navigator_id
       team
       cancellation_date
       notes
    }
  }
`;


export const createDeleteEquipmentHistoryFix = gql`
  mutation CreateDeleteEquipmentHistoryFix ($input: CreateDeleteEquipmentHistoryFixInput!
    $condition: ModelDeleteEquipmentHistoryFixConditionInput
  ) {
    createDeleteEquipmentHistoryFix (input: $input, condition: $condition) {
  id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
  userId
 
    }
  }
`;

export const deleteDeleteEquipmentHistoryFix = gql`
  mutation DeleteDeleteEquipmentHistoryFix($input: DeleteDeleteEquipmentHistoryFixInput!, $condition: ModelDeleteEquipmentHistoryFixConditionInput) {
    deleteDeleteEquipmentHistoryFix(input: $input, condition: $condition) {
  id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
  userId
  
  
    }
  }
`;

export const updateDeleteEquipmentHistoryFix = gql`
  mutation UpdateDeleteEquipmentHistoryFix($input: UpdateDeleteEquipmentHistoryFixInput!, $condition: ModelDeleteEquipmentHistoryFixConditionInput) {
    updateDeleteEquipmentHistoryFix(input: $input, condition: $condition) {
  id
  Item
  tagId
  model
  brand
  serial_Number
  price
  store
  purchase_date
  assignment_date
  available
  previous_employees
  location
  notes
  userId
  
    }
  }
`;

export const CREATE_PROMOTIONAL_REPORT = gql`
  mutation CreatePromotionalReport(
    $input: CreatePromotionalReportInput!
  ) {
    createPromotionalReport(input: $input) {
      id
      activityName
      activityDateTime
      numberOfConsumersTargeted
      mediaType
      activityDescription
      UploadedFileName
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CALL_LIST = gql`
  mutation CreateCallList($input: CreateCallListInput!) {
    createCallList(input: $input) {
      id
      clientID
      navigator
      userId
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      followUpScheduledDate
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CALL_LIST = gql`
  mutation UpdateCallList($input: UpdateCallListInput!) {
    updateCallList(input: $input) {
      id
      clientID
      navigator
      userId
      callDate
      answered
      appointmentMade
      needsFollowUp
      notes
      contactRound
      updatedAt
    }
  }
`;

export const DELETE_CALL_LIST = gql`
  mutation DeleteCallList($input: DeleteCallListInput!) {
    deleteCallList(input: $input) {
      id
    }
  }
`;

export const createWorkSchedule = gql`
  mutation CreateWorkSchedule($input: CreateWorkScheduleInput!) {
    createWorkSchedule(input: $input) {
      id
      userId
      date
      startTime
      endTime
      breakStart
      breakEnd
      location
    }
  }
`;

export const deleteWorkSchedule = gql`
  mutation DeleteWorkSchedule($input: DeleteWorkScheduleInput!) {
    deleteWorkSchedule(input: $input) {
      id
    }
  }
`;

export const updateWorkSchedule = gql`
  mutation UpdateWorkSchedule($input: UpdateWorkScheduleInput!) {
    updateWorkSchedule(input: $input) {
      id
      userId
      date
      startTime
      endTime
      breakStart
      breakEnd
      location
    }
  }
`;

export const createScheduleTemplate = gql`
  mutation CreateScheduleTemplate($input: CreateScheduleTemplateInput!) {
    createScheduleTemplate(input: $input) {
      id
      name
      days {
        day
        shift
      }
      totalHours
      createdAt
      updatedAt
    }
  }
`;

export const updateScheduleTemplate = gql`
  mutation UpdateScheduleTemplate($input: UpdateScheduleTemplateInput!) {
    updateScheduleTemplate(input: $input) {
      id
      name
      days {
        day
        shift
      }
      totalHours
      updatedAt
    }
  }
`;

export const deleteScheduleTemplate = gql`
  mutation DeleteScheduleTemplate($input: DeleteScheduleTemplateInput!) {
    deleteScheduleTemplate(input: $input) {
      id
    }
  }
`;


export const CREATE_INTERACTION = gql`
  mutation CreateInteraction($input: CreateInteractionInput!) {
    createInteraction(input: $input) {
      id
      callDate
      time
      answered
      appointmentMade
      notes
      contactMethod
      followUpScheduledDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_INTERACTION = gql`
  mutation DeleteInteraction($id: ID!) {
    deleteInteraction(input: { id: $id }) {
      id
    }
  }
`;

export const CREATE_TICKET = gql`
  mutation CreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
  id
  type
  name
  location
  time
  date
  dateTimeSupervisor
  dateTimeAdmin
  supervisor_name
  admin_name
  type_issue
  program_name
  equipment_name
  equipment_tag
  equipment_brand
  equipment_model
  equipment_ram
  equipment_storage
  status
  completed_date
  request_item
  quantity
  tried_troubleshooting
  priority
  supervisor_approval
  troubleshooting_problem1
  troubleshooting_problem2
  admin_troubleshooting_problem
  troubleshooting_steps_taken1
  troubleshooting_steps_taken2
  admin_troubleshooting_steps_taken
  troubleshooting_resolution1
  troubleshooting_resolution2
  admin_troubleshooting_resolution
  date_needed
  purchase_link
  userId


    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation UpdateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
  id
  type
  name
  location
  time
  date
  dateTimeSupervisor
  dateTimeAdmin
  supervisor_name
  admin_name
  type_issue
  program_name
  equipment_name
  equipment_tag
  equipment_brand
  equipment_model
  equipment_ram
  equipment_storage
  status
  completed_date
  request_item
  quantity
  tried_troubleshooting
  priority
  supervisor_approval
  troubleshooting_problem1
  troubleshooting_problem2
  admin_troubleshooting_problem
  troubleshooting_steps_taken1
  troubleshooting_steps_taken2
  admin_troubleshooting_steps_taken
  troubleshooting_resolution1
  troubleshooting_resolution2
  admin_troubleshooting_resolution
  date_needed
  purchase_link
  userId

    }
  }
`;
export const DELETE_TICKET = gql`
  mutation DeleteTicket($input: DeleteTicketInput!) {
    deleteTicket(input: $input) {
      id
    }
  }
`;

export const createNotification = gql`
  mutation createNotification($input:CreateNotificationInput!) {
    createNotification(input: $input) {
  id
  type
  userID
  supervisorID
  adminID
  relatedId
  status
  active
  creationDate
  expirationDate
  userId
  
    }
  }
`;

export const updateNotification = gql`
  mutation UpdateNotification($input: UpdateNotificationInput!, $condition: ModelNotificationConditionInput) {
    updateNotification(input: $input, condition: $condition) {
  id
  type
  userID
  supervisorID
  adminID
  relatedId
  status
  active
  creationDate
  expirationDate
  userId
    }
  }
`;
export const deleteNotification = gql`
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
    }
  }
`;

export const CREATE_DYNAMIC_FORM = gql`
  mutation CreateDynamicForm($input: CreateDynamicFormInput!) {
    createDynamicForm(input: $input) {
      id
      name
      cards {
        id
        label
       pageIndex
        fields {
          id
          label
          type
          options
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DYNAMIC_FORM = gql`
  mutation UpdateDynamicForm($input: UpdateDynamicFormInput!) {
    updateDynamicForm(input: $input) {
      id
      name
      cards {
        id
        label
       pageIndex
        fields {
          id
          label
          type
          options
        }
      }
      createdAt
      updatedAt
    }
  }
`;


export const DELETE_DYNAMIC_FORM = gql`
  mutation DeleteDynamicForm($input: DeleteDynamicFormInput!) {
    deleteDynamicForm(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_DYNAMIC_FORM_FIELD = gql`
  mutation CreateDynamicFormField($input: CreateDynamicFormFieldInput!) {
    createDynamicFormField(input: $input) {
      id
      label
      type
      options
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DYNAMIC_FORM_FIELD = gql`
  mutation UpdateDynamicFormField($input: UpdateDynamicFormFieldInput!) {
    updateDynamicFormField(input: $input) {
      id
      label
      type
      options
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_DYNAMIC_FORM_FIELD = gql`
  mutation DeleteDynamicFormField($input: DeleteDynamicFormFieldInput!) {
    deleteDynamicFormField(input: $input) {
      id
      label
    }
  }
`;


// OPTIONAL: If you want to store user answers
export const CREATE_FORM_SUBMISSION = gql`
  mutation CreateFormSubmission($input: CreateFormSubmissionInput!) {
  createFormSubmission(input: $input) {
    id
    formID
    answers
    createdAt
  }
}
`;
