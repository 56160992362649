import React from 'react';
import './QHP_Report.css'; // Adjust this file for specific styles
import { capitalize } from '../../../components/common/capitalize';

// Helper function to format dates in 'YYYY-MM-DD' format
const formatDate = (date) => date.toLocaleDateString('en-CA');

const QHP_Report = ({ allReports, startDate, endDate }) => {
  // Helper function to generate date range
  const generateDateRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const dateRange = generateDateRange(startDate, endDate);

  // We'll store data in an object keyed by userId
  // Each key (userId) holds { displayName, dates: {}, total: 0 }
  const navigatorData = {};
  const totalByDate = {};

  allReports.forEach((item) => {
    // Use userId as the aggregator key
    const userId = item.user?.id || item?.userId || 'UnknownUserId';

    if (!item.user) {
      console.warn('item.user is missing for item:', item.id);
    }

    // For display purposes, build a name. Fallback if user is missing
    const displayName = item.user
      ? `${capitalize(item.user?.first_name)} ${capitalize(item.user?.last_name)}`
      : 'Unassigned';

    // Parse the date from item.Date
    const dateParts = item.Date ? item.Date.split('-').map(Number) : null;
    const parsedDate = dateParts
      ? new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
      : null;
    const reportDate = parsedDate && !isNaN(parsedDate) ? formatDate(parsedDate) : null;

    // Sum up the three QHP-related fields
    const qhp_cases =
      (parseInt(item.qhp_enrollment_hcgov, 10) || 0) +
      (parseInt(item.elctronic_qhp_enrollment_marketplaceCallCenter, 10) || 0) +
      (parseInt(item.written_qhp_enrollment_hcgov, 10) || 0);

    if (reportDate) {
      // Initialize aggregator object if necessary
      if (!navigatorData[userId]) {
        navigatorData[userId] = {
          displayName,
          dates: {},
          total: 0,
        };
      }
      // If user changes in different items (less common), always keep same displayName or update
      navigatorData[userId].displayName = displayName;

      // Initialize date if needed
      if (!navigatorData[userId].dates[reportDate]) {
        navigatorData[userId].dates[reportDate] = 0;
      }

      // Add to that userId’s daily and total QHP count
      navigatorData[userId].dates[reportDate] += qhp_cases;
      navigatorData[userId].total += qhp_cases;

      // Also track total QHPs across all navigators by date
      if (!totalByDate[reportDate]) {
        totalByDate[reportDate] = 0;
      }
      totalByDate[reportDate] += qhp_cases;
    } else {
      console.warn('Invalid date found for item:', item);
    }
  });

  // Convert the navigatorData into an array so we can map over it in render
  // Each entry: [userId, { displayName, dates, total }]
  const navigatorEntries = Object.entries(navigatorData);

  // Summation of everything across all days
  const grandTotal = Object.values(totalByDate).reduce((acc, curr) => acc + curr, 0);

  return (
    <div className='qhpByNavigator-container'>
      <header className="qhpByNavigator-header">
        <h1>QHPs by Navigator</h1>
      </header>
      <table className="qhpByNavigator-table">
        <thead>
          <tr>
            <th>Navigator</th>
            {dateRange.map(date => (
              <th key={formatDate(date)}>
                {date.toLocaleDateString()}
              </th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {navigatorEntries.map(([userId, { displayName, dates, total }]) => (
            <tr key={userId}>
              <td>{displayName}</td>
              {dateRange.map(date => {
                const formattedDate = formatDate(date);
                return (
                  <td key={formattedDate}>
                    {dates[formattedDate] || 0}
                  </td>
                );
              })}
              <td>{total}</td>
            </tr>
          ))}
          <tr>
            <td><strong>Total of the day</strong></td>
            {dateRange.map(date => {
              const formattedDate = formatDate(date);
              return (
                <td key={formattedDate}>
                  <strong>{totalByDate[formattedDate] || 0}</strong>
                </td>
              );
            })}
            <td><strong>{grandTotal}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default QHP_Report;
