import React, { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import '@aws-amplify/ui-react/styles.css';
import { components, formFields } from './components/layout/CustomStyles'; 
import Sidebar from './components/layout/Sidebar';
import UserTab from './components/layout/UserTab';
import Footer from './components/layout/Footer';
import { Route, Routes, Navigate } from "react-router-dom";
import AppRoutes from './routes';


const cookieStorage = new CookieStorage({
  domain: window.location.hostname,
  path: '/',
  expires: 30,
  secure: true, // Use true for HTTPS, false for HTTP
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);

function App() {
  const [userDetails, setUserDetails] = useState({ firstName: '', lastNameInitial: '' });
  const [idleTime, setIdleTime] = useState(0);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { signInDetails } = await getCurrentUser();
        const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
        const firstNameCapitalized = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        const lastNameInitial = lastNameWithDomain.charAt(0).toUpperCase();
        setUserDetails({ firstName: firstNameCapitalized, lastNameInitial });
      } catch (error) {
        console.log('User not authenticated', error);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const resetIdleTime = () => setIdleTime(0);

    window.addEventListener('mousemove', resetIdleTime);
    window.addEventListener('keypress', resetIdleTime);
    window.addEventListener('click', resetIdleTime);
    window.addEventListener('scroll', resetIdleTime);

    const intervalId = setInterval(() => {
      setIdleTime((prevIdleTime) => prevIdleTime + 1);
    }, 60000); // 1 minute

    return () => {
      window.removeEventListener('mousemove', resetIdleTime);
      window.removeEventListener('keypress', resetIdleTime);
      window.removeEventListener('click', resetIdleTime);
      window.removeEventListener('scroll', resetIdleTime);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'signOut') {
        signOut();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (idleTime >= 15 && idleTime < 30) {
      alert('You have been idle for 15 minutes. Please log in again.');
      signOut();
      localStorage.setItem('signOut', Date.now());
    } else if (idleTime >= 30) {
      alert('Your session has expired due to inactivity. Please log in again.');
      signOut();
      localStorage.setItem('signOut', Date.now());
    }
  }, [idleTime]);


  return (
    <Authenticator components={components} formFields={formFields} variation="modal" hideSignUp>
      {({ user }) => (
        <div>
          <Sidebar />
          <UserTab />
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/*" element={<AppRoutes />} />
          </Routes>
          <Footer />
        </div>
      )}
    </Authenticator>
  );
}

export default App;
