import { useMemo } from "react";

function useFilteredData(data, searchTerm, sortConfig = null, searchField = null, users = []) {



  // Función para buscar el nombre completo basado en `userId`
  const findUserFullNameByID = (userID) => {
    if (!userID || typeof userID !== "string") return "Unknown User"; // ✅ Maneja valores no válidos
    userID = userID.toString().trim(); // ✅ Convertir siempre a string
    if (!Array.isArray(users)) return "Unknown User"; // ✅ Evita errores si `users` no está definido
    const user = users.find((u) => u.id === userID);
    return user ? `${user.first_name} ${user.last_name}` : "Unknown User"; // ✅ Retorna un valor siempre
  };

  return useMemo(() => {
    console.log("🔄 Nueva data recibida:", data); // Verifica si data está actualizada
    console.log("🔍 searchTerm:", searchTerm);
    console.log("🔍 searchField:", searchField);

    if (!Array.isArray(data)) {
      console.error('❌ Error: `data` no es un array:', data);
      return [];
    }

    let filteredData = data;

    if (searchTerm && searchTerm.trim()) {
      const lowercasedQuery = searchTerm.toLowerCase();

      filteredData = data.filter((item) => {
        let fieldValue = '';

        console.log("🔍 userId recibido:", item.userId, "Tipo:", typeof item.userId);

        if (searchField === 'assigned_to') {
          console.log("⚠️ Ignorando assigned_to en filtro.");
          return true;
        }

        // ✅ Si `searchField` es `userId`, buscar por el nombre completo
        if (searchField === 'userId') {
          const fullName = findUserFullNameByID(item.userId);
          fieldValue = fullName ? fullName.toLowerCase() : '';
        }
        // ✅ Manejo de otros campos relacionados con usuarios
        else if (['supervisorID', 'adminID', 'userID'].includes(searchField)) {
          const fullName = findUserFullNameByID(item[searchField]);
          fieldValue = fullName ? fullName.toLowerCase() : '';
        }
        // ✅ Si `searchField` tiene un valor, busca en ese campo específico
        else if (searchField) {
          fieldValue = item[searchField] ? item[searchField].toString().toLowerCase() : '';
        }
        // 🌎 Búsqueda Global: Unir todos los valores del objeto
        else {
          const userFields = ['supervisorID', 'adminID', 'userID', 'userId'];

          // ✅ Convertir `userId` en nombres completos
          const translatedFields = userFields
            .map((field) => findUserFullNameByID(item[field])) // ❌ CORREGIDO: `users` ya se usa dentro de la función
            .filter(Boolean)
            .join(' ');

          // ✅ Concatenar todos los valores del objeto
          fieldValue = Object.values(item)
            .map((value) => {
              if (value === true) return 'yes';
              if (value === false) return 'no';
              if (value === 'inv') return 'inventario';
              return value != null ? value.toString().toLowerCase() : '';
            })
            .join(' ');

          // ✅ Agregar nombres traducidos a la búsqueda
          fieldValue += ` ${translatedFields.toLowerCase()}`;
        }

      
        return fieldValue.includes(lowercasedQuery);
      });
    }

    // 🔽 Aplicar ordenamiento si `sortConfig` está presente
    if (sortConfig !== null) {
      filteredData = [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, searchTerm, sortConfig, searchField, users]);
}

export default useFilteredData;

