import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DashboardListReports, getDeletedUserHistory } from '../../../graphql/queries';
import { capitalize } from '../../../components/common/capitalize';
import { Bar } from 'react-chartjs-2';
import './Rank_Dashboard.css';

// Custom hook for normal (active) users
import useFetchUserByID from '../../../components/UserInfoLoader/findUserbyID';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Helper: Parse a 'YYYY-MM-DD' string as local date at 00:00:00
function parseDateLocal(dateString) {
  // e.g. '2024-02-01' => new Date(2024, 1, 1, 0, 0, 0)
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day, 0, 0, 0, 0);
}


// Helper: Force the selected date to "Friday" start
function getFriday(d) {
  const date = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
  const day = date.getDay(); // Sunday=0, Monday=1, ... Saturday=6
  const shift = ((day - 5) + 7) % 7;
  date.setDate(date.getDate() - shift);
  return date;
}

// Helper: Friday + 6 days => Thursday
function getThursday(d) {
  const friday = getFriday(d);
  const thursday = new Date(friday.getTime() + (6 * 24 * 60 * 60 * 1000));
  thursday.setHours(23, 59, 59, 999);
  return thursday;
}

// Check if the navigator field looks like an ID (long string)
function isLikelyID(value) {
  return typeof value === 'string' && value.trim().length > 30;
}

const RankDashboard = () => {
  // -------------------------------------------------
  // 1) States for data & loading
  // -------------------------------------------------
  const [allReports, setAllReports] = useState([]);
  const [navigatorData, setNavigatorData] = useState({});
  const [loading, setLoading] = useState(true);

  // -------------------------------------------------
  // 2) Selection logic
  // -------------------------------------------------
  const [selectedNavigators, setSelectedNavigators] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true); // only do "select all" once

  // -------------------------------------------------
  // 3) Time range toggles
  // -------------------------------------------------
  const [showByWeek, setShowByWeek] = useState(false);
  const [weekDate, setWeekDate] = useState(new Date());
  const defaultStartDate = new Date('2024-08-27');
  const defaultEndDate = new Date('2025-08-29');
  const weekStart = getFriday(weekDate);
  const weekEnd = getThursday(weekDate);

  // -------------------------------------------------
  // 4) Ranking Type
  // -------------------------------------------------
  const [rankingType, setRankingType] = useState('qhp');

  // -------------------------------------------------
  // 5) GQL queries for reports
  // -------------------------------------------------
  const [fetchReports] = useLazyQuery(DashboardListReports);

  // -------------------------------------------------
  // 6) Normal user (active) resolution
  // -------------------------------------------------
  const [nameMap, setNameMap] = useState({});
  const [navigatorQueue, setNavigatorQueue] = useState([]);
  const [currentIdx, setCurrentIdx] = useState(0);

  // This custom hook fetches "active" user info by userId
  const currentID = navigatorQueue[currentIdx] || null;
  const { userInfo, loading: fetchingUser } = useFetchUserByID(currentID);

  // When userInfo arrives, store in nameMap
  useEffect(() => {
    if (!fetchingUser && userInfo && currentID) {
      const displayName = capitalize(userInfo.first_name) + ' ' + capitalize(userInfo.last_name);
      setNameMap((prev) => ({ ...prev, [currentID]: displayName }));
      setCurrentIdx((prev) => prev + 1);
    }
  }, [fetchingUser, userInfo, currentID]);

  // Once we finish fetching all IDs in normal queue, re-aggregate
  useEffect(() => {
    if (currentIdx >= navigatorQueue.length && navigatorQueue.length > 0) {
      processNavigatorData(allReports);
    }
    // eslint-disable-next-line
  }, [currentIdx, navigatorQueue]);

  // -------------------------------------------------
  // 6b) Deleted user resolution
  // -------------------------------------------------
  const [deletedNameMap, setDeletedNameMap] = useState({});
  const [deletedQueue, setDeletedQueue] = useState([]);
  const [deletedIdx, setDeletedIdx] = useState(0);

  const [fetchDeletedUser, { data: deletedUserData, loading: loadingDeleted }] = useLazyQuery(getDeletedUserHistory);

  const deletedID = deletedQueue[deletedIdx] || null;

  // Kick off a fetch for deleted user if we have an ID
  useEffect(() => {
    if (deletedID) {
      fetchDeletedUser({ variables: { id: deletedID } });
    }
  }, [deletedID, fetchDeletedUser]);

  // Store deleted user name once fetched
  useEffect(() => {
    if (!loadingDeleted && deletedUserData && deletedUserData.getDeletedUserHistory && deletedID) {
      const dUser = deletedUserData.getDeletedUserHistory;
      const displayName = capitalize(dUser.first_name) + ' ' + capitalize(dUser.last_name);
      setDeletedNameMap((prev) => ({ ...prev, [deletedID]: displayName }));
      setDeletedIdx((prev) => prev + 1);
    }
  }, [loadingDeleted, deletedUserData, deletedID]);

  // Once we finish the deleted user queue, re-aggregate
  useEffect(() => {
    if (deletedIdx >= deletedQueue.length && deletedQueue.length > 0) {
      processNavigatorData(allReports);
    }
    // eslint-disable-next-line
  }, [deletedIdx, deletedQueue]);

  // -------------------------------------------------
  // 7) Load All Reports once
  // -------------------------------------------------
  useEffect(() => {
    loadAllReports();
    // eslint-disable-next-line
  }, []);

  // Re-run aggregator whenever date toggles change
  useEffect(() => {
    if (allReports.length === 0) return;
    processNavigatorData(allReports);
    // eslint-disable-next-line
  }, [showByWeek, weekDate]);

  // Load all pages of reports
  const loadAllReports = async () => {
    let combinedReports = [];
    let nextToken = null;
    let isLoadingReports = true;

    setLoading(true);

    try {
      while (isLoadingReports) {
        const { data } = await fetchReports({
          variables: {
            limit: 50,
            nextToken,
          },
        });

        if (data?.listReportFixes?.items) {
          combinedReports = [...combinedReports, ...data.listReportFixes.items];
          nextToken = data.listReportFixes.nextToken;
        } else {
          nextToken = null;
        }
        isLoadingReports = !!nextToken;
      }
      setAllReports(combinedReports);
      processNavigatorData(combinedReports);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  // -------------------------------------------------
  // 8) Aggregator Logic
  // -------------------------------------------------
  const processNavigatorData = (reportsArray) => {
    const startDate = showByWeek ? weekStart : defaultStartDate;
    const endDate = showByWeek ? weekEnd : defaultEndDate;

    const queueSet = new Set([...navigatorQueue]);
    const deletedSet = new Set([...deletedQueue]);

    // Key by userId or fallback name. 
    // For each key: { displayName, QHP, Medicaid }
    const navData = {};

    for (let report of reportsArray) {
      const reportDate = parseDateLocal(report.Date);

      if (reportDate < startDate || reportDate > endDate) {
        continue; // skip if out of range
      }

      // 1) If we have a user.id => aggregator key is user.id
      if (report.user && report.user.id) {
        const userId = report.user.id;
        const displayName = capitalize(report.user.first_name) + ' ' + capitalize(report.user.last_name);

        if (!navData[userId]) {
          navData[userId] = {
            displayName,
            QHP: 0,
            Medicaid: 0,
          };
        }
        // In case we previously had "Unassigned"
        if (!navData[userId].displayName || navData[userId].displayName === 'Unassigned') {
          navData[userId].displayName = displayName;
        }

        accumulate(navData[userId], report);
        continue;
      }
      else {// 2) Otherwise, no user object => maybe deleted or missing
        const possibleID = report.Navigator || '';
        if (isLikelyID(possibleID)) {
          // aggregator key is the "possibleID"
          const userId = possibleID;
          let displayName = nameMap[userId] || deletedNameMap[userId];

          // If we haven't yet fetched this ID, queue it
          if (!displayName && !queueSet.has(userId) && !deletedSet.has(userId)) {
            queueSet.add(userId);
          }
          // fallback if we haven't discovered a name yet
          if (!displayName) {
            displayName = `Unknown (ID ending ...${userId.slice(-8)})`;
          }

          if (!navData[userId]) {
            navData[userId] = {
              displayName,
              QHP: 0,
              Medicaid: 0,
            };
          }
          accumulate(navData[userId], report);
        } else {
          // possibly a short name or "N/A"
          const fallback = possibleID.trim() || 'Previous Users';
          if (!navData[fallback]) {
            navData[fallback] = {
              displayName: fallback,
              QHP: 0,
              Medicaid: 0,
            };
          }
          accumulate(navData[fallback], report);
        }
      }
    }

    // update the queue states
    setNavigatorQueue(Array.from(queueSet));

    setNavigatorData(navData);

    // if first time => select all
    if (initialLoad) {
      setSelectedNavigators(Object.keys(navData));
      setInitialLoad(false);
    }
  };

  // Summation for QHP + Medicaid
  function accumulate(navObj, report) {
    const qhpFields = [
      'qhp_enrollment_hcgov',
      'elctronic_qhp_enrollment_marketplaceCallCenter',
      'written_qhp_enrollment_hcgov'
    ];

    const dateKey = report.Date; // "YYYY-MM-DD" from your DB (assuming it's stored that way)

    // If not existing, initialize a "dates" object
    if (!navObj.dates) {
      navObj.dates = {};
    }
    if (!navObj.dates[dateKey]) {
      navObj.dates[dateKey] = { QHP: 0, Medicaid: 0 };
    }

    // Sum QHP fields for *this* single report
    const qhpValue = qhpFields.reduce(
      (sum, field) => sum + (parseFloat(report[field]) || 0),
      0
    );
    const medicaidValue = parseFloat(report.medicaid_chip_app_ref) || 0;

    // 1) Add to this dateKey's totals
    navObj.dates[dateKey].QHP += qhpValue;
    navObj.dates[dateKey].Medicaid += medicaidValue;

    // 2) Also add to navigator's overall totals
    navObj.QHP += qhpValue;
    navObj.Medicaid += medicaidValue;
  }

  // -------------------------------------------------
  // 9) Sorting + Chart Data
  // -------------------------------------------------
  const sortedNavigatorsByQHP = Object.entries(navigatorData).sort(([, a], [, b]) => b.QHP - a.QHP);
  const sortedNavigatorsByMedicaid = Object.entries(navigatorData).sort(([, a], [, b]) => b.Medicaid - a.Medicaid);
  const sortedNavigatorsBySum = Object.entries(navigatorData).sort(([, a], [, b]) => (b.QHP + b.Medicaid) - (a.QHP + a.Medicaid));

  function getSortedNavigators(rankingType) {
    if (rankingType === 'qhp') return sortedNavigatorsByQHP;
    if (rankingType === 'medicaid') return sortedNavigatorsByMedicaid;
    return sortedNavigatorsBySum; // 'sum'
  }
  const currentSortedNavigators = getSortedNavigators(rankingType);

  function getColumnLabel(rankingType) {
    if (rankingType === 'qhp') return 'QHP';
    if (rankingType === 'medicaid') return 'Medicaid';
    return 'Total';
  }
  function getValueByType(data, rankingType) {
    if (rankingType === 'qhp') return data.QHP;
    if (rankingType === 'medicaid') return data.Medicaid;
    return data.QHP + data.Medicaid;
  }
  const columnLabel = getColumnLabel(rankingType);

  // Filter for QHP & Medicaid bar charts
  const filteredDataByQHP = sortedNavigatorsByQHP.filter(([userId]) => selectedNavigators.includes(userId));
  const filteredDataByMedicaid = sortedNavigatorsByMedicaid.filter(([userId]) => selectedNavigators.includes(userId));

  // Build chart data
  const qhpChartData = {
    labels: filteredDataByQHP.map(([userId]) => navigatorData[userId].displayName),
    datasets: [
      {
        label: 'QHP Enrollments',
        data: filteredDataByQHP.map(([, data]) => data.QHP),
        backgroundColor: 'rgba(142, 212, 212, 0.6)',
      },
    ],
  };

  const medicaidChartData = {
    labels: filteredDataByMedicaid.map(([userId]) => navigatorData[userId].displayName),
    datasets: [
      {
        label: 'Medicaid Enrollments',
        data: filteredDataByMedicaid.map(([, data]) => data.Medicaid),
        backgroundColor: 'rgba(189, 158, 250, 0.6)',
      },
    ],
  };

  const options = {
    scales: {
      y: { beginAtZero: true },
    },
  };

  // -------------------------------------------------
  // 10) Checkbox toggling
  // -------------------------------------------------
  const toggleNavigator = (userId) => {
    setSelectedNavigators((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((nav) => nav !== userId)
        : [...prevSelected, userId]
    );
  };

  // -------------------------------------------------
  // 11) Toggle "All Time" vs "By Week"
  // -------------------------------------------------
  const handleShowByWeekChange = (e) => {
    setShowByWeek(e.target.value === 'byWeek');
  };

  // -------------------------------------------------
  // RENDER
  // -------------------------------------------------
  return (
    <div className="rank-dashboard">
      <header className="rank-dashboard-header">
        <h1>Navigator Productivity Dashboard</h1>
      </header>

      {loading ? (
        <p>Loading data...</p>
      ) : (
        <>
          {/* Toggle: All Time vs By Week */}
          <div style={{ marginBottom: '1rem' }}>
            <label>
              <input
                type="radio"
                name="timeRange"
                value="allTime"
                checked={!showByWeek}
                onChange={handleShowByWeekChange}
              />
              All Time
            </label>
            &nbsp;&nbsp;
            <label>
              <input
                type="radio"
                name="timeRange"
                value="byWeek"
                checked={showByWeek}
                onChange={handleShowByWeekChange}
              />
              By Week
            </label>

            {showByWeek && (
              <div style={{ marginTop: '0.5rem' }}>
                <label htmlFor="weekDate">Select a date in the desired week:</label>
                <input
                  type="date"
                  id="weekDate"
                  value={weekDate.toISOString().split('T')[0]}
                  onChange={(e) => {
                    if (!e.target.value) return;
                    const [yyyy, mm, dd] = e.target.value.split('-');
                    setWeekDate(new Date(Number(yyyy), Number(mm) - 1, Number(dd), 12, 0, 0, 0));
                  }}
                />
                <p>
                  <strong>Week Range: </strong>
                  {weekStart.toDateString()} – {weekEnd.toDateString()}
                </p>
              </div>
            )}
          </div>

          {/* Navigator selection checkboxes */}
          <div className="navigator-selection">
            <button
              onClick={() => {
                if (selectedNavigators.length > 0) {
                  // If one or more navigators are selected, unselect them
                  setSelectedNavigators([]);
                } else {
                  // If none are selected, select them all
                  setSelectedNavigators(Object.keys(navigatorData));
                }
              }}
            >
              {selectedNavigators.length > 0 ? 'Remove All' : 'Select All'}
            </button>
            {Object.entries(navigatorData)
              .sort(([aId], [bId]) => {
                // Sort by displayName
                const aName = navigatorData[aId].displayName || '';
                const bName = navigatorData[bId].displayName || '';
                return aName.localeCompare(bName);
              })
              .map(([userId, { displayName }]) => (
                <label key={userId} style={{ display: 'block' }}>
                  <input
                    type="checkbox"
                    checked={selectedNavigators.includes(userId)}
                    onChange={() => toggleNavigator(userId)}
                  />
                  {displayName}
                </label>
              ))}
          </div>

          {/* QHP Bar Chart */}
          <div className="chart-container">
            <h2>QHP Enrollments</h2>
            <Bar data={qhpChartData} options={options} />
          </div>

          {/* Medicaid Bar Chart */}
          <div className="chart-container">
            <h2>Medicaid Enrollments</h2>
            <Bar data={medicaidChartData} options={options} />
          </div>

          {/* Ranking Type Selector */}
          <div style={{ margin: '1rem 0' }}>
            <label>
              <input
                type="radio"
                name="rankingType"
                value="qhp"
                checked={rankingType === 'qhp'}
                onChange={(e) => setRankingType(e.target.value)}
              />
              QHP
            </label>
            &nbsp;&nbsp;
            <label>
              <input
                type="radio"
                name="rankingType"
                value="medicaid"
                checked={rankingType === 'medicaid'}
                onChange={(e) => setRankingType(e.target.value)}
              />
              Medicaid
            </label>
            &nbsp;&nbsp;
            <label>
              <input
                type="radio"
                name="rankingType"
                value="sum"
                checked={rankingType === 'sum'}
                onChange={(e) => setRankingType(e.target.value)}
              />
              Sum
            </label>
          </div>

          {/* Single Ranking Table */}
          <div className="rank-dashboard-tables-container">
            <h2 className="rank-dashboard-table-title">
              Ranking by {columnLabel}
            </h2>

            <table className="rank-dashboard-table rank-dashboard-single-table">
              <thead className="rank-dashboard-table-head">
                <tr>
                  <th className="rank-dashboard-table-header">Rank</th>
                  <th className="rank-dashboard-table-header">Navigator</th>
                  <th className="rank-dashboard-table-header">{columnLabel}</th>
                </tr>
              </thead>
              <tbody className="rank-dashboard-table-body">
                {currentSortedNavigators.map(([userId, data], index) => {
                  const { displayName } = data;
                  const value = getValueByType(data, rankingType);
                  return (
                    <tr className="rank-dashboard-table-row" key={userId}>
                      <td className="rank-dashboard-table-cell">{index + 1}</td>
                      <td className="rank-dashboard-table-cell">{displayName}</td>
                      <td className="rank-dashboard-table-cell">{value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default RankDashboard;
