import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { listDeleteEquipmentHistoryFixes, listDockingStationFixes, listUsers } from '../../../graphql/queries';
import { createDeleteEquipmentHistoryFix, createDockingStationFix, deleteDockingStationFix, updateDockingStationFix } from '../../../graphql/mutations';
import './Docking_Station.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';


const Docking_Station = () => {
    const [dockingStation, setDockingStation] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showDockingStationAddOverlay, setShowDockingStationAddOverlay] = useState(false);
    const [selectedDockingStation, setSelectedDockingStation] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newDockingStation, setNewDockingStation] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        purchase_date: '',
        mac_compatible: '',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: true,
        status: 'inv',
        assigned_to: '',
        location: '',
        team: '',
        userId: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('');

    const handleSaveAsExcel = async () => {
        try {
            const dockingStationToExport = filteredDockingStations.length > 0 ? filteredDockingStations : dockingStation;
            if (dockingStationToExport.length > 0) {
                // Sort the DockingStations by Timestamp in ascending order (earliest first)
                const sortedDockingStation = [...dockingStationToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedDockingStation.map((dockingStation, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG:': dockingStation.tagid,
                    'Model:': dockingStation.model,
                    'Available:': dockingStation.user?.first_name
                        ? dockingStation.user?.first_name +
                        (dockingStation.user?.last_name ? " " + dockingStation.user?.last_name : "")
                        : "N/A",
                    'Serial Number:': dockingStation.serial_Number,
                    'Status:': dockingStation.status,
                    'Assigned to:': dockingStation.assigned_to,
                    'Location:': dockingStation.location,

                    // Add other fields as needed

                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Splitters']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }
                worksheet['!cols'] = [
                    { wch: 5 },
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 25 },
                    { wch: 15 },

                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'DockingStations');

                // Write file
                XLSX.writeFile(workbook, 'DockingStationsData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditDockingStation = (dockingStation) => {
        setSelectedDockingStation(dockingStation);
        setIsEditing(true);
        setShowDockingStationAddOverlay(true);
        setDuplicateError('');
        setPriceError('');
        setStatusError('');
    };

    const { loading, data, fetchMore } = useQuery(listDockingStationFixes, {
        variables: { limit: 100 },
    });

    const [addDockingStation] = useMutation(createDockingStationFix, {
        refetchQueries: [{ query: listDockingStationFixes, variables: { limit: 100 } }]
    });

    const [removeDockingStation] = useMutation(deleteDockingStationFix, {
        refetchQueries: [{ query: listDockingStationFixes, variables: { limit: 100 } }]
    });
    const [updDockingStation] = useMutation(updateDockingStationFix, {
        refetchQueries: [{ query: listDockingStationFixes, variables: { limit: 100 } }]
    });
    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistoryFix, {
        refetchQueries: [{ query: listDeleteEquipmentHistoryFixes, variables: { limit: 100 } }]
    });

    useEffect(() => {
        const fetchAllDockingStations = async () => {
            let AllDockingStations = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllDockingStations = [...AllDockingStations, ...data.listDockingStationFixes.items];
                currentNextToken = data.listDockingStationFixes.nextToken;
            } while (currentNextToken);

            setDockingStation(AllDockingStations);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllDockingStations();
        }
    }, [data, fetchMore]);

    const clearNewDockingStation = () => {
        setNewDockingStation({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            purchase_date: '',
            mac_compatible: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: true,
            status: 'inv',
            assigned_to: '',
            location: '',
            team: '',
            userId: '',
        });
    };

    // Función para buscar el nombre completo en mayúsculas por userID
    const findUserFullNameByID = (userID) => {
        if (!userID) return null;

        const userIDString = userID.toString().trim();

        if (!Array.isArray(userData?.listUserFixes?.items)) return null;
        const user = userData?.listUserFixes?.items.find((u) => u.id === userIDString);
        return user ? `${user.first_name} ${user.last_name}` : null;
    };

    // Cerrar overlay
    const closeOverlay = () => {
        setShowDockingStationAddOverlay(false);
        clearNewDockingStation();
        setSelectedDockingStation(null);
        setIsEditing(false);
        setDuplicateError('');
        setPriceError('');
        setStatusError('');
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listDockingStationFixes.nextToken);
                return {
                    listDockingStationFixes: {
                        ...fetchMoreResult.listDockingStationFixes,
                        items: [...prevResult.listDockingStationFixes.items, ...fetchMoreResult.listDockingStationFixes.items]
                    }
                };
            }
        });
    };

    const filteredDockingStations = useFilteredData(dockingStation || [], searchTerm, sortConfig, searchField, userData?.listUserFixes?.items);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;


        if (isEditing) {
            setSelectedDockingStation({
                ...selectedDockingStation,
                [name]: formattedValue
            });
        } else {
            setNewDockingStation({
                ...newDockingStation,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddDockingStation = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newDockingStation.purchase_date
            ? new Date(newDockingStation.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newDockingStation.assignment_date
            ? new Date(newDockingStation.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newDockingStation.warranty_date
            ? new Date(newDockingStation.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newDockingStation.returnable_date
            ? new Date(newDockingStation.returnable_date).toISOString().split('T')[0]
            : null;


        // Validate that the price contains only digits and is a valid number
        let priceValue = null;
        if (newDockingStation.price !== "" && newDockingStation.price !== null && newDockingStation.price != undefined) {
            priceValue = parseFloat(newDockingStation.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = dockingStation.some(dockingStation =>
            dockingStation.tagid === newDockingStation.tagid &&
            dockingStation.serial_Number === newDockingStation.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A dockingStation with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }
        // Check availability first
        if (newDockingStation.available === true || newDockingStation.available === 'true') {

            // If available, status must be Inventario
            if (newDockingStation.status !== 'inv') {
                setStatusError('If the Docking Station is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, userId must be empty or "N/A"
            if (newDockingStation.userId !== '' && newDockingStation.userId !== 'n/a') {
                setStatusError('If the Docking Station is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                return; // Exit function if validation fails
            }

        } else if (newDockingStation.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newDockingStation.status)) {
                setStatusError('If the Docking Station is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        // If all validations pass, proceed with adding the dockingStation to the database
        const safeUserId = newDockingStation.userId && newDockingStation.userId.trim() !== "" ? newDockingStation.userId : "n/a";

        addDockingStation({
            variables: { input: { ...newDockingStation, price: priceValue, userId: safeUserId, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
        })
            .then(() => {
                clearNewDockingStation();
                alert("The Docking Station was an added success")
                setShowDockingStationAddOverlay(false);
            }).catch(error => {
                console.error("Error adding dockingStation:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };
    const handleDeleteDockingStation = async () => {
        try {
            // Remover campos innecesarios
            const DockingStationData = removeUnnecessaryFields({ ...selectedDockingStation }, [
                'warranty_date',
                'returnable_date',
                'status',
                'team',
            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
                'userId',
            ];

            // Filtrar los campos permitidos
            const cleanedNewDockingStation = Object.fromEntries(
                Object.entries(DockingStationData).filter(([key]) => allowedFields.includes(key))
            );

            // Normalizar claves alternativas
            cleanedNewDockingStation.tagId = cleanedNewDockingStation.tagId || cleanedNewDockingStation.tagid;
            delete cleanedNewDockingStation.tagid;

            cleanedNewDockingStation.previous_employees =
                cleanedNewDockingStation.previous_employees || cleanedNewDockingStation.assigned_to;
            delete cleanedNewDockingStation.assigned_to;

            cleanedNewDockingStation.userId =
                cleanedNewDockingStation.userId || cleanedNewDockingStation.assigned_to;
            delete cleanedNewDockingStation.assigned_to;


            // Añadir valores clave predeterminados
            if (!cleanedNewDockingStation.Item) {
                cleanedNewDockingStation.Item = "Docking Station"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewDockingStation).forEach((key) => {
                if (cleanedNewDockingStation[key] === "" || cleanedNewDockingStation[key] === null) {
                    delete cleanedNewDockingStation[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewDockingStation },
            });

            // Eliminar el DockingStation
            await removeDockingStation({
                variables: { input: { id: cleanedNewDockingStation.id } },
            });

            // Resetear estados
            setShowDockingStationAddOverlay(false);
            setIsEditing(false);
            setSelectedDockingStation(null);

        } catch (error) {
            console.error("Error deleting DockingStation", error);
        }
    };

    const handleUpdateDockingStation = async () => {
        console.log(selectedDockingStation.available)

        const formattedData_purchase = selectedDockingStation.purchase_date ? new Date(selectedDockingStation.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedDockingStation.warranty_date ? new Date(selectedDockingStation.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedDockingStation.returnable_date ? new Date(selectedDockingStation.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedDockingStation.assignment_date ? new Date(selectedDockingStation.assignment_date).toISOString().split('T')[0] : null;

        // Validate that the price contains only digits and is a valid number

        let priceValue = null;
        if (selectedDockingStation.price !== "" && selectedDockingStation.price !== null && selectedDockingStation.price != undefined) {
            priceValue = parseFloat(selectedDockingStation.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first
        if (selectedDockingStation.available === 'true' || selectedDockingStation.available === true) {
            // If available, status must be Inventario
            if (selectedDockingStation.status !== 'inv') {
                setStatusError('If the Docking Station is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, userId must be empty or "N/A"
            if (selectedDockingStation.userId !== '' && selectedDockingStation.userId !== 'n/a') {
                setStatusError('If the Docking Station is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                return; // Exit function if validation fails
            }

        } else
            if (selectedDockingStation.available === 'false' || selectedDockingStation.available === false) {
                // If not available, status must be one of the allowed statuses
                const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
                if (!allowedStatuses.includes(selectedDockingStation.status)) {
                    setStatusError('If the Docking Station is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                    console.log('Status validation failed');
                    return; // Exit function if validation fails
                }
                // Clear error if validation passes
                setStatusError('');
            }


        updDockingStation({
            variables: {
                input: {
                    id: selectedDockingStation.id,
                    tagid: selectedDockingStation.tagid,
                    model: selectedDockingStation.model,
                    brand: selectedDockingStation.brand,
                    serial_Number: selectedDockingStation.serial_Number,
                    price: priceValue,
                    store: selectedDockingStation.store,
                    purchase_date: formattedData_purchase,
                    mac_compatible: selectedDockingStation.mac_compatible,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedDockingStation.available,
                    status: selectedDockingStation.status,
                    assigned_to: "",
                    location: selectedDockingStation.location,
                    team: selectedDockingStation.team,
                    userId: selectedDockingStation.userId,

                }
            }
        }).then(() => {

            setPriceError('');
            setStatusError('');
            setDuplicateError('');
            setShowDockingStationAddOverlay(false);
        }).catch(error => {
            console.error("Error updating Docking Station:", error);
        });
    };


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    //listUserFixes 
    const navigatorOptions = userData?.listUserFixes?.items
        ?.filter((u) => u && u.first_name && u.last_name) // Filtrar valores nulos o sin nombres
        ?.map((u) => ({
            id: u.id,
            name: `${u.first_name.charAt(0).toUpperCase() + u.first_name.slice(1).toLowerCase()} 
           ${u.last_name.charAt(0).toUpperCase() + u.last_name.slice(1).toLowerCase()}`
        }))
        ?.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' })) || [];

    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };


    return (
        <div className="dockingStation-page">
            <header className="inventory-header">
                <h1>Docking Station</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>
            </div>

            <div className="searchBar">
                <button className="add-dockingStation-btn" onClick={() => {
                    clearNewDockingStation();
                    setSelectedDockingStation(null);
                    setIsEditing(false);
                    setShowDockingStationAddOverlay(true);
                }}>+ Add New Docking Station</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="dockingStation-table-container">
                <table className="dockingStation-table">
                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('mac_compatible')}>
                                MAC Compatible
                                {sortConfig?.key === 'mac_compatible' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('userId')}>
                                Assigned To
                                {sortConfig?.key === 'userId' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDockingStations.map((dockingStation, index) => {

                            const userFullName = findUserFullNameByID(dockingStation.userId);

                            return (
                                <tr key={dockingStation.id} onClick={() => handleEditDockingStation(dockingStation)} >
                                    <td>{index + 1}</td> {/* Número de fila */}
                                    <td className="dockingStation-tagid">{(dockingStation.tagid)}</td>
                                    <td className="dockingStation-model">{capitalize(dockingStation.model.toLowerCase())}</td>
                                    <td className="dockingStation-brand">{(dockingStation.brand)}</td>
                                    <td className="dockingStation-serial_Number">{dockingStation.serial_Number?.toUpperCase() || 'N/A'}</td>
                                    <td className="dockingStation-price">${formatPrice(dockingStation.price)}</td>
                                    <td className="dockingStation-store">{dockingStation.store}</td>
                                    <td className="dockingStation-purchase_date">{dockingStation.purchase_date ? formatDate(dockingStation.purchase_date) : 'No Date'}</td>
                                    <td className="dockingStation-mac_compatible">{dockingStation.mac_compatible ? 'Yes' : 'No'}</td>
                                    <td className="dockingStation-available">{dockingStation.available ? 'Yes' : 'No'} </td>
                                    <td className="dockingStation-status">
                                        {dockingStation.status === 'broken' ? 'Broken' :
                                            dockingStation.status === 'use' ? 'Use' :
                                                dockingStation.status === 'transfer' ? 'Transfer' :
                                                    dockingStation.status === 'lost' ? 'Lost' :
                                                        dockingStation.status === 'inv' ? 'Inventario' :
                                                            'Unknown'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                    </td>
                                    <td className={`userId ${searchField === 'userId' ? 'highlight' : ''}`}>
                                        {userFullName ? capitalize(userFullName) : "N/A"}
                                    </td>
                                    <td className="dockingStation-assignment_date">{dockingStation.assignment_date ? formatDate(dockingStation.assignment_date) : 'No Date'}</td>
                                    <td className="dockingStation-warranty_date">{dockingStation.warranty_date ? formatDate(dockingStation.warranty_date) : 'No Date'}</td>
                                    <td className="dockingStation-returnable_date">{dockingStation.returnable_date ? formatDate(dockingStation.returnable_date) : 'No Date'}</td>

                                    <td className="dockingStation-location">{dockingStation.location}</td>
                                    <td className="dockingStation-team">{dockingStation.team}</td>

                                </tr>
                            );
                        })}

                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showDockingStationAddOverlay && (

                <div className="dockingStation_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("dockingStation_add_overlay")) {
                        closeOverlay();
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Docking Station' : 'Add New Docking Station'}</h2>
                            <button onClick={() => {
                                closeOverlay();
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedDockingStation.tagid : newDockingStation.tagid} onChange={handleInputChange} required />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedDockingStation.model : newDockingStation.model} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedDockingStation.brand : newDockingStation.brand} onChange={handleInputChange} />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedDockingStation.serial_Number : newDockingStation.serial_Number} onChange={handleInputChange} required />

                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedDockingStation.price : newDockingStation.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedDockingStation.store : newDockingStation.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedDockingStation.purchase_date : newDockingStation.purchase_date}
                            onChange={handleInputChange}

                        />
                        <label>MAC Compatible:</label>

                        <select name="mac_compatible" placeholder="mac compatible" value={isEditing ? selectedDockingStation.mac_compatible : newDockingStation.mac_compatible} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedDockingStation.assignment_date : newDockingStation.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedDockingStation.warranty_date : newDockingStation.warranty_date}
                            onChange={handleInputChange}


                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedDockingStation.returnable_date : newDockingStation.returnable_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedDockingStation.available : newDockingStation.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedDockingStation.status : newDockingStation.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>

                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="userId"
                            value={isEditing ? selectedDockingStation.userId : newDockingStation.userId}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled>
                                Select an employee
                            </option>
                            <option value="n/a">N/A</option>
                            {navigatorOptions.length > 0 ? (
                                navigatorOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>No employees available</option>
                            )}


                        </select>

                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedDockingStation.location : newDockingStation.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedDockingStation.team : newDockingStation.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                        <div className="button-group">
                            <button className="overlay-add-dockingStation" onClick={isEditing ? handleUpdateDockingStation : handleAddDockingStation} >
                                {isEditing ? 'Update Docking Station' : 'Add Docking Station'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-dockingStation"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this dockingStation?')) {
                                            handleDeleteDockingStation();
                                        }
                                    }}
                                >
                                    Delete Docking Station
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );














};

export default Docking_Station;
