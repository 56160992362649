import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { ChromePicker } from 'react-color'; // Import the color picker
import { GET_ALL_LOCATIONS } from '../../graphql/queries';
import { CREATE_LOCATION, UPDATE_LOCATION } from '../../graphql/mutations';
import './Locations.css';

const Locations = ({ isOpen, onClose }) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_LOCATIONS, {
    fetchPolicy: 'network-only',
  });
  const [createLocation] = useMutation(CREATE_LOCATION);
  const [updateLocation] = useMutation(UPDATE_LOCATION);

  // Form fields
  const [locationId, setLocationId] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [cityName, setCityName] = useState('');
  const [county, setCounty] = useState('');
  const [outreachDate, setOutreachDate] = useState('');
  const [color, setColor] = useState('#3174ad'); // Default color
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const handleColorChange = (selectedColor) => {
    setColor(`rgba(${selectedColor.rgb.r}, ${selectedColor.rgb.g}, ${selectedColor.rgb.b}, ${selectedColor.rgb.a})`);
  };

  const handleSaveLocation = async () => {
    if (!locationName || !locationAddress || !cityName || !county) {
      alert('Please fill in all required fields.');
      return;
    }

    setIsSubmitting(true);

    try {
      if (locationId) {
        // Update existing location
        await updateLocation({
          variables: {
            input: {
              id: locationId,
              name: locationName,
              address: locationAddress,
              city: cityName,
              county: county,
              outreachDate: outreachDate || null,
              color
            },
          },
        });
        alert('Location updated successfully!');
      } else {
        // Create new location
        await createLocation({
          variables: {
            input: {
              name: locationName,
              address: locationAddress,
              city: cityName,
              county: county,
              outreachDate: outreachDate || null,
              color
            },
          },
        });
        alert('Location added successfully!');
      }

      refetch(); // Refresh locations
      resetForm();
    } catch (err) {
      console.error('Error saving location:', err);
      alert('An error occurred while saving the location.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditLocation = (location) => {
    setLocationId(location.id);
    setLocationName(location.name);
    setLocationAddress(location.address);
    setCityName(location.city);
    setCounty(location.county);
    setOutreachDate(location.outreachDate || '');
  };

  const resetForm = () => {
    setLocationId(null);
    setLocationName('');
    setLocationAddress('');
    setCityName('');
    setCounty('');
    setOutreachDate('');
    setColor('#3174ad'); // Reset color picker
    onClose(); // Close the modal
  };
  

  if (!isOpen) return null;

  const locations = data?.listLocationIndependents?.items || [];

  return (
    <div className="locations-modal-overlay" onClick={(e) => e.target.classList.contains('locations-modal-overlay') && onClose()}>
      <div className="locations-modal" onClick={(e) => e.stopPropagation()}>
        <h2>{locationId ? 'Edit Location' : 'Add New Location'}</h2>
        <div className="locations-container">
          {/* Left - Form Section */}
          <div className="locations-form">
            <div className="modal-form">
              <label>
                Select Color:
                <button
                  className="color-preview-button"
                  style={{ backgroundColor: color }}
                  onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                />
              </label>
              {isColorPickerOpen && (
                <div className="color-picker-overlay" onClick={(e) => e.stopPropagation()}>
                  <ChromePicker color={color} onChange={handleColorChange} />
                  <button className="close-color-picker" onClick={() => setIsColorPickerOpen(false)}>
                    ✖
                  </button>
                </div>
              )}
              <label>
                Location Name:
                <input type="text" value={locationName} onChange={(e) => setLocationName(e.target.value)} placeholder="Enter location name" />
              </label>
              <label>
                Address:
                <input type="text" value={locationAddress} onChange={(e) => setLocationAddress(e.target.value)} placeholder="Enter address" />
              </label>
              <label>
                City:
                <input type="text" value={cityName} onChange={(e) => setCityName(e.target.value)} placeholder="Enter city name" />
              </label>
              <label>
                County:
                <input type="text" value={county} onChange={(e) => setCounty(e.target.value)} placeholder="Enter county" />
              </label>
              <button onClick={handleSaveLocation} disabled={isSubmitting} className="add-button">
                {isSubmitting ? 'Saving...' : locationId ? 'Update Location' : 'Add Location'}
              </button>
              <button onClick={resetForm} className="cancel-button">Cancel</button>
            </div>
          </div>

          {/* Right - List Section */}
          <div className="locations-list-container">
            <h3>Existing Locations</h3>
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>Error loading locations: {error.message}</p>
            ) : locations.length > 0 ? (
              <ul className="locations-list">
                {locations.map((loc) => (
                  <li key={loc.id}>
                    <strong>{loc.name}</strong> - {loc.address}, {loc.city}, {loc.county}
                    <br />
                    Outreach Date: {loc.outreachDate || 'N/A'}
                    <br />
                    Navigators:{' '}
                    {loc.navigators?.items?.length > 0
                      ? loc.navigators.items.map((nav) => `${nav.first_name} ${nav.last_name}`).join(', ')
                      : 'N/A'}
                    <br />
                    <button className="edit-button" onClick={() => handleEditLocation(loc)}>Edit</button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No locations found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
