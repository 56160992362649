import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getUserByID } from '../../graphql/queries';

const useFetchUserByID = (id) => {
    const [userInfo, setUserInfo] = useState(null);
    const [fetchUser, { data, loading, error }] = useLazyQuery(getUserByID, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (id) {
            fetchUser();
        }
    }, [id, fetchUser]);

    useEffect(() => {
        if (data?.getUser) {
            setUserInfo(data.getUser);
        }
    }, [data]);

    return { userInfo, loading, error };
};

export default useFetchUserByID;
