import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import './Notifications.css';
import useFilteredData from '../../components/layout/useFilteredDate';
import { deleteNotification, updateNotification } from '../../graphql/mutations';
import { listNotifications, listUsers } from '../../graphql/queries';
import SearchBar from '../../components/layout/SearchBar';
import { capitalize } from '../../components/common/capitalize';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';


const Notifications = () => {

    const [notification, setNotification] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [overlayType, setOverlayType] = useState(null);
    const [showNotificationsAddOverlay, setShowNotificationsAddOverlay] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [sortConfig, setSortConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [userGroupsState, setUserGroups] = useState([]);
    const { loading1, error: userError, data: userData } = useQuery(listUsers);
    const [session, setSession] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const client = useApolloClient(); // Obtén el cliente Apollo
    const [searchField, setSearchField] = useState('');


    const [updNotification] = useMutation(updateNotification, {
        refetchQueries: [{ query: listNotifications, variables: { limit: 100 } }],
        awaitRefetchQueries: true,
    });

    const [removeNotification] = useMutation(deleteNotification, {
        refetchQueries: [{ query: listNotifications, variables: { limit: 100 } }]
    });

    const { loading, data, fetchMore } = useQuery(listNotifications, {
        variables: { limit: 100 },
    });

    const handleNotificationAction = (Notifications, action) => {

        if (action === 'edit') {
            console.log("Entrando en modo edición...");
            setOverlayType('edit'); // Configura el tipo de overlay como 'edit'
            setIsEditing(true); // Habilita el modo de edición
            setSelectedNotifications(Notifications);
            setShowNotificationsAddOverlay(true);
        }
    };

    const users = React.useMemo(() => {
        return userData?.listUserFixes?.items || [];
    }, [userData]);


    // Función para buscar el nombre completo en mayúsculas por userID
    const findUserFullNameByID = (users, userID) => {
        if (!userID?.trim() || !Array.isArray(users)) return null;

        const user = users.find((u) => u.id === userID);
        return user ? `${capitalize(user.first_name)} ${capitalize(user.last_name)}` : null;
    };

    // Función para encontrar el ID dado el nombre y apellido
    const findUserIDByFullName = (users, fullName) => {
        if (!Array.isArray(users) || !fullName?.trim()) return null;

        // Separar el nombre completo en `firstName` y `lastName`
        const parts = fullName.trim().split(" ");
        const firstName = parts[0];
        const lastName = parts.slice(1).join(" ");

        if (!firstName || !lastName) return null; // Validar que ambos existan

        // Buscar al usuario
        const user = users.find(
            (u) =>
                u.first_name.toLowerCase() === firstName.toLowerCase() &&
                u.last_name.toLowerCase() === lastName.toLowerCase()
        );

        return user ? user.id : null; // Retornar el ID si se encuentra, o null si no
    };


    const filteredNotifications = useFilteredData(notification || [], searchTerm, sortConfig, searchField, users);


    // Obtener sesión y detalles del usuario
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const sessionData = await fetchAuthSession();
                setSession(sessionData);

                const { signInDetails } = await getCurrentUser();
                const groups = sessionData?.tokens?.idToken?.payload['cognito:groups'] || [];
                setUserGroups(groups);

                const loginId = signInDetails?.loginId;
                if (!loginId) throw new Error('Invalid loginId format');

                const [firstName, lastNameWithDomain] = loginId.split('.');
                const lastName = lastNameWithDomain?.split('@')[0];

                const fullName = `${(firstName)} ${(lastName)}`;

                setUserDetails({
                    firstName: capitalize(firstName),
                    lastName: capitalize(lastName),
                    fullName,
                });
            } catch (error) {
                console.error('User not authenticated', error);
            }
        };

        fetchUserData();
    }, []);


    //Manejo de Notifications 
    const [hasFetchedNotifications, setHasFetchedNotifications] = useState(false);

    useEffect(() => {
        if (hasFetchedNotifications) return;

        const fetchAllNotifications = async () => {
            let allNotifications = [];
            let currentNextToken = null;

            console.log("Cargando todos los Notifications para Admin...");

            try {
                do {
                    const { data } = await fetchMore({
                        variables: { limit: 1000, nextToken: currentNextToken },
                    });

                    if (data?.listNotifications?.items) {
                        console.log("Notifications obtenidos:", data.listNotifications.items);

                        allNotifications = [...allNotifications, ...data.listNotifications.items];
                    }

                    currentNextToken = data?.listNotifications?.nextToken;
                    console.log("nextToken recibido:", currentNextToken);

                } while (currentNextToken);

                console.log("Todos los Notifications cargados:", allNotifications);
                setNotification(allNotifications);
                setHasFetchedNotifications(true); // Marcar como cargado
            } catch (error) {
                console.error("Error al obtener Notifications para Admin:", error);
            }
        };

        fetchAllNotifications();
    }, [fetchMore]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setSelectedNotifications((prev) => ({
            ...prev,
            [name]: value,

        }));
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };



    // Eliminar Notifications
    const handleDeleteNotification = async (NotificationId) => {
        // Confirmación antes de eliminar el lNotification
        const isConfirmed = window.confirm("Are you sure you want to delete this Notification?");

        if (!isConfirmed) return; // Si el usuario cancela, no hace nada.

        if (!NotificationId) {
            console.error("Notification ID is missing. Cannot delete.");
            return;
        }
        try {
            await removeNotification({
                variables: { input: { id: NotificationId } },
            });

            // Actualiza la lista de Notifications después de eliminar
            setNotification((prevNotifications) => prevNotifications.filter(Notification => Notification.id !== NotificationId));
            // Resetear estados
            setShowNotificationsAddOverlay(false);
            setIsEditing(false);
            setSelectedNotifications(null);

        } catch (error) {
            console.error("Error deleting lNotification", error);
        }
    };


    const handleUpdateNotification = async () => {
        // Crear timestamp en formato AWSDateTime
        const timestampNow = new Date().toISOString();
        console.log("AWS DateTime:", timestampNow);

        // Convertir supervisorID y adminID si contienen nombres
        const supervisor = findUserIDByFullName(users, selectedNotifications.supervisorID) || selectedNotifications.supervisorID;
        const admin = findUserIDByFullName(users, selectedNotifications.adminID) || selectedNotifications.adminID;


        // Validar los valores antes de enviar
        if (!supervisor || !admin || !selectedNotifications.id) {
            console.error("Invalid IDs detected:", { supervisor, admin, selectedNotifications });
            return;
        }

        try {
            // Ejecutar la mutación para actualizar la notificación
            const { data } = await updNotification({
                variables: {
                    input: {
                        id: selectedNotifications.id,
                        type: selectedNotifications.type,
                        userID: selectedNotifications.userID,
                        supervisorID: supervisor,
                        adminID: admin,
                        status: selectedNotifications.status,
                        active: selectedNotifications.active,
                        relatedId: selectedNotifications.relatedId,
                    },
                },
            });
            // Verificar los datos devueltos por la mutación
            if (!data?.updateNotification) {
                console.warn("No valid notification data returned from mutation:", data);
                return;
            }

            console.log("Notification updated successfully.");

            const updatedNotification = data.updateNotification;

            // Actualizar el estado local
            setNotification((prevNotifications) =>
                prevNotifications.map((notification) =>
                    notification.id === updatedNotification.id
                        ? { ...notification, ...updatedNotification }
                        : notification
                )
            );

            closeOverlay();
        } catch (error) {
            console.error("Error updating notification:", error);
        }
    };



    // Cerrar overlay
    const closeOverlay = () => {
        setOverlayType('edit')
        setShowNotificationsAddOverlay(false);
        setSelectedNotifications(null);
        setIsEditing(false);
    };

    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };


    return (
        <div className="Notifications-page" >
            <header className="inventory-header">
                <h1>Notifications List</h1>
            </header>

            <div className="searchBar">

                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="Notifications-table-container">
                <table className="Notifications-table">
                    <thead>
                        <tr>
                            <th>#</th>

                            <th onClick={() => requestSort('type')}>
                                Type
                                {sortConfig?.key === 'type' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('userID')}>
                                User Name
                                {sortConfig?.key === 'userID' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('supervisorID')}>
                                Supervisor Name
                                {sortConfig?.key === 'supervisorID' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('adminID')}>
                                Admin Name
                                {sortConfig?.key === 'adminID' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('relatedId')}>
                                Related ID
                                {sortConfig?.key === 'relatedId' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('active')}>
                                Active
                                {sortConfig?.key === 'active' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('creationDate')}>
                                Creation Date
                                {sortConfig?.key === 'creationDate' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('expirationDate')}>
                                Expiration Date
                                {sortConfig?.key === 'expirationDate' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {filteredNotifications.map((notification, index) => {
                            // Generar el `displayrelatedId`
                            const ShortrelatedId = notification.relatedId;
                            const displayrelatedId = ShortrelatedId
                                ? ShortrelatedId.toString(36).toUpperCase().slice(-6)
                                : "UNKNOWN";

                            const userFullName = findUserFullNameByID(users, notification.userID);
                            const supervisorFullName = findUserFullNameByID(users, notification.supervisorID);
                            console.log("supervisorFullName",supervisorFullName)
                            const adminFullName = findUserFullNameByID(users, notification.adminID);


                            return (
                                <tr
                                    key={notification.id}
                                    onClick={() => handleNotificationAction(notification, 'edit')}
                                >
                                    <td>{index + 1}</td>
                                    <td className="type">{notification.type}</td>
                                    <td className={`userID ${searchField === 'userID' ? 'highlight' : ''}`}>{userFullName}</td>
                                    <td className={`supervisorID ${searchField === 'supervisorID' ? 'highlight' : ''}`}>{supervisorFullName}</td>
                                    <td className={`adminID ${searchField === 'adminID' ? 'highlight' : ''}`}>{adminFullName}</td>
                                    <td className="relatedId">{displayrelatedId}</td>
                                    <td className="status">{notification.status}</td>
                                    <td className="active">{notification.active ? 'Yes' : 'No'}</td>
                                    <td className="creationDate">{notification.creationDate ? formatDate(notification.creationDate) : ""}</td>
                                    <td className="expirationDate">{notification.expirationDate ? formatDate(notification.expirationDate) : ""}</td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
            </div>

            {
                overlayType === 'edit' && showNotificationsAddOverlay && (

                    <div className="Notifications_add_overlay" onClick={(e) => {
                        if (e.target.classList.contains("Notifications_add_overlay")) {
                            closeOverlay();
                        }
                    }}>
                        <div className="overlay-content-inventory">
                            <div className="header-row">
                                <h2 className="header-title">{'Update Notification'}</h2>
                                <button onClick={closeOverlay}>X</button>
                            </div>
                            <label>Type:</label><input name="type" placeholder="Notification Type" value={selectedNotifications.type} onChange={handleInputChange} />
                            <label>User Name:</label> <input name="userID" placeholder="User" value={findUserFullNameByID(users, selectedNotifications.userID)} onChange={handleInputChange} readOnly />
                            <label>supervisor Name:</label><input name="supervisorID" placeholder="Supervisor" value={findUserFullNameByID(users, selectedNotifications.supervisorID)} onChange={handleInputChange} required />
                            <label>Admin Name:</label><input name="adminID" placeholder="Admin" value={findUserFullNameByID(users, selectedNotifications.adminID)} onChange={handleInputChange} />
                            <label>Related Id:</label><input name="relatedId" placeholder="related" value={selectedNotifications.relatedId
                                ? (selectedNotifications.relatedId).toString(36).toUpperCase().slice(-6)
                                : "UNKNOWN"} onChange={handleInputChange} readOnly />
                            <label>Status:</label>
                            <select name="status" placeholder="status" value={selectedNotifications.status} onChange={handleInputChange} >
                                <option value="" disabled hidden>Select Status</option>
                                <option value="unread">Unread</option>
                                <option value="read">Read</option>
                            </select>
                            <label>Active:</label>
                            <select name="active" placeholder="Active" value={selectedNotifications.active} onChange={handleInputChange} >
                                <option value="" disabled hidden>Select Active</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>

                            <div className="button-group">
                                <button className="overlay-add-Notifications" onClick={handleUpdateNotification}>
                                    Update Notification
                                </button>
                                {isEditing && (
                                    <button onClick={() => handleDeleteNotification(selectedNotifications.id)} className="overlay-delet-Notifications">
                                        Delete Notification
                                    </button>
                                )}
                            </div>
                        </div>

                    </div>
                )
            }


        </div >


    );


};

export default Notifications;
