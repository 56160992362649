import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { listUsersByTeamOne,listUsers } from '../../graphql/queries';

const useFetchUserByEmail = (email) => {
    const [userInfo, setUserInfo] = useState(null);
    const [fetchUsers, { data, loading, error, fetchMore }] = useLazyQuery(listUsers, {
        variables: { limit: 1000 },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (email) {
            fetchUsers();
        }
    }, [email, fetchUsers]);

    useEffect(() => {
        const findUserByEmail = (users, email) => {
            email = email? email.toLowerCase(): email;
            return users.find((user) => user.thc_email_id === email);
        };

        if (data?.listUserFixes?.items) {
            let users = [...data.listUserFixes.items];
            let nextToken = data.listUserFixes.nextToken;

            const loadAllUsers = async () => {
                while (nextToken) {
                    const moreUsers = await fetchMore({
                        variables: { nextToken, limit: 1000 },
                    });
                    users = [...users, ...moreUsers.data.listUserFixes.items];
                    nextToken = moreUsers.data.listUserFixes.nextToken;
                }

                const foundUser = findUserByEmail(users, email);
                setUserInfo(foundUser || null);
            };

            loadAllUsers();
        }
    }, [data, email, fetchMore]);

    return { userInfo, loading, error };
};

export default useFetchUserByEmail;
