// appointmentUtils.js
import moment from 'moment';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { signIn, handleOutlookEvent, deleteOutlookEvent, updateOutlookEventStatus } from '../../components/AzureConfig/AzureFunctions';
import { capitalize } from '../../components/common/capitalize';

export const handleAddAppointment = async (
    newAppointment,
    setShowAppointmentAddOverlay,
    createAppt,) => {
    try {
        const { signInDetails } = await getCurrentUser();
        const username = signInDetails.loginId;

        // Pass the intended redirect page as a state or query parameter
        const accessToken = await signIn({
            state: { redirect: '/calendar' }
        });

        const formattedStart = moment(newAppointment.date).toISOString();
        const formattedTime = moment(newAppointment.time, 'HH:mm').format('HH:mm');

        const appointmentData = {
            ...newAppointment,
            title: `Appointment with ${newAppointment.customer || 'Customer'}`,
            date: formattedStart,
            time: formattedTime,
            clientEmail: newAppointment.clientEmail,
            duration: parseInt(newAppointment.duration, 10),
            userId: newAppointment.userId,            // attach the current user's ID
            navigator: newAppointment.navigator,
            location: newAppointment.location || '',
            recurrence: newAppointment.recurrence || '',
            service: newAppointment.service || '',
            ModifiedBy: username,
            status: 'Created',
            reminder24Sent: false,
            reminder30Sent: false,
        };
        const outlookEventId = await handleOutlookEvent(appointmentData, accessToken);
        appointmentData.id = outlookEventId;

        await createAppt({
            variables: { input: appointmentData },
        });

        console.log("appointmentData", appointmentData?.clientEmail)
        if (appointmentData.clientEmail) {
            const customerName = capitalize(appointmentData.customer); // Capitalize the first letter of each word

            // Email content (both HTML and plain-text)
            const emailData = {
                to: appointmentData.clientEmail,
                cc: appointmentData.ModifiedBy, // CC the navigator who modified the appointment
                subject: "The Health Collaborative Appointment Confirmation",
                body: `
                    <html>
                    <body>
                        <p>Hi ${customerName},</p>
                        <p>This is a confirmation for your appointment with ${appointmentData.navigator}, which has been scheduled.</p>
                        <p>Here are the details of your appointment:</p>
                        <ul>
                            <li><strong>Service:</strong> ${appointmentData.service}</li>
                            <li><strong>Date and Time:</strong> ${moment(appointmentData.date).format('MMMM Do YYYY')} at ${appointmentData.time}</li>
                            <li><strong>Location:</strong> ${appointmentData.location}</li>
                        </ul>
                        <p>Thank you,</p>
                        <p>The Health Collaborative</p>
                    </body>
                    </html>
                `.trim(),
                plainText: `
                    Hi ${customerName},

                    This is a confirmation for your appointment with ${appointmentData.navigator}.

                    Here are the details of your appointment:
                    - Service: ${appointmentData.service}
                    - Date and Time: ${moment(appointmentData.date).format('MMMM Do YYYY')} at ${appointmentData.time}
                    - Location: ${appointmentData.location}

                    Thank you,
                    The Health Collaborative
                `.trim(),
            };

            // Reset form and state
            setShowAppointmentAddOverlay(false);
            console.log("OVERLAY SET TO FALSE")

            // Invoke the Lambda function after adding the appointment
            await invokeLambdaFunction(emailData);
            console.log("LAMBDA FUNCTION INVOKED")

        }
    } catch (error) {
        console.error('Error adding appointment:', error);
    }
};

export const handleUpdateAppointment = async (
    newAppointment,
    setShowAppointmentAddOverlay,
    setNewAppointment,
    setIsEditing,
    setSelectedAppointment,
    updateAppt,
) => {
    try {
        // Get the current user
        const { signInDetails } = await getCurrentUser();
        const username = signInDetails.loginId;

        const formattedStart = moment(newAppointment.date).toISOString(); // Add the "Z" for UTC
        const formattedTime = moment(newAppointment.time, 'HH:mm').format('HH:mm'); // Format time correctly as HH:mm

        // Create the appointmentData object to be passed to the update mutation
        const appointmentData = {
            id: newAppointment.id,
            title: newAppointment.title,
            customer: newAppointment.customer,
            clientEmail: newAppointment.clientEmail,
            location: newAppointment.location || '', // Ensure location is sent
            date: formattedStart,
            recurrence: newAppointment.recurrence || '', // Ensure recurrence is sent
            service: newAppointment.service || '', // Ensure service is sent
            time: formattedTime,
            duration: parseInt(newAppointment.duration, 10), // Ensure duration is sent
            userId: newAppointment.userId,
            navigator: newAppointment.navigator,
            notes: newAppointment.notes || '',
            ModifiedBy: username, // Include the current user
            status: newAppointment.status || 'active' // Default to 'active' if no status is provided
        };

        // Update the appointment in your database
        await updateAppt({
            variables: { input: appointmentData },
        });

        // Update or create the corresponding event in Outlook
        const outlookAccessToken = await signIn({
            state: { redirect: '/appointments' },
        });
        const eventId = newAppointment.id || null; // Use existing eventId or create a new one

        await handleOutlookEvent(
            {
                title: newAppointment.title,
                location: newAppointment.location,
                date: newAppointment.date,
                time: newAppointment.time,
                duration: newAppointment.duration,
                customer: newAppointment.customer, // If customer should be added as attendee
                clientEmail: newAppointment.clientEmail,
                notes: newAppointment.notes,
            },
            outlookAccessToken,
            eventId
        );
        if (newAppointment.clientEmail) {
            const customerName = capitalize(newAppointment.customer); // Capitalize the first letter of each word
            let emailContent;

            if (newAppointment.status === 'canceled') {
                emailContent = {
                    to: newAppointment.clientEmail,
                    cc: newAppointment.ModifiedBy, // CC the navigator who modified the appointment
                    subject: `The Health Collaborative Appointment Canceled`,
                    body: `
                        Dear Customer,<br><br>
                        Your appointment has been <strong>canceled</strong>. If you think this is a mistake, please call us at (210)761-3420.<br><br>
                        Thank you.
                    `,
                };
                // Step 1: Sign in to Azure and get the access token

                const accessToken = await signIn();

                // Step 2: Attempt to delete the event from Outlook
                let outlookDeleted = false;
                try {
                    await deleteOutlookEvent(newAppointment.id, accessToken);
                    console.log('Successfully deleted event from Outlook');
                    outlookDeleted = true;
                } catch (outlookError) {
                    // Check if the error is a 404 Not Found
                    if (
                        outlookError.message.includes('404') ||
                        outlookError.message.includes('The specified object was not found in the store')
                    ) {
                        console.warn('Outlook event not found, skipping deletion on Outlook');
                        outlookDeleted = true; // Mark as "deleted" for the case of non-existent event
                    } else {
                        // If it's another error, rethrow it to be handled later
                        throw outlookError;
                    }
                }
                await invokeLambdaFunction(emailContent);

            } else {
                emailContent = {
                    to: newAppointment.clientEmail,
                    cc: newAppointment.ModifiedBy, // CC the navigator who modified the appointment
                    subject: `The Health Collaborative Appointment Updated`,
                    body: `
                        Dear ${customerName},
                        <br>
                        <br>
                        Your appointment has been updated.
                        <br>
                        <br>
                        Date:</strong> ${moment(newAppointment.date).format('MMMM Do YYYY')} at ${moment(newAppointment.time, 'HH:mm').format('HH:mm')} (24-hour format)<br>
                        Location:</strong> ${newAppointment.location}<br>
                        Service:</strong> ${newAppointment.service}<br>
                        Navigator:</strong> ${newAppointment.navigator}<br>
                        Duration: ${newAppointment.duration} mins<br>
                        Notes: ${newAppointment.notes}<br><br>
                        Please contact us if you have any questions.
                    `,
                };
                await invokeLambdaFunction(emailContent);
            }
            // Send the email
            // await sendEmail(outlookAccessToken, emailContent);
        }
        // Reset the form and state
        setShowAppointmentAddOverlay(false);
        setNewAppointment({
            id: '',
            title: '',
            customer: '',
            location: '',
            date: '',
            recurrence: '',
            service: '',
            navigator: '',
            time: '',
            duration: '',
            notes: '',
            outlookEventId: '', // Reset the outlook eventId
        });
        setIsEditing(false);
        setSelectedAppointment(null);
    } catch (error) {
        console.error('Error updating appointment:', error);
    }
};

export const handleDeleteAppointment = async (
    selectedAppointment,
    setShowAppointmentAddOverlay,
    setIsEditing,
    setSelectedAppointment,
    deleteAppt
) => {
    if (!selectedAppointment) return; // Ensure there's a selected appointment

    try {
        // Step 1: Sign in to Azure and get the access token
        const accessToken = await signIn();

        // Step 2: Attempt to delete the event from Outlook
        let outlookDeleted = false;
        try {
            await deleteOutlookEvent(selectedAppointment.id, accessToken);
            console.log('Successfully deleted event from Outlook');
            outlookDeleted = true;
        } catch (outlookError) {
            // Check if the error is a 404 Not Found
            if (
                outlookError.message.includes('404') ||
                outlookError.message.includes('The specified object was not found in the store')
            ) {
                console.warn('Outlook event not found, skipping deletion on Outlook');
                outlookDeleted = true; // Mark as "deleted" for the case of non-existent event
            } else {
                // If it's another error, rethrow it to be handled later
                throw outlookError;
            }
        }

        // Step 3: Proceed to delete the appointment from the database if the event is successfully deleted from Outlook or not found
        if (outlookDeleted) {
            await deleteAppt({
                variables: { input: { id: selectedAppointment.id } },
            });

            // Step 4: Reset the state
            setShowAppointmentAddOverlay(false);
            setIsEditing(false);
            setSelectedAppointment(null);

            console.log('Appointment successfully deleted from the database');
        }
    } catch (error) {
        console.error('Error deleting appointment:', error);
    }
};

export const handleCancelAppointment = async (
    selectedAppointment,
    setShowAppointmentAddOverlay,
    setIsEditing,
    setSelectedAppointment,
    updateAppt,
) => {
    if (!selectedAppointment) return; // Ensure there's a selected appointment

    try {
        // Step 1: Sign in to Azure and get the access token
        const accessToken = await signIn();

        // Step 2: Attempt to update the event status to "canceled" in Outlook
        let outlookUpdated = false;
        try {
            await updateOutlookEventStatus(selectedAppointment.id, 'canceled', accessToken); // Assuming you have a function to update the status in Outlook
            console.log('Successfully updated event status to canceled in Outlook');
            outlookUpdated = true;
        } catch (outlookError) {
            // Check if the error is a 404 Not Found
            if (
                outlookError.message.includes('404') ||
                outlookError.message.includes('The specified object was not found in the store')
            ) {
                console.warn('Outlook event not found, skipping status update on Outlook');
                outlookUpdated = true; // Mark as "updated" for the case of non-existent event
            } else {
                // If it's another error, rethrow it to be handled later
                throw outlookError;
            }
        }

        // Step 3: Proceed to update the appointment status to "canceled" in the database
        if (outlookUpdated) {
            await updateAppt({
                variables: { input: { id: selectedAppointment.id, status: 'canceled' } },
            });
            const customerName = capitalize(selectedAppointment.customer); // Capitalize the first letter of each word
            // Step 4: Send a cancellation email to the client
            const emailContent = {
                to: selectedAppointment.clientEmail, // Assuming this is the client's email
                cc: selectedAppointment.ModifiedBy, // CC the navigator who modified the appointment
                subject: `The Health Collaborative Appointment Canceled`,
                body: `
                    Dear ${customerName},
                    Your appointment scheduled on ${moment(selectedAppointment.date).format('MMMM Do YYYY')} has been canceled.<br><br>
                    If you think this is a mistake, please call us at or reply to this email.<br><br>
                    Thank you.
                `,
            };
            await invokeLambdaFunction(emailContent);

            // await sendEmail(accessToken, emailContent); // Sending the email

            // Step 5: Reset the state
            setShowAppointmentAddOverlay(false);
            setIsEditing(false);
            setSelectedAppointment(null);

            console.log('Appointment successfully updated to canceled in the database and Outlook, and email sent');
        }
    } catch (error) {
        console.error('Error canceling appointment:', error);
    }
};

export const generateDurationOptions = () => {
    const options = [];

    // Generate options up to 55 minutes
    for (let i = 5; i <= 55; i += 5) {
        options.push(
            <option key={i} value={i}>
                {i} mins
            </option>
        );
    }

    // Generate options from 1 hour to 24 hours, with 5-minute increments
    for (let hour = 1; hour <= 24; hour++) {
        // Add the full hour option
        const hourInMinutes = hour * 60;
        options.push(
            <option key={hourInMinutes} value={hourInMinutes}>
                {hour} hr{hour > 1 ? 's' : ''}
            </option>
        );

        // Add 5-minute increments past the full hour, up to 55 minutes
        for (let mins = 5; mins <= 55; mins += 5) {
            const valueInMinutes = hourInMinutes + mins;
            options.push(
                <option key={valueInMinutes} value={valueInMinutes}>
                    {hour} hr{hour > 1 ? 's' : ''} {mins} mins
                </option>
            );
        }
    }

    return options;
};

// Add this function in your component or utility file
export const formatDuration = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    let formattedDuration = '';

    if (hours > 0) {
        formattedDuration += `${hours} hr${hours > 1 ? 's' : ''}`;
    }
    if (minutes > 0) {
        if (hours > 0) {
            formattedDuration += ' '; // Add space if hours were added
        }
        formattedDuration += `${minutes} min${minutes > 1 ? 's' : ''}`;
    }
    return formattedDuration || '0 mins';
};

export const invokeLambdaFunction = async (emailData) => {
    const url = "https://buykqjzjjh.execute-api.us-east-2.amazonaws.com/compassdev/sendemail";

    try {
        // Get the current session for the logged-in user
        const session = await fetchAuthSession();
        if (!session || !session.tokens || !session.tokens.idToken) {
            throw new Error("Invalid session or missing token");
        }

        // Extract the id token
        const idToken = session.tokens.idToken.toString();

        // Prepare the POST request
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(emailData) // Include the formatted email data as the request body
        });

        // Check if response is okay, otherwise throw an error
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to invoke Lambda function. Status: ${response.status}. Error: ${errorText}`);
        }

        // Parse and log the response data
        const data = await response.json();
        // console.log("Lambda invocation result:", data);
    } catch (error) {
        console.error("Error invoking Lambda:", error);
    }
};
