import React, { useState } from 'react';
import './NotificationModal.css'; // Archivo CSS
import { capitalize } from '../common/capitalize';


const NotificationModal = ({ notifications, isModalOpen, toggleModal, isAdmin, onViewAll, handleNotificationClick }) => {

    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const getCustomMessage = (type) => {
        switch (type) {
            case 'Ticket':
                return 'A new ticket has been created';
            case 'alert':
                return 'You have received an important alert.';
            case 'reminder':
                return 'This is a friendly reminder.';
            default:
                return 'You have a new notification.';
        }
    };

    return (
        <>
            {isModalOpen && (
                <div className="modal-notification-overlay" onClick={(e) => {
                    // Cierra el modal solo si se hace clic fuera del contenido
                    if (e.target.classList.contains("modal-notification-overlay")) {
                        toggleModal();
                    }
                }}

                >
                    <div className="modal-notification-content" onClick={(e) => e.stopPropagation()}>
                        <h3>Notifications</h3>
                        <div className="notification-messages">
                            {notifications.length > 0 ? (
                                <>
                                    {/* Nuevas notificaciones */}
                                    <div className="notifications-section">
                                        <h3 className="section-title">New Notifications</h3>
                                        {notifications.filter((notification) => notification.status === "unread").length > 0 ? (
                                            notifications
                                                .filter((notification) => notification.status === "unread")
                                                .map((notification) => (
                                                    <div
                                                        key={notification.relatedId}
                                                        className="notification-item unread"
                                                        onClick={() => handleNotificationClick(notification)}
                                                    >
                                                        {getCustomMessage(notification.type)} on {formatDate(notification.creationDate)} by {capitalize(notification.fullname)}.
                                                    </div>
                                                ))
                                        ) : (
                                            <p className="no-notifications">No available new notifications</p>
                                        )}
                                    </div>

                                    {/* Notificaciones antiguas */}
                                    <div className="notifications-section">
                                        <h3 className="section-title">Earlier</h3>
                                        {notifications.filter((notification) => notification.status === "read").length > 0 ? (
                                            notifications
                                                .filter((notification) => notification.status === "read")
                                                .map((notification) => (
                                                    <div
                                                        key={notification.relatedId}
                                                        className="notification-item read"
                                                        onClick={() => handleNotificationClick(notification)}
                                                    >
                                                        {getCustomMessage(notification.type)} on {formatDate(notification.creationDate)} by {capitalize(notification.fullname)}.
                                                    </div>
                                                ))
                                        ) : (
                                            <p className="no-notifications">No available earlier notifications</p>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <p className="no-notifications">No notifications available</p>
                            )}
                        </div>



                        {/* Mostrar un mensaje introductorio solo si hay notificaciones */}
                        {notifications.length > 0 && (
                            <p className="notification-intro">
                                You have a new message! Please check it as soon as possible.
                            </p>
                        )}
                        <button className="close-notification-button" onClick={toggleModal}>
                            Close
                        </button>
                        {isAdmin && (
                            <button className="close-notification-button" onClick={onViewAll}>
                                View All Notifications
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default NotificationModal;