import React, { useState, useEffect, useRef } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { VariableSizeGrid as Grid } from "react-window";
import { FaCopy } from 'react-icons/fa';
import moment from 'moment';
import * as XLSX from 'xlsx';
import useFetchUserByEmail from '../../components/UserInfoLoader/LoadUserInfo';
import LoadingSpiner from '../../components/layout/LoadingSpinner';
import ClientForm from '../../components/ClientForm';
import DuplicatesModal from './components/DuplicatesModal'; // ADDED FOR DUPLICATES
import { listClientFix2s, listUsersByTeamOne, clientsByUser } from '../../graphql/queries';
import { DELETE_CLIENTFIX2 } from '../../graphql/mutations';
import { capitalize } from '../../components/common/capitalize';
import './Clients.css';

const ClientsTable = () => {
  // --------------------------------------
  // State and Refs
  // --------------------------------------
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [showClientAddOverlay, setShowClientAddOverlay] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  // The current sorting rules
  const [sortConfig, setSortConfig] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [isLoading, setIsLoading] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const headerRef = useRef(null);
  const [userEmail, setUserEmail] = useState(null);

  const { userInfo: userLoggedIn, loading: userLoading, error: userError } = useFetchUserByEmail(userEmail);

  // For checkboxes
  const [selectedClientIds, setSelectedClientIds] = useState([]);

  // ADDED FOR DUPLICATES
  const [duplicateGroups, setDuplicateGroups] = useState([]);
  const [showDuplicatesModal, setShowDuplicatesModal] = useState(false);

  // Grid settings
  const headers = [
    "Select",
    "ID",
    "Name",
    "Last Name",
    "Phone",
    "Email",
    "Preferred Language",
    "Address",
    "City",
    "County",
    // "Insurance",
    // "Plan",
    "Last Contacted Date",
    "Navigator",
    // "Payment Per Month"
  ];

  // Each column’s “key” for sorting. `null` means “no sorting available.” 
  // Here, we skip index 0 because that's the "Select" column.
  const sortKeys = [
    null,                 // 0: "Select"
    "id",                 // 1: "ID"
    "name",               // 2: "Name"
    "last_name",          // 3: "Last Name"
    "phone",              // 4: "Phone"
    "email",              // 5: "Email"
    "prefered_lang",      // 6: "Preferred Language"
    "address",            // 7: "Address"
    "city",               // 8: "City"
    "county",             // 9: "County"
    // "insurance_picked",   // 10: "Insurance"
    // "plan",               // 11: "Plan"
    "last_contacted_date",// 12: "Last Contacted Date"
    "navigator",          // 13: "Navigator"
    // "payment_per_month"   // 14: "Payment Per Month"
  ];

  const columnWidth = 180;
  const rowHeight = 30;
  const columnCount = 12;
  const [gridWidth, setGridWidth] = useState(window.innerWidth * 0.965);
  const [gridHeight, setGridHeight] = useState(400); // Default height

  const ALLOWED_USER_GROUPS = ['Admin', 'Manager'];
  const userHasAccess = () => userGroups.some(group => ALLOWED_USER_GROUPS.includes(group));


  const getRowHeight = (rowIndex) => {
    // For now, if you want the exact same behavior you had before (i.e. fixed row height),
    // just return a constant number:
    return 30; // or rowHeight variable
  };

  const getColumnWidth = (columnIndex) => {
    // For reference, your columns are:
    // 0 -> "Select"
    // 1 -> "ID"
    // 2 -> "Name"
    // 3 -> "Last Name"
    // 4 -> "Phone"
    // 5 -> "Email"
    // 6 -> "Preferred Language"
    // 7 -> "Address"
    // 8 -> "City"
    // 9 -> "County"
    // 10 -> "Last Contacted Date"
    // 11 -> "Navigator"

    switch (columnIndex) {
      case 0: // "Select"
        return 50;
      case 1: // "ID"
        return 120;
      case 2: // "Name"
        return 140;
      case 3: // "Last Name"
        return 150;
      case 4: // "Phone"
        return 120;
      case 5: // "Email"
        return 220;
      case 6: // "Preferred Language"
        return 160;
      case 7: // "Address"
        return 220;
      case 8: // "City"
        return 120;
      case 9: // "County"
        return 120;
      case 10: // "Last Contacted Date"
        return 140;
      case 11: // "Navigator"
        return 160;
      default:
        return 180;
    }
  };

  // Queries and Mutations
  const [fetchAllClients] = useLazyQuery(listClientFix2s, {
    variables: { limit: 200 },
    fetchPolicy: "network-only",
  });

  // For Admin/Manager => listClientFix2s, else => clientsByUser
  const { loading, data, fetchMore, refetch } = useQuery(
    userHasAccess() ? listClientFix2s : clientsByUser,
    {
      variables: userHasAccess()
        ? { limit: 1000 }
        : { userId: userLoggedIn?.id || "Unknown Navigator!", limit: 1000 },
      fetchPolicy: "network-only"
    }
  );

  const [deleteClient] = useMutation(DELETE_CLIENTFIX2, {
    refetchQueries: [{ query: listClientFix2s, variables: { limit: 100 } }]
  });

  // --------------------------------------
  // Sorting Logic
  // --------------------------------------
  const handleSort = (key) => {
    if (!key) return; // Some columns have no key => skip

    let direction = 'ascending';
    // If we're already sorting by this key in ascending, flip to descending
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // We derive sorted data from `clients`
  const sortedClients = React.useMemo(() => {
    // Copy the array so we don't mutate original
    let sortable = [...clients];

    if (sortConfig?.key) {
      sortable.sort((a, b) => {
        const aVal = a[sortConfig.key];
        const bVal = b[sortConfig.key];

        // Handle string vs number vs date, etc. 
        // For simplicity, just do "string" compare 
        // But if it's a date, you might parse into new Date() etc.
        let aComp = (aVal ?? '').toString().toLowerCase();
        let bComp = (bVal ?? '').toString().toLowerCase();

        if (aComp < bComp) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aComp > bComp) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortable;
  }, [clients, sortConfig]);



  // --------------------------------------
  // Filtering Logic
  // --------------------------------------
  const filteredClients = React.useMemo(() => {
    const searchValue = searchQuery?.toLowerCase() || '';

    return sortedClients.filter(client => {
      let fieldValue = '';

      if (searchField === 'navigator') {
        // If searching by navigator, build the full name of "user"
        if (client.user) {
          const fName = client.user.first_name || '';
          const lName = client.user.last_name || '';
          fieldValue = (fName + ' ' + lName).toLowerCase();
        }
      } else {
        // Default: read from client[searchField]
        // Safety: Convert null/undefined => ''
        fieldValue = client[searchField] ? client[searchField].toString().toLowerCase() : '';
      }

      return fieldValue.includes(searchValue);
    });
  }, [sortedClients, searchQuery, searchField]);


  // --------------------------------------
  // Effects
  // --------------------------------------
  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    // Once data loads, set `clients`
    if (data) {
      const fetchedClients = userHasAccess()
        ? data.listClientFix2s.items
        : data.ClientFix2ByUser.items;
      setClients(fetchedClients);
    }
  }, [data, userGroups]);

  // If you want to fully load *all pages* of results:
  useEffect(() => {
    const fetchAllClientsData = async () => {
      let allClients = [];
      let currentNextToken = null;

      if (!data) return;

      do {
        const response = await fetchMore({
          variables: userHasAccess()
            ? { limit: 1000, nextToken: currentNextToken }
            : { userId: userLoggedIn?.id || "Unknown Navigator!", limit: 1000, nextToken: currentNextToken },
        });

        const fetchedData = userHasAccess()
          ? response?.data?.listClientFix2s
          : response?.data?.ClientFix2ByUser;

        if (fetchedData && fetchedData.items) {
          allClients = [...allClients, ...fetchedData.items];
          currentNextToken = fetchedData.nextToken;
        } else {
          console.warn("Unexpected response structure from fetchMore:", response);
          break;
        }
      } while (currentNextToken);

      setClients(allClients);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllClientsData();
    }
  }, [data, fetchMore, userGroups, userLoggedIn]);

  useEffect(() => {
    // Adjust grid height based on # of rows that match the filters
    const calculateHeight = () => {
      const headerHeight = document.querySelector('.clients-header')?.offsetHeight || 50;
      const footerHeight = 20;
      const availableHeight = window.innerHeight - headerHeight - footerHeight;

      const visibleRows = Math.min(filteredClients.length, Math.floor(availableHeight / rowHeight));
      const newHeight = visibleRows * rowHeight;

      // Enforce min & max heights:
      const minHeight = 50;
      const maxHeight = 600; // or whatever upper limit you prefer
      // This ensures we never go below 200px or above 600px
      const finalHeight = Math.max(minHeight, Math.min(newHeight, maxHeight));
      setGridHeight(finalHeight);
    };

    window.addEventListener('resize', calculateHeight);
    calculateHeight();

    return () => window.removeEventListener('resize', calculateHeight);
  }, [filteredClients, rowHeight]);


  useEffect(() => {
    const handleResize = () => {
      setGridWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // --------------------------------------
  // Utility
  // --------------------------------------
  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);
      setUserEmail(signInDetails.loginId.toLowerCase());
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  // --------------------------------------
  // CRUD-Like Handlers
  // --------------------------------------
  const handleEditClient = (client) => {
    setSelectedClient(client);
    setIsEditing(true);
    setShowClientAddOverlay(true);
  };

  const handleDeleteClient = () => {
    setIsLoading(true);
    if (!selectedClient) return;

    deleteClient({
      variables: { input: { id: selectedClient.id } },
      update: (cache) => {
        const existingClients = cache.readQuery({ query: listClientFix2s, variables: { limit: 1000 } });
        const updatedClients = existingClients.listClientFix2s.items.filter(
          (c) => c.id !== selectedClient.id
        );

        cache.writeQuery({
          query: listClientFix2s,
          variables: { limit: 1000 },
          data: {
            listClientFix2s: {
              ...existingClients.listClientFix2s,
              items: updatedClients
            }
          }
        });
      }
    })
      .then(() => {
        setSelectedClient(null);
        setShowClientAddOverlay(false);
      })
      .catch((error) => {
        console.error("Error deleting client:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNavigateToOptOutClients = () => {
    navigate('/opt-out-clients');
  };

  // For the example "Download excel"
  const downloadExcel = async () => {
    if (!userHasAccess()) {
      console.error("Access denied: You do not have permission to download data.");
      return;
    }

    setIsDownloading(true);

    try {
      let allClients = [];
      let currentNextToken = null;

      do {
        const response = await fetchAllClients({
          variables: { limit: 1000, nextToken: currentNextToken },
          fetchPolicy: "network-only",
        });

        const fetchedClients = response.data.listClientFix2s.items;
        allClients = [...allClients, ...fetchedClients];
        currentNextToken = response.data.listClientFix2s.nextToken;
      } while (currentNextToken);

      if (allClients.length === 0) {
        alert("No clients available to download.");
        setIsDownloading(false);
        return;
      }

      const formattedClients = allClients.map(client => ({
        ID: client.id,
        Name: capitalize(client.name),
        "Last Name": capitalize(client.last_name),
        Email: (client.email || '').toLowerCase(),
        Address: client.address,
        City: client.city,
        County: client.county,
        Zip_Code: client.zip_code,
        "Preferred Language": Array.isArray(client.prefered_lang)
          ? client.prefered_lang.join(', ')
          : (client.prefered_lang || ''),
        // "Insurance Picked": client.insurance_picked,
        // Plan: client.plan,
        "Last Contacted Date": client.last_contacted_date,
        Navigator: client.user
          ? `${client.user.first_name} ${client.user.last_name}`
          : '',
        // "Payment Per Month": client.payment_per_month,
        Phone: formatPhoneNumber(client.phone)
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedClients);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");
      XLSX.writeFile(workbook, "clients.xlsx");
    } catch (error) {
      console.error("Error fetching all clients:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  // Checkbox selection
  const handleCheckboxChange = (clientId) => {
    setSelectedClientIds((prevSelected) => {
      if (prevSelected.includes(clientId)) {
        return prevSelected.filter(id => id !== clientId);
      } else {
        return [...prevSelected, clientId];
      }
    });
  };

  // --------------------------------------
  // DUPLICATE DETECTION
  // --------------------------------------
  const handleFindDuplicates = () => {
    // For example, group by phone
    const mapByPhone = new Map();

    filteredClients.forEach((client) => {
      const phoneKey = (client.phone || '').replace(/\D/g, '');
      if (!phoneKey) return;

      if (!mapByPhone.has(phoneKey)) {
        mapByPhone.set(phoneKey, []);
      }
      mapByPhone.get(phoneKey).push(client);
    });

    const groups = [];
    for (const [phoneNumber, clientsArr] of mapByPhone.entries()) {
      if (clientsArr.length > 1) {
        groups.push({
          groupKey: phoneNumber,
          items: clientsArr,
        });
      }
    }

    setDuplicateGroups(groups);
    setShowDuplicatesModal(true);
  };

  const handleMergeDuplicates = (groupKey, chosenClient, otherClients) => {
    // Implement your merging logic...
    console.log("Merging duplicates for phone:", groupKey);
    console.log("Keep this client:", chosenClient);
    console.log("Remove/merge these:", otherClients);

    // Then close the modal if desired
    setShowDuplicatesModal(false);
    setDuplicateGroups([]);
    // Possibly call `refetch()` to refresh data
  };

  // --------------------------------------
  // Grid Cell Renderers
  // --------------------------------------

  const HeaderCell = ({ columnIndex, style }) => {
    const sortKey = sortKeys[columnIndex];

    const onHeaderClick = () => {
      if (sortKey) {
        handleSort(sortKey);
      }
    };

    // Display small arrow if sorted by this key
    let arrow = '';
    if (sortConfig && sortConfig.key === sortKey) {
      arrow = sortConfig.direction === 'ascending' ? '▲' : '▼';
    }

    return (
      <div
        style={{
          ...style, // Keeps react-window's positioning
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#23395d',
          color: 'white',
          fontWeight: 'bold',
          borderBottom: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          cursor: sortKey ? 'pointer' : 'default',
        }}
        onClick={onHeaderClick}
      >
        {headers[columnIndex]}
        {arrow && <span style={{ marginLeft: '5px' }}>{arrow}</span>}
      </div>
    );
  };

  const DataCell = ({ columnIndex, rowIndex, style }) => {
    const client = filteredClients[rowIndex];

    // Build array of data
    const clientFields = [
      client.id,
      capitalize(client.name),
      capitalize(client.last_name),
      formatPhoneNumber(client.phone),
      client.email ? client.email.toLowerCase() : 'N/A',
      Array.isArray(client.prefered_lang)
        ? client.prefered_lang.join(', ')
        : client.prefered_lang || 'N/A',
      client.address,
      client.city,
      client.county,
      moment(client.last_contacted_date).format('MM-DD-YYYY'),
      client.user
        ? `${capitalize(client.user.first_name)} ${capitalize(client.user.last_name)}`
        : 'N/A',
    ];

    const handleRowClick = () => {
      setSelectedRow(client.id);
      handleEditClient(client);
    };

    // Example alignment logic
    const leftAlignedColumns = [5, 7, 11];
    const alignment = leftAlignedColumns.includes(columnIndex)
      ? 'flex-start'
      : 'center';
    const extraPadding = leftAlignedColumns.includes(columnIndex) ? '10px' : '0';

    // "Select" column (checkbox)
    if (columnIndex === 0) {
      return (
        <div
          style={{
            ...style, // let react-window handle positioning
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '1px solid #ddd',
            borderRight: '1px solid #ddd',
            backgroundColor:
              selectedRow === client.id
                ? '#3f4f69'
                : rowIndex % 2 === 0
                  ? '#f9f9f9'
                  : '#e0e0e0',
          }}
        >
          <input
            type="checkbox"
            checked={selectedClientIds.includes(client.id)}
            onChange={() => handleCheckboxChange(client.id)}
          />
        </div>
      );
    }

    // "ID" column
    if (columnIndex === 1) {
      const idStr = client.id.toString();
      const truncatedId = idStr.length > 17 ? idStr.substring(0, 17) + '...' : idStr;

      return (
        <div
          style={{
            ...style, // let react-window handle positioning
            // **width removed**
            // **height: rowHeight** can remain if you want row height fixed
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #ddd',
            borderRight: '1px solid #ddd',
            backgroundColor:
              selectedRow === client.id
                ? '#3f4f69'
                : rowIndex % 2 === 0
                  ? '#f9f9f9'
                  : '#e0e0e0',
            color: selectedRow === client.id ? 'white' : 'black',
            cursor: 'pointer',
            padding: '0 5px',
          }}
          onClick={handleRowClick}
          className="grid-cell id-cell"
        >
          <span>{truncatedId}</span>
          <span
            className="copy-icon"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard
                .writeText(client.id)
                .then(() => console.log('Client ID copied!'))
                .catch((err) => console.error('Copy failed:', err));
            }}
          >
            <FaCopy />
          </span>
        </div>
      );
    }

    // Other columns
    // Adjust for the fact that our first column is the checkbox
    const fieldData = clientFields[columnIndex - 1];

    return (
      <div
        style={{
          ...style, // let react-window handle positioning
          // **width removed**
          // **height: rowHeight** can remain if you want row height fixed
          display: 'flex',
          alignItems: 'center',
          justifyContent: alignment,
          paddingLeft: extraPadding,
          borderBottom: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          backgroundColor:
            selectedRow === client.id
              ? '#3f4f69'
              : rowIndex % 2 === 0
                ? '#f9f9f9'
                : '#e0e0e0',
          color: selectedRow === client.id ? 'white' : 'black',
          cursor: 'pointer',
        }}
        onClick={handleRowClick}
        className="grid-cell"
      >
        {fieldData}
      </div>
    );
  };

  // --------------------------------------
  // Render
  // --------------------------------------
  return (
    <div className="clients">
      <header className="clients-header">
        <h1>Clients</h1>
      </header>

      <div className="clients-search">
        <input
          type="text"
          placeholder="Client Name, Email, Address"
          value={searchQuery}
          onChange={handleSearch}
        />
        <select onChange={(e) => setSearchField(e.target.value)}>
          <option value="name">Name</option>
          <option value="last_name">Last Name</option>
          <option value="email">Email</option>
          <option value="address">Address</option>
          {/* <option value="insurance_picked">Insurance Picked</option> */}
          <option value="last_contacted_date">Last Contacted Date</option>
          <option value="navigator">Navigator</option>
          {/* <option value="payment_per_month">Payment Per Month</option> */}
          <option value="phone">Phone</option>
          {/* <option value="plan">Plan</option> */}
        </select>
      </div>

      <button
        className="add-user-btn"
        onClick={() => {
          setSelectedClient(null);
          setIsEditing(false);
          setShowClientAddOverlay(true);
        }}
      >
        + Add User
      </button>

      {userHasAccess() && (
        <button className="download-excel-btn" onClick={downloadExcel} disabled={isDownloading}>
          {isDownloading ? <LoadingSpiner /> : "Download Clients Excel"}
        </button>
      )}

      <button
        className="navigate-opt-out-btn"
        onClick={handleNavigateToOptOutClients}
      >
        View Opt-Out Clients
      </button>

      <button
        className="find-duplicates-btn"
        style={{ marginLeft: '10px' }}
        onClick={handleFindDuplicates}
      >
        Detect Duplicates
      </button>

      {/* Header Grid */}
      <div
        className='grid-header'
        style={{ position: 'sticky', top: 0, zIndex: 10, overflowX: 'auto' }}
        ref={headerRef}
      >
        <Grid
          columnCount={columnCount}
          columnWidth={getColumnWidth}          // <- function instead of number
          height={rowHeight}                    // This is the total pixel height of the header
          rowCount={1}
          rowHeight={() => rowHeight}          // function, returning your constant header height
          width={Math.max(gridWidth, columnWidth * columnCount)}
        >
          {HeaderCell}
        </Grid>
      </div>

      {/* Data Grid */}
      <Grid
        columnCount={columnCount}
        columnWidth={getColumnWidth}            // function
        height={gridHeight}
        rowCount={filteredClients.length}
        rowHeight={getRowHeight}               // function
        width={gridWidth}
        onScroll={({ scrollLeft, scrollTop, scrollUpdateWasRequested }) => {
          // Keep your existing sync logic:
          if (headerRef.current) {
            headerRef.current.scrollLeft = scrollLeft;
          }
        }}
      >
        {DataCell}
      </Grid>

      <div className="item-count">
        <p>Total Clients: {filteredClients.length}</p>
      </div>

      {showClientAddOverlay && (
        <div
          className="client_add_overlay"
          onClick={(e) => {
            if (e.target.classList.contains("client_add_overlay")) {
              setShowClientAddOverlay(false);
              setSelectedClient(null);
              setIsEditing(false);
            }
          }}
        >
          <div
            className="overlay-content-clients"
            onClick={(e) => e.stopPropagation()}
          >
            <ClientForm
              isEditing={isEditing}
              selectedClient={selectedClient}
              onClientAdded={() => {
                setShowClientAddOverlay(false);
                setSelectedClient(null);
                setIsEditing(false);
              }}
              onClose={() => {
                setShowClientAddOverlay(false);
                setSelectedClient(null);
                setIsEditing(false);
              }}
              userGroups={userGroups}
              handleDeleteClient={handleDeleteClient}
              refetch={refetch}
            />
          </div>
        </div>
      )}

      {showDuplicatesModal && (
        <DuplicatesModal
          duplicateGroups={duplicateGroups}
          onClose={() => {
            setShowDuplicatesModal(false);
            refetch();
          }}
          onMerge={handleMergeDuplicates}
        />
      )}
    </div>
  );
};

export default ClientsTable;
