import React, { useState } from 'react';
import './principal.css';
import logo from '../../../assets/images/hc_login_logo.png';
import XLSX from 'xlsx-js-style';
import { useQuery } from '@apollo/client';
import { listDeleteEquipmentHistoryFixes, listMonitorFixes, listPhoneFixes, listLaptopFixes, listMouseFixes, listKeyboardFixes, listScannerFixes, listPrinterFixes, listExtensionCordFixes, listPowerBankFixes, listBackupBatteryFixes, listUSBFixes, listDockingStationFixes, listSpeakerFixes, listTabletRemarkableFixes, listHeadsetFixes, listGeneralInventoryFixes } from '../../../graphql/queries';
import { useNavigate } from 'react-router-dom';
import { capitalize } from '../../../components/common/capitalize';


const Inventory_Home = () => {


    // Estado para manejar el valor seleccionado
    const [selectedEquipment, setSelectedEquipment] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    const { data: userData1 } = useQuery(listMonitorFixes);
    const { data: userData2 } = useQuery(listPhoneFixes);
    const { data: userData3 } = useQuery(listLaptopFixes);
    const { data: userData4 } = useQuery(listMouseFixes);
    const { data: userData5 } = useQuery(listKeyboardFixes);
    const { data: userData6 } = useQuery(listScannerFixes);
    const { data: userData7 } = useQuery(listPrinterFixes);
    const { data: userData8 } = useQuery(listExtensionCordFixes);
    const { data: userData9 } = useQuery(listPowerBankFixes);
    const { data: userData10 } = useQuery(listBackupBatteryFixes);
    const { data: userData11 } = useQuery(listUSBFixes);
    const { data: userData12 } = useQuery(listDockingStationFixes);
    const { data: userData13 } = useQuery(listSpeakerFixes);
    const { data: userData14 } = useQuery(listTabletRemarkableFixes);
    const { data: userData15 } = useQuery(listHeadsetFixes);
    const { data: userData16 } = useQuery(listGeneralInventoryFixes);
    const { data: userData17 } = useQuery(listDeleteEquipmentHistoryFixes);


    const navigate = useNavigate();

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Maneja el cambio de selección en el <select> para selectedEquipment
    const handleEquipmentChange = (event) => {
        setSelectedEquipment(event.target.value);
    };

    const handleClick = () => {
        if (selectedEquipment) {
            navigate(`../equipment-${selectedEquipment}`);
        } else if (selectedDate) {
            navigate(`../equipment-report-by-date`, { state: { selectedDate } });
        }
    };

    //  data una vez que las verificaciones de errores y carga se han realizado
    const monitors = React.useMemo(() => (userData1 && userData1.listMonitorFixes ? userData1.listMonitorFixes.items : []), [userData1]);
    const phones = React.useMemo(() => (userData2 && userData2.listPhoneFixes ? userData2.listPhoneFixes.items : []), [userData2]);
    const laptops = React.useMemo(() => (userData3 && userData3.listLaptopFixes ? userData3.listLaptopFixes.items : []), [userData3]);
    const mice = React.useMemo(() => (userData4 && userData4.listMouseFixes ? userData4.listMouseFixes.items : []), [userData4]);
    const keyboards = React.useMemo(() => (userData5 && userData5.listKeyboardFixes ? userData5.listKeyboardFixes.items : []), [userData5]);
    const scanners = React.useMemo(() => (userData6 && userData6.listScannerFixes ? userData6.listScannerFixes.items : []), [userData6]);
    const printers = React.useMemo(() => (userData7 && userData7.listPrinterFixes ? userData7.listPrinterFixes.items : []), [userData7]);
    const extensionCords = React.useMemo(() => (userData8 && userData8.listExtensionCordFixes ? userData8.listExtensionCordFixes.items : []), [userData8]);
    const powerBanks = React.useMemo(() => (userData9 && userData9.listPowerBankFixes ? userData9.listPowerBankFixes.items : []), [userData9]);
    const backupBatteries = React.useMemo(() => (userData10 && userData10.listBackupBatteryFixes ? userData10.listBackupBatteryFixes.items : []), [userData10]);
    const usb = React.useMemo(() => (userData11 && userData11.listUSBFixes ? userData11.listUSBFixes.items : []), [userData11]);
    const dockingStations = React.useMemo(() => (userData12 && userData12.listDockingStationFixes ? userData12.listDockingStationFixes.items : []), [userData12]);
    const speakers = React.useMemo(() => (userData13 && userData13.listSpeakerFixes ? userData13.listSpeakerFixes.items : []), [userData13]);
    const tablets = React.useMemo(() => (userData14 && userData14.listTabletRemarkableFixes ? userData14.listTabletRemarkableFixes.items : []), [userData14]);
    const headset = React.useMemo(() => (userData15 && userData15.listHeadsetFixes ? userData15.listHeadsetFixes.items : []), [userData15]);
    const generalInventory = React.useMemo(() => (userData16 && userData16.listGeneralInventoryFixes ? userData16.listGeneralInventoryFixes.items : []), [userData16]);
    const deleteEquip = React.useMemo(() => (userData17 && userData17.listDeleteEquipmentHistoryFixes ? userData17.listDeleteEquipmentHistoryFixes.items : []), [userData17]);

    const handleAllInventory = async () => {
        try {
            const workbook = XLSX.utils.book_new();

            // Función auxiliar para crear una hoja de trabajo para una categoría específica
            const createWorksheet = (data, title) => {
                // Ordenar los datos por Timestamp en orden ascendente
                const sortedData = [...data].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Preparar datos para exportación con títulos personalizados
                const worksheetData = sortedData.map((item, index) => {

                    const formattedPurchaseDate = item.purchase_date
                        ? new Date(item.purchase_date).toLocaleDateString('en-US') // Cambia el formato a MM/DD/YYYY
                        : '';

                    const rowData = {
                        '#': index + 1,
                        'TAG:': item.tagid || item.tagId,
                        'Brand': item.brand || "",
                        'Serial Number:': item.serial_Number,
                    };

                    // Solo agregar la columna "Type" si el título es "Monitors"
                    if (title === 'Monitors') {
                        rowData['Type:'] = item.monitor_type;
                    }
                    if (title === 'Phones') {
                        rowData['Cell Number:'] = item.cel_number;
                    }
                    if (title === 'Broken Equipment') {
                        rowData['Item Name:'] = item.Item;
                    }
                    rowData['Available:'] = item.available ? 'Yes' : 'No';
                    rowData['Status:'] = item.status || "";
                    rowData['Assigned to:'] = item.user
                    ? `${capitalize(item.user.first_name) || ''} ${capitalize(item.user.last_name) || ''}`.trim() || capitalize(item.previous_employees)
                    : capitalize(item.previous_employees);                  
                    rowData['Price:'] = item.price;
                    rowData['Purchase Date:'] = formattedPurchaseDate;
                    rowData['Team:'] = item.team || "";
                    rowData['Location:'] = item.location;

                    return rowData;
                });

                // Crear la hoja de trabajo y agregar el título
                const worksheet = XLSX.utils.json_to_sheet([]);
                XLSX.utils.sheet_add_aoa(worksheet, [[title]], { origin: 'A1' });

                // Agregar datos a partir de la fila A2
                XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                // Definir el rango de referencia
                const endRow = worksheetData.length + 1;
                const endCol = Object.keys(worksheetData[0]).length - 1;
                worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });

                // Fusionar celdas del título
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: endCol } });


                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Configurar anchos de columna (sin aplicar estilos de celda)
                worksheet['!cols'] = [
                    { wch: 5 }, { wch: 10 }, { wch: 30 }, { wch: 30 }, { wch: 10 },
                    { wch: 10 }, { wch: 30 }, { wch: 10 }, { wch: 30 }, { wch: 5 }, { wch: 20 }, { wch: 25 }, { wch: 25 },

                ];

                return worksheet;
            };


            // Agregar cada categoría al libro de trabajo
            if (laptops.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(laptops, 'Laptops'), 'Laptops');
            if (phones.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(phones, 'Phones'), 'Phones');
            if (monitors.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(monitors, 'Monitors'), 'Monitors');
            if (mice.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(mice, 'Mice'), 'Mice');
            if (keyboards.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(keyboards, 'Keyboards'), 'Keyboards');
            if (scanners.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(scanners, 'Scanners'), 'Scanners');
            if (printers.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(printers, 'Printers'), 'Printers');
            if (extensionCords.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(extensionCords, 'Extension Cords'), 'Extension Cords');
            if (powerBanks.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(powerBanks, 'Power Banks'), 'Power Banks');
            if (backupBatteries.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(backupBatteries, 'Backup Batteries'), 'Backup Batteries');
            if (usb.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(usb, 'USB'), 'USB');
            if (dockingStations.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(dockingStations, 'Docking Stations'), 'Docking Stations');
            if (tablets.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(tablets, 'Tablets'), 'Tablets');
            if (speakers.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(speakers, 'Speakers'), 'Speakers');
            if (headset.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(headset, 'Headsets'), 'Headsets');
            if (generalInventory.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(generalInventory, 'Media && Other Equipment'), 'Media && Other Equipment');
            if (deleteEquip.length > 0) XLSX.utils.book_append_sheet(workbook, createWorksheet(deleteEquip, 'Broken Equipment'), 'Broken Equipment');

            // Exportar el libro de trabajo
            XLSX.writeFile(workbook, 'InventoryData.xlsx');

        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };
    const options = [
        { value: "mouse", label: "Mouse" },
        { value: "keyboard", label: "Keyboard" },
        { value: "monitor", label: "Monitor" },
        { value: "scanner", label: "Scanner" },
        { value: "printer", label: "Printer" },
        { value: "extension-cord", label: "Extension Cord" },
        { value: "phone", label: "Phone" },
        { value: "power-bank", label: "Power Bank" },
        { value: "backup-battery", label: "Backup Battery" },
        { value: "usb", label: "USB Drive" },
        { value: "docking-station", label: "Docking Station" },
        { value: "speaker", label: "Speaker" },
        { value: "laptop", label: "Laptop" },
        { value: "tablet-remarkable", label: "Tablet && Remarkable" },
        { value: "headset", label: "Headset" },
        { value: "projector", label: "Projector" },
        { value: "external-disk", label: "External Disk" },
        { value: "other-equipment", label: "Media && Other Equipment" },
        { value: "bag", label: "Bag" },
        { value: "click-counter", label: "Click Counter" },
        { value: "office-supplies", label: "Office Supplies" },
    ];

    // Ordenar las opciones alfabéticamente por etiqueta
    const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));


    return (


        <div className="general-container">
            <header className="principal-container">
                <h1>Inventory Home</h1>
                <div className="logo-container" >

                    <img src={logo} alt="logo" className="logo" />
                </div>
            </header>


            <div className="principal-box">

                <section id="employees" className="principal-form">
                    <div id="employee-header" className="employee-header">
                        <h2>Employees:</h2>
                        <div id="employee-content" className="employee-content">
                            <button className="add-inventory-btn" onClick={() => navigate('../inventory-employees')}>+ Add/Edit Employee</button>
                            <button className="add-inventory-btn" onClick={() => navigate('../inventory-previous-employees')}> Previous  Employees</button>
                        </div>
                    </div>
                </section>

                <section id="equipment" className="principal-form">
                    <h2>Equipment:</h2>
                    <select id="equipment-select" className="select-box" value={selectedEquipment} onChange={handleEquipmentChange}>
                        <option value="" disabled>Select Equipment</option>
                        {sortedOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <button className="add-inventory-btn" onClick={handleClick} disabled={!selectedEquipment}>+ Add / Edit Equipment </button>
                    <button className="add-inventory-btn" onClick={() => navigate('../inventory-removed-equipment')}> Equipment Removed From Inventory:</button>
                    <button className="add-inventory-btn" onClick={handleAllInventory}>Export All Inventory to Excel:</button>
                </section>

                <section id2="all" className="principal-form">
                    <h2>Inventory Report:</h2>

                    <select id="selected-Date" value={selectedDate} className="select-box" onChange={handleDateChange}>
                        <option value="" disabled>Type Date</option>
                        <option value="purchase_date"> Purchase Date</option>
                        <option value="returnable_date"> Returnable Date</option>
                        <option value="warranty_date">Warranty Date</option>

                    </select>
                    <button className="add-inventory-btn" onClick={handleClick} disabled={!selectedDate}>Create Inventory Report by dates:</button>


                </section>


            </div>
        </div>

    )


};

export default Inventory_Home;
