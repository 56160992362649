import { fetchAuthSession } from 'aws-amplify/auth';

export const invokeLambdaNotification = async (userId) => {
  // Base URL for your API Gateway endpoint
  // (Make sure the stage name 'compassdev' is correct and matches your environment)
  const url = `https://vyp8pz77f9.execute-api.us-east-2.amazonaws.com/compassdev/notifications?userID=${userId}`;

  try {
    // Get the current authentication session (this may vary depending on your Amplify setup)
    const session = await fetchAuthSession();

    // Verify we have a valid token
    if (!session || !session.tokens || !session.tokens.idToken) {
      throw new Error('User is not authenticated or token is missing.');
    }

    // Prepare the ID token for the Authorization header
    const idToken = session.tokens.idToken.toString();

    // Make the GET request to your Lambda via API Gateway
    // Note: GET requests typically do not have a body, so we'll remove that
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
    });

    // Check for a successful response
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to fetch notifications. Error: ${errorText}`);
    }

    // Parse the JSON response
    const data = await response.json();

    // According to your Lambda, data should have { unreadCount: number }
    console.log('Lambda function response:', data);

    // You can display the unread count in any way you prefer; for example:
    if (data.unreadCount > 0)
    alert(`You have ${data.unreadCount} unread notifications.`);

    // Optionally return this number so the caller can use it
    return data.unreadCount;
  } catch (error) {
    console.error('Error invoking Lambda:', error);
    alert('Failed to fetch notifications.');
    // You might also rethrow or return an error indicator
    return null;
  }
};
