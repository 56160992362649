import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { listDeleteEquipmentHistoryFixes, listSuppliesFixes, listUsers } from '../../../graphql/queries';
import { createDeleteEquipmentHistoryFix, createSuppliesFix, deleteSuppliesFix, updateSuppliesFix } from '../../../graphql/mutations';
import './Office_Supplies.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import Inventory_header from '../../../components/layout/Inventory_header';
import { capitalize } from '../../../components/common/capitalize';

const Office_Supplies = () => {

    const [supplies, setSupplies] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showSuppliesAddOverlay, setShowSuppliesAddOverlay] = useState(false);
    const [showInkAddOverlay, setShowInkAddOverlay] = useState(false);
    const [selectedSupplies, setSelectedSupplies] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [quantity, setQuantity] = useState(1); // Cantidad por defecto

    const [newSupplies, setNewSupplies] = useState({

        id: '',
        name: '',
        brand: '',
        no: '',
        color: '',
        tagId: '',
        price: '',
        store: '',
        purchase_date: '',
        assignment_date: '',
        available: true,
        assigned_to: '',
        location: '',
        team: '',
        userId: '',
    });

    const [sortConfig, setSortConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [InkError, setInkError] = useState('');
    const [InkNameError, setInkNameError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);


    const handleSaveAsExcel = async () => {
        try {
            const suppliesToExport = filteredSuppliess.length > 0 ? filteredSuppliess : supplies;

            if (suppliesToExport.length > 0) {
                const sortedsupplies = [...suppliesToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                const worksheetData = sortedsupplies.map((supplies, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG': supplies.tagId,
                    'Name': supplies.name,
                    'Assigned To': supplies.user?.first_name
                        ? supplies.user?.first_name +
                        (supplies.user?.last_name ? " " + supplies.user?.last_name : "")
                        : "N/A",
                    'Available': supplies.available ? 'Yes' : 'No',
                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Office Supplies']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Width for Row Number
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 25 },
                    { wch: 10 },

                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'supplies');

                // Export the file
                XLSX.writeFile(workbook, 'suppliesData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };


    const handleEditSupplies = (Supplies) => {
        setSelectedSupplies(Supplies);
        setIsEditing(true);
        setShowSuppliesAddOverlay(true);
        setShowInkAddOverlay(false);
    };


    const { loading, data, fetchMore } = useQuery(listSuppliesFixes, {
        variables: { limit: 100 },
    });

    const [addSupplies] = useMutation(createSuppliesFix, {
        refetchQueries: [{ query: listSuppliesFixes, variables: { limit: 100 } }]
    });

    const [removeSupplies] = useMutation(deleteSuppliesFix, {
        refetchQueries: [{ query: listSuppliesFixes, variables: { limit: 100 } }]
    });
    const [updSupplies] = useMutation(updateSuppliesFix, {
        refetchQueries: [{ query: listSuppliesFixes, variables: { limit: 100 } }]
    });
    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistoryFix, {
        refetchQueries: [{ query: listDeleteEquipmentHistoryFixes, variables: { limit: 100 } }]
    });


    useEffect(() => {
        const fetchAllSupplies = async () => {
            let AllSuppliess = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllSuppliess = [...AllSuppliess, ...data.listSuppliesFixes.items];
                currentNextToken = data.listSuppliesFixes.nextToken;
            } while (currentNextToken);

            setSupplies(AllSuppliess);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllSupplies();
        }
    }, [data, fetchMore]);

    const clearNewSupplies = () => {
        setNewSupplies({
            id: '',
            name: '',
            brand: '',
            no: '',
            color: '',
            tagId: '',
            price: '',
            store: '',
            purchase_date: '',
            assignment_date: '',
            available: true,
            assigned_to: '',
            location: '',
            team: '',
            userId: '',

        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listSuppliesFixes.nextToken);
                return {
                    listSuppliesFixes: {
                        ...fetchMoreResult.listSuppliesFixes,
                        items: [...prevResult.listSuppliesFixes.items, ...fetchMoreResult.listSuppliesFixes.items]
                    }
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (isEditing) {
            setSelectedSupplies((prev) => ({
                ...prev,
                [name]: formattedValue,

            }));
        } else {
            setNewSupplies((prev) => ({
                ...prev,
                id: prev.id || uuidv4(), // Genera id solo si no existe
                [name]: formattedValue,

            }));
        }
    };

    const findUserFullNameByID = (userID) => {
        if (!userID) return null;

        const userIDString = userID.toString().trim();

        if (!Array.isArray(userData?.listUserFixes?.items)) return null;
        const user = userData?.listUserFixes?.items.find((u) => u.id === userIDString);
        return user ? `${user.first_name} ${user.last_name}` : null;
    };

    //Filter
    const filteredSuppliess = useFilteredData(supplies || [], searchTerm, sortConfig, searchField, userData?.listUserFixes?.items);


    const filterFieldsForMutation = (supply) => {
        const allowedFields = ["name", "price", "tagId", "store", "purchase_date", "assignment_date", "available", "assigned_to", "location", "team", "userId"];

        return Object.keys(supply)
            .filter((key) => allowedFields.includes(key) && supply[key] !== "")
            .reduce((obj, key) => {
                obj[key] = key === "userId" ? supply[key] || "n/a" : supply[key]; // Si userId es null, usa "n/a"
                return obj;
            }, {});
    };



    const handleAddSupplies = (overlay) => {

        const formattedName =
            (newSupplies.brand || newSupplies.no || newSupplies.color) // Verifica si alguno existe
                ? [newSupplies.brand, newSupplies.no, newSupplies.color] // Usa un array para manejar mejor los valores
                    .filter(Boolean) // Elimina valores `undefined` o `""`
                    .join(" ") + " Ink" // Une los valores con espacio y agrega "Ink"
                : newSupplies.name; // Si no existen, usa `name`



        const formattedPurchase = newSupplies.purchase_date
            ? new Date(newSupplies.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newSupplies.assignment_date
            ? new Date(newSupplies.assignment_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newSupplies.price !== "" && newSupplies.price !== null && newSupplies.price != undefined) {
            priceValue = parseFloat(newSupplies.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');


        // Limpia errores previos
        setInkError('');
        setInkNameError('');

        console.log('The fields Brand, Number, and Color are all required.')
        // Validar según el overlay activo
        if (overlay === 1) {
            // Validar Overlay 1: Brand, No, Color
            if (
                (!newSupplies.brand?.trim() && !newSupplies.no?.trim() && !newSupplies.color?.trim()) // Todos los campos están vacíos
            ) {
                console.log('All fields are empty.');
                setInkError('The fields Brand, Number, and Color cannot all be empty.');
                return; // Detener ejecución
            }

            if (
                (newSupplies.brand?.trim() || newSupplies.no?.trim() || newSupplies.color?.trim()) && // Alguno tiene valor
                (!newSupplies.brand?.trim() || !newSupplies.no?.trim() || !newSupplies.color?.trim()) // Alguno está vacío
            ) {
                console.log('The fields Brand, Number, and Color are all required.');
                setInkError('The fields Brand, Number, and Color are all required.');
                return; // Detener ejecución
            }


        } else if (overlay === 2) {
            // Validar Overlay 2: Name
            if (!newSupplies.name?.trim()) {
                setInkNameError('The field Name is required.');
                return; // Detener ejecución
            }
        }

        console.log('All validations passed for overlay', overlay);
        // Aquí continúa tu lógica para guardar los datos

        // Multiplicada fila por la variable quantity
        if (!Number.isInteger(quantity) || quantity < 1) {
            alert("Please enter a valid quantity (a whole number greater than 0).");
            return;
        }

        // Crear un array con tantos objetos como la cantidad indicada
        const safeUserId = newSupplies.userId && newSupplies.userId.trim() !== "" ? newSupplies.userId : "n/a";

        const suppliesToAdd = Array.from({ length: quantity }, (_, index) => ({
            ...newSupplies,
            price: priceValue,
            name: formattedName,
            purchase_date: formattedPurchase,
            assignment_date: formattedAssignment,
            id: `${newSupplies.id || "supply"}-${index}-${Date.now()}`, // Generar ID único
            userId: safeUserId,
        }));

        // Filtrar campos no permitidos
        const filteredSupplies = suppliesToAdd.map(filterFieldsForMutation);

        console.log("Items to insert:", filteredSupplies.length, filteredSupplies);

        // Insertar en la base de datos
        Promise.allSettled(
            filteredSupplies.map((supply) =>
                addSupplies({
                    variables: { input: supply },
                })
            )
        )
            .then(() => {
                setQuantity(1);
                clearNewSupplies();
                setQuantity(1);
                alert("The Supplies was an added success")
                setShowSuppliesAddOverlay(false);
                setShowInkAddOverlay(false);
            })
            .catch((error) => {
                console.error("Error adding supplies:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };
    const handleDeleteSupplies = async () => {
        try {
            // Remover campos innecesarios
            const SuppliesData = removeUnnecessaryFields({ ...selectedSupplies }, [

                'team',
                'no',

            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'name',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
                'userId',
            ];

            // Filtrar los campos permitidos
            const cleanedNewSupplies = Object.fromEntries(
                Object.entries(SuppliesData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewSupplies);

            // Normalizar claves alternativas
            cleanedNewSupplies.tagId = cleanedNewSupplies.tagId || cleanedNewSupplies.tagid;
            delete cleanedNewSupplies.tagid;

            cleanedNewSupplies.previous_employees =
                cleanedNewSupplies.previous_employees || cleanedNewSupplies.assigned_to;
            delete cleanedNewSupplies.assigned_to;

            cleanedNewSupplies.userId =
                cleanedNewSupplies.userId || cleanedNewSupplies.assigned_to;
            delete cleanedNewSupplies.assigned_to;

            cleanedNewSupplies.Item =
                cleanedNewSupplies.Item || cleanedNewSupplies.name;
            delete cleanedNewSupplies.name;

            // Eliminar valores vacíos
            Object.keys(cleanedNewSupplies).forEach((key) => {
                if (cleanedNewSupplies[key] === "" || cleanedNewSupplies[key] === null) {
                    delete cleanedNewSupplies[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewSupplies },
            });

            // Eliminar el Supplies
            await removeSupplies({
                variables: { input: { id: cleanedNewSupplies.id } },
            });

            // Resetear estados
            setShowSuppliesAddOverlay(false);
            setIsEditing(false);
            setSelectedSupplies(null);

        } catch (error) {
            console.error("Error deleting Supplies", error);
        }
    };

    const handleUpdateSupplies = async () => {

        const formattedData_purchase = selectedSupplies.purchase_date ? new Date(selectedSupplies.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedSupplies.assignment_date ? new Date(selectedSupplies.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedSupplies.price !== "" && selectedSupplies.price !== null && selectedSupplies.price != undefined) {
            priceValue = parseFloat(selectedSupplies.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        updSupplies({
            variables: {
                input: {
                    id: selectedSupplies.id,
                    name: selectedSupplies.name,
                    tagId: selectedSupplies.tagId,
                    price: priceValue,
                    store: selectedSupplies.store,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    available: selectedSupplies.available,
                    assigned_to: "",
                    location: selectedSupplies.location,
                    team: selectedSupplies.team,
                    userId: selectedSupplies.userId,
                }
            }
        }).then(() => {
            setIsEditing(true);
            setShowSuppliesAddOverlay(false);
            setShowInkAddOverlay(false);
            setPriceError('');
            setInkError('');
        }).catch(error => {
            console.error("Error updating Supplies:", error);
        });
    };



    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    //listUserFixes 
    const UsersOptions = userData?.listUserFixes?.items
        ?.filter((u) => u && u.first_name && u.last_name)
        ?.map((u) => ({
            id: u.id,
            name: `${u.first_name.charAt(0).toUpperCase() + u.first_name.slice(1).toLowerCase()} 
           ${u.last_name.charAt(0).toUpperCase() + u.last_name.slice(1).toLowerCase()}`
        }))
        ?.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' })) || [];

    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    return (
        <div className="supplies-page" >
            <header className="inventory-header">
                <h1>Office Supplies</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>
            </div>

            <div className="searchBar">
                <button className="add-supplies-btn" onClick={() => {
                    clearNewSupplies();
                    setSelectedSupplies(null);
                    setIsEditing(false);
                    setShowSuppliesAddOverlay(true);
                    setShowInkAddOverlay(false);
                }}>+ Add New Office Supplies</button>
                <button className="add-supplies-btn" onClick={() => {
                    clearNewSupplies();
                    setSelectedSupplies(null);
                    setIsEditing(false);
                    setShowSuppliesAddOverlay(false);
                    setShowInkAddOverlay(true);
                }}>+ Add New Ink</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="supplies-table-container">
                <table className="supplies-table">

                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}
                            <th onClick={() => requestSort('name')}>
                                Name
                                {sortConfig?.key === 'name' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('tagId')}>
                                TAG
                                {sortConfig?.key === 'tagId' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('userId')}>
                                Assigned to
                                {sortConfig?.key === 'userId' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSuppliess.map((supplies, index) => {

                            const userFullName = findUserFullNameByID(supplies.userId);

                            return (
                                <tr key={supplies.id} onClick={() => handleEditSupplies(supplies)} >
                                    <td>{index + 1}</td> {/* Número de fila */}
                                    <td className="supplies-name">{supplies.name}</td>
                                    <td className="supplies-tagId">{supplies.tagId || 'N/A'}</td>
                                    <td className="supplies-price">${formatPrice(supplies.price)}</td>
                                    <td className="supplies-store">{supplies.store}</td>
                                    <td className="supplies-purchase_date">{supplies.purchase_date ? formatDate(supplies.purchase_date) : 'No Date'}</td>
                                    <td className="supplies-available">{supplies.available ? 'Yes' : 'No'} </td>
                                    <td className={`userId ${searchField === 'userId' ? 'highlight' : ''}`}>
                                        {userFullName ? capitalize(userFullName) : "N/A"}
                                    </td>
                                    <td className="supplies-assignment_date">{supplies.assignment_date ? formatDate(supplies.assignment_date) : 'No Date'}</td>
                                    <td className="supplies-location">{supplies.location}</td>
                                    <td className="supplies-team">{supplies.team}</td>

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


          

            {showSuppliesAddOverlay && (

                <div className="supplies_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("supplies_add_overlay")) {
                        setShowSuppliesAddOverlay(false);
                        clearNewSupplies();
                        setSelectedSupplies(null);
                        setIsEditing(false);
                        setInkNameError('');
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Office Supplies' : 'Add New Office Supplies'}</h2>
                            <button onClick={() => {
                                setShowSuppliesAddOverlay(false);
                                clearNewSupplies();
                                setSelectedSupplies(null);
                                setIsEditing(false);
                                setInkNameError('');
                            }}>X</button>
                        </div>
                        <label>Name:</label><input name="name" placeholder="name" value={isEditing ? selectedSupplies.name : newSupplies.name} onChange={handleInputChange} required />
                        <label>TAG:</label><input name="tagId" placeholder="TAG" value={isEditing ? selectedSupplies.tagId : newSupplies.tagId} onChange={handleInputChange} />
                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedSupplies.price : newSupplies.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedSupplies.store : newSupplies.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedSupplies.purchase_date : newSupplies.purchase_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="quantity">Quantity:</label>

                        <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            placeholder="Enter quantity"
                            min="1"
                            defaultValue="1"
                            onChange={(e) => setQuantity(Number(e.target.value))}
                        />

                        <label htmlFor="assignment_date">Assigned Date:</label>
                        <input
                            type="Date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedSupplies.assignment_date : newSupplies.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedSupplies.available : newSupplies.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Assigned To:</label>
                        <select
                            name="userId"
                            value={isEditing ? selectedSupplies.userId : newSupplies.userId}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled>
                                Select an employee
                            </option>

                            <option value="n/a">N/A</option>
                            {UsersOptions.length > 0 ? (
                                UsersOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>No employees available</option>
                            )}


                        </select>
                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedSupplies.location : newSupplies.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedSupplies.team : newSupplies.team} onChange={handleInputChange} />
                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {InkNameError && <p className="error">{InkNameError}</p>}

                        <div className="button-group">
                            <button
                                className="overlay-add-supplies"
                                onClick={isEditing ? handleUpdateSupplies : () => handleAddSupplies(2)} // Usa una función anónima
                            >
                                {isEditing ? 'Update Office Supplies' : 'Add Office Supplies'}
                            </button>

                            {isEditing && (
                                <button
                                    className="overlay-delet-supplies"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this supplies?')) {
                                            handleDeleteSupplies();
                                        }
                                    }}
                                >
                                    Delete Office Supplies
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showInkAddOverlay && (

                <div className="supplies_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("supplies_add_overlay")) {
                        setShowSuppliesAddOverlay(false);
                        setShowInkAddOverlay(false);
                        clearNewSupplies();
                        setSelectedSupplies(null);
                        setIsEditing(false);
                        setInkError('');
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Ink' : 'Add New Ink'}</h2>
                            <button onClick={() => {
                                setShowSuppliesAddOverlay(false);
                                setShowInkAddOverlay(false);
                                clearNewSupplies();
                                setSelectedSupplies(null);
                                setIsEditing(false);
                                setInkError('');
                            }}>X</button>
                        </div>

                        <label>Brand:</label>
                        <input
                            name="brand"
                            placeholder="HP, EPSON, Canon.."
                            value={isEditing ? selectedSupplies.brand : newSupplies.brand}
                            onChange={handleInputChange}
                            required
                        />
                        <label>Number:</label>
                        <input
                            name="no"
                            placeholder="62 XL, 64, 245.."
                            value={isEditing ? selectedSupplies.no : newSupplies.no}
                            onChange={handleInputChange}
                            required
                        />
                        <label>Color:</label>
                        <select
                            name="color"
                            placeholder="color"
                            value={isEditing ? selectedSupplies.color : newSupplies.color}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" disabled hidden>Select a Color</option>
                            <option value="Black">Black</option>
                            <option value="Tricolor">Tri-Color</option>
                            <option value="Black-tricolor">Black & Tri-Color</option>
                            <option value="Magenta">Magenta</option>
                            <option value="Cyan">Cyan</option>
                            <option value="Yellow">Yellow</option>
                            <option value="Black-cmyk">Black, Cyan, Magenta & Yellow</option>
                        </select>


                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedSupplies.price : newSupplies.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedSupplies.store : newSupplies.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedSupplies.purchase_date : newSupplies.purchase_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="quantity">Quantity:</label>

                        <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            placeholder="Enter quantity"
                            min="1"
                            defaultValue="1"
                            onChange={(e) => setQuantity(Number(e.target.value))}
                        />


                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedSupplies.available : newSupplies.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedSupplies.location : newSupplies.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedSupplies.team : newSupplies.team} onChange={handleInputChange} />
                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {InkError && <p className="error">{InkError}</p>}


                        <div className="button-group">
                            <button
                                className="overlay-add-supplies"
                                onClick={isEditing ? handleUpdateSupplies : () => handleAddSupplies(1)} // Usa una función anónima
                            >
                                {isEditing ? 'Update Ink' : 'Add Ink'}
                            </button>

                            {isEditing && (
                                <button
                                    className="overlay-delet-supplies"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this Ink?')) {
                                            handleDeleteSupplies();
                                        }
                                    }}
                                >
                                    Delete Ink
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );

};

export default Office_Supplies;
