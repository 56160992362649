import React, { useState, useEffect, useRef } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import dayjs from 'dayjs';
import './ShiftModal.css';

const ShiftModal = ({
  isOpen,
  onClose,
  onSave,
  onSaveTemplateShifts, // optional multi-shift handling
  shift,
  locations,
  navigators,
  onDelete,  // We'll call this if user clicks "Delete"
  templates = [],
}) => {
  const modalRef = useRef(null);

  // Single Shift States
  const [navigatorId, setNavigatorId] = useState('');
  const [startTime, setStartTime] = useState('08:00');
  const [endTime, setEndTime] = useState('16:00');
  const [breakStart, setBreakStart] = useState('12:00');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [isTemporary, setIsTemporary] = useState(false);

  // Edit vs Create
  const isEditing = !!shift?.id;

  // Tabs: Single or Multi
  const [activeTab, setActiveTab] = useState('singleShift');

  // Multi Shift States (if you have template usage)
  const [selectedTemplate, setSelectedTemplate] = useState('');

  // User Group / Permissions
  const [userGroup, setUserGroup] = useState([]);

  const isEditable = Array.isArray(userGroup) &&
    userGroup.some((group) => ['Admin', 'Manager'].includes(group));

  const initializeShiftState = (shift) => {
    setNavigatorId(shift?.userId || shift?.resourceId || '');
    setStartTime(shift?.startTime || '08:00');
    setEndTime(shift?.endTime || '16:00');
    setBreakStart(shift?.breakStart || '12:00');
    setLocation(shift?.location || '');
    setStartDate(shift?.date || dayjs().format('YYYY-MM-DD'));
    setIsTemporary(shift?.isTemporary || false);
  };

  // Fetch user groups on mount
  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const session = await fetchAuthSession();
        const groups = session?.tokens?.accessToken?.payload['cognito:groups'] || [];
        setUserGroup(groups);
      } catch (error) {
        console.error('Error fetching user groups:', error);
        setUserGroup([]);
      }
    };
    fetchUserGroup();
  }, []);

  // Populate or clear the form whenever `shift` changes

  useEffect(() => {
    initializeShiftState(shift);
  }, [shift]);

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('shift-modal-overlay')) {
      onClose();
    }
  };

  // CREATE SHIFT
  const handleCreate = () => {
    if (!navigatorId || !startTime || !endTime || !breakStart) {
      alert('Please fill in all fields.');
      return;
    }
    // Check if typed location is in DB
    const foundLocation = locations.find((loc) => loc.name === location);
    const tempFlag = !foundLocation; // true if user typed a custom location
    const newShift = {
      date: dayjs(startDate).format('YYYY-MM-DD'),
      startTime,
      endTime,
      breakStart,
      breakEnd: dayjs(`${startDate}T${breakStart}`).add(1, 'hour').format('HH:mm'),
      title: `Shift for Navigator ${navigatorId}`,
      location,
      resourceId: navigatorId,
      isTemporary: tempFlag,
    };
    onSave(newShift);
  };

  // UPDATE SHIFT
  const handleUpdate = () => {
    if (!navigatorId || !startTime || !endTime || !breakStart) {
      alert('Please fill in all fields.');
      return;
    }
    const foundLocation = locations.find((loc) => loc.name === location);
    const tempFlag = !foundLocation;

    const updatedShift = {
      id: shift.id, // keep existing ID
      date: dayjs(startDate).format('YYYY-MM-DD'),
      startTime,
      endTime,
      breakStart,
      breakEnd: dayjs(`${startDate}T${breakStart}`).add(1, 'hour').format('HH:mm'),
      title: shift.title || `Shift for Navigator ${navigatorId}`,
      location,
      resourceId: navigatorId,
      isTemporary: tempFlag,
    };
    onSave(updatedShift);
  };

  // MULTI SHIFT (using a template)
  const handleMultiShiftSubmit = () => {
    if (!navigatorId || !startDate || !selectedTemplate) {
      alert('Please select a navigator, start date, and template.');
      return;
    }
    const template = templates.find((t) => t.id === selectedTemplate);

    console.log(templates);
    if (!template) {
      alert('Selected template not found.');
      return;
    }
    onSaveTemplateShifts?.(navigatorId, startDate, template, location);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="shift-modal-overlay" onClick={handleOverlayClick}>
      <div className="shift-modal" ref={modalRef}>
        <div className="shift-modal-tabs">
          <button
            className={activeTab === 'singleShift' ? 'active' : ''}
            onClick={() => setActiveTab('singleShift')}
          >
            Single Shift
          </button>
          <button
            className={activeTab === 'multiShift' ? 'active' : ''}
            onClick={() => setActiveTab('multiShift')}
          >
            Multi Shift
          </button>
        </div>

        {activeTab === 'singleShift' && (
          <div className="tab-content">
            <h3>{isEditing ? 'Edit Shift' : 'Create Shift'}</h3>

            {/* Navigator Selector */}
            <div className="modal-row">
              <label htmlFor="navigator-select">Navigator</label>
              <select
                id="navigator-select"
                value={navigatorId}
                onChange={(e) => setNavigatorId(e.target.value)}
              >
                <option value="" disabled>
                  Select Navigator
                </option>
                {navigators.map((nav) => (
                  <option key={nav.id} value={nav.id}>
                    {nav.title}
                  </option>
                ))}
              </select>
            </div>

            {/* Start + End DateTime */}
            <div className="modal-row modal-row-split">
              <div>
                <label htmlFor="start-time-picker">Start DateTime</label>
                <input
                  type="datetime-local"
                  id="start-time-picker"
                  value={`${dayjs(startDate).format('YYYY-MM-DD')}T${startTime}`}
                  onChange={(e) => {
                    const [date, time] = e.target.value.split('T');
                    setStartDate(date);
                    setStartTime(time);
                  }}
                  disabled={!isEditable}
                />
              </div>
              <div>
                <label htmlFor="end-time-picker">End DateTime</label>
                <input
                  type="datetime-local"
                  id="end-time-picker"
                  value={`${dayjs(startDate).format('YYYY-MM-DD')}T${endTime}`}
                  onChange={(e) => {
                    const [date, time] = e.target.value.split('T');
                    setStartDate(date);
                    setEndTime(time);
                  }}
                  disabled={!isEditable}
                />
              </div>
            </div>

            {/* Break + Location */}
            <div className="modal-row modal-row-split">
              <div>
                <label htmlFor="break-start-picker">Break Start</label>
                <input
                  type="time"
                  id="break-start-picker"
                  value={breakStart}
                  onChange={(e) => setBreakStart(e.target.value)}
                  disabled={!isEditable}
                />
              </div>
              <div>
                <label htmlFor="location-input">Location</label>
                <input
                  id="location-input"
                  type="text"
                  placeholder="Type or pick a location"
                  list="locationOptions"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  disabled={!isEditable && !isEditing}
                />
                {/* A datalist for convenience if you want suggestions */}
                <datalist id="locationOptions">
                  {locations.map((loc) => (
                    <option key={loc.id} value={loc.name}>
                      {loc.name}
                    </option>
                  ))}
                </datalist>
              </div>
            </div>

            {/* Buttons */}
            <div className="modal-row modal-buttons">
              {/* If not editing => show "Save" (create new) */}
              {!isEditing && (
                <button onClick={handleCreate}>Save</button>
              )}
              {/* If editing => show "Update" & optional "Delete" */}
              {isEditable && isEditing && (
                <>
                  <button onClick={handleUpdate}>Update</button>
                  {onDelete && (
                    <button
                      onClick={() => {
                        onDelete();
                      }}
                    >
                      Delete
                    </button>
                  )}
                </>
              )}
              <button onClick={onClose}>Cancel</button>
            </div>
          </div>
        )}

        {activeTab === 'multiShift' && (
          <div className="tab-content">
            <h3>Create Multi Shift with Template</h3>

            {/* Navigator Selector */}
            <div className="modal-row">
              <label htmlFor="navigator-select">Navigator</label>
              <select
                id="navigator-select"
                value={navigatorId}
                onChange={(e) => setNavigatorId(e.target.value)}
              >
                <option value="" disabled>
                  Select Navigator
                </option>
                {navigators.map((nav) => (
                  <option key={nav.id} value={nav.id}>
                    {nav.title}
                  </option>
                ))}
              </select>
            </div>

            {/* Start Date */}
            <div className="modal-row">
              <label htmlFor="start-date-picker">Start Date</label>
              <input
                type="date"
                id="start-date-picker"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            {/* Template Picker */}
            <div className="modal-row">
              <label htmlFor="template-select">Template</label>
              <select
                id="template-select"
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                <option value="" disabled>
                  Select Template
                </option>
                {templates.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Location Picker (for multi shift) */}
            <div className="modal-row">
              <label htmlFor="multi-location-input">Location</label>
              <input
                id="multi-location-input"
                type="text"
                placeholder="Type or pick a location"
                list="locationOptions"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
              <datalist id="locationOptions">
                {locations.map((loc) => (
                  <option key={loc.id} value={loc.name}>
                    {loc.name}
                  </option>
                ))}
              </datalist>
            </div>

            {/* Save + Cancel */}
            <div className="modal-buttons">
              <button onClick={handleMultiShiftSubmit}>Save Shifts</button>
              <button onClick={onClose}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShiftModal;
